import { Component, Input, ViewChild, EventEmitter } from "@angular/core"
import { GeneradorInstrumento, Asignaturas, contestableTiposNombreKeys } from "@puntaje/nebulosa/api-services"
import { Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AllReadyService } from "@puntaje/puntaje/new-modules/shared"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "pruebas-propias-por-generador",
    templateUrl: "pruebas-propias-por-generador.component.html",
    styleUrls: ["pruebas-propias-por-generador.component.scss"]
})
export class PruebasPropiasPorGeneradorComponent {
    @Input() generadorInstrumento: GeneradorInstrumento
    @Input() isExpandable: boolean = true
    @Input() evaluacionTipo: string
    instrumentos: Instrumento[]
    instrumento: Instrumento
    public totalInstrumentos: number = 0
    allReadyKey: string = "historial-pruebas-propias"
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    openModal: EventEmitter<any> = new EventEmitter<any>()
    contestableTiposNombreKeys: any = contestableTiposNombreKeys

    constructor(
        protected asignaturasService: Asignaturas,
        protected instrumentosService: Instrumentos,
        protected authService: AuthService,
        protected allreadyservice: AllReadyService
    ) {}

    showAll(page: number = 1, count: number = 20) {
        if (this.loadingLayout) this.loadingLayout.standby()
        let params = {
            page: page,
            per: count,
            sort_by: "created_at",
            order: "desc",
            instrumento: {
                usuario_id: this.authService.getUserData().id,
                propio: 1,
                generador_instrumento_id: this.generadorInstrumento.id
            },
            include: "[instrumento_material]",
            methods: "[material]"
        }
        return this.instrumentosService.where(params).then((response: Instrumento[], total: number) => {
            this.instrumentos = response
            this.totalInstrumentos = total
            this.allreadyservice.updateCounterWithKey(this.allReadyKey)
            if (this.loadingLayout) this.loadingLayout.ready()
            return total
        })
    }

    compartirInstrumento(instrumento: Instrumento) {
        this.instrumento = instrumento
        setTimeout(() => {
            this.openModal.emit()
        }, 100)
    }
}
