import { Component, Input, Directive, ElementRef, HostBinding, ContentChild } from "@angular/core"

@Directive({
    selector: "[target-select]"
})
export class TargetSelectDirective {
    @Input() @HostBinding("disabled") disabled: boolean
    el: HTMLElement
    constructor(elRef: ElementRef) {
        this.el = elRef.nativeElement
    }
}

@Component({
    selector: "select-with-loader",
    template: `
        <ng-content></ng-content>
        <div class="loader-select" *ngIf="showLoader"></div>
    `,
    styleUrls: ["select-with-loader.component.scss"]
})
export class SelectWithLoaderComponent {
    showLoader: boolean = false
    loadWhenOptionsUndefined: boolean = true
    @ContentChild(TargetSelectDirective) select: TargetSelectDirective
    _options: any[]

    @Input()
    set options(options: any[]) {
        this._options = options
        if (this.loadWhenOptionsUndefined) {
            this.toggleLoader(this._options == undefined)
        }
    }
    get options() {
        return this._options
    }

    constructor() {}

    toggleLoader(value: boolean) {
        this.showLoader = value
        if (this.select) this.select.disabled = this.showLoader
    }
}
