<ll-over-title>
    <site-warning *ngIf="enableSiteWarning" [warningText]="warningText"></site-warning>
</ll-over-title>
<ll-titulo>{{ "puntaje_core.plan_clases.titulo" | t }}</ll-titulo>
<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas
        [showTitleOverMenu]="false"
        [onlyWithPlanesClase]="true"
    ></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>
<cui-tabs justifyContent="flex-start" [useTabActive]="true">
    <cui-tab
        title="{{ 'puntaje_core.plan_clases.tab_planes_alumnos' | t }}"
        (select)="selectTab('plan_clases_alumnos')"
        [active]="selectedTab == 'plan_clases_alumnos'"
    >
        <div class="form-group">
            <div class="margin">
                <plan-clases-alumnos
                    *ngIf="generadorInstrumentos"
                    [planClaseRoute]="planClasesRoute"
                    [evaluacionTipo]="tipoInstrumento"
                    [ignoreNiveles]="true"
                    [showMisPlanes]="true"
                    [generadorInstrumentos]="generadorInstrumentos"
                    titulo="{{ 'puntaje_core.plan_clases.planes_compartidos' | t }}"
                    [showYearFilter]="true"
                ></plan-clases-alumnos>
                <plan-clases-alumnos
                    *ngIf="generadorInstrumentos"
                    [planClaseRoute]="planClasesRoute"
                    [evaluacionTipo]="tipoInstrumento"
                    [ignoreNiveles]="true"
                    [showFromProfesor]="true"
                    [showPlanesPredefinidos]="true"
                    [generadorInstrumentos]="generadorInstrumentos"
                    titulo="{{ 'puntaje_core.plan_clases.planes_recomendados' | t }}"
                ></plan-clases-alumnos>
            </div>
        </div>
    </cui-tab>
    <cui-tab
        title="{{ 'puntaje_core.plan_clases.tab_mis_planes' | t }}"
        (select)="selectTab('mis_plan_clases')"
        [active]="selectedTab == 'mis_plan_clases'"
    >
        <div class="form-group">
            <div class="margin">
                <plan-clases-profesor
                    *ngIf="generadorInstrumentos"
                    [generadorInstrumentos]="generadorInstrumentos"
                    [enableCreatePlan]="true"
                    [enableEditPlan]="true"
                    [mios]="true"
                    [asignaturaId]="asignaturaId"
                    [evaluacionTipo]="tipoInstrumento"
                ></plan-clases-profesor>
            </div>
        </div>
    </cui-tab>
</cui-tabs>
