import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { Instrumento, InstrumentoMultiples, Instrumentos, InstrumentoMultiple } from "@puntaje/puntaje/api-services"
import { PaginatorComponent } from "@puntaje/shared/core"
import { Component, Input, ViewChild, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "instrumentos-multiples-no-compartidos",
    templateUrl: "instrumentos_multiples_no_compartidos.component.html",
    styleUrls: ["instrumentos_multiples_no_compartidos.component.scss"]
})
export class InstrumentosMultiplesNoCompartidosComponent {
    _asignaturaId: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    instrumentoMultiple: InstrumentoMultiple
    @Input() tipoId: number = 1
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @Input() tipoEvaluacion: string

    //este y el de arriba son distintos porque es del filtro (hay que ver si el otro se puede sacar)
    @Input() evaluacionTipo: string

    instrumentosMultiples: InstrumentoMultiple[]

    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        protected asignaturasService: Asignaturas,
        protected instrumentoMultiplesService: InstrumentoMultiples,
        protected instrumentosService: Instrumentos,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        //this.setData();
    }

    setEvaluacionTipo(evaluacionTipo) {
        this.evaluacionTipo = evaluacionTipo

        this.paginator.changePage(1)
    }

    setData(page: number, per: number) {
        this.loadingLayout.standby()

        let params = {
            page: page,
            per: per,
            no_compartidos: 1,
            mios: 1,
            order: "desc",
            sort_by: "created_at",
            include: "[instrumentos,usuario]"
        }

        if (this.evaluacionTipo) params["tipo_instrumento"] = { tipo_instrumento: this.evaluacionTipo }

        this.cdr.detectChanges() //por alguna razón, al hacer un detectChanges se salta el ngOnInit del compartir.

        return this.instrumentoMultiplesService
            .where(params)
            .then((instrumentosMultiples: InstrumentoMultiple[], total: number) => {
                this.instrumentosMultiples = instrumentosMultiples
                this.loadingLayout.ready()
                return total
            })
    }

    compartirInstrumentoMultiple(instrumentoMultiple: InstrumentoMultiple) {
        this.instrumentoMultiple = instrumentoMultiple
        this.openModal.emit()
    }
}
