import { Component } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "establecimientos_administrar.component.html"
})
export class EstablecimientosAdministrarComponent {
    subData: Subscription

    showVaciar = true

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.establecimientos.administrar")
        this.subData = this.route.data.subscribe(data => {
            this.showVaciar = data.showVaciar ?? this.showVaciar
        })
    }

    ngOnDestroy() {
        this.subData.unsubscribe()
    }
}
