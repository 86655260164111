import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from "@angular/core"
import { PaginatorComponent } from "@puntaje/shared/core"
import { Material } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "materiales-lista",
    templateUrl: "./materiales-lista.component.html",
    styleUrls: ["./materiales-lista.component.scss"]
})
export class MaterialesListaComponent implements OnInit {
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    @Input() materiales: Material[]
    @Output() selected = new EventEmitter<Material>()
    selectedMaterial: Material

    uploading: boolean = false

    constructor() {}

    ngOnInit() {}

    getIcon(material_tipo_nombre) {
        if (material_tipo_nombre == "Instrumento") {
            return "prueba"
        } else if (material_tipo_nombre == "Video" || material_tipo_nombre == "Streaming") {
            return "video"
        } else {
            return "pdf"
        }
    }

    disponibleProfesor(material) {
        const clasificaciones = material.clasificaciones.map(x => x.clasificacion)
        return !clasificaciones.includes("Alumnos")
    }

    selectMaterial(material) {
        this.selected.emit(material)
    }
}
