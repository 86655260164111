<div resize-aware (currentSize)="adaptToSize($event)">
    <div class="content-carousel" *ngIf="mostrarCarousel">
        <carousel
            *ngIf="mostrarCarousel && plan_personal_contenidos.length > 0"
            [itemsPerSlide]="itemsPerSlide"
            [singleSlideOffset]="singleSlideOffset"
            [noWrap]="noWrap"
            [interval]="$any(false)"
            [startFromIndex]="0"
            [showIndicators]="false"
            [class.izq-hidden]="no_show_izq"
            [class.derec-hidden]="no_show_derec"
            (slideRangeChange)="showControler($event)"
        >
            <slide *ngFor="let planPersonalContenido of plan_personal_contenidos; let i = index">
                <card-material-profesor
                    [clasificacionActividadByMaterialId]="clasificacionActividadByMaterialId"
                    [clasificacionContextoByMaterialId]="clasificacionContextoByMaterialId"
                    [planPersonalContenido]="planPersonalContenido"
                    [vistaPrevia]="vistaPrevia"
                ></card-material-profesor>
            </slide>
        </carousel>
    </div>

    <div class="content-not-carousel" *ngIf="mostrarSecontCarousel">
        <div *ngFor="let planPersonalContenido of plan_personal_contenidos; let i = index">
            <card-material-profesor
                [clasificacionActividadByMaterialId]="clasificacionActividadByMaterialId"
                [clasificacionContextoByMaterialId]="clasificacionContextoByMaterialId"
                [planPersonalContenido]="planPersonalContenido"
                [vistaPrevia]="vistaPrevia"
            ></card-material-profesor>
        </div>
    </div>
</div>
