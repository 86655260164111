import { Component, OnInit } from "@angular/core"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./plan-clase-new.component.html",
    styleUrls: ["./plan-clase-new.component.scss"]
})
export class PlanClaseNewComponent implements OnInit {
    asignaturaId: number
    asignatura: Asignatura
    tipoInstrumento: string

    subData: Subscription

    enableModelos = true

    constructor(
        protected titleService: TitleService,
        protected authService: AuthService,
        protected route: ActivatedRoute,
        protected router: Router
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_clases.plan_clases.new")
        this.route.queryParams.subscribe(query => {
            this.asignaturaId = +query["asignatura_id"]
            this.tipoInstrumento = query["tipo_instrumento"] || config.plataforma.evaluacionDefault
        })

        this.subData = this.route.data.subscribe(data => {
            this.enableModelos = data.enableModelos ?? this.enableModelos
        })
    }

    ngOnDestroy(): void {
        this.subData.unsubscribe()
    }
}
