<div class="print-show-block print-info print-page-break-after">
    <img class="portada-logo" src="{{ logosrc }}" alt="logo portada" />
    <h2 *ngIf="establecimiento">{{ establecimiento.establecimiento }}</h2>
    <p class="subtitle">{{ titulo }}</p>

    <p class="dotted-line clearfix" *ngIf="grupoUsuario">
        <label>{{ nombreGrupo | capitalize }}:</label>
        <span class="value">{{ grupoUsuario.nombre }}</span>
    </p>
    <p class="dotted-line clearfix" *ngIf="usuario">
        <label>Alumno:</label>
        <span class="value">{{ usuario.nombreCompleto() }}</span>
    </p>
    <p class="dotted-line clearfix" *ngIf="evaluacionMultiple">
        <label>{{ evaluacionTipo | transformInstrumento: "singular" | capitalize }}:</label>
        <span class="value">#{{ evaluacionMultiple.id }} - {{ evaluacionMultiple.evaluacion_multiple }}</span>
    </p>
    <p class="dotted-line clearfix" *ngIf="asignatura">
        <label>Asignatura:</label>
        <span class="value">{{ asignatura.asignatura }}</span>
    </p>
    <div class="row printInfo-tables">
        <ng-content></ng-content>
    </div>
</div>
