import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from "@angular/core"
import { Router } from "@angular/router"
import { Noticia, Noticias, NoticiaView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"

@Component({
    selector: "noticia-profesor",
    templateUrl: "noticia-profesor.component.html",
    styleUrls: ["noticia-profesor.component.scss"]
})
export class NoticiaProfesorComponent implements OnInit {
    @Input() noticia: Noticia
    @Output() showNoticiaEvent = new EventEmitter()
    @Output() showEditarEvent = new EventEmitter()
    @Output() showDeleteEvent = new EventEmitter()
    compartidoCon: string

    constructor(
        protected noticiasService: Noticias,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.compartidoCon = this.noticia.noticia_usuarios.map(u => u.grupo_usuario.nombre).join(", ")
    }

    view(noticia: Noticia) {
        this.router.navigate(["noticias/" + noticia.id])
    }

    showNoticia(noticia: Noticia) {
        this.showNoticiaEvent.emit(noticia)
    }

    showEditar(noticia: Noticia) {
        this.showEditarEvent.emit(noticia)
    }

    showDelete(noticia: Noticia) {
        this.showDeleteEvent.emit(noticia)
    }
}
