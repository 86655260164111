<div class="clearfix" id="step1">
    <div class="row">
        <div class="col-md-4">
            <div class="content-f">
                <div class="tutorial">
                    <div class="container-tutorial-icon">
                        <div class="tutorial-icon-pos">
                            <ogr-icon name="resultados" align="top" class="icon"></ogr-icon>
                        </div>
                    </div>
                </div>
                <div class="content-text">
                    <h3 class="step">{{ 'evaluar.pasos.step' | t }} {{ step }}</h3>
                    <p class="tutorial-text">{{ 'evaluar.pasos.vista_previa.message' | t }}</p>
                    <p class="tutorial-text-descripcion">
                        {{ 'evaluar.pasos.vista_previa.desc' | t }}
                    </p>
                </div>
            </div>
            <div class="clearfix">
                <cui-button class="btn_style pull-right" (click)="goToAsignatura()">
                    <ogr-icon name="ticket" align="text-top"></ogr-icon>
                    &nbsp;{{ 'evaluar.pasos.vista_previa.button' | t }}
                </cui-button>
            </div>
        </div>
        <div class="col-md-8">
            <model-plain-value [params]="fileParams" [value]="material" key="fileSrc"></model-plain-value>
        </div>
    </div>
</div>
