<ll-over-title>
    <site-warning *ngIf="enableSiteWarning" [warningText]="'generar_ensayo_profesor.site_warning' | t"></site-warning>
</ll-over-title>
<ll-titulo>
    {{
        "generar_ensayo_profesor.titulo"
            | t: { evaluacionTipo: (evaluacionTipoAlias | transformInstrumento: "singular" | titlecase) }
    }}
</ll-titulo>
<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas
        [evaluacionTipos]="evaluacionTipos"
        [showTitleOverMenu]="showTitleOverMenu"
    ></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>

<generar-ensayo-profesor-dynamic-tabs
    *ngIf="displayGenerarProfesor"
    [tipoEvaluacion]="tipoInstrumento"
    [evaluacionTipoAlias]="evaluacionTipoAlias"
    [asignaturaId]="asignaturaId"
    (onReadyAsignatura)="onReady($event)"
    [enableReporte]="enableReporte"
    [showTutorial]="showTutorial"
    [showButtonGuardar]="showButtonGuardar"
    [enableGenerador]="enableGenerar"
    [compartirTodosInstrumentos]="compartirTodosInstrumentos"
></generar-ensayo-profesor-dynamic-tabs>
