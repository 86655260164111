<div class="button-box">
    <cui-button class="btn_style_sm print-hide btn_abrir" (click)="showBuscador()">
        {{ textoBoton }}
    </cui-button>
</div>
<div class="buscador-content" *ngIf="buscadorOpen">
    <form [formGroup]="buscadorForm" class="buscador">
        <div class="wrapper">
            <ul>
                <li>
                    <input
                        type="text"
                        [formControlName]="'rut'"
                        name="rut"
                        class="form-control"
                        placeholder="Rut ej: 12345678-9"
                    />
                </li>
                <li>
                    <input
                        type="text"
                        [formControlName]="'nombre'"
                        name="nombre"
                        class="form-control"
                        placeholder="Nombre"
                    />
                </li>
                <li>
                    <input
                        type="text"
                        [formControlName]="'apellido_paterno'"
                        name="apellido_paterno"
                        class="form-control"
                        placeholder="Apellido paterno"
                    />
                </li>
                <li>
                    <input
                        type="text"
                        [formControlName]="'apellido_materno'"
                        name="apellido_materno"
                        class="form-control"
                        placeholder="Apellido materno"
                    />
                </li>
                <li>
                    <cui-button-link class="btn_style btn_search" (click)="search()">
                        <fa name="search"></fa>
                        &nbsp;Buscar
                    </cui-button-link>
                </li>
                <li>
                    <cui-button-link class="btn_style btn_clear" (click)="cleanData()">
                        <fa name="search"></fa>
                        &nbsp;Limpiar datos
                    </cui-button-link>
                </li>
            </ul>
        </div>
    </form>
</div>
