import { Component, OnInit, ViewChild, Input, SimpleChanges } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { BaseChart, BarChart, ChartColorType, I18nService } from "@puntaje/shared/core"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*********************************
Gráfico de barras de comparación de puntajes entre grupos
Recibe grupos: string[], data: number[]
**********************************/

@Component({
    selector: "grafico-comparacion-puntaje-grupos",
    template: `
        <loading-layout #loadingLayout>
            <estadisticas-grafico [grafico]="grafico" *ngIf="!loadingLayout.loading"></estadisticas-grafico>
        </loading-layout>
    `
})
export class GraficoComparacionPuntajesGruposComponent implements OnInit {
    @Input() asignatura: Asignatura
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() grupos: string[] = []
    @Input() data: number[] = []
    @Input() tipoGrupo: string = ""
    @Input() evaluacionTipo: string
    grafico: BaseChart
    enableGraph: boolean = false

    constructor(protected i18nService: I18nService) {}

    ngOnInit() {
        if (!this.evaluacionTipo) this.evaluacionTipo = "ensayo"
    }

    setGrafico() {
        this.loadingLayout.standby()
        let g1: any = {}
        switch (this.tipoGrupo) {
            case "alumnos":
                
                g1.titulo = this.i18nService.translate('estadisticas.graficos.cpg.title_alumno', {}) + this.asignatura.asignatura
                g1.descripcion = this.i18nService.translate('estadisticas.graficos.cpg.descripcion_alumno', {})
                break
            case "cursos":
                g1.titulo = this.i18nService.translate('estadisticas.graficos.cpg.title_profe', {}) + this.asignatura.asignatura
                g1.descripcion = this.i18nService.translate('estadisticas.graficos.cpg.descripcion_profe', {})
                break
            default:
                g1.titulo = this.i18nService.translate('estadisticas.graficos.cpg.title_alumno', {}) + this.asignatura.asignatura
                g1.descripcion = this.i18nService.translate('estadisticas.graficos.cpg.descripcion_alumno', {})
                break
        }
        let { tickInterval, max } = config.evaluaciones[this.evaluacionTipo].rangoCalificacion

        g1.ejeX = this.grupos
        g1.ejeY = { min: 0, tickInterval, max }
        g1.data = [{ nombre: this.i18nService.translate('estadisticas.graficos.cpg.title', {}), data: this.data }]
        this.grafico = new BarChart(g1)
        this.loadingLayout.ready()
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setGrafico()
    }
}
