import { Component, Input, ViewChild, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { GeneradorInstrumentos, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({ template: "" })
//export abstract class AbstractGenerarEnsayoComponent implements OnInit {
export class AbstractGenerarInstrumentoComponent implements OnInit {
    @Input() asignaturaId: number
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    generadorInstrumentos: GeneradorInstrumento[]
    generadorInstrumento: GeneradorInstrumento

    numeroPreguntas: number
    tiempo: number

    clasificacionesIds: number[]

    show_contenidos: boolean

    constructor(protected router: Router, protected generadorInstrumentosService: GeneradorInstrumentos) {}

    ngOnInit() {}

    onReady() {}

    toggleContenidos() {}

    obtainGeneradorInstrumentos() {}
}
