import {
    Component,
    OnInit,
    ViewChild,
    ViewRef,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef,
    Renderer2
} from "@angular/core"
import {
    Asignaturas,
    Asignatura,
    ClasificacionTipo,
    ClasificacionTipos,
    Pregunta,
    Preguntas
} from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    Usuario,
    Usuarios,
    EvaluacionInstancia,
    EvaluacionInstancias,
    Evaluacion,
    GrupoUsuario,
    Evaluaciones,
    Estadisticas,
    EvaluacionTiempo,
    EvaluacionTiempos,
    Establecimiento
} from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { ModalDirective } from "ngx-bootstrap/modal"

@Component({
    selector: "estadisticas-evaluacion",
    templateUrl: "estadisticas_evaluacion.component.html",
    styleUrls: ["estadisticas.component.scss"]
})
export class EstadisticasEvaluacionComponent implements OnInit, OnChanges {
    @Input() asignaturaId: number
    @Input() grupoUsuario: GrupoUsuario
    @Input() evaluacion: Evaluacion
    @Input() previousEvaluacion: Evaluacion
    @Input() estadisticas: any
    @Input() establecimiento: Establecimiento
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    asignatura: Asignatura
    evaluacionInstancias: EvaluacionInstancia[] // solo oficiales
    allEvaluacionInstancias: EvaluacionInstancia[]
    previousEvaluacionInstancias: EvaluacionInstancia[]
    evaluacionTiempos: EvaluacionTiempo[]
    @Input() evaluacionTipo: string
    @Input() evaluacionTipoAlias: string

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    enableGraphs: boolean = false

    dataRespuestas: any

    clasificacionTipos: ClasificacionTipo[]
    clasificacionTiposEstadisticas: any
    clasificacionReferenced: any
    clasificacionTipoByNombre: any

    distribucion_ensayos_tramo_puntaje: number[] = []
    desempeno_materia_omitidas: number[] = []
    desempeno_materia_correctas: number[] = []
    desempeno_materia_incorrectas: number[] = []
    desempeno_promedio_omitidas: number
    desempeno_promedio_correctas: number
    desempeno_promedio_incorrectas: number
    desempeno_subejes: string[] = []
    desempeno_subejes_omitidas: number[] = []
    desempeno_subejes_correctas: number[] = []
    desempeno_subejes_incorrectas: number[] = []
    comparacion_alumnos: string[] = []
    comparacion_promedios: number[] = []

    estadisticaUsuarios: any[] = []
    usuarios: Usuario[] = []

    canceled: boolean = false
    errorMsg: boolean = false
    @ViewChild("mandatoryLoading", { static: true }) mandatoryLoading: ModalDirective
    hayRespuestas: boolean = false

    constructor(
        protected asignaturasService: Asignaturas,
        protected usuariosService: Usuarios,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected clasificacionTiposService: ClasificacionTipos,
        protected preguntasService: Preguntas,
        protected evaluacionesService: Evaluaciones,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected estadisticasService: Estadisticas,
        protected cdr: ChangeDetectorRef,
        protected renderer: Renderer2
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()
    }

    async setData() {
        this.loadingLayout.standby()
        let params: any = {
            include: "[respuestas:alternativa,evaluacion_instancia_asignaturas]",
            evaluacion_instancia: { evaluacion_id: this.evaluacion.id },
            methods: "[has_forma]"
        }

        this.clasificacionReferenced = { ...config.evaluaciones[this.evaluacionTipo].clasificaciones.referencesTo }
        if (Object.values(this.clasificacionReferenced).length > 0) {
            const clasificacionTiposReferenced = await this.clasificacionTiposService.where({
                clasificacion_tipo: { clasificacion_tipo: Object.values(this.clasificacionReferenced) }
            })

            Object.keys(this.clasificacionReferenced).forEach(key => {
                this.clasificacionReferenced[key] = clasificacionTiposReferenced.find(
                    ctr => ctr.clasificacion_tipo == this.clasificacionReferenced[key]
                )
            })
        }

        this.clasificacionTiposEstadisticas = config.evaluaciones[
            this.evaluacionTipo
        ].clasificacionTiposEstadisticas.filter(ct => ct.profesores)

        let cTipos = this.clasificacionTiposEstadisticas.map(ct => ct.nombre)

        this.clasificacionTipos = <ClasificacionTipo[]>(
            await this.clasificacionTiposService.where({ clasificacion_tipo: { clasificacion_tipo: cTipos } })
        )
        this.clasificacionTipos = cTipos.map(ct => this.clasificacionTipos.find(cto => cto.clasificacion_tipo == ct))
        this.clasificacionTipoByNombre = this.clasificacionTipos.groupBy(ct => ct.clasificacion_tipo)

        this.asignatura = <Asignatura>await this.asignaturasService.find(this.asignaturaId)

        if (this.grupoUsuario) {
            params.evaluacion_instancia.usuario_id = this.grupoUsuario.usuarios.map(u => u.id)
        } else {
            params.establecimiento = { id: this.establecimiento.id }
        }

        this.allEvaluacionInstancias = <EvaluacionInstancia[]>await this.evaluacionInstanciasService.wherePost(params)
        this.evaluacionInstancias = this.allEvaluacionInstancias.filter(ei => ei.oficial == true)

        if (this.evaluacionInstancias.length > 0) {
            this.hayRespuestas = true
        }

        if (this.grupoUsuario && this.previousEvaluacion) {
            this.grupoUsuario && (params.evaluacion_instancia.evaluacion_id = this.previousEvaluacion.id)
            this.previousEvaluacionInstancias = <EvaluacionInstancia[]>(
                await this.evaluacionInstanciasService.where(params)
            )
        } else {
            this.previousEvaluacionInstancias = []
        }

        this.evaluacionTiempos = <EvaluacionTiempo[]>(
            await this.evaluacionTiemposService.where({ evaluacion_tiempo: { evaluacion_id: this.evaluacion.id } })
        )

        params = {
            collection: "EstadisticaInstanciaClasificacion",
            estadistica: { evaluacion_instancia_id: this.evaluacionInstancias.map(ei => ei.id) },
            clasificacion_methods: config.evaluaciones[this.evaluacionTipo].clasificaciones.methods
        }

        if (this.estadisticas[0]) {
            params.estadistica.generador_instrumento_id = this.estadisticas[0].generador_instrumento_id
        }

        if (this.grupoUsuario) {
            this.usuarios = this.grupoUsuario.usuarios
        } else {
            let grupoUsuarioIds = this.evaluacion.evaluacion_usuarios
                .filter(eu => eu.grupo_usuario)
                .map(eu => eu.receptor_id)

            const params = {
                sort_by: "usuarios.apellido_paterno, usuarios.apellido_materno, usuarios.nombre",
                grupo_usuario: { id: grupoUsuarioIds }
            }

            this.usuarios = <Usuario[]>await this.usuariosService.where(params)
        }

        let estadisticaInstanciaClasificaciones = <any[]>await this.estadisticasService.wherePost(params)
        this.estadisticaUsuarios = estadisticaInstanciaClasificaciones.map(eic => {
            let data: any = {}
            let evaluacionInstancia = this.evaluacionInstancias.find(ei => ei.id == eic.evaluacion_instancia_id)

            data.usuario_id = evaluacionInstancia.usuario_id
            data.estadistica_clasificaciones = eic.estadistica_clasificaciones

            return data
        })

        this.setValues()
        this.enableGraphs = true
        this.loadingLayout.ready()
        this.onReady.emit(this.asignatura)

        if (!(this.cdr as ViewRef).destroyed) this.cdr.detectChanges()
    }

    setValues() {
        this.distribucion_ensayos_tramo_puntaje = this.estadisticas.reduce((acc, e) => {
            Object.keys(e.distribucion_calificaciones).forEach(key => {
                const calificacion = +key.replace(",", ".")

                acc[calificacion] = acc[calificacion] || 0
                acc[calificacion] += e.distribucion_calificaciones[key]
            })

            return acc
        }, {})

        let estadistica_clasificaciones = {}
        this.estadisticas.forEach(e => {
            e.estadistica_clasificaciones &&
                e.estadistica_clasificaciones.forEach(ec => {
                    if (!estadistica_clasificaciones[ec.clasificacion_id]) {
                        estadistica_clasificaciones[ec.clasificacion_id] = ec
                    } else {
                        estadistica_clasificaciones[ec.clasificacion_id].correctas += ec.correctas
                        estadistica_clasificaciones[ec.clasificacion_id].incorrectas += ec.incorrectas
                        estadistica_clasificaciones[ec.clasificacion_id].omitidas += ec.omitidas
                    }
                })
        })

        estadistica_clasificaciones = (Object as any).values(estadistica_clasificaciones)

        this.clasificacionTipos.forEach(ct => {
            let ecs = (estadistica_clasificaciones as any[]).filter(
                ec => ec.clasificacion.clasificacion_tipo_id == ct.id
            )

            let nombre = (ct as any).clasificacion_tipo

            this["desempeno_" + nombre] = ecs.map(
                ec =>
                    ec.clasificacion.clasificacion +
                    (ec.clasificacion.curso ? " (" + ec.clasificacion.curso.clasificacion + ")" : "")
            )
            this["desempeno_" + nombre + "_omitidas"] = ecs.map(ec => ec.omitidas)
            this["desempeno_" + nombre + "_incorrectas"] = ecs.map(ec => ec.incorrectas)
            this["desempeno_" + nombre + "_correctas"] = ecs.map(ec => ec.correctas)
            this["desempeno_" + nombre + "_total"] = ecs.map(ec => ec.omitidas + ec.incorrectas + ec.correctas)
        })

        let numero_evaluaciones = this.estadisticas.reduce((x, e) => x + e.numero_evaluaciones, 0)
        this.desempeno_promedio_omitidas = ~~(
            this.estadisticas.reduce((x, e) => x + e.omitidas, 0) / numero_evaluaciones
        )
        this.desempeno_promedio_incorrectas = ~~(
            this.estadisticas.reduce((x, e) => x + e.incorrectas, 0) / numero_evaluaciones
        )
        this.desempeno_promedio_correctas = ~~(
            this.estadisticas.reduce((x, e) => x + e.correctas, 0) / numero_evaluaciones
        )

        /*let estadistica_clasificacion_cursos = estadistica_clasificaciones.filter((ec) => ec.clasificacion.clasificacion_tipo_id == 1);
		this.desempeno_materia_cursos = estadistica_clasificacion_cursos.map((ec) => ec.clasificacion.clasificacion);
		this.desempeno_materia_omitidas = estadistica_clasificacion_cursos.map((ec) => ec.omitidas);
		this.desempeno_materia_incorrectas = estadistica_clasificacion_cursos.map((ec) => ec.incorrectas);
		this.desempeno_materia_correctas = estadistica_clasificacion_cursos.map((ec) => ec.correctas);
		let numero_evaluaciones = this.estadisticas.reduce((x, e) => x + e.numero_evaluaciones, 0);
		this.desempeno_promedio_omitidas = ~~(this.estadisticas.reduce((x, e) => x + e.omitidas, 0)/numero_evaluaciones);
		this.desempeno_promedio_incorrectas = ~~(this.estadisticas.reduce((x, e) => x + e.incorrectas, 0)/numero_evaluaciones);
		this.desempeno_promedio_correctas = ~~(this.estadisticas.reduce((x, e) => x + e.correctas, 0)/numero_evaluaciones);
		let estadistica_clasificacion_subejes = estadistica_clasificaciones.filter((ec) => ec.clasificacion.clasificacion_tipo_id == 7 && ec.clasificacion.clasificacion_padre_id != null);
		this.desempeno_subejes = estadistica_clasificacion_subejes.map((ec) => ec.clasificacion.clasificacion);
		this.desempeno_subejes_omitidas = estadistica_clasificacion_subejes.map((ec) => ec.omitidas);
		this.desempeno_subejes_incorrectas = estadistica_clasificacion_subejes.map((ec) => ec.incorrectas);
		this.desempeno_subejes_correctas = estadistica_clasificacion_subejes.map((ec) => ec.correctas);*/

        /*let grupo_usuario_ids =	this.evaluacion.evaluacion_usuarios.filter((eu) => eu.grupo_usuario).map((eu) => eu.receptor_id);

		this.usuariosService.where({grupo_usuario: {id: grupo_usuario_ids}}).then((usuarios: Usuario[]) => {
			this.comparacion_alumnos = usuarios.map((u) => u.nombreCompleto());
			this.evaluacionInstanciasServices.where({evaluacion_instancia: {evaluacion_id: this.evaluacion.id, usuario_id: usuarios.map((u) => u.id)}}).then((evaluacionInstancias: EvaluacionInstancia[]) => {
				this.comparacion_promedios = usuarios.map((u) => {
					let evaluacionInstancia = evaluacionInstancias.find((ei) => ei.usuario_id == u.id);
					if(evaluacionInstancia)
						return evaluacionInstancia.calificacion;
					else
						return 0;
				});
			});
		});*/
    }

    printAlumnos() {
        this.canceled = false
        this.errorMsg = false
        this.mandatoryLoading.show()
        const clasificacionTipo = config.evaluaciones[this.evaluacionTipo].clasificacionTiposEstadisticas.map(
            cte => cte.nombre
        )

        const params = {
            clasificacion_tipo: clasificacionTipo,
            grupo_usuario: {
                id: this.grupoUsuario.id
            }
        }
        this.evaluacionesService.enableIgnoreModel()
        this.evaluacionesService.enableIgnoreCatch()
        this.evaluacionesService
            .imprimirInformeAlumnos(this.evaluacion.id, params)
            .then((obj: any) => {
                if (this.canceled) {
                    throw new Error("Cancelado")
                }
                const info = obj.info
                this.evaluacionesService.disableIgnoreModel()
                this.logInfoLatex(info.statusCode, info.exit_status, info.stdout, info.stderr)
                this.mandatoryLoading.hide()
                const downloadString = "Informe_evaluacion_" + this.evaluacion.id + ".pdf"

                this.createLinkAndOpen(info.link, downloadString)
            })
            .catch(response => {
                this.evaluacionesService.disableIgnoreModel()
                if (!this.canceled) {
                    this.errorMsg = true
                }
            })
    }

    logInfoLatex(statusCodeLambda, exitStatus, stdout, stderr) {
        console.log("STATUS CODE LAMBDA")
        console.log("%c " + statusCodeLambda, "color: blue")
        console.log("EXIT STATUS LATEX")
        console.log("%c " + exitStatus, "color: blue")
        console.log("SALIDA ESTANDAR LATEX")
        console.log("%c " + stdout, "color: blue")
        console.log("SALIDA DE ERRORES LATEX")
        console.log("%c " + stderr, "color: crimson")
    }

    createLinkAndOpen(link, downloadString) {
        const a = document.createElement("a")
        a.download = downloadString
        a.href = link
        a.click()
    }

    cancelPrint() {
        this.evaluacionesService.disableIgnoreModel()
        this.mandatoryLoading.hide()
        this.canceled = true
    }

    onResultadosPorPreguntaReady(data) {
        this.dataRespuestas = data
    }

    //TODO: deshabilitar esto cuando lo demás funcione
    setTestValues() {
        this.distribucion_ensayos_tramo_puntaje = [96, 81, 91, 155, 100, 0, 0]
        this.desempeno_materia_omitidas = [5, 3, 4, 7, 2]
        this.desempeno_materia_incorrectas = [2, 2, 3, 2, 1]
        this.desempeno_materia_correctas = [3, 4, 4, 2, 5]
        this.desempeno_promedio_omitidas = 25
        this.desempeno_promedio_correctas = 50
        this.desempeno_promedio_incorrectas = 25
        this.desempeno_subejes = ["Números Reales", "Números Complejos", "Potencias", "Raíces", "Logaritmos"]
        this.desempeno_subejes_omitidas = [849, 69, 266, 168, 255]
        this.desempeno_subejes_incorrectas = [984, 159, 407, 288, 397]
        this.desempeno_subejes_correctas = [1094, 148, 650, 461, 317]
        this.comparacion_alumnos = ["Alumno A", "Alumno B", "Alumno C"]
        this.comparacion_promedios = [290, 302, 177]
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"]) {
            this.setData()
        }
    }
}
