<el-titulo>
    <ng-template [ngIf]="evaluacionInstancia">{{ evaluacionInstancia.evaluacion.evaluacion }}</ng-template>
</el-titulo>
<el-subtitulo>
    <ng-template [ngIf]="evaluacionInstancia"></ng-template>
</el-subtitulo>

<el-titulo-contenido-lateral>
    <a (click)="goBack()" class="back-btn" href="javascript:void(0)">
        <fa name="arrow-left"></fa>
        Atrás
    </a>
</el-titulo-contenido-lateral>

<el-contenido-lateral>
    <ensayo-instancia-resumen-alumno
        *ngIf="evaluacionInstancia?.respuestas"
        [evaluacionInstancia]="evaluacionInstancia"
    ></ensayo-instancia-resumen-alumno>
</el-contenido-lateral>

<respuestas-ensayo
    [evaluacionInstanciaId]="evaluacionInstanciaId"
    (onEvaluacionInstanciaReady)="onEvaluacionInstanciaReady($event)"
    [showClasificaciones]="true"
    [enableDudas]="enableDudas"
    [enableReporte]="enableReporte"
    perfil="profesor"
    [withCurso]="true"
></respuestas-ensayo>
