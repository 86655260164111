import { Component, OnInit, ViewChild, Input, ElementRef, SimpleChanges, OnChanges } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PlanPersonales, PlanPersonal } from "@puntaje/puntaje/api-services"
import { Subscription, BehaviorSubject } from "rxjs"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AuthService, PaginatorComponent } from "@puntaje/shared/core"

@Component({
    selector: "plan-clases-profesor",
    templateUrl: "./plan-clases-profesor.component.html",
    styleUrls: ["./plan-clases-profesor.component.scss"]
})
export class PlanClasesProfesorComponent implements OnInit, OnChanges {
    planificaciones: PlanPersonal[]
    planificacionesByAsignatura: { [key: number]: PlanPersonal[] }
    evaluacionTipoSubject = new BehaviorSubject(null)
    sub: Subscription

    asignaturas: Asignatura[]
    asignatura: Asignatura
    tipoInstrumento: string

    colNumber1: boolean = false
    colNumber2: boolean = false
    colNumber3: boolean = false
    colNumber4: boolean = false

    @Input() enableEditPlan: boolean = false
    @Input() evaluacionTipo: string
    @Input() generadorInstrumentos: GeneradorInstrumento[]
    @Input() mios: boolean = false
    @Input() enableCreatePlan: boolean = false
    @Input() asignaturaId: number

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    @ViewChild("misPlanes", { static: true }) misPlanes: ElementRef

    constructor(
        protected planificacionesService: PlanPersonales,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected authService: AuthService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected asignaturasService: Asignaturas
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["generadorInstrumentos"]) {
            this.loadInstancias()
        }
        if (changes["evaluacionTipo"]) {
            this.tipoInstrumento = this.evaluacionTipo
            this.loadInstancias()
        }
    }

    ngOnInit() {
        this.route.queryParams.subscribe(query => {
            if (!this.asignaturaId) {
                this.asignaturaId = query["asignatura_id"]
            }

            this.tipoInstrumento = query["tipo_instrumento"] || this.evaluacionTipo
        })
    }

    loadInstancias() {
        this.loadingLayout.standby()

        const paramsPlanificaciones = {
            with_instrumento: 1,
            plan_personal: {
                generador_instrumento_id: this.generadorInstrumentos.map(g => g.id)
            },
            plan_personal_tipo: {
                tipo: "plan de clase"
            },
            mis_niveles_profesor: 1,
            order: "desc",
            sort_by: "created_at",
            render_options: {
                include: {
                    usuario: null,
                    generador_instrumento: null
                },
                methods: ["total_ciclos", "total_sesiones"]
            }
        }

        if (this.mios) {
            paramsPlanificaciones["mios"] = 1
            paramsPlanificaciones["plan_personal_tipo"]["tipo"] = "plan de clase profesor"
        } else {
            paramsPlanificaciones["predefinidos"] = 1
        }

        return this.planificacionesService
            .where(paramsPlanificaciones)
            .then((planificaciones: PlanPersonal[], total: number) => {
                this.planificaciones = planificaciones

                this.loadingLayout.ready()
                return total
            })
    }
}
