import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import {
    Instrumento,
    Instrumentos,
    Establecimiento,
    Establecimientos,
    EstablecimientoUsuarios,
    Usuario,
    GrupoUsuario
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Component, Input, ViewChild, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { CUITableComponent } from "@puntaje/shared/cui"

@Component({
    selector: "instrumentos-no-compartidos",
    templateUrl: "instrumentos_no_compartidos.component.html",
    styleUrls: ["instrumentos_no_compartidos.component.scss"]
})
export class InstrumentosNoCompartidosComponent {
    _asignaturaId: number
    _evaluacionTipo: string
    instrumento: Instrumento
    @Input() tipoId: number = 1
    openModal: EventEmitter<any> = new EventEmitter<any>()

    @Input()
    set evaluacionTipo(evaluacionTipo: string) {
        let old = this._evaluacionTipo
        this._evaluacionTipo = evaluacionTipo
        old && this.cuiTable && this.cuiTable.changePage(1)
    }

    get evaluacionTipo() {
        return this._evaluacionTipo
    }

    @Input()
    set asignaturaId(id: number) {
        let old = this._asignaturaId
        this._asignaturaId = id
        old && this.cuiTable && this.cuiTable.changePage(1)
    }

    get asignaturaId() {
        return this._asignaturaId
    }

    establecimiento: Establecimiento
    establecimientos: Establecimiento[]
    asignatura: Asignatura
    fechaInicial: Date
    fechaFinal: Date
    usuariosUtp: boolean
    instrumentoId: number
    grupoUsuario: GrupoUsuario
    propietarios: Usuario[]
    propietario: Usuario

    instrumentos: Instrumento[]

    @ViewChild(CUITableComponent) cuiTable: CUITableComponent

    constructor(
        protected asignaturasService: Asignaturas,
        protected instrumentosService: Instrumentos,
        protected establecimientoUsuariosService: EstablecimientoUsuarios,
        protected establecimientosService: Establecimientos,
        protected authService: AuthService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.isUtp()
        //this.setData();
    }

    isUtp() {
        if (this.authService.getEstablecimientos().length > 0) {
            this.usuariosUtp = true
        } else {
            this.usuariosUtp = false
        }
    }

    setEvaluacionTipo(evaluacionTipo) {
        this.evaluacionTipo = evaluacionTipo

        if (this.cuiTable) this.cuiTable.changePage(1)
    }

    setData = (page: number, per: number) => {
        let params = {
            page: page,
            per: per,
            no_compartidos: 1,
            mios: 1,
            instrumento: {
                asignatura_id: this.asignaturaId
            },
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal,
            order: "desc",
            sort_by: "created_at"
        }

        if (this.evaluacionTipo) params["tipo_instrumento"] = { tipo_instrumento: this.evaluacionTipo }
        this.instrumentoId && (params["instrumento"]["id"] = [this.instrumentoId])
        if (this.grupoUsuario) params["grupo_usuario"] = { id: this.grupoUsuario.id }
        if (this.propietario) params["usuario"] = { usuario_id: this.propietario.id }
        //this.cdr.detectChanges(); por alguna razón, al hacer un detectChanges se salta el ngOnInit del compartir.

        return this.instrumentosService.where(params).then((instrumentos: Instrumento[], total: number) => {
            this.instrumentos = instrumentos
            return total
        })
    }

    reloadData() {
        if (this.cuiTable) this.cuiTable.changePage(1)
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicial = fechaInicial
        this.fechaFinal = fechaFinal

        this.setEstablecimientos()
    }

    setEstablecimientos() {
        let params = {
            mios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            establecimiento: { activo: 1 },
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            if (this.establecimientos.length == 1) {
                this.establecimiento = this.establecimientos[0]
                this.setPropietarios()
            } else if (this.establecimientos.length == 0) {
                this.establecimiento = undefined
            }
            if (this.cuiTable) this.cuiTable.resetPaginator()
        })
    }

    setPropietarios() {
        this.propietarios = []
        this.propietario = undefined
        if (this.establecimiento) {
            if (this.grupoUsuario) {
                this.propietarios = this.grupoUsuario.propietarios
            } else {
                this.establecimiento.grupo_usuarios.forEach(g => {
                    g.propietarios.forEach(p => {
                        let prop = this.propietarios.find(pr => pr.id == p.id)
                        if (!prop) {
                            this.propietarios.push(p)
                        }
                    })
                })
            }
        }
    }

    searchInstrumento(value: string) {
        this.instrumentoId = +value
        if (this.instrumentoId) {
            if (this.cuiTable) this.cuiTable.changePage(1)
        }
    }

    clearInstrumento() {
        if (this.instrumentoId) {
            this.instrumentoId = null
            if (this.cuiTable) this.cuiTable.changePage(1)
        }
    }

    compartirInstrumento(instrumento: Instrumento) {
        this.instrumento = instrumento
        setTimeout(() => {
            this.openModal.emit()
        }, 100)
    }
}
