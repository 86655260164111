import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"

import { Asignatura, Asignaturas, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import {
    Establecimiento,
    Establecimientos,
    Evaluaciones,
    Evaluacion,
    Usuario,
    Usuarios,
    GrupoUsuario
} from "@puntaje/puntaje/api-services"
import { FiltroEstadisticasService } from "./filtro_estadisticas.service"

import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "filtro-estadisticas",
    templateUrl: "filtro_estadisticas.component.html",
    styleUrls: ["filtro_estadisticas.component.scss"]
})
export class FiltroEstadisticasComponent implements OnInit {
    config: typeof config = config

    @Output() filter: EventEmitter<any> = new EventEmitter<any>()

    sub: Subscription
    subPeriodo: Subscription

    establecimiento: Establecimiento
    @Input() grupoUsuario: GrupoUsuario
    evaluacion: Evaluacion
    usuario: Usuario
    generadorInstrumento: GeneradorInstrumento
    periodo: number[]

    @Input() asignaturaId: number
    asignatura: Asignatura

    establecimientos: Establecimiento[]
    evaluaciones: Evaluacion[]
    usuarios: Usuario[]
    nombreGrupo: string = "Curso"

    @Input() evaluacionTipo: string

    fechaInicial: Date
    fechaFinal: Date

    loadingCount: number = 0

    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    constructor(
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected establecimientosService: Establecimientos,
        protected evaluacionesService: Evaluaciones,
        protected usuariosService: Usuarios,
        protected asignaturasService: Asignaturas,
        protected cdr: ChangeDetectorRef
    ) {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias ? config.plataforma.grupoUsuarioAlias : "Curso"
    }

    ngOnInit() {
        this.subPeriodo = this.filtroEstadisticasService
            .getFiltrosObservable(this.evaluacionTipo, this.asignaturaId)
            .subscribe(filtros => {
                if (filtros["periodo"]) {
                    this.periodo = filtros["periodo"]
                }
            })
    }

    setData() {
        this.establecimiento = undefined
        this.grupoUsuario = undefined
        this.evaluacion = undefined

        let params: any = {
            mios: 1,
            establecimiento: { activo: 1 },
            utp_establecimiento: 1,
            utp_asociacion: 1,
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal,
            render_options: {
                include: {
                    escala_evaluacion: null
                }
            }
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            this.asignaturasService
                .find(this.asignaturaId, { with_generador_instrumentos: 1, tipo_instrumento: this.evaluacionTipo })
                .then((asignatura: Asignatura) => {
                    this.asignatura = asignatura
                    this.sub = this.filtroEstadisticasService
                        .getFiltrosObservable(this.evaluacionTipo, this.asignaturaId)
                        .subscribe(this.updateSelected.bind(this))

                    this.filtroEstadisticasService.onChangeParams()
                })
        })
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicial = fechaInicial
        this.fechaFinal = fechaFinal

        this.setData()
    }

    updateSelected(values) {
        this.loadingLayout.standby()

        let changeGeneradorInstrumento,
            changeEstablecimiento,
            changeGrupoUsuario,
            changeEvaluacion = false

        let establecimientoId = values.establecimientoId
        let grupoUsuarioId = values.grupoUsuarioId
        let evaluacionId = values.evaluacionId
        let generadorInstrumentoId = values.generadorInstrumentoId

        const oldEstablecimiento = this.establecimiento
        const oldGrupoUsuario = this.grupoUsuario
        const oldEvaluacion = this.evaluacion

        this.establecimiento = undefined
        this.grupoUsuario = undefined
        this.evaluacion = undefined

        if (!establecimientoId) {
            this.loadingLayout.ready()
            return
        }

        if (
            generadorInstrumentoId &&
            (!this.generadorInstrumento || this.generadorInstrumento.id != generadorInstrumentoId)
        ) {
            this.generadorInstrumento = this.asignatura.generador_instrumentos.find(
                gi => gi.id == generadorInstrumentoId
            )
            changeGeneradorInstrumento = true
        }

        if (!oldEstablecimiento || oldEstablecimiento.id != establecimientoId) {
            this.establecimiento = this.establecimientos.find(e => e.id == establecimientoId)
            changeEstablecimiento = true
        } else {
            this.establecimiento = oldEstablecimiento
        }

        let estudiantesPromise = Promise.resolve()

        if (grupoUsuarioId && (!oldGrupoUsuario || oldGrupoUsuario.id != grupoUsuarioId || changeEstablecimiento)) {
            this.grupoUsuario = this.establecimiento.grupo_usuarios.find(gu => gu.id == grupoUsuarioId)
            changeGrupoUsuario = true
            estudiantesPromise = this.getEstudiantes()
        } else {
            this.grupoUsuario = oldGrupoUsuario
        }

        if (
            !oldEvaluacion ||
            oldEvaluacion.id != evaluacionId ||
            changeGeneradorInstrumento ||
            changeEstablecimiento ||
            changeGrupoUsuario
        ) {
            let promise = this.getEvaluaciones()

            if (promise && evaluacionId) {
                Promise.all([promise, estudiantesPromise]).then(() => {
                    this.evaluacion = this.evaluaciones.find(e => e.id == evaluacionId)
                    this.loadingLayout.ready()

                    this.filtrar()
                })
            } else {
                estudiantesPromise.then(() => {
                    this.loadingLayout.ready()

                    this.filtrar()
                })
            }
        } else {
            estudiantesPromise.then(() => {
                this.evaluacion = oldEvaluacion
                this.loadingLayout.ready()

                this.filtrar()
            })
        }
    }

    getEvaluaciones() {
        this.evaluaciones = undefined
        this.evaluacion = undefined

        let params: any = {
            mis_colegios: 1,
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }
        if (this.grupoUsuario) {
            params.grupo_usuario = { id: this.grupoUsuario.id } as any
        } else if (this.establecimiento) {
            params.grupo_usuario = { establecimiento_id: this.establecimiento.id } as any
        }
        if (this.generadorInstrumento) {
            params.instrumento = { generador_instrumento_id: this.generadorInstrumento.id }

            if (this.establecimiento) {
                this.loadingCount += 1

                return this.evaluacionesService.where(params).then((evaluaciones: Evaluacion[]) => {
                    this.loadingCount -= 1
                    this.evaluaciones = evaluaciones
                })
            } else {
                this.evaluaciones = []
            }
        } else {
            this.evaluaciones = []
        }

        return null
    }

    getEstudiantes() {
        this.usuarios = undefined
        this.usuario = undefined

        if (this.grupoUsuario) {
            this.loadingCount += 1

            return this.usuariosService
                .where({
                    grupo_usuario: { id: this.grupoUsuario.id },
                    sort_by: "usuarios.apellido_paterno, usuarios.apellido_materno, usuarios.nombre"
                })
                .then((usuarios: Usuario[]) => {
                    this.loadingCount -= 1
                    this.usuarios = usuarios
                    ;(this.grupoUsuario as any).usuarios = this.usuarios
                })
        }

        return null
    }

    filtrar() {
        let previousEvaluacionIndex = this.evaluacion
            ? this.evaluaciones.findIndex(e => e.id == this.evaluacion.id) - 1
            : -1
        let previousEvaluacion
        if (previousEvaluacionIndex >= 0) {
            previousEvaluacion = this.evaluaciones[previousEvaluacionIndex]
        }

        this.filter.emit({
            establecimiento: this.establecimiento,
            grupoUsuario: this.grupoUsuario,
            previousEvaluacion,
            evaluacion: this.evaluacion,
            usuario: this.usuario,
            generadorInstrumento: this.generadorInstrumento
        })
    }

    setFiltros() {
        let establecimientoId, grupoUsuarioId, usuarioId, evaluacionId, generadorInstrumentoId

        if (this.establecimiento) {
            establecimientoId = this.establecimiento.id
        }

        if (this.grupoUsuario) {
            grupoUsuarioId = this.grupoUsuario.id
        }

        if (this.evaluacion) {
            evaluacionId = this.evaluacion.id
        }

        if (this.generadorInstrumento) {
            generadorInstrumentoId = this.generadorInstrumento.id
        }

        this.filtroEstadisticasService.setFiltrosId(
            establecimientoId,
            grupoUsuarioId,
            usuarioId,
            evaluacionId,
            generadorInstrumentoId,
            this.evaluacionTipo,
            this.asignaturaId
        )
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
        if (this.subPeriodo) this.subPeriodo.unsubscribe()
    }
}
