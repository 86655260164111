import { EvaluationLayoutService } from "@puntaje/shared/cui"
import { Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { EvaluacionInstancia } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "resultados_ensayo_alumno.component.html"
})
export class ResultadosEnsayoAlumnoComponent implements OnInit, OnDestroy {
    evaluacionInstanciaId: number
    evaluacionInstancia: EvaluacionInstancia
    sub: Subscription
    subData: Subscription

    enableDudas = true
    enableReporte = true

    @Output() onEvaluacionReady: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected evaluationLayoutService: EvaluationLayoutService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.resultados_ensayo_alumno")
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionInstanciaId = +params["id"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.enableDudas = data.enableDudas ?? this.enableDudas
            this.enableReporte = data.enableReporte ?? this.enableReporte
        })

        this.evaluationLayoutService.setOptions({ contenidoLateralXL: true })
    }

    onEvaluacionInstanciaReady(evaluacionInstancia) {
        this.evaluacionInstancia = evaluacionInstancia
    }

    goBack() {
        history.back()
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
