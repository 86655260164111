<loading-layout #loadingLayout [opaque]="true">
    <div class="info-grupo-usuarios">
        <div class="info-group-icon-container">
            <ogr-icon name="comunidad" class="icono"></ogr-icon>
        </div>
        <div class="info-container">
            <h4>
                {{ grupoUsuario.nombre }}
                <span class="pull-right">
                    <fa name="user"></fa>
                    <loading-layout #loadingLayoutUsers [loaderType]="'default-xsmall'" class="loader-users">
                        {{ totalUsuarios }}
                    </loading-layout>
                </span>
            </h4>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="info-mini-table">
                        <div class="info-mini-container">
                            <div class="mini-container-header">
                                {{ "grupo_usuarios.profesores.info.establecimiento" | t }}
                            </div>
                            <div class="mini-container-content">
                                {{ grupoUsuario.establecimiento.establecimiento }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <cui-button-bar [marginBottom]="0">
                        <cui-button class="right-side details-" [toggleExpandable]="expandable" #buttonToExpand>
                            <span class="ver">{{ "grupo_usuarios.profesores.info.ver" | t }}</span>
                            &nbsp;/&nbsp;
                            <span class="ocultar">{{ "grupo_usuarios.profesores.info.ocultar" | t }}</span>
                            &nbsp;{{ "grupo_usuarios.profesores.info.integrantes" | t }}
                        </cui-button>
                        <cui-button class="right-side details-" (click)="resetAllPass(grupoUsuario)">
                            <ogr-icon name="reset-password" class="icono left-icon"></ogr-icon>
                            {{ "grupo_usuarios.profesores.info.restart_passwords" | t }}
                        </cui-button>
                    </cui-button-bar>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div id="tabla-info-grupo-{{ grupoUsuario.id }}" expandableContent #expandable>
            <table class="table tabla-info-grupos" *ngIf="usuarios">
                <thead>
                    <tr>
                        <th>{{ "grupo_usuarios.profesores.info.tabla.nombre" | t }}</th>
                        <th *ngIf="!enableCURPestudiantes"></th>
                        <th *ngIf="enableCURPestudiantes">CURP</th>
                        <th *ngIf="!enableUserId">{{ identificador() }}</th>
                        <th *ngIf="enableUserId">ID</th>
                        <th>{{ "grupo_usuarios.profesores.info.tabla.ultima_fecha" | t }}</th>
                        <th>{{ "grupo_usuarios.profesores.info.tabla.n_ingresos" | t }}</th>
                        <th class="actions">{{ "grupo_usuarios.profesores.info.tabla.acciones" | t }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let usuario of usuarios">
                        <td>{{ usuario.apellido_paterno }} {{ usuario.apellido_materno }}, {{ usuario.nombre }}</td>
                        <td *ngIf="!enableCURPestudiantes"></td>
                        <td *ngIf="enableCURPestudiantes">{{ usuario.usuario_mexico.numero_unico }}</td>
                        <td *ngIf="!enableUserId">
                            {{ run(usuario) | easyplaceholder: "Sin Información" }}
                        </td>
                        <td *ngIf="enableUserId">
                            {{ usuario.id | easyplaceholder: "-" }}
                        </td>
                        <td>
                            {{
                                usuario.fecha_ultimo_login
                                    | date: "dd/MM/yyyy HH:mm"
                                    | easyplaceholder: "Sin fecha de ingreso"
                            }}
                        </td>
                        <td>
                            {{ usuario.count_logins }}
                        </td>
                        <td class="actions">
                            <cui-button class="btn_style option-btn pull-right" (click)="resetPassword(usuario)">
                                <ogr-icon name="reset-password" class="icono"></ogr-icon>
                                {{ "grupo_usuarios.profesores.info.tabla.restart_password" | t }}
                            </cui-button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <paginator
                #paginator
                [getList]="verUsuariosFun()"
                [per]="20"
                *ngIf="grupoUsuario"
                [resultadosStringMapping]="mappingCantidadAlumnos"
            ></paginator>
        </div>
    </div>
</loading-layout>
