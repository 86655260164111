<div class="sesion-container">
    <label class="numero-sesion">{{ nombreSesion }} {{ planPersonalSesion.orden + 1 }}</label>
    <div class="form-group">
        <p>Nombre {{ nombreSesion }}</p>
        <div class="nombre-wrap">
            <input
                type="text"
                class="form-control"
                [(ngModel)]="planPersonalSesion.plan_personal_sesion"
                (ngModelChange)="planClaseStorageService.savePlanPersonal()"
                placeholder="Nombre {{ nombreSesion }}"
            />
            <fa name="pencil" class="pencil-icon"></fa>
        </div>
    </div>
    <ng-template #itemTemplate let-item="item" let-i="index">
        <plan-clase-contenido-box
            [planPersonalContenido]="item.value"
            [materialTipos]="materialTipos"
            [index]="i"
            (deleteContenido)="deleteContenido($event)"
            (showModalDeleteContenido)="showModalDeleteContenido($event)"
        ></plan-clase-contenido-box>
    </ng-template>
    <div class="contenidos-container-container">
        <div class="scroll-left" *ngIf="showScrollLeft">
            <button type="button" class="btn btn-default" aria-label="scroll sesion izquierda" (click)="scrollLeft()">
                <span class="glyphicon glyphicon glyphicon-menu-left" aria-hidden="true"></span>
            </button>
        </div>
        <bs-sortable
            #sortable
            class="contenidos-container"
            [(ngModel)]="planPersonalSesion.plan_personal_contenidos"
            itemClass="contenido"
            itemActiveClass="sortable-item-active"
            placeholderItem="Arrastrar aquí"
            placeholderClass="placeholderStyle"
            wrapperClass="contenidos-wrap"
            [itemTemplate]="itemTemplate"
            (onChange)="updateContenidos($event)"
        ></bs-sortable>
        <div class="scroll-right" *ngIf="showScrollRight">
            <button type="button" class="btn btn-default" aria-label="scroll sesion derecha" (click)="scrollRight()">
                <span class="glyphicon glyphicon glyphicon-menu-right" aria-hidden="true"></span>
            </button>
        </div>
        <div class="break"></div>
        <div class="agregar-container" (click)="openModal()">
            <div class="agregar">
                <fa name="plus-circle"></fa>
                <span>Agregar material</span>
            </div>
        </div>
    </div>
    <div class="fecha_inicio">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="nombre-wrap">
                        <b>Configurar fecha &nbsp;</b>
                        <input
                            type="checkbox"
                            [(ngModel)]="visible"
                            (ngModelChange)="planPersonalSesion.fecha_inicial = ''"
                        />
                    </div>
                </div>
            </div>
            <div *ngIf="visible" class="col-sm-6">
                <p>Desde el día:</p>
                <datepicker-form
                    #fechaInicio="ngModel"
                    name="fechaInicio"
                    [(ngModel)]="planPersonalSesion.fecha_inicial"
                    required
                ></datepicker-form>
                <div *ngIf="fechaInicio.errors && (fechaInicio.dirty || fechaInicio.touched)" class="error-warning">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    La Sesion debe tener una fecha de inicio.
                </div>
            </div>
        </div>
    </div>

    <cui-button
        *ngIf="planPersonalSesion.id"
        class="btn_style btn-borrar-session"
        (click)="showModalDeleteSesion(planPersonalSesion, indxSesion)"
    >
        <fa name="trash-o"></fa>
    </cui-button>
    <cui-button *ngIf="!planPersonalSesion.id" class="btn_style btn-borrar-session" (click)="updateSesion(indxSesion)">
        <fa name="trash-o"></fa>
    </cui-button>
</div>

<generic-modal>
    <modal-titulo>
        <div class="modalTitulo">{{ "plan_clase_sesion_form.agregar_material.titulo" | t }}</div>
    </modal-titulo>
    <modal-contenido>
        <div class="contentBotones">
            <div class="contentRow">
                <cui-button
                    class="btn_style btnMargin"
                    [class.active]="nombreTabs[0].activo"
                    (click)="selectTab('biblioteca')"
                >
                    {{ "plan_clase_sesion_form.agregar_material.tab_biblioteca" | t }}
                </cui-button>
                <cui-button
                    class="btn_style btnMargin"
                    [class.active]="nombreTabs[1].activo"
                    (click)="selectTab('propios')"
                >
                    {{ "plan_clase_sesion_form.agregar_material.tab_mi_material" | t }}
                </cui-button>
                <cui-button class="btn_style" [class.active]="nombreTabs[2].activo" (click)="selectTab('subir')">
                    {{ "plan_clase_sesion_form.agregar_material.tab_subir" | t }}
                </cui-button>
            </div>
            <div class="contentRow marginTop">
                <cui-button
                    class="btn_style btnMargin"
                    [class.active]="nombreTabs[3].activo"
                    (click)="selectTab('evaluacion')"
                >
                    {{ "plan_clase_sesion_form.agregar_material.tab_agregar_evaluacion" | t }}
                </cui-button>
                <cui-button
                    class="btn_style"
                    [class.active]="nombreTabs[4].activo"
                    (click)="selectTab('prueba_propia')"
                >
                    {{ "plan_clase_sesion_form.agregar_material.tab_agregar_prueba_propia" | t }}
                </cui-button>
            </div>
        </div>
        <div *ngIf="selectedTab == 'biblioteca'">
            <materiales-biblioteca
                [asignaturaId]="asignaturaId"
                [evaluacionTipo]="evaluacionTipo"
                [generadorInstrumento]="generadorInstrumento"
                (selected)="addMaterial($event)"
            ></materiales-biblioteca>
        </div>
        <div *ngIf="selectedTab == 'propios'">
            <materiales-propios
                [asignaturaId]="asignaturaId"
                [evaluacionTipo]="evaluacionTipo"
                [generadorInstrumento]="generadorInstrumento"
                (selected)="addMaterial($event)"
            ></materiales-propios>
        </div>
        <div *ngIf="selectedTab == 'subir'">
            <subir-material-profesor
                *ngIf="materialTipos && clasificaciones"
                [asignaturaId]="asignaturaId"
                [evaluacionTipo]="evaluacionTipo"
                [materialTipos]="materialTipos"
                [clasificaciones]="clasificaciones"
                (saved)="addMaterial($event)"
            ></subir-material-profesor>
        </div>
        <div *ngIf="selectedTab == 'evaluacion'">
            <agregar-evaluacion-plan-clase
                [evaluacionTipo]="evaluacionTipo"
                [asignaturaId]="asignaturaId"
                (saved)="addEvaluacion($event)"
            ></agregar-evaluacion-plan-clase>
        </div>
        <div *ngIf="selectedTab == 'prueba_propia'">
            <agregar-prueba-propia-plan-clase
                [evaluacionTipo]="evaluacionTipo"
                [asignaturaId]="asignaturaId"
                (saved)="addEvaluacion($event)"
            ></agregar-prueba-propia-plan-clase>
        </div>
    </modal-contenido>
</generic-modal>
