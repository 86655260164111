import { Component, OnInit, Input } from "@angular/core"
import { Noticia, Noticias } from "@puntaje/puntaje/api-services"

@Component({
    selector: "ver-noticia",
    templateUrl: "ver_noticia.component.html"
})
export class VerNoticiaComponent implements OnInit {
    @Input() noticiaId
    @Input() noticia: Noticia
    @Input() showTitle: boolean = true

    constructor(protected noticiasService: Noticias) {}

    ngOnInit() {
        if (!this.noticia && this.noticiaId) {
            this.noticiasService.find(this.noticiaId).then((noticia: Noticia) => {
                this.noticia = noticia
            })
        }
    }
}
