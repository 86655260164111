import { PuntajeAyudaModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { AyudaModule } from "@puntaje/puntaje/new-modules/ayuda"

import { profesoresAyudaRouting } from "./profesores_ayuda.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProfesoresLayoutsModule,
        profesoresAyudaRouting,
        AyudaModule,
        PuntajeAyudaModule
    ],
    declarations: [],
    exports: [],
    providers: []
})
export class ProfesoresAyudaModule {}
