import { Component, OnInit, Input } from "@angular/core"
import { Asignatura, TipoInstrumento } from "@puntaje/nebulosa/api-services"
import { select, Store } from "@ngrx/store"
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"

@Component({
    selector: "tabla-promedio-general-y-asignaturas",
    templateUrl: "./tabla-promedio-general-y-asignaturas.component.html",
    styleUrls: ["./tabla-promedio-general-y-asignaturas.component.scss"]
})
export class TablaPromedioGeneralYAsignaturasComponent implements OnInit {
    @Input() tipoInstrumento: string
    @Input() promedioGeneral: number
    @Input() promedioAsignaturas: { [key: number]: number }

    asignaturasByEvaluacionTipo: { [tipoEvaluacion: string]: Asignatura[] }
    asignaturas: Asignatura[]

    constructor(protected store: Store<State>) {}

    async ngOnInit() {
        this.asignaturasByEvaluacionTipo = await this.store
            .pipe(
                select(selectAsignaturasByEvaluacionTipo),
                filter(x => !!x),
                first()
            )
            .toPromise()
        this.setAsignaturas()
    }

    setAsignaturas() {
        this.asignaturas = this.asignaturasByEvaluacionTipo[this.tipoInstrumento]?.filter(asignatura => {
            let promedio = this.promedioAsignaturas[asignatura.id]
            return promedio !== undefined && promedio !== null
        })
    }
}
