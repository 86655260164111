<ll-titulo>{{ titulo }}</ll-titulo>
<ll-top-contenido><menu-evaluacion-tipo-asignaturas></menu-evaluacion-tipo-asignaturas></ll-top-contenido>
<br />
<plan-clases-profesor
    *ngIf="generadorInstrumentos"
    [generadorInstrumentos]="generadorInstrumentos"
    [enableCreatePlan]="enableCreatePlan"
    [enableEditPlan]="enableEditPlan"
    [mios]="true"
    [asignaturaId]="asignaturaId"
    [evaluacionTipo]="tipoInstrumento"
></plan-clases-profesor>
