import { Component, OnInit, Input } from "@angular/core"
import { PlanPersonal, PlanPersonales, PlanPersonalCiclo } from "@puntaje/puntaje/api-services"

import { Router } from "@angular/router"
import { PlanClaseStorageService } from "../plan-clase-storage.service"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "plan-clase-new",
    templateUrl: "./plan-clase-new.component.html",
    styleUrls: ["./plan-clase-new.component.scss"]
})
export class PlanClaseNewComponent implements OnInit {
    @Input() asignaturaId: number
    @Input() tipoEvaluacion: string
    @Input() enableModelos: boolean = true
    planClase: PlanPersonal = new PlanPersonal()
    planPersonalCiclo = new PlanPersonalCiclo()
    planClasesRoute = config?.plataforma?.planClasesRouteDocentes || "plan_clases"
    disableSave: boolean = false

    constructor(
        protected authService: AuthService,
        protected planClaseService: PlanPersonales,
        public planClaseStorageService: PlanClaseStorageService,
        protected router: Router
    ) {}

    ngOnInit() {
        this.planClase.usuario_id = this.authService.getUserData().id
        this.planPersonalCiclo["orden"] = 0

        const planPersonalCiclos = []
        planPersonalCiclos.push(this.planPersonalCiclo)
        this.planClase.plan_personal_ciclos = planPersonalCiclos
    }

    save(planClase: PlanPersonal) {
        this.disableSave = true

        planClase.usuario_id = this.authService.getUserData().id

        this.planClaseService
            .save(planClase)
            .then(response => {
                this.router.navigate([this.planClasesRoute], {
                    queryParams: {
                        tipo_instrumento: this.tipoEvaluacion,
                        asignatura_id: this.asignaturaId,
                        tab: "mis_plan_clases"
                    }
                })

                this.planClaseStorageService.clear()
            })
            .finally(() => {
                this.disableSave = false
            })
    }
}
