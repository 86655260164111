import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Subscription, combineLatest } from "rxjs"
import { ActivatedRoute, Router } from "@angular/router"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { select, Store } from "@ngrx/store"
import {
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    State,
    SetSelectedAsignaturaWithId,
    selectEvaluacionTipoAliases
} from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { AuthService } from "@puntaje/shared/core"
import { FiltroEstadisticasService } from "@puntaje/puntaje/new-modules/estadisticas"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "estadisticas.component.html"
})
export class EstadisticasComponent implements OnInit, OnDestroy {
    asignatura: Asignatura
    asignaturaId: number
    tipoInstrumento: string = config.plataforma.evaluacionDefault
    evaluacionTipoAlias: string

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    sub: Subscription
    subQuery: Subscription

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected store: Store<State>,
        protected authService: AuthService,
        protected router: Router,
        protected filtroEstadistica: FiltroEstadisticasService,
        protected loggedLayoutService: LoggedLayoutService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.estadisticas")
        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$, this.evaluacionTipoAliases$).subscribe(
            ([asignatura, evaluacionTipo, evaluacionTipoAliases]) => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo
                this.evaluacionTipoAlias = evaluacionTipoAliases[evaluacionTipo]
            }
        )

        this.loggedLayoutService.setOptions({ hideTitleOnPrint: true })
    }

    ngOnDestroy() {
        this.subQuery?.unsubscribe()
        this.sub?.unsubscribe()
    }

    back() {
        history.back()
    }
}
