import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, PaginatorComponent, pluralize } from "@puntaje/shared/core"
import {
    Establecimiento,
    Establecimientos,
    GrupoUsuario,
    GrupoUsuarios,
    GrupoUsuarioUsuarios,
    Usuarios,
    Asociaciones,
    Asociacion
} from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { BuscadorUsuarioService } from "./buscador-usuario/buscador_usuario.service"

@Component({
    selector: "grupo-usuarios-profesor",
    templateUrl: "grupo_usuarios_profesor.component.html",
    styleUrls: ["grupo_usuarios_profesor.component.scss"]
})
export class GrupoUsuariosProfesorComponent implements OnInit {
    searchParams: any
    establecimiento: Establecimiento
    grupoUsuarios: GrupoUsuario[]
    grupoUsuario: GrupoUsuario
    config: typeof config = config
    fechaInicial: Date
    fechaFinal: Date
    showGrupos: boolean = false
    asociacionesLoaded: boolean = false
    userId: number = this.authService.getUserData().id
    grupoUsuarioAlias: string = config.plataforma.grupoUsuarioAlias || "curso"
    subscription: Subscription
    buscadorParams: any

    asociaciones: Asociacion[]
    establecimientos: Establecimiento[]
    establecimientoSelected: Establecimiento

    mapCantidadCursos: { [k: string]: string } = {
        "=1": this.grupoUsuarioAlias,
        other: pluralize(this.grupoUsuarioAlias)
    }

    @ViewChild("externalLoading", { static: true }) externalLoading: LoadingLayoutComponent
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("paginator") paginator: PaginatorComponent

    constructor(
        protected usuariosService: Usuarios,
        protected grupoUsuariosService: GrupoUsuarios,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected authService: AuthService,
        protected establecimientosService: Establecimientos,
        protected asociacionesService: Asociaciones,
        protected cdr: ChangeDetectorRef,
        protected usuarioBuscadorService: BuscadorUsuarioService
    ) { }

    ngOnInit() {
        this.externalLoading.standby()
        this.getAsociaciones()
        this.subscription = this.usuarioBuscadorService.buscadorSubject.subscribe(buscadorParams => {
            this.buscadorParams = buscadorParams
            this.searchParams = buscadorParams
            this.paginator.reload()
        })
    }

    showAll = (page: number = 1, per: number = 20) => {
        this.grupoUsuarios = []
        this.loadingLayout.standby()

        const params = this.grupoUsuarioParams(page, per)

        return this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[], total: number) => {
            this.grupoUsuarios = grupoUsuarios
            if (total > 0) {
                this.showGrupos = true
            } else {
                this.showGrupos = false
            }
            this.loadingLayout.ready()

            return total
        })
    }

    setTotalUsuariosGrupoUsuario(idGrupototalUsuario) { }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicial = fechaInicial
        this.fechaFinal = fechaFinal
        if (this.paginator) this.paginator.reload()
    }

    private getAsociaciones = () => {
        this.asociacionesService
            .where({
                usuario_id: this.userId,
                include: "establecimientos"
            })
            .then((asociaciones: Asociacion[]) => {
                this.establecimientosService.where({ mios: 1 }).then((establecimientos: Establecimiento[]) => {
                    if (asociaciones && asociaciones.length > 0) {
                        this.asociaciones = asociaciones
                        this.establecimientos = ([] as Establecimiento[]).concat.apply(
                            [],
                            this.asociaciones.map(x => x.establecimientos)
                        )
                    }
                    if (establecimientos && establecimientos.length > 0) {
                        establecimientos.forEach(establecimiento => {
                            if (!this.establecimientos.find(e => e.id == establecimiento.id))
                                this.establecimientos.push(establecimiento)
                        })
                    }
                })
                this.externalLoading.ready()
                this.loadingLayout.ready()
            })
    }

    private grupoUsuarioParams(page: number = 1, per: number = 20) {
        let params = {
            page: page,
            per: per,
            establecimiento_id_not_nil: 1,
            propios: 1,
            activos: 1,
            grupo_usuario: {
                privado: 1
            },
            utp_establecimiento: 1,
            utp_asociacion: 1,
            grupo_usuario_usuario: {
                propietario: 1
            },
            establecimiento: {
                activo: 1
            },
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }
        if (this.establecimientoSelected) {
            params["grupo_usuario"]["establecimiento_id"] = this.establecimientoSelected.id
        }
        if (Object.keys(this.buscadorParams ?? {}).length > 0) {
            params = { ...params, ...this.buscadorParams }
        }

        return params
    }
}
