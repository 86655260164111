<ll-titulo>Instrumento #{{ instrumentoMultiple?.id }}</ll-titulo>

<ng-template [ngIf]="instrumentoMultiple">
    <ng-template ngFor let-instrumento [ngForOf]="instrumentoMultiple.instrumentos">
        <datos-generales
            [instrumento]="instrumento"
            [groupedBy]="['curso', 'habilidad cognitiva', 'eje temático,eje temático']"
        ></datos-generales>
    </ng-template>
</ng-template>
<div class="row top-margin">
    <div class="col-md-4 inverted-col">
        <opciones-instrumento-multiple
            *ngIf="instrumentoMultiple"
            [instrumentoMultiple]="instrumentoMultiple"
            [tipoEvaluacion]="'simulacro saber11'"
            [evaluacionMultipleId]="evaluacionMultipleId"
            [redirect]="rutaEvaluaciones"
        ></opciones-instrumento-multiple>
    </div>
    <div class="col-md-8">
        <h3 class="h3_sub_title_2">Vista previa</h3>
        <instrumento-multiple
            *ngIf="instrumentoMultiple"
            [instrumentoMultiple]="instrumentoMultiple"
        ></instrumento-multiple>
    </div>
</div>
