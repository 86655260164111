<!-- <a class="showLink" (click)="openModal.emit()">
    {{ "orientacion.carreras.terminos_modal.terminos_y_condiciones" | t }}
</a> -->
<generic-modal [buttonClicked]="openModal" [id]="'clasificar-recurso-modal'" [autoHeight]="true" [justifyBody]="true"
    #genericModal>
    <modal-titulo>{{ "Clasificar" | t }}</modal-titulo>
    <modal-contenido class="modal-contenido">
        <embed-clasificaciones [recursoEducativo]="recursoEducativo" [curriculum]="curriculum"
            [asignaturaId]="asignaturaId" [clasificaciones_seleccionadas]="clasificacionesSeleccionadas"
            (clasificacionesChange)="updateClasificaciones($event)"></embed-clasificaciones>
    </modal-contenido>
    <modal-botones>
        <cui-button type="button" class="" (click)="onAccept()">Aceptar</cui-button>
    </modal-botones>
</generic-modal>
