import { Component, OnInit } from "@angular/core"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { Subscription, combineLatest } from "rxjs"
import { Store, select } from "@ngrx/store"
import {
    State,
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    SetSelectedAsignaturaWithId
} from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "mis-plan-clases",
    templateUrl: "./mis-plan-clases.component.html",
    styleUrls: ["./mis-plan-clases.component.scss"]
})
export class MisPlanClasesComponent implements OnInit {
    asignaturaId: number
    asignatura: Asignatura
    tipoInstrumento: string
    generadorInstrumentos: GeneradorInstrumento[]
    hideProgress: boolean = false
    titulo = "Mis Planes de Clase"

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    sub: Subscription
    subQuery: Subscription
    subData: Subscription

    enableCreatePlan: boolean = true
    enableEditPlan: boolean = true

    constructor(
        protected titleService: TitleService,
        protected asignaturasService: Asignaturas,
        protected route: ActivatedRoute,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected store: Store<State>,
        protected authService: AuthService,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_clases.mis_plan_clases")
        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$).subscribe(
            ([asignatura, evaluacionTipo]) => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo
                this.getGeneradores()
            }
        )

        this.subData = this.route.data.subscribe(data => {
            this.enableCreatePlan = data.enableCreatePlan ?? this.enableCreatePlan
            this.enableEditPlan = data.enableEditPlan ?? this.enableEditPlan
            this.titulo = this.i18nService.translate("titles.core.plan_clases.mis_plan_clases") ?? this.titulo
        })
    }

    async getGeneradores() {
        const params = {
            asignatura_plataforma: {
                asignatura_id: this.asignaturaId,
                plataforma_id: config.plataforma.id
            },
            tipo_instrumento: {
                tipo_instrumento: this.tipoInstrumento
            },
            include: "[tipo_instrumento]"
        }

        this.generadorInstrumentos = await this.generadorInstrumentosService.where(params)
    }

    ngOnDestroy() {
        if (this.subQuery) this.subQuery.unsubscribe()
    }
}
