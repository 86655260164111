import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    Asignatura,
    Asignaturas,
    Clasificaciones,
    GeneradorInstrumentos,
    Preguntas
} from "@puntaje/nebulosa/api-services"
import { Evaluaciones, GrupoUsuarios, Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { AbstractGenerarInstrumentoComponent } from "./abstract_generar_instrumento.component"

declare let $

@Component({
    selector: "generar-instrumento-profesor",
    templateUrl: "generar_instrumento_profesor.component.html",
    styleUrls: ["generar_instrumento_profesor.component.scss"]
})
export class GenerarInstrumentoProfesorComponent
    extends AbstractGenerarInstrumentoComponent
    implements OnInit, OnChanges
{
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    _asignatura: Asignatura
    instrumentoAsignatura: Asignatura
    @Output() onReadyAsignatura: EventEmitter<any> = new EventEmitter<any>()
    instrumento: Instrumento
    instrumentos: Instrumento[]
    selectedInstrumento: Instrumento
    instrumentoId: number
    @Input() tipoInstrumento: string
    instrumentoBuscado: Instrumento
    instrumentosBuscados: Instrumento[]
    config = config
    totalPreguntas: string = "-"
    @Output() onReadyInstrumento: EventEmitter<any> = new EventEmitter<any>()
    @Output() onReadyInstrumentos: EventEmitter<any> = new EventEmitter<any>()
    @Input()
    set asignatura(asignatura: Asignatura) {
        this._asignatura = asignatura
    }
    get asignatura() {
        return this._asignatura
    }

    constructor(
        protected route: ActivatedRoute,
        protected asignaturasService: Asignaturas,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected preguntasService: Preguntas,
        protected evaluacionesService: Evaluaciones,
        protected grupoUsuariosService: GrupoUsuarios,
        protected clasificacionesService: Clasificaciones,
        protected instrumentosService: Instrumentos
    ) {
        super(router, generadorInstrumentosService)
    }

    ngOnInit() {
        super.ngOnInit()
        this.loadingLayout.ready()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignatura"] || changes["tipoEvaluacion"]) {
            this.loadData()
        }
    }

    callbackRecomendado(i: Instrumento) {
        this.instrumento = i
    }

    loadData() {
        //this.loadingLayout.standby();
        if (this.asignatura) this.generadorInstrumentos = this._asignatura.generador_instrumentos
        /*if(this.asignaturaId) {
            this.asignaturasService.find(this.asignaturaId, {tipo_instrumento: this.tipoEvaluacion, generador_instrumento: {grupal: [0, 1]}}).then((asignatura: Asignatura) => {
                this.asignatura = asignatura;
                this.onReadyAsignatura.emit(this.asignatura);
                this.generadorInstrumentos = asignatura.generador_instrumentos;
            });
        }*/
    }

    generarInstrumento() {
        if (this.numeroPreguntas == null || this.numeroPreguntas == 0) {
            return
        }
        this.loadingLayout.standby()
        let instrumento = new Instrumento() as any
        instrumento.tiempo = this.tiempo
        instrumento.numero_preguntas = this.numeroPreguntas
        instrumento.asignatura_id = this.asignatura.id
        instrumento.generador_instrumento_id = this.generadorInstrumento.id
        instrumento.clasificaciones_id = this.clasificacionesIds
        this.instrumentosService.save(instrumento).then((i: Instrumento | Instrumento[]) => {
            if (Array.isArray(i)) {
                this.onReadyInstrumento.emit(i)
            } else {
                this.onReadyInstrumentos.emit(i)
            }
            this.loadingLayout.ready()
        })
    }

    onExternalLoadingInstrumento() {
        //this.loadingLayout.standby();
    }

    onInstrumentoReady() {
        //this.loadingLayout.ready();
    }

    onAsignaturaInstrumentoReady(instrumentoAsignatura) {
        this.instrumentoAsignatura = instrumentoAsignatura
    }

    async onClasificacionesChange(clasificacionesIds: any) {
        if (clasificacionesIds && clasificacionesIds.length > 0) {
            this.totalPreguntas = "-"
            const params: any = {
                platform: config.plataforma.name,
                group_by: config.evaluaciones[this.tipoInstrumento].clasificaciones.groupBy,
                clasificacionesIds: clasificacionesIds
            }
            const total = await this.generadorInstrumentosService.totalFiltroClasificaciones(
                this.generadorInstrumento.id,
                params
            )
            this.totalPreguntas = total + ""
        }
    }
}
