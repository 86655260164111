import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core"
import {
    MaterialTipos,
    MaterialTipo,
    Material,
    NebuAuthService,
    Materiales,
    Clasificacion,
    ClasificacionMaterial,
    Clasificaciones
} from "@puntaje/nebulosa/api-services"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { S3Service, S3, AuthService } from "@puntaje/shared/core"
import { MaterialUsuario, MaterialUsuarios } from "@puntaje/puntaje/api-services"

@Component({
    selector: "subir-material-profesor",
    templateUrl: "./subir-material-profesor.component.html",
    styleUrls: ["./subir-material-profesor.component.scss"]
})
export class SubirMaterialProfesorComponent implements OnInit {
    @Input() asignaturaId: number
    @Input() evaluacionTipo: string = "curricular"
    @Output() saved = new EventEmitter<Material>()

    @Input() materialTipos: MaterialTipo[]
    @Input() clasificaciones: Clasificacion[]

    selectedTab = "pdf"

    materialPdf: Material
    materialVideo: Material
    materialMp4: Material
    materialLink: Material
    materialPPT: Material
    materialWord: Material
    materialExcel: Material

    errorMessage: string

    uploading: boolean = false

    clasificacion = false
    clasificacionMateriales: any[]
    clasificacionesSeleccionadas = { curso: null, primerNivel: null, segundoNivel: null }

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected s3Service: S3Service,
        protected nebuAuthService: NebuAuthService,
        protected materialesService: Materiales,
        protected authService: AuthService,
        protected materialUsuariosService: MaterialUsuarios,
        protected clasificacionesService: Clasificaciones,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        this.clasificacionMateriales =
            config.evaluaciones[this.evaluacionTipo].clasificaciones.clasificacionTiposMaterialPlanClase
        this.initialize()
    }

    initialize() {
        const materialTipoArchivo = this.materialTipos.find(mt => mt.material_tipo == "Archivo")
        const materialTipoVideo = this.materialTipos.find(mt => mt.material_tipo == "Video")
        const materialTipoMp4 = this.materialTipos.find(mt => mt.material_tipo == "Video MP4")
        const materialTipoLink = this.materialTipos.find(mt => mt.material_tipo == "Link")
        const materialTipoPPT = this.materialTipos.find(mt => mt.material_tipo == "PPT")
        const materialTipoWord = this.materialTipos.find(mt => mt.material_tipo == "Word")
        const materialTipoExcel = this.materialTipos.find(mt => mt.material_tipo == "Excel")

        this.materialPdf = this.createMaterial(materialTipoArchivo)
        this.materialVideo = this.createMaterial(materialTipoVideo)
        this.materialMp4 = this.createMaterial(materialTipoMp4)
        this.materialLink = this.createMaterial(materialTipoLink)
        this.materialPPT = this.createMaterial(materialTipoPPT)
        this.materialWord = this.createMaterial(materialTipoWord)
        this.materialExcel = this.createMaterial(materialTipoExcel)

        this.loadingLayout.ready()
    }

    createMaterial(materialTipo) {
        const material = new Material()
        material.visible = false
        material.asignatura_id = this.asignaturaId
        material.material_tipo_id = materialTipo.id

        return material
    }

    selectTab(tab) {
        this.errorMessage = null
        this.selectedTab = tab
    }

    uploadVideo() {
        if (!this.materialVideo.material) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_nombre")

            return
        }

        if (!this.materialVideo.url) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_video_url")

            return
        }

        if (
            (!this.materialVideo.url.startsWith("http") && !this.materialVideo.url.startsWith("https")) ||
            (this.materialVideo.url.indexOf("youtube.com") == -1 && this.materialVideo.url.indexOf("youtu.be") == -1)
        ) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_video_url_youtube_valida")

            return
        }

        this.saveMaterial(this.materialVideo)
    }

    uploadPDF() {
        if (this.materialPdf.file && !this.materialPdf.file.name.endsWith(".pdf")) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_pdf")

            return
        }

        if (!this.materialPdf.material) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_nombre")

            return
        }

        if (!this.materialPdf.file) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_pdf_archivo")

            return
        }

        this.uploadToS3(this.materialPdf)
    }

    uploadPPT() {
        if (
            this.materialPPT.file &&
            !this.materialPPT.file.name.endsWith(".ppt") &&
            !this.materialPPT.file.name.endsWith(".pptx")
        ) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_ppt")

            return
        }

        if (!this.materialPPT.material) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_nombre")

            return
        }

        if (!this.materialPPT.file) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_ppt_archivo")

            return
        }

        this.uploadToS3(this.materialPPT)
    }

    uploadWord() {
        if (
            this.materialWord.file &&
            !this.materialWord.file.name.endsWith(".doc") &&
            !this.materialWord.file.name.endsWith(".docx")
        ) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_word")

            return
        }

        if (!this.materialWord.material) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_nombre")

            return
        }

        if (!this.materialWord.file) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_word_archivo")

            return
        }

        this.uploadToS3(this.materialWord)
    }

    uploadExcel() {
        if (
            this.materialExcel.file &&
            !this.materialExcel.file.name.endsWith(".xls") &&
            !this.materialExcel.file.name.endsWith(".xlsx")
        ) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_excel")

            return
        }

        if (!this.materialExcel.material) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_nombre")

            return
        }

        if (!this.materialExcel.file) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_excel_archivo")

            return
        }

        this.uploadToS3(this.materialExcel)
    }

    uploadMp4() {
        if (this.materialMp4.file && !this.materialMp4.file.name.endsWith(".mp4")) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_mp4")

            return
        }

        if (!this.materialMp4.material) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_nombre")

            return
        }

        if (!this.materialMp4.file) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_mp4_archivo")

            return
        }

        this.uploadToS3(this.materialMp4)
    }

    uploadLink() {
        if (!this.materialLink.material) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_nombre")

            return
        }

        if (!this.materialLink.url) {
            this.errorMessage = this.i18nService.translate("subir_material_profesor.error_link")

            return
        }

        this.saveMaterial(this.materialLink)
    }

    uploadToS3(material: Material) {
        this.uploading = true

        this.s3Service.enableNebuBaseUrl()
        this.s3Service.changeAuthService(this.nebuAuthService)

        this.s3Service.where(material.getS3Params()).then(policy => {
            const p = policy as any as S3
            material.url = p["key"]

            this.s3Service.uploadToS3(p, material.file, this.materialesService.tableName, () =>
                this.saveMaterial(material)
            )

            this.s3Service.disableNebuBaseUrl()
            this.s3Service.restoreAuthService()
        })
    }

    handleSelectClasificacion(c, tipo) {
        this.clasificacionesSeleccionadas[tipo] = c
    }

    saveMaterial = (material: Material) => {
        this.uploading = true

        if (this.clasificaciones) {
            const clasificacionMateriales = this.clasificaciones.map(clasificacion => {
                const clasificacionMaterial = new ClasificacionMaterial()

                clasificacionMaterial.clasificacion_id = clasificacion.id

                return clasificacionMaterial
            })

            material.clasificacion_materiales = clasificacionMateriales
        }

        if (this.clasificacion) {
            const clasificacionMateriales = []
            Object.values(this.clasificacionesSeleccionadas)
                .filter(x => !!x)
                .forEach((c: Material) => {
                    const clasificacionMaterial = new ClasificacionMaterial()
                    clasificacionMaterial.clasificacion_id = c.id
                    clasificacionMateriales.push(clasificacionMaterial)
                })

            material.clasificacion_materiales = [...material.clasificacion_materiales, ...clasificacionMateriales]
        }

        const usuario = this.authService.getUserData()
        material["personaExterna"] = {
            usuario_id: usuario.id,
            nombre: usuario.nombre,
            apellido_paterno: usuario.apellido_paterno,
            apellido_materno: usuario.apellido_materno,
            plataforma_id: config.plataforma.id
        }

        this.materialesService.save(material).then(m => {
            const materialUsuario = new MaterialUsuario()
            materialUsuario.material_id = m.id

            this.materialUsuariosService.save(materialUsuario).then(mu => {
                this.uploading = false
                const materialTipo = this.materialTipos.find(mt => m.material_tipo_id == mt.id)
                m.material_tipo = materialTipo

                this.saved.emit(m)
            })
        })
    }
}
