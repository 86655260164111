import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./plan-clase.component.html",
    styleUrls: ["./plan-clase.component.scss"]
})
export class PlanClaseComponent implements OnInit {
    sub: Subscription
    querySub: Subscription
    planPersonalId: number
    disableCompartir: boolean

    vistaPrevia = config.app.name == "admin"
    urlNavigate = config?.plataforma?.planClasesRoute || "/plan_clases"

    subData: Subscription

    constructor(protected titleService: TitleService, protected router: Router, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_clases.plan_clases.show")
        this.sub = this.route.params.subscribe(params => {
            this.planPersonalId = +params["id"]
        })

        this.querySub = this.route.queryParams.subscribe(params => {
            this.disableCompartir = params["disableCompartir"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.vistaPrevia = data.vistaPrevia ?? this.vistaPrevia
            this.urlNavigate = data.urlNavigate ?? this.urlNavigate
        })
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
        this.querySub.unsubscribe()
    }
}
