<div *ngIf="!lastStep">
    <label *ngIf="config && !config.plataforma.hideMessageSugerencia" class="sugerencia">
        {{ "evaluar.subir_propia.sugerencia" | t }}
    </label>
    <div class="contentComponente">
        <subir-archivo [step]="1" [material]="material" (changeData)="goToShowPreview($event)"></subir-archivo>
    </div>
    <div class="contentComponente" id="showPreview">
        <div *ngIf="showPreview">
            <vista-previa-archivo [step]="2" [fileParams]="fileParams" [material]="material"
                (changeData)="goToAsignaturas()"></vista-previa-archivo>
        </div>
    </div>
    <div class="contentComponente" id="showAsignaturas">
        <div *ngIf="showAsignaturas">
            <selects-evaluacion-asignatura [step]="3" [evaluacionTipo]="evaluacionTipo"
                (changeData)="goToContestableTipos($event)"></selects-evaluacion-asignatura>
        </div>
    </div>
    <div class="contentComponente" id="showContestableTipos">
        <div *ngIf="showContestableTipos">
            <select-contestable-tipo [step]="4" (changeData)="goToPautaOrClasificarPreguntas($event)">
            </select-contestable-tipo>
        </div>
    </div>
    <div class="contentComponente" id="showPauta">
        <div *ngIf="showPauta">
            <ng-container *ngIf="contestableTipo?.contestable_tipo == 'Pregunta de alternativas'">
                <subir-pauta [step]="skipPaso4 ? 4 : 5" [evaluacionTipo]="evaluacionTipo"
                    [max_alternativas]="maximoAlternativas" (changeData)="goToClasificarPreguntas($event)">
                </subir-pauta>
            </ng-container>
            <ng-container *ngIf="!contestableTipo || contestableTipo.contestable_tipo != 'Pregunta de alternativas'">
                <subir-pauta-mixta [step]="5" (changeData)="goToClasificarPreguntas($event)"
                    [disableChangeContestableTipo]="disableChangeContestableTipo" [contestableTipo]="contestableTipo"
                    [generadorInstrumento]="generadorInstrumento"></subir-pauta-mixta>
            </ng-container>
        </div>
    </div>
    <div class="contentComponente" id="showClasificarPreguntas">
        <div *ngIf="showClasificarPreguntas">
            <clasificar-preguntas-pauta [curriculum]="curriculum" [asignaturaId]="asignaturaId"
                [numeroPreguntas]="numero_preguntas" [contestableTipo]="contestableTipo"
                [askNumeroPreguntas]="askNumeroPreguntas" [step]="showPauta && !skipPaso4 ? 6 : 5"
                (changeData)="goToTerminos($event)"></clasificar-preguntas-pauta>
        </div>
    </div>
    <div class="contentComponente" id="showTerminos">
        <div *ngIf="showTerminos">
            <terminos-condiciones [step]="showPauta && !skipPaso4 ? 7 : 6"
                [askNumeroPreguntas]="askNumeroPreguntas && !numero_preguntas" (changeData)="uploadInstrumento($event)">
            </terminos-condiciones>
        </div>
    </div>
</div>

<div *ngIf="lastStep && !doNotShowLastStep">
    <div class="contentFinalaizado">
        <div class="contentInfo">
            <div class="contenIconCheck">
                <div class="icon">
                    <ogr-icon name="ticket" align="text-top"></ogr-icon>
                </div>
            </div>
            <p>
                {{ "evaluar.subir_propia.exito" | t }}
                <a [routerLink]="['/instrumentos', instrumento.id]">
                    {{ "evaluar.subir_propia.instrumento" | t }} #{{ instrumento.id }}
                </a>
            </p>
        </div>

        <div class="contentButton">
            <button class="btn btn-default" (click)="restart()" style="color: #2691c5">
                <ogr-icon name="arrow-left-o" align="text-top"></ogr-icon>
                &nbsp;{{ "evaluar.subir_propia.boton_subir" | t }}
            </button>
            <div class="contentButtonTwo">
                <cui-button class="btn_style" (click)="imprimirAlCompartir = false; openModal.emit()">
                    {{ "evaluar.subir_propia.enviar_online" | t }}
                </cui-button>
                <cui-button class="btn_style" (click)="imprimirAlCompartir = true; openModal.emit()">
                    {{ "evaluar.subir_propia.asignar_impr" | t }}
                </cui-button>
                <cui-button *ngIf="showCompartirMasTarde" class="btn_style" (click)="goToHistory()">
                    {{ "evaluar.subir_propia.comp_tarde" | t }}
                </cui-button>
            </div>
        </div>
    </div>
</div>

<compartir-instrumento-profesor *ngIf="instrumento" [instrumento]="instrumento"
    (evaluacionCompartida)="onEvaluacionCompartida($event)" [openModal]="openModal"
    [closeOnFinished]="imprimirAlCompartir" [presencial]="imprimirAlCompartir" [tipoEvaluacion]="evaluacionTipo"
    [redirect]="''"></compartir-instrumento-profesor>

<ng-template #mandatoryLoadingTemplate>
    <div class="modal-body">
        <div *ngIf="uploading > 0">
            <loading-layout #loadingLayout></loading-layout>
            <ng-container [ngSwitch]="uploading">
                <p *ngSwitchCase="1">{{ "evaluar.subir_propia.loading1" | t }}...</p>
                <p *ngSwitchCase="2">{{ "evaluar.subir_propia.loading2" | t }}...</p>
                <p *ngSwitchCase="3">{{ "evaluar.subir_propia.loading3" | t }}...</p>
                <p *ngSwitchCase="4">{{ "evaluar.subir_propia.loading4" | t }}...</p>
                <p *ngSwitchCase="5">{{ "evaluar.subir_propia.loading5" | t }}...</p>
                <p *ngSwitchDefault>...</p>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #imprimiendoLoadingTemplate>
    <div class="modal-content">
        <div class="modal-body">
            <ng-container *ngIf="!errorMsg">
                <loading-layout #loadingLayout [noMarginY]="true" class="loading"></loading-layout>
                <p class="loading-message">
                    {{ "evaluar.subir_propia.loading_message1" | t }}
                    <br />
                    {{ "evaluar.subir_propia.loading_message2" | t }}
                </p>
            </ng-container>
            <ng-container *ngIf="errorMsg">
                <p class="warning-icon">
                    <fa name="warning"></fa>
                </p>
                <p class="error-message">
                    {{ "evaluar.subir_propia.error1" | t }}
                    <br />
                    {{ "evaluar.subir_propia.error2" | t }}
                </p>
            </ng-container>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cancelPrint()"
                *ngIf="!errorMsg">
                {{ "evaluar.subir_propia.cancel_button" | t }}
            </button>
            <button type="button" class="btn btn-default" data-dismiss="modal" *ngIf="errorMsg"
                (click)="mandatoryLoading.hide()">
                {{ "evaluar.subir_propia.cerrar_button" | t }}
            </button>
        </div>
    </div>
</ng-template>