import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { PaginatorComponent } from "@puntaje/shared/core"
import { PlanPersonalSesiones, Usuarios } from "@puntaje/puntaje/api-services"

@Component({
    selector: "plan-clase-sesion-detalle-por-contenido",
    templateUrl: "./plan-clase-sesion-detalle-por-contenido.component.html",
    styleUrls: ["./plan-clase-sesion-detalle-por-contenido.component.scss"]
})
export class PlanClaseSesionDetallePorContenidoComponent implements OnInit {
    @Input() planPersonalSesionId: number
    detallePorContenidoAlumnos: any
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    private _grupoUsuarioId: number
    totalAlumnos: number
    @Input()
    get grupoUsuarioId(): number {
        return this._grupoUsuarioId
    }
    set grupoUsuarioId(grupoUsuarioId: number) {
        this._grupoUsuarioId = grupoUsuarioId
        this.usuariosService
            .where({ grupo_usuario_usuario: { propietario: false, grupo_usuario_id: grupoUsuarioId } })
            .then((_, total) => (this.totalAlumnos = total))
    }

    constructor(protected planPersonalSesionesService: PlanPersonalSesiones, protected usuariosService: Usuarios) {}

    ngOnInit() {
        this.getData()
    }

    getData = async (page: number = 1, count: number = 10) => {
        let params = {
            page,
            per: count,
            grupo_usuario_id: this.grupoUsuarioId
        }
        this.detallePorContenidoAlumnos = await this.planPersonalSesionesService.detalleContenidoSesion(
            this.planPersonalSesionId,
            params
        )
        return this.totalAlumnos
    }
}
