import { Component, OnInit, Input } from "@angular/core"
import { Evaluacion, Establecimiento, GrupoUsuario, EvaluacionInstancia, Usuario } from "@puntaje/puntaje/api-services"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "estadisticas-portada",
    templateUrl: "./estadisticas-portada.component.html",
    styleUrls: ["./estadisticas-portada.component.scss"]
})
export class EstadisticasPortadaComponent implements OnInit {
    @Input() titulo: string
    @Input() evaluacionTipo: string
    @Input() evaluacion: Evaluacion
    @Input() establecimiento: Establecimiento
    @Input() asignatura: Asignatura
    @Input() grupoUsuario: GrupoUsuario
    @Input() usuario: Usuario
    @Input() evaluacionInstancias: EvaluacionInstancia[]
    @Input() estadisticas: any
    nombreGrupo: string = "Curso"
    config: typeof config
    logosrc: string = ""

    constructor() {
        this.config = config
    }

    ngOnInit() {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias
        this.logosrc = config.app.assets.logoMedium
    }
}
