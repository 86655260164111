<ll-titulo>{{ "puntaje_core.revisar_ensayos.titulo" | t }}</ll-titulo>
<ll-subtitulo>
    {{ asignatura?.asignatura }}
</ll-subtitulo>
<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas [showTitleOverMenu]="showTitleOverMenu"></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>

<ng-container *ngIf="asignaturaId">
    <evaluaciones-compartidas
        [asignaturaId]="asignaturaId"
        [evaluacionTipo]="tipoInstrumento"
        [tipoInstrumentoAlias]="tipoInstrumentoAlias"
        [showRevisionPersonalizada]="showRevisionPersonalizada"
    ></evaluaciones-compartidas>
    <evaluaciones-presenciales
        [asignaturaId]="asignaturaId"
        [evaluacionTipo]="tipoInstrumento"
        [tipoInstrumentoAlias]="tipoInstrumentoAlias"
    ></evaluaciones-presenciales>
    <instrumentos-no-compartidos
        [asignaturaId]="asignaturaId"
        [evaluacionTipo]="tipoInstrumento"
    ></instrumentos-no-compartidos>
</ng-container>
