import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { PaginatorComponent } from "@puntaje/shared/core"
import {
    Establecimiento,
    Establecimientos,
    Evaluaciones,
    EvaluacionMultiple,
    EvaluacionMultiples,
    GrupoUsuario,
    InstrumentoMultiple,
    InstrumentoMultiples,
    Usuario
} from "@puntaje/puntaje/api-services"
import { FiltroEstadisticasService } from "@puntaje/puntaje/new-modules/estadisticas"
import { CompartirReforzamientosComponent } from "../compartir_reforzamientos.component"

@Component({
    selector: "evaluaciones-multiples-compartidas",
    templateUrl: "evaluaciones_multiples_compartidas.component.html",
    styleUrls: ["evaluaciones_multiples_compartidas.component.scss"]
})
export class EvaluacionesMultiplesCompartidasComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(CompartirReforzamientosComponent)
    compartirReforzamientos: CompartirReforzamientosComponent
    instrumentoMultiple: InstrumentoMultiple
    evaluacionMultipleToEdit: EvaluacionMultiple
    // evaluacionToReforzar: EvaluacionMultiple;
    @Input() tipoId: number = 1
    @Input() rutaEvaluaciones: string
    openModal: EventEmitter<any> = new EventEmitter<any>()
    openModalEdit: EventEmitter<any> = new EventEmitter<any>()
    nombreGrupo: string

    fechaInicial: Date
    fechaFinal: Date

    @Input() evaluacionTipo: string

    establecimiento: Establecimiento
    grupoUsuario: GrupoUsuario

    asignatura: Asignatura
    evaluacionMultiples: EvaluacionMultiple[]
    establecimientos: Establecimiento[]
    propietarios: Usuario[]
    propietario: Usuario

    presencial: number = 0
    tipo: string

    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    constructor(
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected router: Router,
        protected asignaturasService: Asignaturas,
        protected evaluacionesService: Evaluaciones,
        protected evaluacionMultiplesService: EvaluacionMultiples,
        protected establecimientosService: Establecimientos,
        protected instrumentoMultiplesService: InstrumentoMultiples,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.tipo = this.presencial == 1 ? "presenciales" : "compartidas"
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias
        if (!this.establecimientos) {
            this.loadingLayout.standby()
        }
    }

    setEstablecimientos() {
        let params = {
            mios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            //filtro activo
            establecimiento: { activo: 1 },
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            if (this.establecimientos.length == 1) {
                this.establecimiento = this.establecimientos[0]
                this.setPropietarios()
            } else if (this.establecimientos.length == 0) {
                this.loadingLayout.ready()
            }
        })
    }

    setEvaluacionTipo(evaluacionTipo) {
        this.evaluacionTipo = evaluacionTipo

        this.paginator.changePage(1)
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicial = fechaInicial
        this.fechaFinal = fechaFinal

        this.setEstablecimientos()
    }

    setData = (page: number, per: number) => {
        this.loadingLayout.standby()

        let params = {
            page: page,
            per: per,
            mis_colegios: 1,
            with_instrumento_multiple: 1,
            // evaluacion_multiple: {
            //     presencial: this.presencial
            // },
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal,
            order: "desc",
            sort_by: "created_at"
        }

        this.establecimiento && (params["establecimiento"] = { id: this.establecimiento.id })
        this.grupoUsuario && (params["grupo_usuario"] = { id: this.grupoUsuario.id })
        this.propietario && (params["evaluacion_multiple_usuario"] = { usuario_id: this.propietario.id })
        this.evaluacionTipo && (params["evaluacion_tipo"] = { evaluacion_tipo: this.evaluacionTipo })

        this.cdr.detectChanges()
        return this.evaluacionMultiplesService
            .where(params)
            .then((evaluacionMultiples: EvaluacionMultiple[], total: number) => {
                this.evaluacionMultiples = evaluacionMultiples
                this.loadingLayout.ready()
                return total
            })
    }

    onVerEstadisticas(
        establecimientoId,
        grupoUsuarioId,
        evaluacionMultipleId,
        generadorInstrumentoId,
        tipoInstrumento
    ) {
        this.filtroEstadisticasService.setFiltrosId(
            establecimientoId,
            grupoUsuarioId,
            null,
            evaluacionMultipleId,
            generadorInstrumentoId,
            tipoInstrumento,
            0
        )
        this.router.navigate(["/saber11/estadisticas_multiples"], {
            queryParams: { tipo_instrumento: tipoInstrumento }
        })
    }

    compartirInstrumento(instrumentoMultipleId: number) {
        this.instrumentoMultiplesService
            .find(instrumentoMultipleId)
            .then((instrumentoMultiple: InstrumentoMultiple) => {
                this.instrumentoMultiple = instrumentoMultiple
                setTimeout(() => {
                    this.openModal.emit()
                }, 100)
            })
    }

    editarEvaluacion(evaluacionMultiple: EvaluacionMultiple) {
        this.evaluacionMultipleToEdit = evaluacionMultiple
        this.openModalEdit.emit()
    }

    setPropietarios() {
        this.propietarios = []
        this.propietario = undefined
        if (this.establecimiento) {
            if (this.grupoUsuario) {
                this.propietarios = this.grupoUsuario.propietarios
            } else {
                this.establecimiento.grupo_usuarios.forEach(g => {
                    g.propietarios.forEach(p => {
                        let prop = this.propietarios.find(pr => pr.id == p.id)
                        if (!prop) {
                            this.propietarios.push(p)
                        }
                    })
                })
            }
        }
    }

    // compartirReforzamiento(evaluacion: Evaluacion) {
    //     this.evaluacionMultipleToReforzar = evaluacion;

    //     this.compartirReforzamientos.open();
    // }
}
