import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from "@angular/core"
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms"
import { Usuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
import { BuscadorUsuarioService } from "./buscador_usuario.service"
declare const config: AppConfig

@Component({
    selector: "buscador-usuario",
    templateUrl: "./buscador-usuario.component.html",
    styleUrls: ["./buscador-usuario.component.scss"]
})
export class BuscadorUsuarioComponent implements OnInit {
    @Output() limpiarFiltro: EventEmitter<any> = new EventEmitter<any>()
    pais: string = config.plataforma.pais
    identificadoresPais: string[] = config.plataforma.identificadorUsuario.filter(
        identificador => identificador.toLowerCase() != "usuario_id"
    )
    usuarios: Usuario[] = []
    buscadorOpen: boolean = false
    limpiarDatos: boolean = false
    textoBoton: String = "Buscar por estudiante"
    buscadorForm: UntypedFormGroup
    gruposParaFiltrar: any[]

    constructor(private formBuilder: UntypedFormBuilder, protected buscadorUsuario: BuscadorUsuarioService) {}

    ngOnInit(): void {
        this.buscadorForm = this.formBuilder.group({
            rut: [""],
            nombre: [""],
            apellido_materno: [""],
            apellido_paterno: [""]
        })
    }

    search() {
        const params: any = {}
        if (this.buscadorForm.controls.rut.value) {
            params["usuario_" + this.pais] = {}
            params["usuario_" + this.pais][this.identificadoresPais.map(id => id.toLowerCase()).join("_o_")] =
                this.buscadorForm.controls.rut.value
        }
        if (this.buscadorForm.controls.nombre.value) {
            params.usuario = params.usuario || {}
            params.usuario.like = params.usuario.like || {}
            params.usuario.like["nombre"] = this.buscadorForm.controls.nombre.value?.toLowerCase()
        }
        if (this.buscadorForm.controls.apellido_materno.value) {
            params.usuario = params.usuario || {}
            params.usuario.like = params.usuario.like || {}
            params.usuario.like["apellido_materno"] = this.buscadorForm.controls.apellido_materno.value?.toLowerCase()
        }
        if (this.buscadorForm.controls.apellido_paterno.value) {
            params.usuario = params.usuario || {}
            params.usuario.like = params.usuario.like || {}
            params.usuario.like["apellido_paterno"] = this.buscadorForm.controls.apellido_paterno.value?.toLowerCase()
        }

        return this.buscadorUsuario.setBuscadorValues(params)
    }

    cleanData() {
        this.buscadorForm.reset()
        this.limpiarDatos = true
        return this.buscadorUsuario.setBuscadorValues({})
    }

    showBuscador() {
        if (!this.buscadorOpen) {
            this.buscadorOpen = true
            this.textoBoton = "Cerrar buscador"
        } else {
            this.buscadorOpen = false
            this.textoBoton = "Buscar por estudiante"

            this.cleanData()
        }
    }
}
