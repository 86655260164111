import { Component, OnInit, Input, EventEmitter, ViewChild } from "@angular/core"
import { GrupoUsuario, PlanPersonales, PlanPersonal } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { GenericModalComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "replicar-plan",
    templateUrl: "./replicar-plan.component.html",
    styleUrls: ["./replicar-plan.component.scss"]
})
export class ReplicarPlanComponent implements OnInit {
    hideReplicarPlan = config.plataforma.hideReplicarPlan || false
    constructor(protected planPersonalesService: PlanPersonales) {}

    ngOnInit() {
        this.loadingLayout.ready()
    }

    replicado = false

    _grupoUsuarios: { selected: boolean; grupoUsuario: GrupoUsuario }[] = []
    openModal: EventEmitter<boolean> = new EventEmitter<boolean>()

    @Input() grupoUsuarioOrigen: GrupoUsuario
    @Input() planPersonal: PlanPersonal
    @Input("grupoUsuarios")
    set grupoUsuarios(grupoUsuarios: GrupoUsuario[]) {
        this._grupoUsuarios = grupoUsuarios.map(gu => {
            return { selected: true, grupoUsuario: gu }
        })
    }

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent

    startReplicar() {
        this.replicado = false
        this.openModal.emit(true)
    }

    replicar() {
        this.loadingLayout.standby()
        this.planPersonalesService
            .replicar(
                this.planPersonal.id,
                this.grupoUsuarioOrigen.id,
                this._grupoUsuarios.map(gu => gu.grupoUsuario.id).filter(id => id != this.grupoUsuarioOrigen.id)
            )
            .then(response => {
                this.replicado = true
                this.loadingLayout.ready()
            })
    }
}
