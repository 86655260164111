import { BarraAvancePlanClaseComponent } from "./../barra-avance-plan-clase/barra-avance-plan-clase.component"
import { Component, OnInit, Input, SimpleChanges, ViewChild } from "@angular/core"
import {
    PlanPersonales,
    PlanPersonal,
    PlanPersonalContenidoGrupoUsuarios,
    PlanPersonalSesionGrupoUsuarios,
    GrupoUsuarios,
    GrupoUsuario,
    PlanClaseService,
    PlanPersonalUsuarios,
    PlanPersonalUsuario
} from "@puntaje/puntaje/api-services"
import { Materiales, ClasificacionTipos, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Store, select } from "@ngrx/store"
import { Subscription, BehaviorSubject, combineLatest } from "rxjs"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { AplicarPlanPersonalProfesoresComponent } from "@puntaje/puntaje/new-modules/planificaciones"
import { PlanClaseModeloService } from "../plan-clase-modelo.service"
import { Router } from "@angular/router"

@Component({
    selector: "plan-clase",
    templateUrl: "./plan-clase.component.html",
    styleUrls: ["./plan-clase.component.scss"]
})
export class PlanClaseComponent implements OnInit {
    titulo: string
    titulo_grupos: string
    mensaje_sin_grupos: string
    nombre_submit: string
    @Input() urlNavigate: string = config?.plataforma?.planClasesRoute || "/plan_clases"

    @Input() planPersonalId: number
    @Input() vistaPrevia: boolean = false
    @Input() actividad: boolean = false
    @Input() enableReplicar = true
    @Input() showButtonCompartir = true
    @Input() onlyShared = false
    @Input() enableModelo = true
    @Input() enableFiltroCurso = true
    grupoUsuario: GrupoUsuario
    planPersonalUsuarios: PlanPersonalUsuario[]

    @ViewChild(AplicarPlanPersonalProfesoresComponent)
    aplicarPlanPersonalComponent: AplicarPlanPersonalProfesoresComponent
    @ViewChild(LoadingLayoutComponent, { static: true })
    loadingLayoutComponent: LoadingLayoutComponent
    @ViewChild(BarraAvancePlanClaseComponent) barraAvanceComponent: BarraAvancePlanClaseComponent
    clasificacionContextoByMaterialId: any
    clasificacionActividadByMaterialId: any
    grupoUsuarios: GrupoUsuario[]
    curso_id: number
    sub: Subscription
    asignatura: any
    planPersonalSesiones = []
    _planPersonal: PlanPersonal
    planPersonalSubject = new BehaviorSubject<PlanPersonal>(null)
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    @Input()
    set planPersonal(planificacion: any) {
        this._planPersonal = planificacion
        if (planificacion) {
            this.planPersonalSesiones = planificacion.plan_personal_ciclos[0]?.plan_personal_sesiones.sort(
                (a, b) => a.orden - b.orden
            )
        }

        this.planPersonalSubject.next(planificacion)
    }

    get planPersonal() {
        return this._planPersonal
    }

    constructor(
        protected planPersonalesService: PlanPersonales,
        protected planPersonalContenidoGrupoUsuariosService: PlanPersonalContenidoGrupoUsuarios,
        protected planPersonalSesionGrupoUsuariosService: PlanPersonalSesionGrupoUsuarios,
        protected grupoUsuariosService: GrupoUsuarios,
        protected materialesService: Materiales,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        private store: Store<State>,
        protected planClaseService: PlanClaseService,
        protected planPersonalUsuariosService: PlanPersonalUsuarios,
        protected planClaseModeloService: PlanClaseModeloService,
        protected router: Router,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        this.titulo = this.i18nService.translate("estudiar.plan_de_clases.title", {})
        this.titulo_grupos = this.i18nService.translate("estudiar.plan_de_clases.title_grupos", {})
        this.mensaje_sin_grupos = this.i18nService.translate("estudiar.plan_de_clases.no_found_grupos", {})
        this.nombre_submit = this.i18nService.translate("estudiar.plan_de_clases.compartir", {})
        this.sub = combineLatest(
            this.planPersonalSubject.pipe(filter(x => !!x)),
            this.asignaturasByEvaluacionTipo$
        ).subscribe(([planificacion, asignaturasByEvaluacionTipo]) => {
            let lista_asignaturas = asignaturasByEvaluacionTipo[config.plataforma.evaluacionDefault]
            this.asignatura = lista_asignaturas.find(
                l => l.asignatura == this.planPersonal.generador_instrumento.asignatura.asignatura
            )
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["planPersonalId"] && this.planPersonalId) {
            this.setGrupoUsuarios()
        }
    }

    onSelectGrupoUsuario(grupoUsuario: GrupoUsuario) {
        this.grupoUsuario = grupoUsuario
        this.getData()
    }

    async setGrupoUsuarios() {
        const planPersonal = await this.planPersonalesService.find(this.planPersonalId)
        const generadorInstrumento = await this.generadorInstrumentosService.find(
            planPersonal.generador_instrumento_id,
            { include: "generador_instrumento_categorias:categoria:clasificacion_tipo" }
        )

        if (config.plataforma.clasificacionTipoNivel) {
            this.curso_id = generadorInstrumento.generador_instrumento_categorias
                .map(gic => gic.categoria)
                .find(c => c.clasificacion_tipo.clasificacion_tipo == config.plataforma.clasificacionTipoNivel).id
        }

        let guParams = {
            propios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1
        }

        if (this.curso_id && !this.onlyShared) {
            guParams["grupo_usuario"] = { curso_id: this.curso_id }
        }

        this.grupoUsuarios = await this.grupoUsuariosService.where(guParams)

        if (this.grupoUsuarios.length > 0) {
            if (this.onlyShared) {
                await this.searchShared()
            }
        } else {
            await this.getData()
        }

        this.onSelectGrupoUsuario(this.grupoUsuarios[0])
    }

    async searchShared() {
        this.planPersonalUsuarios = await this.planPersonalUsuariosService.where({
            grupo_usuarios: true,
            plan_personal_id: this.planPersonalId
        })
        const gu_ids_compartidos = this.planPersonalUsuarios.map(ppu => ppu.receptor_id)
        this.grupoUsuarios = this.grupoUsuarios.filter(gu => gu_ids_compartidos.includes(gu.id))
    }

    async getData() {
        this.loadingLayoutComponent.standby()
        await this.planClaseService.getData(this.planPersonalId, this.grupoUsuario ? this.grupoUsuario.id : null)
        this.planPersonal = this.planClaseService.planPersonal
        this.clasificacionContextoByMaterialId = this.planClaseService.clasificacionContextoByMaterialId
        this.clasificacionActividadByMaterialId = this.planClaseService.clasificacionActividadByMaterialId
        this.loadingLayoutComponent.ready()
    }

    avanceChanged(changed: boolean) {
        if (changed) {
            this.barraAvanceComponent.setAvanceBar()
        }
    }

    aplicar() {
        this.aplicarPlanPersonalComponent.open()
    }

    selectModelo() {
        this.planClaseModeloService.planClaseModelo = this.planPersonal
        this.router.navigate(["/plan_clases/new"], {
            queryParams: {
                asignatura_id: this.planPersonal.generador_instrumento.asignatura.id,
                tipo_instrumento: this.planPersonal.generador_instrumento.tipo_instrumento.tipo_instrumento
            }
        })
    }
}
