import { Component, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"
import {
    Establecimiento,
    Establecimientos,
    Estadisticas,
    Evaluacion,
    Evaluaciones,
    EvaluacionInstancia,
    EvaluacionInstancias,
    EvaluacionTiempo,
    EvaluacionTiempos,
    Usuario,
    Usuarios
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "resultados-evaluaciones-utp",
    templateUrl: "./resultados-evaluaciones-utp.component.html",
    styleUrls: ["./resultados-evaluaciones-utp.component.scss"]
})
export class ResultadosEvaluacionesUtpComponent implements OnInit {
    evaluacionIds: number[]
    evaluacionId: number
    establecimientos: Establecimiento[]
    selectedEstablecimientos: Establecimiento[]

    dropdownEstablecimientosSettings = {
        singleSelection: false,
        idField: "id",
        textField: "establecimiento",
        selectAllText: "Seleccionar todos",
        unSelectAllText: "Deseleccionar todos",
        itemsShowLimit: 4,
        allowSearchFilter: false
    }

    evaluacion: Evaluacion
    evaluacionInstancias: EvaluacionInstancia[]
    allEvaluacionInstancias: EvaluacionInstancia[]
    evaluacionTiempos: EvaluacionTiempo[]
    usuarios: Usuario[]
    estadisticas: any[]

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutEvaluaciones", { static: true }) loadingLayoutEvaluaciones: LoadingLayoutComponent

    constructor(
        protected establecimientosService: Establecimientos,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected evaluacionesService: Evaluaciones,
        protected simpleModalService: SimpleModalService,
        protected usuariosService: Usuarios,
        protected estadisticasService: Estadisticas,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.loadingLayoutEvaluaciones.ready()

        this.loadingLayout.standby()

        let params = { establecimiento: { id: this.authService.getEstablecimientos(), activo: 1 } }
        params["include"] = "establecimiento_" + config.plataforma.pais

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            const establecimiento = establecimientos[0]

            this.dropdownEstablecimientosSettings["enableCheckAll"] = this.establecimientos.length > 1 ? true : false

            if (this.establecimientos.length > 0) this.selectedEstablecimientos = [establecimiento]

            this.loadingLayout.ready()
        })
    }

    setEvaluacionIds(evaluacionesText: string) {
        this.evaluacionIds = evaluacionesText
            .split(",")
            .map(e => +e)
            .filter(e => e != 0 && !isNaN(e))
    }

    async buscarResultados() {
        this.loadingLayoutEvaluaciones.standby()

        const establecimientoIds = this.selectedEstablecimientos.map(e => e.id)

        const evaluaciones = await this.evaluacionesService.where({
            mis_colegios: 1,
            evaluacion: { id: [this.evaluacionId] }
        })

        if (!evaluaciones[0]) {
            this.simpleModalService.cleanModal()
            this.simpleModalService.setModalHeader("Advertencia")
            this.simpleModalService.setModalStringContent(
                "La evaluación no existe o no pertenece a tus establecimientos/asociaciones."
            )
            this.simpleModalService.showSimpleModal()

            this.loadingLayoutEvaluaciones.ready()

            return
        }

        this.evaluacion = evaluaciones[0]

        const params: any = {
            evaluacion_instancia: { evaluacion_id: this.evaluacion.id },
            establecimiento: {
                id: establecimientoIds
            },
            methods: "[has_forma]"
        }

        this.allEvaluacionInstancias = await this.evaluacionInstanciasService.wherePost(params)
        this.evaluacionInstancias = this.allEvaluacionInstancias.filter(ei => ei.oficial == true)

        this.evaluacionTiempos = await this.evaluacionTiemposService.where({
            evaluacion_tiempo: { evaluacion_id: this.evaluacion.id }
        })

        const grupoUsuarioIds = this.evaluacion.evaluacion_usuarios
            .filter(eu => eu.grupo_usuario)
            .filter(eu => establecimientoIds.find(eId => eId == eu.grupo_usuario.establecimiento_id))
            .map(eu => eu.receptor_id)

        const usuarioParams = {
            sort_by: "apellido_paterno",
            grupo_usuario: { id: grupoUsuarioIds }
        }

        this.usuarios = await this.usuariosService.where(usuarioParams)

        const estadisticasParams: any = {
            collection: "EstadisticaEvaluacion",
            estadistica: {
                oficial: 1,
                evaluacion_id: this.evaluacion.id,
                grupo_usuario_id: grupoUsuarioIds
            }
        }

        this.estadisticas = await this.estadisticasService.wherePost(estadisticasParams)

        this.loadingLayoutEvaluaciones.ready()
    }
}
