import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { FormBuilder, FormGroup } from "@angular/forms"

import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import {
    Establecimiento,
    Establecimientos,
    Evaluaciones,
    Evaluacion,
    EvaluacionMultiples,
    EvaluacionMultiple,
    Usuario,
    Usuarios,
    GrupoUsuario
} from "@puntaje/puntaje/api-services"
import { FiltroEstadisticasService } from "../filtro_estadisticas.service"
import { FiltroEstadisticasComponent } from "../filtro_estadisticas.component"

import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "filtro-estadisticas-multiples",
    templateUrl: "./filtro-estadisticas-multiples.component.html",
    styleUrls: ["../filtro_estadisticas.component.scss"]
})
export class FiltroEstadisticasMultiplesComponent extends FiltroEstadisticasComponent implements OnInit {
    evaluacionesMultiples: EvaluacionMultiple[]
    evaluacionMultiple: EvaluacionMultiple
    generadorInstrumentos: GeneradorInstrumento[]

    constructor(
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected establecimientosService: Establecimientos,
        protected evaluacionesService: Evaluaciones,
        protected evaluacionMultiplesService: EvaluacionMultiples,
        protected usuariosService: Usuarios,
        protected asignaturasService: Asignaturas,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected cdr: ChangeDetectorRef
    ) {
        super(
            filtroEstadisticasService,
            establecimientosService,
            evaluacionesService,
            usuariosService,
            asignaturasService,
            cdr
        )
    }

    ngOnInit() {}

    setData() {
        this.establecimiento = undefined
        this.grupoUsuario = undefined
        this.evaluacion = undefined
        this.evaluacionMultiple = undefined

        const params: any = {
            mios: 1,
            establecimiento: { activo: 1 },
            utp_establecimiento: 1,
            utp_asociacion: 1,
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos

            const giParams = {
                tipo_instrumento: { tipo_instrumento: this.evaluacionTipo },
                include: "[tipo_instrumento,generador_instrumentos_hijos]",
                asignatura_plataforma: {
                    plataforma_id: config.plataforma.id
                }
            }

            this.generadorInstrumentosService.where(giParams).then((generadores: GeneradorInstrumento[]) => {
                this.generadorInstrumentos = generadores.filter(g => g.generador_instrumentos_hijos.length > 0)

                this.sub = this.filtroEstadisticasService
                    .getFiltrosObservable(this.evaluacionTipo, 0)
                    .subscribe(this.updateSelected.bind(this))

                this.filtroEstadisticasService.onChangeParams()
                this.loadingLayout.ready()
            })
        })
    }

    updateSelected(values) {
        this.loadingLayout.standby()

        let changeGeneradorInstrumento,
            changeEstablecimiento,
            changeGrupoUsuario,
            changeEvaluacion = false

        let establecimientoId = values.establecimientoId
        let grupoUsuarioId = values.grupoUsuarioId
        let usuarioId = values.usuarioId
        let evaluacionMultipleId = values.evaluacionId
        let generadorInstrumentoId = values.generadorInstrumentoId

        const oldEstablecimiento = this.establecimiento
        const oldGrupoUsuario = this.grupoUsuario
        const oldUsuario = this.usuario
        const oldEvaluacionMultiple = this.evaluacionMultiple

        this.establecimiento = undefined
        this.grupoUsuario = undefined
        this.usuario = undefined
        this.evaluacionMultiple = undefined

        if (!establecimientoId) {
            this.loadingLayout.ready()
            return
        }

        if (
            generadorInstrumentoId &&
            (!this.generadorInstrumento || this.generadorInstrumento.id != generadorInstrumentoId)
        ) {
            this.generadorInstrumento = this.generadorInstrumentos.find(g => g.id == generadorInstrumentoId)
            changeGeneradorInstrumento = true
        }

        if (!oldEstablecimiento || oldEstablecimiento.id != establecimientoId) {
            this.establecimiento = this.establecimientos.find(e => e.id == establecimientoId)
            changeEstablecimiento = true
        } else {
            this.establecimiento = oldEstablecimiento
        }

        let estudiantesPromise = Promise.resolve()

        if (grupoUsuarioId && (!oldGrupoUsuario || oldGrupoUsuario.id != grupoUsuarioId || changeEstablecimiento)) {
            this.grupoUsuario = this.establecimiento.grupo_usuarios.find(gu => gu.id == grupoUsuarioId)
            changeGrupoUsuario = true
            estudiantesPromise = this.getEstudiantes()
        } else {
            this.grupoUsuario = oldGrupoUsuario
        }

        estudiantesPromise.then(() => {
            if (usuarioId && (!oldUsuario || oldUsuario.id != usuarioId || changeGrupoUsuario)) {
                this.usuario = this.usuarios.find(u => u.id == usuarioId)
            } else {
                this.usuario = oldUsuario
            }

            if (
                !oldEvaluacionMultiple ||
                oldEvaluacionMultiple.id != evaluacionMultipleId ||
                changeGeneradorInstrumento ||
                changeEstablecimiento ||
                changeGrupoUsuario
            ) {
                let promise = this.getEvaluaciones()

                if (promise && evaluacionMultipleId) {
                    promise.then(() => {
                        this.evaluacionMultiple = this.evaluacionesMultiples.find(e => e.id == evaluacionMultipleId)
                        this.loadingLayout.ready()

                        this.filtrar()
                    })
                } else {
                    this.loadingLayout.ready()

                    this.filtrar()
                }
            } else {
                this.evaluacionMultiple = oldEvaluacionMultiple
                this.loadingLayout.ready()

                this.filtrar()
            }
        })
    }

    getEvaluaciones() {
        this.evaluacionesMultiples = undefined
        this.evaluacionMultiple = undefined

        const params: any = {
            mis_colegios: 1,
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal,
            include: "[evaluaciones,evaluacion_tipo,instrumento_multiple]",
            with_instrumento_multiple: 1
        }
        if (this.grupoUsuario) {
            params.grupo_usuario = { id: this.grupoUsuario.id } as any
        } else if (this.establecimiento) {
            params.grupo_usuario = { establecimiento_id: this.establecimiento.id } as any
        }
        if (this.generadorInstrumento) {
            params.instrumento_multiple = { generador_instrumento_id: this.generadorInstrumento.id }

            if (this.establecimiento) {
                this.loadingCount += 1

                return this.evaluacionMultiplesService
                    .where(params)
                    .then((evaluacionesMultiples: EvaluacionMultiple[]) => {
                        this.loadingCount -= 1
                        this.evaluacionesMultiples = evaluacionesMultiples
                    })
            }
        }

        return null
    }

    filtrar() {
        // let previousEvaluacionIndex = this.evaluacion ? this.evaluaciones.findIndex(e => e.id == this.evaluacion.id) - 1 : -1;
        // let previousEvaluacion;
        // if(previousEvaluacionIndex >= 0) {
        //     previousEvaluacion = this.evaluaciones[previousEvaluacionIndex];
        // }

        const data = {
            establecimiento: this.establecimiento,
            grupoUsuario: this.grupoUsuario,
            usuario: this.usuario,
            evaluacionMultiple: this.evaluacionMultiple,
            generadorInstrumento: this.generadorInstrumento
        }

        this.filter.emit(data)
    }

    setFiltros() {
        let establecimientoId, grupoUsuarioId, evaluacionMultipleId, usuarioId, generadorInstrumentoId

        if (this.establecimiento) {
            establecimientoId = this.establecimiento.id
        }

        if (this.grupoUsuario) {
            grupoUsuarioId = this.grupoUsuario.id
        }

        if (this.evaluacionMultiple) {
            evaluacionMultipleId = this.evaluacionMultiple.id
        }

        if (this.usuario) {
            usuarioId = this.usuario.id
        }

        if (this.generadorInstrumento) {
            generadorInstrumentoId = this.generadorInstrumento.id
        }

        this.filtroEstadisticasService.setFiltrosId(
            establecimientoId,
            grupoUsuarioId,
            usuarioId,
            evaluacionMultipleId,
            generadorInstrumentoId,
            this.evaluacionTipo,
            0
        )
    }
}
