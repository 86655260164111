<loading-layout #loadingLayout>
    <estadisticas-evaluacion-portada
        [evaluacionTipo]="evaluacionTipo"
        [evaluacion]="evaluacion"
        [establecimiento]="establecimiento"
        [asignatura]="asignatura"
        [grupoUsuario]="grupoUsuario"
        [evaluacionInstancias]="evaluacionInstancias"
        [estadisticas]="estadisticas"
    ></estadisticas-evaluacion-portada>
    <div class="clearfix">
        <cui-button
            class="btn_style pull-right print-hide"
            *ngIf="grupoUsuario && hayRespuestas"
            (click)="printAlumnos()"
        >
            <fa name="print"></fa>
            &nbsp;Imprimir informe alumnos
        </cui-button>
    </div>

    <div class="big-title-margin print-hide">
        <div class="big-title-container big-title-red">
            <div class="big-title-left">
                <span class="big-title-icon"><fa [name]="'list'"></fa></span>
            </div>
            <h2 class="big-title-text">
                Resultados {{ evaluacionTipoAlias | transformInstrumento: "singular" }}
                <span *ngIf="asignatura">de {{ asignatura?.asignatura }}:</span>
                <span class="big-title-color">#{{ evaluacion.id }} - {{ evaluacion.evaluacion }}</span>
            </h2>
        </div>
    </div>

    <div *ngIf="enableGraphs">
        <tabla-resumen-evaluacion-por-alumno
            class="print-page-break-after"
            *ngIf="evaluacionInstancias && (!previousEvaluacion || previousEvaluacionInstancias)"
            [evaluacionInstancias]="evaluacionInstancias"
            [allEvaluacionInstancias]="allEvaluacionInstancias"
            [previousEvaluacionInstancias]="previousEvaluacionInstancias"
            [estadisticas]="estadisticas"
            [grupoUsuario]="grupoUsuario"
            [usuarios]="usuarios"
            [evaluacion]="evaluacion"
            [evaluacionTiempos]="evaluacionTiempos"
        ></tabla-resumen-evaluacion-por-alumno>

        <grafico-distribucion-tramo-puntaje
            class="print-page-break-after"
            [asignatura]="asignatura"
            [data]="distribucion_ensayos_tramo_puntaje"
            [tipoEvaluacion]="evaluacionTipo"
            [evaluacion]="evaluacion"
            [evaluacionInstancias]="evaluacionInstancias"
            [usuarios]="usuarios"
        ></grafico-distribucion-tramo-puntaje>

        <tabla-resultados-por-pregunta
            class="print-page-break-after"
            *ngIf="evaluacionInstancias"
            [evaluacionInstancias]="evaluacionInstancias"
            [evaluacion]="evaluacion"
            (dataReady)="onResultadosPorPreguntaReady($event)"
        ></tabla-resultados-por-pregunta>

        <ng-container *ngFor="let cte of clasificacionTiposEstadisticas">
            <ng-template [ngIf]="cte.compuesto && this['desempeno_' + cte.nombre + '_total']?.length > 0">
                <grafico-desempeno-clasificacion-compuesto
                    class="print-page-break-after"
                    [evaluacionTipo]="evaluacionTipo"
                    [tipoClasificacion]="cte.nombreMostrable || cte.nombre"
                    [asignatura]="asignatura"
                    [clasificaciones]="this['desempeno_' + cte.nombre]"
                    [dataOmitidas]="this['desempeno_' + cte.nombre + '_omitidas']"
                    [dataIncorrectas]="this['desempeno_' + cte.nombre + '_incorrectas']"
                    [dataCorrectas]="this['desempeno_' + cte.nombre + '_correctas']"
                    [dataTotal]="this['desempeno_' + cte.nombre + '_total']"
                ></grafico-desempeno-clasificacion-compuesto>
            </ng-template>

            <ng-template [ngIf]="!cte.compuesto && this['desempeno_' + cte.nombre + '_total']?.length > 0">
                <grafico-desempeno-clasificacion
                    class="print-page-break-after"
                    [tipoClasificacion]="cte.nombreMostrable || cte.nombre"
                    [asignatura]="asignatura"
                    [clasificaciones]="this['desempeno_' + cte.nombre]"
                    [data]="this['desempeno_' + cte.nombre + '_correctas']"
                    [dataTotal]="this['desempeno_' + cte.nombre + '_total']"
                    [tipoEvaluacion]="evaluacionTipo"
                ></grafico-desempeno-clasificacion>
            </ng-template>

            <tabla-desempeno-por-usuario-clasificacion
                [usuarios]="usuarios"
                [asignaturaId]="asignatura.id"
                [estadisticaUsuarios]="estadisticaUsuarios"
                [clasificacionTipo]="clasificacionTipoByNombre[cte.nombre][0]"
                [clasificacionTipoReferenced]="clasificacionReferenced[cte.nombre]"
            ></tabla-desempeno-por-usuario-clasificacion>
        </ng-container>

        <listado-preguntas-mas-incorrectas
            *ngIf="dataRespuestas"
            [dataFull]="dataRespuestas"
            [evaluacionTipo]="evaluacionTipo"
        ></listado-preguntas-mas-incorrectas>
    </div>
</loading-layout>

<div
    class="modal fade staticModal"
    bsModal
    #mandatoryLoading="bs-modal"
    [config]="{ backdrop: 'static', keyboard: false }"
    tabindex="0"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <ng-container *ngIf="!errorMsg">
                    <loading-layout #loadingLayout2 [noMarginY]="true" class="loading"></loading-layout>
                    <p class="loading-message">
                        Estamos generando el archivo para su impresión.
                        <br />
                        Esta operación puede tardar unos minutos, por favor espere.
                    </p>
                </ng-container>
                <ng-container *ngIf="errorMsg">
                    <p class="warning-icon">
                        <fa name="warning"></fa>
                    </p>
                    <p class="error-message">
                        Se ha producido un error al generar su archivo para impresión.
                        <br />
                        Por favor espere unos minutos e intente de nuevo.
                    </p>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    (click)="cancelPrint()"
                    *ngIf="!errorMsg"
                >
                    Cancelar
                </button>
                <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    *ngIf="errorMsg"
                    (click)="mandatoryLoading.hide()"
                >
                    Cerrar
                </button>
            </div>
        </div>
    </div>
</div>
