import { Component } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "resumen_general.component.html",
    styleUrls: ["resumen_general.component.scss"]
})
export class ResumenGeneralComponent {
    private sub: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}
}
