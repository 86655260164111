import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from "@angular/core"
import { GrupoUsuario, Establecimiento, Usuarios, Usuario, Estadisticas } from "@puntaje/puntaje/api-services"
import {
    Asignatura,
    ClasificacionTipo,
    Asignaturas,
    ClasificacionTipos,
    GeneradorInstrumento
} from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "estadisticas-multiples-cursos",
    templateUrl: "./estadisticas-multiples-cursos.component.html",
    styleUrls: ["../estadisticas.component.scss"]
})
export class EstadisticasMultiplesCursosComponent implements OnInit {
    @Input() asignaturaId: number
    @Input() generadorInstrumento: GeneradorInstrumento
    @Input() estadisticas: any
    @Input() establecimiento: Establecimiento
    @Input() grupoUsuarios: GrupoUsuario[]
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    asignatura: Asignatura
    @Input() evaluacionTipo: string

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    enableGraphs: boolean = false

    clasificacionTipos: ClasificacionTipo[]
    clasificacionTiposEstadisticas: any

    distribucion_ensayos_tramo_puntaje: number[] = []
    desempeno_materia_cursos: string[] = []
    desempeno_materia_omitidas: number[] = []
    desempeno_materia_correctas: number[] = []
    desempeno_materia_incorrectas: number[] = []
    desempeno_promedio_omitidas: { [asignaturaId: number]: number }
    desempeno_promedio_correctas: { [asignaturaId: number]: number }
    desempeno_promedio_incorrectas: { [asignaturaId: number]: number }
    desempeno_subejes: string[] = []
    desempeno_subejes_omitidas: number[] = []
    desempeno_subejes_correctas: number[] = []
    desempeno_subejes_incorrectas: number[] = []
    comparacion_cursos: string[] = []
    comparacion_promedios: number[] = []

    promedioGeneral: number
    promedioAsignaturas: { [key: number]: number }

    asignaturaIds: number[]

    usuarios: Usuario[] = []
    asignaturaIdToEstadisticaUsuariosMap: { [asignaturaId: number]: any[] } = {}

    clasificacionReferenced: any
    clasificacionTipoByNombre: any

    constructor(
        protected asignaturasService: Asignaturas,
        protected clasificacionTiposService: ClasificacionTipos,
        protected cdr: ChangeDetectorRef,
        protected usuariosService: Usuarios,
        protected estadisticasService: Estadisticas
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()
        this.setData()
    }

    async setData() {
        this.loadingLayout.standby()

        this.clasificacionTiposEstadisticas = config.evaluaciones[this.evaluacionTipo].clasificacionTiposEstadisticas

        const cTipos = this.clasificacionTiposEstadisticas.filter(ct => ct.profesores).map(ct => ct.nombre)

        this.clasificacionReferenced = { ...config.evaluaciones[this.evaluacionTipo].clasificaciones.referencesTo }
        if (Object.values(this.clasificacionReferenced).length > 0) {
            const clasificacionTiposReferenced = await this.clasificacionTiposService.where({
                clasificacion_tipo: { clasificacion_tipo: Object.values(this.clasificacionReferenced) }
            })

            Object.keys(this.clasificacionReferenced).forEach(key => {
                this.clasificacionReferenced[key] = clasificacionTiposReferenced.find(
                    ctr => ctr.clasificacion_tipo == this.clasificacionReferenced[key]
                )
            })
        }

        this.clasificacionTipos = await this.clasificacionTiposService.where({
            clasificacion_tipo: { clasificacion_tipo: cTipos }
        })
        this.clasificacionTipoByNombre = this.clasificacionTipos.groupBy(ct => ct.clasificacion_tipo)

        this.setValues()
        this.enableGraphs = true
        this.loadingLayout.ready()
        this.onReady.emit(this.asignatura)
    }

    onPaginatorChange = async (page: number = 1, per: number = 20) => {
        const usuarioParams = {
            sort_by: "apellido_paterno",
            grupo_usuario: { id: this.establecimiento.grupo_usuarios.map(gu => gu.id) },
            page,
            per
        }

        const [usuarios, count] = await this.usuariosService
            .where(usuarioParams)
            .then((usuarios: Usuario[], count: number) => {
                return [usuarios, count]
            })

        this.usuarios = usuarios

        const estadisticaParams = {
            collection: "EstadisticaUsuario",
            estadistica: {
                oficial: 1,
                usuario_id: this.usuarios.map(u => u.id),
                generador_instrumento_id: this.generadorInstrumento.generador_instrumentos_hijos.map(gi => gi.id),
                evaluacion_tipo: this.evaluacionTipo
            },
            clasificacion_methods:
                config.evaluaciones[this.generadorInstrumento.tipo_instrumento.tipo_instrumento].clasificaciones
                    .methods ?? ""
        }

        const estadisticaUsuarios = await this.estadisticasService.where(estadisticaParams)

        const estadisticaAsignaturas = this.estadisticas
            .map(e => e.estadistica_asignaturas || [])
            .flat()
            .groupBy(ea => ea.asignatura_id)

        const asignaturaIds = Object.keys(estadisticaAsignaturas).map(id => +id)

        this.asignaturaIdToEstadisticaUsuariosMap = {}
        ;[0, ...asignaturaIds].forEach(asignaturaId => {
            this.asignaturaIdToEstadisticaUsuariosMap[asignaturaId] = estadisticaUsuarios.map(eu => {
                return {
                    ...eu,
                    estadistica_clasificaciones: eu.estadistica_clasificaciones.filter(
                        ec => ec.clasificacion.asignatura_id == asignaturaId || asignaturaId == 0
                    )
                }
            })
        })

        return count
    }

    setValues() {
        // Calculo promedio evaluaciones para el curso
        const numeroTotalEvaluaciones = this.estadisticas.reduce((acc, e) => acc + e.numero_evaluaciones, 0)
        const sumaPonderada = this.estadisticas.reduce((acc, e) => acc + e.promedio * e.numero_evaluaciones, 0)
        this.promedioGeneral = numeroTotalEvaluaciones == 0 ? 0 : sumaPonderada / numeroTotalEvaluaciones

        const estadisticaAsignaturas = this.estadisticas
            .map(e => e.estadistica_asignaturas || [])
            .flat()
            .groupBy(ea => ea.asignatura_id)

        this.asignaturaIds = Object.keys(estadisticaAsignaturas).map(id => +id)

        this.promedioAsignaturas = Object.keys(estadisticaAsignaturas).reduce((acc, asignaturaId) => {
            const numeroTotalEvaluacionesAsignatura = estadisticaAsignaturas[asignaturaId].reduce(
                (accn, ea) => accn + ea.numero_evaluaciones,
                0
            )
            const sumaPonderadaAsignatura = estadisticaAsignaturas[asignaturaId].reduce(
                (accn, ea) => accn + ea.promedio * ea.numero_evaluaciones,
                0
            )

            acc[asignaturaId] =
                numeroTotalEvaluacionesAsignatura == 0 ? 0 : sumaPonderadaAsignatura / numeroTotalEvaluacionesAsignatura

            return acc
        }, {})

        this.distribucion_ensayos_tramo_puntaje = this.estadisticas.reduce(
            (distribucion, e) => {
                Object.keys(e.distribucion_calificaciones).map(puntaje => {
                    if (!distribucion[0][puntaje]) {
                        distribucion[0][puntaje] = 0
                    }
                    distribucion[0][puntaje] += e.distribucion_calificaciones[puntaje]
                })

                const estadisticaAsignaturas = e.estadistica_asignaturas
                if (estadisticaAsignaturas) {
                    estadisticaAsignaturas.forEach(ea => {
                        if (!distribucion[ea.asignatura_id]) {
                            distribucion[ea.asignatura_id] = {}
                        }
                        Object.keys(ea.distribucion_calificaciones).map(puntaje => {
                            if (!distribucion[ea.asignatura_id][puntaje]) {
                                distribucion[ea.asignatura_id][puntaje] = 0
                            }
                            distribucion[ea.asignatura_id][puntaje] += ea.distribucion_calificaciones[puntaje]
                        })
                    })
                }

                return distribucion
            },
            { 0: {} }
        )

        let estadisticaClasificaciones = {}
        this.estadisticas.forEach(e => {
            if (e.estadistica_clasificaciones) {
                e.estadistica_clasificaciones.forEach(ec => {
                    if (!estadisticaClasificaciones[ec.clasificacion_id]) {
                        estadisticaClasificaciones[ec.clasificacion_id] = ec
                    } else {
                        estadisticaClasificaciones[ec.clasificacion_id].correctas += ec.correctas
                        estadisticaClasificaciones[ec.clasificacion_id].incorrectas += ec.incorrectas
                        estadisticaClasificaciones[ec.clasificacion_id].omitidas += ec.omitidas
                    }
                })
            }
        })
        estadisticaClasificaciones = (Object as any).values(estadisticaClasificaciones)
        const asignaturaIds = Object.keys(estadisticaAsignaturas).map(aId => +aId)
        asignaturaIds.push(0)

        this.desempeno_promedio_omitidas = {}
        this.desempeno_promedio_incorrectas = {}
        this.desempeno_promedio_correctas = {}
        asignaturaIds.forEach(asignaturaId => {
            this.clasificacionTipos.forEach(ct => {
                const ecs = (estadisticaClasificaciones as any[]).filter(
                    ec =>
                        ec.clasificacion.clasificacion_tipo_id == ct.id &&
                        (ec.clasificacion.asignatura_id == asignaturaId || asignaturaId == 0)
                )

                const nombre = (ct as any).clasificacion_tipo

                this["desempeno_" + nombre] = this["desempeno_" + nombre] || {}
                this["desempeno_" + nombre + "_omitidas"] = this["desempeno_" + nombre + "_omitidas"] || {}
                this["desempeno_" + nombre + "_incorrectas"] = this["desempeno_" + nombre + "_incorrectas"] || {}
                this["desempeno_" + nombre + "_correctas"] = this["desempeno_" + nombre + "_correctas"] || {}
                this["desempeno_" + nombre + "_total"] = this["desempeno_" + nombre + "_total"] || {}

                this["desempeno_" + nombre][asignaturaId] = ecs.map(
                    ec =>
                        ec.clasificacion.clasificacion +
                        (ec.clasificacion.curso ? " (" + ec.clasificacion.curso.clasificacion + ")" : "")
                )
                this["desempeno_" + nombre + "_omitidas"][asignaturaId] = ecs.map(ec => ec.omitidas)
                this["desempeno_" + nombre + "_incorrectas"][asignaturaId] = ecs.map(ec => ec.incorrectas)
                this["desempeno_" + nombre + "_correctas"][asignaturaId] = ecs.map(ec => ec.correctas)
                this["desempeno_" + nombre + "_total"][asignaturaId] = ecs.map(
                    ec => ec.omitidas + ec.incorrectas + ec.correctas
                )
            })

            if (asignaturaId) {
                this.desempeno_promedio_correctas[asignaturaId] =
                    estadisticaAsignaturas[asignaturaId].reduce((acc, ea) => acc + ea.correctas, 0) /
                    numeroTotalEvaluaciones
                this.desempeno_promedio_incorrectas[asignaturaId] =
                    estadisticaAsignaturas[asignaturaId].reduce((acc, ea) => acc + ea.incorrectas, 0) /
                    numeroTotalEvaluaciones
                this.desempeno_promedio_omitidas[asignaturaId] =
                    estadisticaAsignaturas[asignaturaId].reduce((acc, ea) => acc + ea.omitidas, 0) /
                    numeroTotalEvaluaciones
            } else {
                this.desempeno_promedio_correctas[0] =
                    this.estadisticas.reduce((acc, e) => acc + e.correctas, 0) / numeroTotalEvaluaciones
                this.desempeno_promedio_incorrectas[0] =
                    this.estadisticas.reduce((acc, e) => acc + e.incorrectas, 0) / numeroTotalEvaluaciones
                this.desempeno_promedio_omitidas[0] =
                    this.estadisticas.reduce((acc, e) => acc + e.omitidas, 0) / numeroTotalEvaluaciones
            }
        })
        // this.distribucion_ensayos_tramo_puntaje = this.estadisticas.reduce((x, e) => {
        //     let d = (Object as any).values(e.distribucion_calificaciones);
        //     if(d.length > x.length) {
        //         let l = x.length;
        //         x.length = d.length;
        //         x = x.fill(0, l);
        //     }
        //     return d.map((y, i) => y + x[i]);
        // }, []);

        // let estadistica_clasificaciones = {};
        // this.estadisticas.forEach((e) => {
        //     e.estadistica_clasificaciones.forEach((ec) => {
        //         if(!estadistica_clasificaciones[ec.clasificacion_id]) {
        //             estadistica_clasificaciones[ec.clasificacion_id] = ec;
        //         } else {
        //             estadistica_clasificaciones[ec.clasificacion_id].correctas += ec.correctas;
        //             estadistica_clasificaciones[ec.clasificacion_id].incorrectas += ec.incorrectas;
        //             estadistica_clasificaciones[ec.clasificacion_id].omitidas += ec.omitidas;
        //         }
        //     });
        // });
        // estadistica_clasificaciones = (Object as any).values(estadistica_clasificaciones);

        // this.clasificacionTipos.forEach((ct) => {
        //     let ecs = (estadistica_clasificaciones as any[]).filter((ec) => ec.clasificacion.clasificacion_tipo_id == ct.id);

        //     let nombre = (ct as any).clasificacion_tipo;

        //     this["desempeno_" + nombre] = ecs.map((ec) => ec.clasificacion.clasificacion + (ec.clasificacion.curso ? " (" + ec.clasificacion.curso.clasificacion + ")" : ""));
        //     this["desempeno_" + nombre + "_omitidas"] = ecs.map((ec) => ec.omitidas);
        //     this["desempeno_" + nombre + "_incorrectas"] = ecs.map((ec) => ec.incorrectas);
        //     this["desempeno_" + nombre + "_correctas"] = ecs.map((ec) => ec.correctas);
        //     this["desempeno_" + nombre + "_total"] = ecs.map((ec) => ec.omitidas + ec.incorrectas + ec.correctas);
        // });

        // let numero_evaluaciones = this.estadisticas.reduce((x, e) => x + e.numero_evaluaciones, 0);
        // this.desempeno_promedio_omitidas = ~~(this.estadisticas.reduce((x, e) => x + e.omitidas, 0)/numero_evaluaciones);
        // this.desempeno_promedio_incorrectas = ~~(this.estadisticas.reduce((x, e) => x + e.incorrectas, 0)/numero_evaluaciones);
        // this.desempeno_promedio_correctas = ~~(this.estadisticas.reduce((x, e) => x + e.correctas, 0)/numero_evaluaciones);

        /*let estadistica_clasificacion_cursos = estadistica_clasificaciones.filter((ec) => ec.clasificacion.clasificacion_tipo_id == 1);
      this.desempeno_materia_cursos = estadistica_clasificacion_cursos.map((ec) => ec.clasificacion.clasificacion);
      this.desempeno_materia_omitidas = estadistica_clasificacion_cursos.map((ec) => ec.omitidas);
      this.desempeno_materia_incorrectas = estadistica_clasificacion_cursos.map((ec) => ec.incorrectas);
      this.desempeno_materia_correctas = estadistica_clasificacion_cursos.map((ec) => ec.correctas);
      let numero_evaluaciones = this.estadisticas.reduce((x, e) => x + e.numero_evaluaciones, 0);
      this.desempeno_promedio_omitidas = ~~(this.estadisticas.reduce((x, e) => x + e.omitidas, 0)/numero_evaluaciones);
      this.desempeno_promedio_incorrectas = ~~(this.estadisticas.reduce((x, e) => x + e.incorrectas, 0)/numero_evaluaciones);
      this.desempeno_promedio_correctas = ~~(this.estadisticas.reduce((x, e) => x + e.correctas, 0)/numero_evaluaciones);
      let estadistica_clasificacion_subejes = estadistica_clasificaciones.filter((ec) => ec.clasificacion.clasificacion_tipo_id == 7 && ec.clasificacion.clasificacion_padre_id != null);
      this.desempeno_subejes = estadistica_clasificacion_subejes.map((ec) => ec.clasificacion.clasificacion);
      this.desempeno_subejes_omitidas = estadistica_clasificacion_subejes.map((ec) => ec.omitidas);
      this.desempeno_subejes_incorrectas = estadistica_clasificacion_subejes.map((ec) => ec.incorrectas);
      this.desempeno_subejes_correctas = estadistica_clasificacion_subejes.map((ec) => ec.correctas);*/
        // this.comparacion_cursos = this.grupoUsuarios.map((gu) => gu.nombre);
        // this.comparacion_promedios = this.grupoUsuarios.map((gu) => {
        //     let estadisticas_grupo_usuario = this.estadisticas.filter((e) => e.grupo_usuario_id == gu.id);
        //     if(estadisticas_grupo_usuario.length != 0) {
        //         let cantidad_evaluaciones_total = estadisticas_grupo_usuario.reduce((x, e) => x + e.numero_evaluaciones, 0);
        //         return estadisticas_grupo_usuario.reduce((x, e) => x + e.numero_evaluaciones*e.promedio, 0)/cantidad_evaluaciones_total;
        //     } else {
        //         return 0;
        //     }

        // });
    }

    //TODO: deshabilitar esto cuando lo demás funcione
    setTestValues() {
        this.distribucion_ensayos_tramo_puntaje = [96, 81, 91, 155, 100, 0, 0]
        this.desempeno_materia_omitidas = [5, 3, 4, 7, 2]
        this.desempeno_materia_incorrectas = [2, 2, 3, 2, 1]
        this.desempeno_materia_correctas = [3, 4, 4, 2, 5]
        this.desempeno_promedio_omitidas = { 0: 25 }
        this.desempeno_promedio_correctas = { 0: 50 }
        this.desempeno_promedio_incorrectas = { 0: 25 }
        this.desempeno_subejes = ["Números Reales", "Números Complejos", "Potencias", "Raíces", "Logaritmos"]
        this.desempeno_subejes_omitidas = [849, 69, 266, 168, 255]
        this.desempeno_subejes_incorrectas = [984, 159, 407, 288, 397]
        this.desempeno_subejes_correctas = [1094, 148, 650, 461, 317]
        this.comparacion_cursos = ["Tercero medio A", "Cuarto medio A", "Cuarto medio B"]
        this.comparacion_promedios = [290, 302, 177]
    }

    onChangeAsignatura(asignatura) {
        this.changeAsignatura(asignatura)
        this.cdr.detectChanges()
    }

    changeAsignatura(asignatura) {
        this.asignatura = asignatura
        if (asignatura) {
            this.asignaturaId = asignatura.id
        } else {
            this.asignaturaId = 0
        }
    }
}
