import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { GrupoUsuario, PlanPersonalSesion, PlanPersonalSesiones, Usuarios } from "@puntaje/puntaje/api-services"

@Component({
    selector: "plan-clase-sesion-avance-por-alumno",
    templateUrl: "./plan-clase-sesion-avance-por-alumno.component.html",
    styleUrls: ["./plan-clase-sesion-avance-por-alumno.component.scss"]
})
export class PlanClaseSesionAvancePorAlumnoComponent implements OnInit {
    avancePorAlumno = []
    totalAlumnos: number

    private _planPersonalSesionId: number
    private _grupoUsuarioId: number

    @Input()
    get planPersonalSesionId(): number {
        return this._planPersonalSesionId
    }
    set planPersonalSesionId(planPersonalSesionId: number) {
        this._planPersonalSesionId = planPersonalSesionId
    }

    @Input()
    get grupoUsuarioId(): number {
        return this._grupoUsuarioId
    }
    set grupoUsuarioId(grupoUsuarioId: number) {
        this._grupoUsuarioId = grupoUsuarioId
        this.usuariosService
            .where({ grupo_usuario_usuario: { propietario: false, grupo_usuario_id: grupoUsuarioId } })
            .then((_, total) => (this.totalAlumnos = total))
    }

    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    constructor(protected planPersonalSesionesService: PlanPersonalSesiones, protected usuariosService: Usuarios) {}

    ngOnInit() {}

    getAvancePorAlumnos(page: number = 1, per: number = 10) {
        if (this._grupoUsuarioId && this._planPersonalSesionId && this.totalAlumnos) {
            return this.planPersonalSesionesService
                .avancePorAlumno(this._planPersonalSesionId, this._grupoUsuarioId, page, per)
                .then((avance: any) => {
                    this.avancePorAlumno = avance
                    return this.totalAlumnos
                })
        } else return new Promise<number>((resolve, reject) => resolve(this.totalAlumnos || 0))
    }
}
