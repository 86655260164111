import { Subscription } from "rxjs"

import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"

import {
    Instrumento,
    Instrumentos,
    Evaluacion,
    Evaluaciones,
    EvaluacionForma,
    EvaluacionFormas
} from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "evaluacion.component.html",
    styleUrls: ["evaluacion.component.scss"]
})
export class EvaluacionComponent implements OnInit {
    evaluacion: Evaluacion
    instrumento: Instrumento
    evaluacionId: number
    subParams: Subscription
    subQuery: Subscription
    evaluacionFormas: EvaluacionForma[]

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected evaluacionFormasService: EvaluacionFormas,
        protected instrumentosService: Instrumentos,
        protected evaluacionesService: Evaluaciones
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.evaluacion")
        this.subParams = this.route.params.subscribe(params => {
            this.evaluacionesService.find(+params["id"], { profesor: 1 }).then(async (evaluacion: Evaluacion) => {
                this.evaluacion = evaluacion
                this.evaluacionFormas = await this.evaluacionFormasService.where({
                    evaluacion_forma: { evaluacion_id: evaluacion.id },
                    include: "[evaluacion_forma_instrumento_preguntas:instrumento_pregunta]"
                })
                this.instrumentosService
                    .find(this.evaluacion.instrumento_id, {
                        include: "[instrumento_material]",
                        with_generador_instrumento: 1
                    })
                    .then((instrumento: Instrumento) => {
                        this.instrumento = instrumento
                    })
            })
        })
    }

    nuevaPregunta() {
        this.evaluacionFormasService
            .where({
                evaluacion_forma: { evaluacion_id: this.evaluacion.id },
                include: "[evaluacion_forma_instrumento_preguntas:instrumento_pregunta]"
            })
            .then(evaluacionFormas => {
                this.evaluacionFormas = evaluacionFormas
            })
    }

    ngOnDestroy() {
        this.subParams.unsubscribe()
    }
}
