<div class="filter-container" *ngIf="showFilter">
    <label>{{ nombreGrupo | capitalize }}:</label>
    <div class="filter">
        <select class="form-control" [(ngModel)]="grupoUsuario" (ngModelChange)="reloadData()">
            <ng-container *ngIf="evaluacion">
                <option
                    *ngFor="let evaluacionUsuario of evaluacion.evaluacion_usuarios"
                    [ngValue]="evaluacionUsuario.grupo_usuario"
                >
                    {{ evaluacionUsuario.grupo_usuario.nombre }}
                </option>
            </ng-container>
        </select>
    </div>
</div>
<loading-layout #loadingLayout>
    <table class="table tabla-evaluacion-forma" *ngIf="estudiantes">
        <thead>
            <tr>
                <th>Estudiante</th>
                <th class="center">{{ nombreGrupo | capitalize }}</th>
                <th class="center">Forma</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let e of estudiantes">
                <td>
                    <fa name="user" class="icono"></fa>
                    {{ e.nombreCompleto() }}
                </td>
                <td class="center">{{ grupoUsuario.nombre }}</td>
                <td class="center">{{ formas && formas[e.id] ? formas[e.id].forma : "-" }}</td>
            </tr>
        </tbody>
    </table>
</loading-layout>
<paginator #paginator *ngIf="evaluacion && grupoUsuario" [getList]="setData"></paginator>
