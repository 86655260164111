import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { Establecimiento, Usuarios, Usuario } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "usuario-buscador",
    templateUrl: "./usuario-buscador.component.html",
    styleUrls: ["./usuario-buscador.component.scss"]
})
export class UsuarioBuscadorComponent implements OnInit {
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() establecimiento: Establecimiento
    searchOpts = {
        rut: "",
        nombre: "",
        paterno: "",
        materno: ""
    }

    //se guarda una copia de los parametros de busqueda para que cuando se cambie de pagina se haga con los mimos parametros que se hizo al principio
    optsSnapshot = {
        rut: null,
        nombre: null,
        paterno: null,
        materno: null
    }

    usuarios: Usuario[] = []
    pais: string = config.plataforma.pais
    idPais: string = config.plataforma.pais
    identificadoresPais: string[] = config.plataforma.identificadorUsuario
    placeholderIdPais

    paginatorActive = false
    searchComplete = false

    constructor(protected usuarioService: Usuarios) {}

    ngOnInit() {
        this.placeholderIdPais = this.identificadoresPais.map(id => id.toUpperCase()).join("/")
        this.loadingLayout.ready()
    }

    startSearch() {
        //la busqueda se hace automaticamente cuando el paginador se hace visible
        if (this.searchOpts.rut || this.searchOpts.nombre || this.searchOpts.paterno || this.searchOpts.materno) {
            this.optsSnapshot.rut = this.searchOpts.rut
            this.optsSnapshot.nombre = this.searchOpts.nombre
            this.optsSnapshot.paterno = this.searchOpts.paterno
            this.optsSnapshot.materno = this.searchOpts.materno
            setTimeout(() => {
                this.paginatorActive = true
            }, 200)
        }
    }

    fetchUsuarios(page: number = 1, count: number = 20) {
        this.searchComplete = false
        this.paginatorActive = false
        this.loadingLayout.standby()
        const params = {
            page: page,
            per: count,
            establecimiento_id: this.establecimiento.id,
            usuario: { like: {} }
        }
        if (this.optsSnapshot.nombre) params.usuario.like["nombre"] = this.optsSnapshot.nombre
        if (this.optsSnapshot.paterno) params.usuario.like["apellido_paterno"] = this.optsSnapshot.paterno
        if (this.optsSnapshot.materno) params.usuario.like["apellido_materno"] = this.optsSnapshot.materno
        if (this.optsSnapshot.rut) {
            params["usuario_" + this.pais] = {}
            params["usuario_" + this.pais][this.identificadoresPais.map(id => id.toLowerCase()).join("_o_")] =
                this.optsSnapshot.rut
        }

        return this.usuarioService.where(params).then((usuarios, total) => {
            this.usuarios = usuarios

            usuarios.forEach(usuario => {
                ;(usuario as any).identificadorPais = this.identificadoresPais.find(
                    idpais => !!usuario["usuario_" + this.pais][idpais.toLowerCase()]
                )
            })

            this.searchComplete = true
            this.loadingLayout.ready()
            return total
        })
    }
}
