<div>
    <div class="top-bar">
        <button
            class="share-info btn-link"
            popover="{{ compartidoCon }}"
            popoverTitle="Compartido con"
            placement="top"
            triggers="focus"
        >
            <span class="cursos">{{ 'noticias.profe.cursos' | t }}: {{ compartidoCon }}</span>
        </button>
        <p class="update-info">{{ 'noticias.profe.actualizado' | t }}: {{ noticia.updated_at | date: "dd-MM-yyyy h:mm a" }}</p>
    </div>
    <div>
        <p>
            {{ noticia.titulo }}
        </p>
    </div>
    <div class="btn-bar">
        <a (click)="showDelete(noticia)">
            {{ 'noticias.profe.eliminar_boton' | t }}&nbsp;
            <fa name="trash"></fa>
        </a>
        <hr />
        <a (click)="showEditar(noticia)">
            {{ 'noticias.profe.editar_boton' | t }}&nbsp;
            <fa name="pencil"></fa>
        </a>
        <hr />
        <a (click)="showNoticia(noticia)" class="ver-mas">{{ 'noticias.profe.ver_mas_boton' | t }}...</a>
    </div>
</div>
