import { Component, OnInit } from "@angular/core"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./plan-clase-edit.component.html",
    styleUrls: ["./plan-clase-edit.component.scss"]
})
export class PlanClaseEditComponent implements OnInit {
    sub: Subscription
    subData: Subscription

    planPersonalId: number

    urlRedirect: string = "/plan_clases"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_clases.plan_clases.edit")
        this.sub = this.route.params.subscribe(params => {
            this.planPersonalId = +params["id"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.urlRedirect = data.urlRedirect ?? this.urlRedirect
        })
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
