<div
    class="site-warning"
    [ngClass]="{
        notice: warningType == siteWarningType.Notice,
        success: warningType == siteWarningType.Success,
        warning: warningType == siteWarningType.Warning,
        danger: warningType == siteWarningType.Danger
    }"
>
    <ogr-icon name="exclamation-dialog" class="icono"></ogr-icon>
    <div>
        <p class="title">{{ warningTitle }}</p>
        <div class="content" [innerHTML]="warningText"></div>
    </div>
</div>
