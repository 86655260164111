import { Component, Input, OnInit } from "@angular/core"

export enum SiteWarningType {
    Notice = "notice",
    Success = "success",
    Warning = "warning",
    Danger = "danger"
}

@Component({
    selector: "site-warning",
    templateUrl: "site-warning.component.html",
    styleUrls: ["site-warning.component.scss"]
})
export class SiteWarningComponent implements OnInit {
    siteWarningType = SiteWarningType
    @Input() warningType: string = SiteWarningType.Notice
    @Input() warningTitle: string = "Aviso"
    @Input() warningText: string = ""

    constructor() {}

    ngOnInit() {}
}
