import { Component, Input, SimpleChanges, OnChanges, ViewChild } from "@angular/core"
import { GeneradorInstrumento, Asignaturas } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Instrumentos } from "@puntaje/puntaje/api-services"
import { AllReadyService } from "@puntaje/puntaje/new-modules/shared"
import { Subscription } from "rxjs"

@Component({
    selector: "pruebas-propias",
    templateUrl: "pruebas-propias.component.html",
    styleUrls: ["pruebas-propias.component.scss"]
})
export class PruebasPropiasComponent implements OnChanges {
    @Input() asignaturaId: number
    @Input() evaluacionTipo: string
    generadorInstrumento: GeneradorInstrumento
    generadorInstrumentos: GeneradorInstrumento[]
    sub: Subscription
    allReadyKey: string = "historial-pruebas-propias"
    isReady: boolean = false
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected asignaturasService: Asignaturas,
        protected instrumentosService: Instrumentos,
        protected allreadyservice: AllReadyService
    ) {
        this.sub = this.allreadyservice.allReady.subscribe(key => {
            if (key == this.allReadyKey) {
                if (this.loadingLayout) this.loadingLayout.ready()
                this.isReady = true
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["evaluacionTipo"]) {
            this.loadGeneradorInstrumentos()
        }
    }

    loadGeneradorInstrumentos() {
        if (this.loadingLayout) this.loadingLayout.standby()
        this.isReady = false
        this.asignaturasService
            .find(this.asignaturaId, { with_generador_instrumentos: 1, tipo_instrumento: this.evaluacionTipo })
            .then(asignatura => {
                this.generadorInstrumentos = asignatura.generador_instrumentos
                this.allreadyservice.setCounterWithKey(this.allReadyKey, this.generadorInstrumentos.length)
            })
    }
}
