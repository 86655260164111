<loading-layout #loadingLayout>
    <estadisticas-multiples-portada
        [titulo]="'Informe de Evaluación'"
        [evaluacionTipo]="evaluacionTipo"
        [evaluacionMultiple]="evaluacionMultiple"
        [establecimiento]="establecimiento"
        [asignatura]="asignatura"
        [grupoUsuario]="grupoUsuario"
        [estadisticas]="estadisticas"
    ></estadisticas-multiples-portada>
    <div class="row">
        <div class="col-md-12">
            <!-- <div class="clearfix">
                <cui-button
                    class="  btn_style pull-right print-hide"
                    *ngIf="grupoUsuario && hayRespuestas"
                    (click)="printAlumnos()"
                >
                    <fa name="print"></fa>
                    &nbsp;Imprimir informe alumnos
                </cui-button>
            </div> -->

            <div class="big-title-margin print-hide">
                <div class="big-title-container big-title-red">
                    <div class="big-title-left">
                        <span class="big-title-icon">
                            <fa [name]="'list'"></fa>
                        </span>
                    </div>
                    <h2 class="big-title-text">
                        Resultados {{ evaluacionTipo | transformInstrumento: "singular" }}
                        <span *ngIf="asignatura">de {{ asignatura?.asignatura }}:</span>
                        <span class="big-title-color">
                            #{{ evaluacionMultiple.id }} - {{ evaluacionMultiple.evaluacion_multiple }}
                        </span>
                    </h2>
                </div>
            </div>

            <div *ngIf="enableGraphs">
                <!--<grafico-desempeno-materia-curso [asignatura]="asignatura" [dataOmitidas]="desempeno_materia_omitidas" [dataIncorrectas]="desempeno_materia_incorrectas" [dataCorrectas]="desempeno_materia_correctas"></grafico-desempeno-materia-curso>-->

                <!-- <grafico-desempeno-promedio class="print-page-break-after" [asignatura]="asignatura" [dataOmitidas]="desempeno_promedio_omitidas" [dataIncorrectas]="desempeno_promedio_incorrectas" [dataCorrectas]="desempeno_promedio_correctas"></grafico-desempeno-promedio> -->

                <sticky-top>
                    <menu-asignaturas-horizontal
                        [asignaturaIds]="asignaturaIds"
                        [enableAllBtn]="true"
                        [tipoAsignaturas]="evaluacionTipo"
                        [enableResponsive]="true"
                        [enableMiniMode]="true"
                        (asignaturaObjChanged)="onChangeAsignatura($event)"
                    ></menu-asignaturas-horizontal>
                </sticky-top>

                <grafico-distribucion-tramo-puntaje
                    class="print-page-break-after"
                    [asignatura]="asignatura"
                    [data]="$any(distribucion_ensayos_tramo_puntaje[asignaturaId])"
                    [tipoEvaluacion]="evaluacionTipo"
                    [tipoGrupo]="'cursos'"
                    [evaluacionInstancias]="evaluacionInstanciasByAsignaturaId[asignaturaId]"
                    [usuarios]="usuarios"
                ></grafico-distribucion-tramo-puntaje>

                <tabla-resumen-evaluacion-multiple-por-alumno
                    class="print-page-break-after"
                    *ngIf="evaluacionInstanciasByAsignaturaId[asignaturaId]"
                    [evaluacionInstancias]="evaluacionInstanciasByAsignaturaId[asignaturaId]"
                    [grupoUsuario]="grupoUsuario"
                    [evaluacion]="$any(evaluacionMultiple)"
                    [evaluacionTiempos]="[]"
                    [hideEvolucion]="true"
                    [usuarios]="usuarios"
                    [estadisticas]="estadisticaEvaluacionMultiple"
                    [evaluacionInstanciasFromMultiples]="evaluacionInstanciasByEvaluacionId"
                    [allEvaluacionInstancias]="allEvaluacionInstancias"
                ></tabla-resumen-evaluacion-multiple-por-alumno>

                <ng-container *ngFor="let evaluacion of evaluacionMultiple.evaluaciones">
                    <h3 class="sesion-title">{{ evaluacion.evaluacion }}</h3>
                    <tabla-resultados-por-pregunta
                        class="print-page-break-after"
                        *ngIf="evaluacionInstanciasByEvaluacionId[evaluacion.id]"
                        [withAsignatura]="true"
                        [evaluacionInstancias]="evaluacionInstanciasByEvaluacionId[evaluacion.id]"
                        [evaluacion]="evaluacion"
                    ></tabla-resultados-por-pregunta>
                </ng-container>

                <!-- <tabla-desempeno-por-usuario-clasificacion *ngFor="let ct of clasificacionTipos" [usuarios]="usuarios" [estadisticaUsuarios]="estadisticaUsuarios" [clasificacionTipo]="ct"></tabla-desempeno-por-usuario-clasificacion> -->

                <!-- <ng-template ngFor let-cte [ngForOf]="clasificacionTiposEstadisticas">
                <ng-template [ngIf]="cte.compuesto">
                <grafico-desempeno-clasificacion-compuesto class="print-page-break-after" [tipoClasificacion]="cte.nombreMostrable || cte.nombre" [asignatura]="asignatura" [clasificaciones]="this['desempeno_' + cte.nombre]" [dataOmitidas]="this['desempeno_' + cte.nombre + '_omitidas']" [dataIncorrectas]="this['desempeno_' + cte.nombre + '_incorrectas']" [dataCorrectas]="this['desempeno_' + cte.nombre + '_correctas']" [dataTotal]="this['desempeno_' + cte.nombre + '_total']"></grafico-desempeno-clasificacion-compuesto>
                </ng-template>

                <ng-template [ngIf]="!cte.compuesto">
                <grafico-desempeno-clasificacion class="print-page-break-after" [tipoClasificacion]="cte.nombreMostrable || cte.nombre" [asignatura]="asignatura" [clasificaciones]="this['desempeno_' + cte.nombre]" [data]="this['desempeno_' + cte.nombre + '_correctas']" [dataTotal]="this['desempeno_' + cte.nombre + '_total']"></grafico-desempeno-clasificacion>
                </ng-template>
            </ng-template> -->

                <!-- <listado-preguntas-mas-incorrectas *ngIf="dataRespuestas" [data]="dataRespuestas"></listado-preguntas-mas-incorrectas> -->
            </div>
        </div>
    </div>
</loading-layout>

<div
    class="modal fade staticModal"
    bsModal
    #mandatoryLoading="bs-modal"
    [config]="{ backdrop: 'static', keyboard: false }"
    tabindex="0"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <ng-container *ngIf="!errorMsg">
                    <loading-layout #loadingLayout2 [noMarginY]="true" class="loading"></loading-layout>
                    <p class="loading-message">
                        Estamos generando el archivo para su impresión.
                        <br />
                        Esta operación puede tardar unos minutos, por favor espere.
                    </p>
                </ng-container>
                <ng-container *ngIf="errorMsg">
                    <p class="warning-icon">
                        <fa name="warning"></fa>
                    </p>
                    <p class="error-message">
                        Se ha producido un error al generar su archivo para impresión.
                        <br />
                        Por favor espere unos minutos e intente de nuevo.
                    </p>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    (click)="cancelPrint()"
                    *ngIf="!errorMsg"
                >
                    Cancelar
                </button>
                <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    *ngIf="errorMsg"
                    (click)="mandatoryLoading.hide()"
                >
                    Cerrar
                </button>
            </div>
        </div>
    </div>
</div>
