<cui-section-headline>{{ 'evaluar.pruebas_propias.title' | t }}</cui-section-headline>
<loading-layout #loadingLayout></loading-layout>
<div *ngIf="generadorInstrumentos" [class.hideWhileLoading]="!isReady">
    <ng-container *ngFor="let g of generadorInstrumentos">
        <pruebas-propias-por-generador
            [evaluacionTipo]="evaluacionTipo"
            [isExpandable]="generadorInstrumentos.length > 1"
            #seccionesPruebaPropia
            [generadorInstrumento]="g"
        ></pruebas-propias-por-generador>
    </ng-container>
</div>
