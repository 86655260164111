<div class="filtros">
    <loading-layout #loadingLayout>
        <h4>
            <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
            Filtrar datos
        </h4>
        <div class="filtro-wrap">
            <div class="filtro">
                <label>Evaluacion IDs:</label>
                <!-- <input type="text" class="form-control" ngModel (ngModelChange)="setEvaluacionIds($event)" /> -->
                <input type="number" class="form-control" [(ngModel)]="evaluacionId" />
            </div>

            <div class="filtro">
                <label [attr.for]="establecimientos">Establecimiento:</label>
                <ng-multiselect-dropdown
                    *ngIf="establecimientos"
                    [settings]="dropdownEstablecimientosSettings"
                    [placeholder]="'-- Seleccione Establecimiento --'"
                    [data]="establecimientos"
                    [(ngModel)]="selectedEstablecimientos"
                ></ng-multiselect-dropdown>
            </div>
        </div>
        <div class="bottom-options">
            <!-- <p class="legenda">*Si se usa más de una evaluación, separarlas con coma.</p> -->
            <cui-button (click)="buscarResultados()">
                <span class="left-icon glyphicon glyphicon-search" aria-hidden="true"></span>
                &nbsp;Buscar
            </cui-button>
        </div>
    </loading-layout>
</div>
<div class="resultados">
    <loading-layout #loadingLayoutEvaluaciones>
        <tabla-resumen-evaluacion-por-alumno
            class="print-page-break-after"
            *ngIf="!loadingLayoutEvaluaciones.loading && evaluacionInstancias"
            [evaluacionInstancias]="evaluacionInstancias"
            [allEvaluacionInstancias]="allEvaluacionInstancias"
            [estadisticas]="estadisticas"
            [usuarios]="usuarios"
            [evaluacion]="evaluacion"
            [evaluacionTiempos]="evaluacionTiempos"
        ></tabla-resumen-evaluacion-por-alumno>
    </loading-layout>
</div>
