import {
    Component,
    OnInit,
    Input,
    Output,
    ViewChild,
    EventEmitter,
    ChangeDetectorRef,
    SimpleChanges
} from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    EvaluacionTiempo,
    EvaluacionInstancia,
    Establecimiento,
    Evaluacion,
    GrupoUsuario,
    EvaluacionInstancias,
    Evaluaciones,
    EvaluacionTiempos,
    Estadisticas,
    EvaluacionMultiple,
    Usuario,
    Usuarios,
    EvaluacionMultiples
} from "@puntaje/puntaje/api-services"
import {
    Asignatura,
    ClasificacionTipo,
    Asignaturas,
    ClasificacionTipos,
    Preguntas
} from "@puntaje/nebulosa/api-services"
import { ModalDirective } from "ngx-bootstrap/modal"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "estadisticas-multiples-evaluacion",
    templateUrl: "./estadisticas-multiples-evaluacion.component.html",
    styleUrls: ["../estadisticas.component.scss", "./estadisticas-multiples-evaluacion.component.scss"]
})
export class EstadisticasMultiplesEvaluacionComponent implements OnInit {
    @Input() grupoUsuario: GrupoUsuario
    @Input() evaluacionMultiple: EvaluacionMultiple
    @Input() estadisticas: any
    @Input() establecimiento: Establecimiento
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    asignatura: Asignatura
    asignaturaId: number
    asignaturaIds: number[]
    evaluacionInstancias: EvaluacionInstancia[]
    evaluacionInstanciasByEvaluacionId: { [id: number]: EvaluacionInstancia[] } = {}
    evaluacionInstanciasByAsignaturaId: { [id: number]: EvaluacionInstancia[] } = {}
    evaluacionInstanciasByEMI: { [id: number]: EvaluacionInstancia[] } = {} //by evaluacion_instancia_multiple_id => EMI
    previousEvaluacionInstancias: EvaluacionInstancia[]
    evaluacionTiempos: EvaluacionTiempo[]
    @Input() evaluacionTipo: string
    allEvaluacionInstancias: EvaluacionInstancia[] = []

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    enableGraphs: boolean = false

    dataRespuestas: any

    clasificacionTipos: ClasificacionTipo[]
    clasificacionTiposEstadisticas: any

    distribucion_ensayos_tramo_puntaje: number[] = []
    desempeno_materia_omitidas: number[] = []
    desempeno_materia_correctas: number[] = []
    desempeno_materia_incorrectas: number[] = []
    desempeno_promedio_omitidas: number
    desempeno_promedio_correctas: number
    desempeno_promedio_incorrectas: number
    desempeno_subejes: string[] = []
    desempeno_subejes_omitidas: number[] = []
    desempeno_subejes_correctas: number[] = []
    desempeno_subejes_incorrectas: number[] = []
    comparacion_alumnos: string[] = []
    comparacion_promedios: number[] = []

    estadisticaUsuarios: any[] = []
    usuarios: Usuario[] = []

    canceled: boolean = false
    errorMsg: boolean = false
    @ViewChild("mandatoryLoading", { static: true }) mandatoryLoading: ModalDirective
    hayRespuestas: boolean = false

    estadisticaEvaluacionMultiple: any[]

    constructor(
        protected asignaturasService: Asignaturas,
        protected usuariosService: Usuarios,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected clasificacionTiposService: ClasificacionTipos,
        protected preguntasService: Preguntas,
        protected evaluacionesService: Evaluaciones,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected estadisticasService: Estadisticas,
        protected evaluacionMultiples: EvaluacionMultiples,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()
        this.setData()
    }

    async setData() {
        this.loadingLayout.standby()
        let params: any = {}

        const evaluacionIds = this.evaluacionMultiple.evaluaciones.map(e => e.id)
        params = {
            include: "[respuestas:alternativa,evaluacion_instancia_asignaturas]",
            evaluacion_instancia: {
                evaluacion_id: evaluacionIds
            }
        }

        if (this.grupoUsuario) {
            params.evaluacion_instancia.usuario_id = this.grupoUsuario.usuarios.map(u => u.id)
        } else {
            params.establecimiento = { id: this.establecimiento.id }
        }

        this.allEvaluacionInstancias = <EvaluacionInstancia[]>await this.evaluacionInstanciasService.wherePost(params)

        for (const evaluacion of this.evaluacionMultiple.evaluaciones) {
            this.evaluacionInstanciasByEvaluacionId[evaluacion.id] = this.allEvaluacionInstancias.filter(
                ei => ei.oficial == true && ei.evaluacion_id == evaluacion.id
            )
        }

        //this.setAllEvaluacionesInatancias(evaluacionInstancias)
        //aqui
        /*for (const evaluacion of this.evaluacionMultiple.evaluaciones) {
            params = {
                include: "[respuestas:alternativa,evaluacion_instancia_asignaturas]",
                evaluacion_instancia: {
                    evaluacion_id: evaluacion.id
                }
            }

            if (this.grupoUsuario) {
                params.evaluacion_instancia.usuario_id = this.grupoUsuario.usuarios.map(u => u.id)
            } else {
                params.establecimiento = { id: this.establecimiento.id }
            }
            const evaluacionInstancias = <EvaluacionInstancia[]>await this.evaluacionInstanciasService.wherePost(params)
            this.evaluacionInstanciasByEvaluacionId[evaluacion.id] = evaluacionInstancias.filter(
                ei => ei.oficial == true
            )
        }
        */

        this.asignaturaIds = []
        this.evaluacionInstanciasByAsignaturaId[0] = []
        this.evaluacionMultiple.evaluaciones.forEach(e => {
            this.evaluacionInstanciasByEvaluacionId[e.id].forEach(ei => {
                let evaluacionInstancia = this.evaluacionInstanciasByAsignaturaId[0].find(
                    evi => evi.usuario_id == ei.usuario_id
                )

                if (evaluacionInstancia) {
                    evaluacionInstancia.correctas += ei.correctas
                    evaluacionInstancia.incorrectas += ei.incorrectas
                    evaluacionInstancia.omitidas += ei.omitidas
                    evaluacionInstancia.calificacion += ei.calificacion
                } else {
                    evaluacionInstancia = Object.assign(new EvaluacionInstancia(), ei)
                    evaluacionInstancia.id =
                        evaluacionInstancia.evaluacion_multiple_instancia_id || evaluacionInstancia.id

                    this.evaluacionInstanciasByAsignaturaId[0].push(evaluacionInstancia)
                }

                ei.evaluacion_instancia_asignaturas.forEach(eia => {
                    eia.usuario_id = ei.usuario_id

                    const asignaturaId = this.asignaturaIds.find(aid => eia.asignatura_id == aid)
                    if (!asignaturaId) {
                        this.asignaturaIds.push(asignaturaId)
                    }

                    this.evaluacionInstanciasByAsignaturaId[eia.asignatura_id] =
                        this.evaluacionInstanciasByAsignaturaId[eia.asignatura_id] || []
                    const evaluacionInstanciaAsignatura = this.evaluacionInstanciasByAsignaturaId[
                        eia.asignatura_id
                    ].find(eias => eia.usuario_id == eias.usuario_id)

                    if (evaluacionInstanciaAsignatura) {
                        evaluacionInstanciaAsignatura.correctas += eia.correctas
                        evaluacionInstanciaAsignatura.incorrectas += eia.incorrectas
                        evaluacionInstanciaAsignatura.omitidas += eia.omitidas
                        evaluacionInstanciaAsignatura.calificacion += eia.calificacion
                    } else {
                        const instancia = new EvaluacionInstancia()
                        Object.assign(instancia, eia)

                        this.evaluacionInstanciasByAsignaturaId[eia.asignatura_id].push(instancia)
                    }
                })
            })
        })

        this.clasificacionTiposEstadisticas = config.evaluaciones[this.evaluacionTipo].clasificacionTiposEstadisticas

        const cTipos = this.clasificacionTiposEstadisticas.filter(ct => ct.profesores).map(ct => ct.nombre)

        this.clasificacionTipos = <ClasificacionTipo[]>(
            await this.clasificacionTiposService.where({ clasificacion_tipo: { clasificacion_tipo: cTipos } })
        )
        this.clasificacionTipos = cTipos.map(ct => this.clasificacionTipos.find(cto => cto.clasificacion_tipo == ct))

        if (this.grupoUsuario) {
            this.usuarios = this.grupoUsuario.usuarios
        } else {
            let grupoUsuarioIds = this.evaluacionMultiple.evaluacion_multiple_usuarios
                .filter(evaluacionMultipleUsuarios => evaluacionMultipleUsuarios.grupo_usuario)
                .map(evaluacionMultipleUsuarios => evaluacionMultipleUsuarios.receptor_id)
            this.usuarios = <Usuario[]>await this.usuariosService.where({ grupo_usuario: { id: grupoUsuarioIds } })
        }

        this.enableGraphs = true
        this.loadingLayout.ready()

        if (this.grupoUsuario) {
            params.evaluacion_instancia.usuario_id = this.grupoUsuario.usuarios.map(u => u.id)
        } else {
            params.establecimiento = { id: this.establecimiento.id }
        }

        this.evaluacionInstancias = <EvaluacionInstancia[]>await this.evaluacionInstanciasService.wherePost(params)

        // if(this.evaluacionInstancias.length > 0) {
        //     this.hayRespuestas = true;
        // }

        // if (this.grupoUsuario) {
        //     // this.grupoUsuario && (params.evaluacion_instancia.evaluacion_id = this.previousEvaluacion.id);
        //     this.previousEvaluacionInstancias = <EvaluacionInstancia[]> await this.evaluacionInstanciasService.where(params);
        // } else {
        //     this.previousEvaluacionInstancias = []
        // }

        // this.evaluacionTiempos = <EvaluacionTiempo[]> await this.evaluacionTiemposService.where({ evaluacion_tiempo: { evaluacion_id: this.evaluacion.id } });

        const evalMultipleWithMoreData = await this.evaluacionMultiples.find(this.evaluacionMultiple.id, {
            include: "instrumento_multiple"
        })

        params = {
            collection: "EstadisticaEvaluacionMultiple",
            estadistica: {
                oficial: 1,
                evaluacion_multiple_id: this.evaluacionMultiple.id,
                evaluacion_tipo: this.evaluacionMultiple.evaluacion_tipo.evaluacion_tipo,
                generador_instrumento_id: evalMultipleWithMoreData.instrumento_multiple.generador_instrumento_id
            }
        }

        if (this.grupoUsuario) {
            params.estadistica.grupo_usuario_id = this.grupoUsuario.id
        }

        let estadisticas = <any[]>await this.estadisticasService.wherePost(params)
        this.estadisticaEvaluacionMultiple = estadisticas.filter(
            e => e.evaluacion_multiple_id == this.evaluacionMultiple.id
        )
        this.cdr.detectChanges()

        this.setValues()
        // this.enableGraphs = true;
        // this.loadingLayout.ready();
        this.onReady.emit(this.asignatura)

        // if (!(this.cdr as ViewRef).destroyed) this.cdr.detectChanges();
    }
    setAllEvaluacionesInatancias(evaluacionInstancias: EvaluacionInstancia[]) {}

    setValues() {
        this.distribucion_ensayos_tramo_puntaje = this.estadisticas
            .filter(e => e.evaluacion_multiple_id == this.evaluacionMultiple.id)
            .reduce(
                (distribucion, e) => {
                    Object.keys(e.distribucion_calificaciones).map(puntaje => {
                        if (!distribucion[0][puntaje]) {
                            distribucion[0][puntaje] = 0
                        }
                        distribucion[0][puntaje] += e.distribucion_calificaciones[puntaje]
                    })
                    return distribucion
                },
                { 0: {} }
            )
        // let estadistica_clasificaciones = {};
        // this.estadisticas.forEach((e) => {
        //     e.estadistica_clasificaciones.forEach((ec) => {
        //         if (!estadistica_clasificaciones[ec.clasificacion_id])  {
        //             estadistica_clasificaciones[ec.clasificacion_id] = ec;
        //         } else {
        //             estadistica_clasificaciones[ec.clasificacion_id].correctas += ec.correctas;
        //             estadistica_clasificaciones[ec.clasificacion_id].incorrectas += ec.incorrectas;
        //             estadistica_clasificaciones[ec.clasificacion_id].omitidas += ec.omitidas;
        //         }
        //     });
        // });
        // estadistica_clasificaciones = (Object as any).values(estadistica_clasificaciones);
        // this.clasificacionTipos.forEach((ct) => {
        //     let ecs = (estadistica_clasificaciones as any[]).filter((ec) => ec.clasificacion.clasificacion_tipo_id == ct.id);
        //     let nombre = (ct as any).clasificacion_tipo;
        //     this["desempeno_" + nombre] = ecs.map((ec) => ec.clasificacion.clasificacion + (ec.clasificacion.curso ? " (" + ec.clasificacion.curso.clasificacion + ")" : ""));
        //     this["desempeno_" + nombre + "_omitidas"] = ecs.map((ec) => ec.omitidas);
        //     this["desempeno_" + nombre + "_incorrectas"] = ecs.map((ec) => ec.incorrectas);
        //     this["desempeno_" + nombre + "_correctas"] = ecs.map((ec) => ec.correctas);
        //     this["desempeno_" + nombre + "_total"] = ecs.map((ec) => ec.omitidas + ec.incorrectas + ec.correctas);
        // });
        // let numero_evaluaciones = this.estadisticas.reduce((x, e) => x + e.numero_evaluaciones, 0);
        // this.desempeno_promedio_omitidas = ~~(this.estadisticas.reduce((x, e) => x + e.omitidas, 0) / numero_evaluaciones);
        // this.desempeno_promedio_incorrectas = ~~(this.estadisticas.reduce((x, e) => x + e.incorrectas, 0) / numero_evaluaciones);
        // this.desempeno_promedio_correctas = ~~(this.estadisticas.reduce((x, e) => x + e.correctas, 0) / numero_evaluaciones);
    }

    // printAlumnos() {
    //     this.canceled = false;
    //     this.errorMsg = false;
    //     this.mandatoryLoading.show();
    //     let clasificacion_tipo = config.evaluaciones[this.evaluacionTipo].clasificacionTiposEstadisticas.map((cte) => cte.nombre);

    //     this.evaluacionesService.enableIgnoreModel();
    //     this.evaluacionesService.enableIgnoreCatch();
    //     this.evaluacionesService.imprimirInformeAlumnos(this.evaluacion.id, { clasificacion_tipo: clasificacion_tipo, grupo_usuario: { id: this.grupoUsuario.id } }).then((obj: any) => {
    //         if(this.canceled) {
    //                 throw "Cancelado";
    //         }
    //         this.evaluacionesService.disableIgnoreModel();
    //         console.log("SALIDA ESTANDAR");
    //         console.log("%c " + atob(obj.stdout), "color: blue");
    //         console.log("SALIDA DE ERRORES");
    //         console.log("%c " + atob(obj.stderr), "color: crimson");

    //         let decodedPdf = atob(obj.pdf_content_encoded)
    //         let binaryData = new Uint8Array(decodedPdf.length);
    //         binaryData = binaryData.map((d, i) => decodedPdf.charCodeAt(i));

    //         this.mandatoryLoading.hide();
    //         let a = document.createElement("a");
    //         a.download = "informe_evaluacion_" + this.evaluacion.id + ".pdf";
    //         a.href = window.URL.createObjectURL(new Blob([binaryData], { type: "application/pdf" }));
    //         a.click();
    //     }).catch((response) => {
    //         this.evaluacionesService.disableIgnoreModel();
    //         if(!this.canceled) {
    //             this.errorMsg = true;
    //         }
    //     });
    // }

    cancelPrint() {
        this.evaluacionesService.disableIgnoreModel()
        this.mandatoryLoading.hide()
        this.canceled = true
    }

    // onResultadosPorPreguntaReady(data) {
    //     let d = data.concat().sort((a, b) => b.incorrectas - a.incorrectas).slice(0, 5);

    //     this.preguntasService.where({ pregunta: { id: d.map((p) => p.pregunta_id) } }).then((preguntas: Pregunta[]) => {
    //         d.forEach((p, i) => {
    //             p.pregunta = preguntas[i];
    //         });

    //         this.dataRespuestas = d;
    //     });
    // }

    //TODO: deshabilitar esto cuando lo demás funcione
    setTestValues() {
        this.distribucion_ensayos_tramo_puntaje = [96, 81, 91, 155, 100, 0, 0]
        this.desempeno_materia_omitidas = [5, 3, 4, 7, 2]
        this.desempeno_materia_incorrectas = [2, 2, 3, 2, 1]
        this.desempeno_materia_correctas = [3, 4, 4, 2, 5]
        this.desempeno_promedio_omitidas = 25
        this.desempeno_promedio_correctas = 50
        this.desempeno_promedio_incorrectas = 25
        this.desempeno_subejes = ["Números Reales", "Números Complejos", "Potencias", "Raíces", "Logaritmos"]
        this.desempeno_subejes_omitidas = [849, 69, 266, 168, 255]
        this.desempeno_subejes_incorrectas = [984, 159, 407, 288, 397]
        this.desempeno_subejes_correctas = [1094, 148, 650, 461, 317]
        this.comparacion_alumnos = ["Alumno A", "Alumno B", "Alumno C"]
        this.comparacion_promedios = [290, 302, 177]
    }

    // ngOnChanges(changes: SimpleChanges) {
    //     if (changes['asignaturaId']) {
    //         this.setData();
    //     }
    // }

    onChangeAsignatura(asignatura) {
        this.changeAsignatura(asignatura)
        this.cdr.detectChanges()
    }

    changeAsignatura(asignatura) {
        this.asignatura = asignatura
        if (asignatura) {
            this.asignaturaId = asignatura.id
        } else {
            this.asignaturaId = 0
        }
    }
}
