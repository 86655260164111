<cui-section-headline>Instrumentos no compartidos</cui-section-headline>
<loading-layout #loadingLayout>
    <table
        class="table tabla_ensayos color-scheme-2"
        *ngIf="!loadingLayout.loading && instrumentosMultiples && instrumentosMultiples.length > 0"
    >
        <thead>
            <tr>
                <th>ID</th>
                <!--<th>Tipo</th>-->
                <th>Creador</th>
                <th>Fecha creación</th>
                <th class="actions">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let instrumentoMultiple of instrumentosMultiples">
                <td>{{ instrumentoMultiple.id }}</td>
                <!--<td>{{ instrumento.generador_instrumento.generador_instrumento }}</td>-->
                <td>{{ instrumentoMultiple.usuario?.nombreCompleto() }}</td>
                <td>{{ instrumentoMultiple.created_at | prettydate: "-" }}</td>
                <!-- <td>
					<div *ngFor="let instrumento of instrumentoMultiple.instrumentos">
						<label>Instrumento {{ instrumento.id }}</label>
						<a [routerLink]="['/instrumentos', instrumento.id]" class="btn btn-table"><span class="glyphicon glyphicon-search" aria-hidden="true"></span></a>
					</div>
				</td> -->
                <td class="actions">
                    <a [routerLink]="['/instrumentos_multiples', instrumentoMultiple.id]" class="btn btn-table">
                        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                    </a>
                    <button
                        type="button"
                        (click)="compartirInstrumentoMultiple(instrumentoMultiple)"
                        class="btn btn-table"
                    >
                        <span class="glyphicon glyphicon-share-alt" aria-hidden="true"></span>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <paginator [getList]="setData.bind(this)"></paginator>
    <compartir-instrumento-multiple-profesor
        *ngIf="instrumentoMultiple"
        [instrumentoMultiple]="instrumentoMultiple"
        [modalId]="'compartir_no_compartido'"
        [openModal]="openModal"
        [tipoEvaluacion]="evaluacionTipo"
    ></compartir-instrumento-multiple-profesor>
    <p *ngIf="!instrumentosMultiples || instrumentosMultiples.length <= 0" class="not-found">
        No se encontraron instrumentos en esta categoría.
    </p>
</loading-layout>
