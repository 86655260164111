<generic-modal [id]="modalId" (closeCrossCallback)="cancelar()">
    <modal-titulo>
        <ng-content select="titulo"></ng-content>
    </modal-titulo>
    <modal-contenido class="modal-contenido">
        <form *ngIf="!postStep" #form="ngForm">
            <ng-template [ngIf]="grupoUsuarios && grupoUsuarios.length > 0">
                <h4 class="h4_sub_title_1">
                    <ng-content select="titulo-grupos"></ng-content>
                </h4>
                <ng-template [ngIf]="grupoUsuarios">
                    <checkboxes
                        #grupos="ngModel"
                        [options]="grupoUsuarios"
                        [(ngModel)]="gruposSeleccionados"
                        [groupBy]="groupBy"
                        name="grupos"
                    ></checkboxes>
                    <div
                        *ngIf="(grupos.dirty || validationsPending) && gruposSeleccionados.length <= 0"
                        class="error-warning"
                    >
                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        {{ 'grupo_usuarios.popup.debe_seleccionar' | t }} {{ nombreGrupo }}
                    </div>
                </ng-template>
            </ng-template>

            <ng-template [ngIf]="grupoUsuarios && grupoUsuarios.length == 0">
                <ng-content select="mensaje-sin-grupos"></ng-content>
            </ng-template>
        </form>

        <ng-container *ngIf="postStep">
            <ng-content select="post-step"></ng-content>
        </ng-container>
    </modal-contenido>
    <modal-botones>
        <loader *ngIf="sharing" class="sharing" loaderType="default-xsmall"></loader>
        <cui-button
            buttonType="cancel"
            *ngIf="!postStep"
            type="button"
            class="btn_style_2"
            data-dismiss="modal"
            (click)="cancelar()"
        >
            {{ 'grupo_usuarios.popup.cancelar' | t }}
        </cui-button>
        <cui-button
            *ngIf="!postStep"
            type="button"
            class="btn_style"
            [disabled]="
                !grupoUsuarios || grupoUsuarios.length == 0 || sharing || gruposSeleccionados.length == 0 || postStep
            "
            (click)="submit()"
        >
            <ng-content select="nombre-submit"></ng-content>
        </cui-button>
    </modal-botones>
</generic-modal>
