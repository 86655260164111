<div class="clearfix" id="step1">
    <div class="row">
        <div class="col-md-12">
            <div class="content-f">
                <div class="tutorial">
                    <div class="container-tutorial-icon">
                        <div class="tutorial-icon-pos">
                            <ogr-icon name="hacer-prueba" align="top" class="icon"></ogr-icon>
                        </div>
                    </div>
                </div>
                <div class="content-text">
                    <h3 class="step">{{ 'evaluar.pasos.step' | t }} {{ step }}</h3>
                    <ng-container *ngIf="askNumeroPreguntas">
                        <p class="tutorial-text">{{ 'evaluar.pasos.tc.message' | t }}</p>
                        <ul class="tutorial-text-descripcion">
                            <li>
                                {{ 'evaluar.pasos.tc.desc' | t }}
                                <input
                                    type="number"
                                    class="form-control"
                                    [(ngModel)]="numero_preguntas"
                                    placeholder="4"
                                    min="1"
                                />
                            </li>
                        </ul>
                    </ng-container>

                    <p class="tutorial-text">{{ 'evaluar.pasos.tc.message2' | t }}</p>
                    <p class="tutorial-text-descripcion">
                        {{ 'evaluar.pasos.tc.desc2' | t }}
                    </p>
                    <div class="confirm-container">
                        <input type="checkbox" class="checkbox" (change)="chengeBoton($event)" />
                        <p>
                            {{ 'evaluar.pasos.tc.accept1' | t }}
                            <a href="javascript::void(0)" (click)="$event.preventDefault(); openModal.next()">
                                {{ 'evaluar.pasos.tc.accept2' | t }}
                            </a>
                            {{ 'evaluar.pasos.tc.accept3' | t }}
                        </p>
                    </div>
                    <div class="clearfix">
                        <button
                            [disabled]="btnDisabled"
                            class="btn btn-default"
                            (click)="sendData()"
                            [ngClass]="btnColor"
                        >
                            <ogr-icon name="ticket" align="text-top"></ogr-icon>
                            &nbsp;{{ 'evaluar.pasos.tc.button' | t }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="openModal">
    <generic-modal [id]="'terminos'" [buttonClicked]="openModal" #ModalTerminos>
        <modal-titulo>{{ 'evaluar.pasos.tc.modal_title' | t }}</modal-titulo>
        <modal-contenido>
            <p>
                {{ 'evaluar.pasos.tc.modal_part01' | t }}
                <b>{{ companyName }},</b>
                {{ 'evaluar.pasos.tc.modal_part02' | t }}
                <b>{{ companySite }}</b>
                {{ 'evaluar.pasos.tc.modal_part03' | t }}
                <b>"{{ companyName }}"</b>
                {{ 'evaluar.pasos.tc.modal_part04' | t }}
            </p>

            <p>
                {{ 'evaluar.pasos.tc.modal_part05' | t }}
                <b>{{ companyName }}</b>
                {{ 'evaluar.pasos.tc.modal_part06' | t }}
                <b>{{ companyName }}.</b>
                {{ 'evaluar.pasos.tc.modal_part07' | t }}
                <b>{{ companyName }}.</b>
            </p>

            <p>
                {{ 'evaluar.pasos.tc.modal_part08' | t }}
                
                <b>{{ companyName }},</b>
                {{ 'evaluar.pasos.tc.modal_part09' | t }}
            </p>

            <p>
                {{ 'evaluar.pasos.tc.modal_part10' | t }}
                <b>{{ companyName }}</b>
                {{ 'evaluar.pasos.tc.modal_part11' | t }}
            </p>
            
            <h4>{{ 'evaluar.pasos.tc.modal_subtitle1' | t }}</h4>
            <b>1.1.</b>

            <p>
                {{ 'evaluar.pasos.tc.modal_subcontent1_11' | t }}
                <b>{{ companyName }}</b>
                {{ 'evaluar.pasos.tc.modal_subcontent1_12' | t }}
                <b>{{ companyName }}.</b>
                {{ 'evaluar.pasos.tc.modal_subcontent1_13' | t }}
            </p>
            <b>1.2.</b>

            <p>
                {{ 'evaluar.pasos.tc.modal_subcontent1_21' | t }}
                <b>{{ companyName }}</b>
                {{ 'evaluar.pasos.tc.modal_subcontent1_22' | t }}
                <b>{{ companyName }},</b>
                {{ 'evaluar.pasos.tc.modal_subcontent1_23' | t }}
                <b>{{ companyName }}</b>
                {{ 'evaluar.pasos.tc.modal_subcontent1_24' | t }}
            </p>
            <b>1.3.</b>

            <p>
                <b>{{ companyName }}</b>
                {{ 'evaluar.pasos.tc.modal_subcontent1_31' | t }}
                <b>{{ companyName }}</b>
                {{ 'evaluar.pasos.tc.modal_subcontent1_32' | t }}
            </p>
            <b>1.4.</b>

            <p>
                <b>{{ companyName }}</b>
                {{ 'evaluar.pasos.tc.modal_subcontent1_41' | t }}
            </p>
        </modal-contenido>
        <modal-botones>
            <button class="btn btn-default" (click)="ModalTerminos.close()">Cerrar</button>
        </modal-botones>
    </generic-modal>
</ng-container>
