import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { NgForm } from "@angular/forms"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, GenericModalComponent } from "@puntaje/shared/core"
import { GrupoUsuario } from "@puntaje/puntaje/api-services"

@Component({
    selector: "popup-grupo-usuarios",
    templateUrl: "popup_grupo_usuarios.component.html",
    styleUrls: ["popup_grupo_usuarios.component.scss"]
})
export class PopupGrupoUsuariosComponent implements OnInit {
    @Input() grupoUsuarios: GrupoUsuario[]
    @Input() sharing: boolean = false
    @Input() modalId: string
    @Input() postStep: boolean = false
    @Output() action = new EventEmitter<any>()

    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent
    @Input() gruposSeleccionados: GrupoUsuario[] = []
    validationsPending: boolean = false

    @ViewChild("form") form: NgForm

    nombreGrupo: string
    // nombreGrupoPlural: string = "cursos";

    constructor(protected authService: AuthService, protected router: Router) {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias || "curso"
    }

    ngOnInit() {
        this.setDefaultValues()
    }

    submit() {
        if (!this.validateData()) {
            this.validationsPending = true

            return
        }

        this.action.emit(this.gruposSeleccionados)
    }

    cancelar() {
        this.validationsPending = false
        if (this.form) this.form.reset()
        this.setDefaultValues()
    }

    open() {
        this.genericModal.buttonPressed()
    }

    hide() {
        this.genericModal.close()
    }

    setDefaultValues() {
        this.gruposSeleccionados = []
    }

    groupBy = (gu: GrupoUsuario) => gu.establecimiento.establecimiento

    validateData() {
        return this.gruposSeleccionados.length > 0
    }
}
