import {
    Component,
    OnInit,
    Input,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    ViewChild
} from "@angular/core"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { PlanPersonales, PlanPersonal } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "plan-clase-modelos",
    templateUrl: "./plan-clase-modelos.component.html",
    styleUrls: ["./plan-clase-modelos.component.scss"]
})
export class PlanClaseModelosComponent implements OnInit, OnChanges {
    @Input() generadorInstrumento: GeneradorInstrumento
    @Output() selectPlan = new EventEmitter<PlanPersonal>()
    loading: boolean = true
    @ViewChild(LoadingLayoutComponent) loadingLayout: LoadingLayoutComponent

    planPersonales: PlanPersonal[]

    constructor(protected planPersonalesService: PlanPersonales, protected cdr: ChangeDetectorRef) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["generadorInstrumento"] && changes["generadorInstrumento"].currentValue) {
            const generadorInstrumento = this.generadorInstrumento
            this.generadorInstrumento = null

            this.cdr.detectChanges()
            this.generadorInstrumento = generadorInstrumento
        }
    }

    getData = (page: number, per: number) => {
        this.loadingLayout.standby()

        const params = {
            plan_personal: {
                generador_instrumento_id: this.generadorInstrumento.id
            },
            plan_personal_tipo: {
                tipo: "plan de clase profesor"
            },
            predefinidos: 1,
            order: "desc",
            sort_by: "plan_personales.created_at",

            render_options: {
                include: {
                    plan_personal_ciclos: {
                        include: {
                            plan_personal_sesiones: {
                                include: {
                                    plan_personal_contenidos: {
                                        include: ["material", "evaluacion"]
                                    }
                                }
                            }
                        }
                    }
                }
            },
            page,
            per
        }

        return this.planPersonalesService.where(params).then((planPersonales, total) => {
            this.planPersonales = planPersonales
            this.loadingLayout.ready()

            return total
        })
    }
}
