import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef
} from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    Material,
    Materiales,
    Clasificacion,
    Clasificaciones,
    GeneradorInstrumento
} from "@puntaje/nebulosa/api-services"
import { MaterialUsuario, MaterialUsuarios } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { PaginatorComponent } from "@puntaje/shared/core"

@Component({
    selector: "materiales-biblioteca",
    templateUrl: "./materiales-biblioteca.component.html",
    styleUrls: ["./materiales-biblioteca.component.scss"]
})
export class MaterialesBibliotecaComponent implements OnInit {
    @Input() asignaturaId: number
    @Output() selected = new EventEmitter<Material>()
    @Input() evaluacionTipo: string
    @Input() generadorInstrumento: GeneradorInstrumento

    materiales: Material[]

    curso: any
    clasificacionPrimerNivel: Clasificacion
    clasificacionSegundoNivel: Clasificacion

    clasificacionMateriales: any[]
    searchByCurso: boolean = true

    searchFilter: string

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    constructor(
        protected materialUsuariosService: MaterialUsuarios,
        protected materialesService: Materiales,
        protected clasificacionesService: Clasificaciones,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.clasificacionMateriales = config.evaluaciones[this.evaluacionTipo].planPersonalClasificacionMateriales
    }

    onChangeCurso(curso: Clasificacion = null) {
        this.curso = curso
        this.reloadData()
    }

    onChangeClasificacionPrimerNivel(clasificacion: Clasificacion) {
        this.clasificacionPrimerNivel = clasificacion
        this.reloadData()
    }

    onChangeClasificacionSegundoNivel(clasificacion: Clasificacion) {
        this.clasificacionSegundoNivel = clasificacion
        this.reloadData()
    }

    onSearchFilter(filter) {
        this.searchFilter = filter
        this.reloadData()
    }

    reloadData() {
        this.paginator.changePage(1)
    }

    getData = (page: number, per: number) => {
        this.loadingLayout.standby()
        this.cdr.detectChanges()

        const clasificacionTipos = this.clasificacionMateriales.map(obj => obj.clasificacion)

        if (this.searchByCurso) {
            clasificacionTipos.push(config.evaluaciones[this.evaluacionTipo].cursoTipo)
        }

        const params: any = {
            per,
            page,
            plataforma_id: [config.plataforma.id],
            clasificacion_tipo: {
                clasificacion_tipo: clasificacionTipos
            },
            material_tipo: {
                material_tipo: ["Archivo", "Video"]
            },
            material: {
                asignatura_id: this.asignaturaId
            }
        }

        if (this.searchFilter) {
            params.material = params.material || {}
            params.material.material = this.searchFilter
        }

        if (this.curso || this.clasificacionPrimerNivel || this.clasificacionSegundoNivel) {
            params.clasificacion = { id: [] }
        }

        if (this.searchByCurso && this.curso) {
            params.clasificacion.id.push(this.curso.id)
        }

        if (this.clasificacionPrimerNivel) {
            params.clasificacion.id.push(this.clasificacionPrimerNivel.id)
            if (this.searchByCurso && this.curso) {
                params.and_clasificacion = 1
            }
        }

        if (this.clasificacionSegundoNivel) {
            params.clasificacion.id.push(this.clasificacionSegundoNivel.id)
            params.and_clasificacion = 1
        }

        return this.materialesService.where(params).then((materiales, total) => {
            this.materiales = materiales

            this.loadingLayout.ready()

            this.cdr.detectChanges()
            return total
        })
    }

    selectMaterial(material) {
        this.selected.emit(material)
    }
}
