import { Component, OnInit } from "@angular/core"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription, combineLatest } from "rxjs"
import { AppConfig, SessionService } from "@puntaje/shared/core"
declare const config: AppConfig
import { select, Store } from "@ngrx/store"
import { filter } from "rxjs/operators"
import {
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    State,
    SetSelectedAsignaturaWithId
} from "@puntaje/puntaje/store"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "plan-clases",
    templateUrl: "./plan-clases.component.html",
    styleUrls: ["./plan-clases.component.scss"]
})
export class PlanClasesComponent implements OnInit {
    asignaturaId: number
    asignatura: Asignatura
    tipoInstrumento: string
    generadorInstrumentos: GeneradorInstrumento[]
    hideProgress: boolean = false
    warningText = "<p>Estamos trabajando para habilitar nuevas funcionalidades en esta sección.</p>"
    planClasesRoute = config?.plataforma?.planClasesRouteDocentes || "/tu_curso"
    useNiveles = config?.plataforma?.planClasesUseNiveles || false

    enableSiteWarning = true

    sub: Subscription
    subQuery: Subscription
    subData: Subscription
    asignatura$ = this.store.pipe(select(selectSelectedAsignatura))

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    selectedTab: string

    constructor(
        protected titleService: TitleService,
        protected asignaturasService: Asignaturas,
        protected route: ActivatedRoute,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected authService: AuthService,
        protected store: Store<State>,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_clases.plan_clases.list")
        this.sub = this.route.queryParams.subscribe(query => {
            this.selectedTab = query["tab"] || "plan_clases_alumnos"
        })

        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$).subscribe(
            ([asignatura, evaluacionTipo]) => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura?.id
                this.tipoInstrumento = evaluacionTipo
                if (this.asignaturaId) {
                    this.getGeneradores()
                } else {
                    this.generadorInstrumentos = null
                }
            }
        )

        this.subData = this.route.data.subscribe(data => {
            this.enableSiteWarning = data.enableSiteWarning ?? this.enableSiteWarning
        })
    }

    async getGeneradores() {
        const params: any = {
            asignatura_plataforma: {
                asignatura_id: this.asignaturaId,
                plataforma_id: config.plataforma.id
            },
            tipo_instrumento: {
                tipo_instrumento: this.tipoInstrumento
            },
            include: "[tipo_instrumento]"
        }

        if (this.useNiveles) {
            params.generador_instrumento_categoria = {
                categoria_id: this.sessionService.getNivelesActivos()
            }
        }

        this.generadorInstrumentos = await this.generadorInstrumentosService.where(params)
        if (!this.asignaturaId) {
            this.generadorInstrumentos = null
        }
    }

    onAsignaturaIdListener(value: any) {
        this.asignaturaId = value
        this.getGeneradores()
    }

    onReadyAsignatura(asignaturaId) {
        const params = { queryParams: { tipo_instrumento: this.tipoInstrumento, asignatura_id: asignaturaId } }
        this.router.navigate([this.planClasesRoute], params)
    }

    selectTab(tabName: string) {
        this.selectedTab = tabName
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQuery.unsubscribe()
    }
}
