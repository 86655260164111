import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Establecimiento, Establecimientos } from "@puntaje/puntaje/api-services"
import { AppConfig, AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { EstablecimientosAdministrarService } from "./establecimientos_administrar.service"
import { EscalaEvaluaciones, EscalaEvaluacion } from "@puntaje/puntaje/api-services"
declare const config: AppConfig

@Component({
    selector: "establecimientos-administrar",
    templateUrl: "establecimientos_administrar.component.html",
    styleUrls: ["establecimientos_administrar.component.scss"]
})
export class EstablecimientosAdministrarComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    establecimientos: Establecimiento[]
    establecimiento: Establecimiento
    showGrupoUsuarios: boolean = false
    buscadorCollapsed = false
    sub: Subscription
    id: number
    notaMin: number
    notaMax: number
    notaProm: number
    notaMid: number
    min: number
    mid: number
    max: number
    p_mid: number
    escalaEvaluacion: EscalaEvaluacion
    escalaEvaluaciones: EscalaEvaluacion[]
    editCollapsed: boolean = false
    enableNotaAprobacionEscala: boolean = config.plataforma.enableNotaAprobacionEscala || false

    @Input() showVaciar: boolean = true

    constructor(
        protected route: ActivatedRoute,
        protected authService: AuthService,
        protected establecimientosService: Establecimientos,
        protected establecimientosAdministrarService: EstablecimientosAdministrarService,
        protected escalaEvaluacionesService: EscalaEvaluaciones,
        protected simpleModal: SimpleModalService
    ) {}

    ngOnInit() {
        this.sub = this.establecimientosAdministrarService.changeParams.subscribe(this.updateSelected.bind(this))
        this.establecimientosService
            .where({ establecimiento: { id: this.authService.getEstablecimientos(), activo: 1 } })
            .then((establecimientos: Establecimiento[]) => {
                this.establecimientos = establecimientos
                this.establecimientosAdministrarService.onChangeParams()
                this.showGrupoUsuarios = true
                this.loadingLayout.ready()
                this.verEscala()
            })
    }

    updateSelected(values) {
        if (values.establecimientoId) {
            this.establecimiento = this.establecimientos.find(e => values.establecimientoId === e.id)
            this.id = values.establecimientoId
        } else {
            this.establecimiento = this.establecimientos[0]
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    verEscala() {
        if (this.establecimiento.escala_evaluacion_id != null) {
            this.escalaEvaluacionesService.find(this.establecimiento.escala_evaluacion_id).then(response => {
                this.notaMin = response.min
                this.notaMax = response.max
                this.notaMid = response.mid
                this.notaProm = response.p_mid * 100
            })
        } else {
            this.notaMin = 1.0
            this.notaMax = 7.0
            this.notaProm = 60
            this.notaMid = 4.0
        }
    }

    editEscala(establecimiento) {
        if (this.min || this.max) {
            this.escalaEvaluacion = new EscalaEvaluacion()
            this.escalaEvaluacion.escala_evaluacion = establecimiento.establecimiento
            this.escalaEvaluacion.min = this.min
            this.escalaEvaluacion.mid = this.mid
            this.escalaEvaluacion.max = this.max
            this.escalaEvaluacion.p_mid = this.p_mid ? this.p_mid : 0.6
            this.p_mid = this.escalaEvaluacion.p_mid
            let e = new Establecimiento()
            e.id = establecimiento.id
            e.escala_evaluacion = this.escalaEvaluacion

            if (establecimiento.escala_evaluacion_id != null) {
                this.escalaEvaluacionesService
                    .update(establecimiento.escala_evaluacion_id, this.escalaEvaluacion)
                    .then(response => {
                        this.simpleModal.headerSubject.next("Confirmación")
                        this.simpleModal.contentStringSubject.next("Datos guardados correctamente")
                        this.simpleModal.showSimpleModal()
                        this.notaMin = this.min
                        this.notaMax = this.max
                        this.notaMid = this.mid
                        this.notaProm = this.p_mid * 100
                    })
            } else {
                this.establecimientosService.update(e.id, e).then(a => {
                    this.simpleModal.headerSubject.next("Confirmación")
                    this.simpleModal.contentStringSubject.next("Datos guardados correctamente")
                    this.simpleModal.showSimpleModal()
                    this.notaMin = this.min
                    this.notaMid = this.mid
                    this.notaMax = this.max
                    this.notaProm = this.p_mid * 100
                })
            }
        }
    }
}
