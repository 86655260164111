import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { PlanPersonales, PlanPersonal } from "@puntaje/puntaje/api-services"
import { GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"
import { PlanClaseStorageService } from "../plan-clase-storage.service"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "plan-clase-edit",
    templateUrl: "./plan-clase-edit.component.html",
    styleUrls: ["./plan-clase-edit.component.scss"]
})
export class PlanClaseEditComponent implements OnInit {
    @Input() planPersonalId: number
    @Input() urlRediret: string = "/curso"

    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    planPersonal: PlanPersonal
    asignaturaId: number
    evaluacionTipo: string
    planClasesRoute = config?.plataforma?.planClasesRouteDocentes || "plan_clases"
    disableSave: boolean = false

    constructor(
        protected planPersonalesService: PlanPersonales,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected router: Router,
        protected planClaseStorageService: PlanClaseStorageService
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()

        this.planPersonalesService
            .find(this.planPersonalId, {
                render_options: {
                    include: {
                        plan_personal_ciclos: {
                            include: {
                                plan_personal_sesiones: {
                                    include: {
                                        plan_personal_contenidos: {
                                            include: ["material", "evaluacion"]
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })
            .then(planPersonal => {
                this.generadorInstrumentosService
                    .find(planPersonal.generador_instrumento_id, { include: "asignatura_plataforma" })
                    .then(generadorInstrumento => {
                        planPersonal.plan_personal_ciclos?.forEach(planPersonalCiclo => {
                            planPersonalCiclo.plan_personal_sesiones = planPersonalCiclo.plan_personal_sesiones?.sort(
                                (s1, s2) => s1.orden - s2.orden
                            )

                            planPersonalCiclo.plan_personal_sesiones?.forEach(planPersonalSesion => {
                                planPersonalSesion.plan_personal_contenidos =
                                    planPersonalSesion.plan_personal_contenidos?.sort((c1, c2) => c1.orden - c2.orden)
                            })
                        })

                        this.asignaturaId = generadorInstrumento.asignatura_plataforma.asignatura_id
                        this.evaluacionTipo = generadorInstrumento.tipo_instrumento.tipo_instrumento
                        this.planPersonal = planPersonal

                        this.loadingLayout.ready()
                    })
            })
    }

    save(planClase: PlanPersonal) {
        this.disableSave = true

        this.planPersonalesService
            .update(planClase.id, planClase)
            .then(response => {
                this.router.navigate([this.planClasesRoute], {
                    queryParams: {
                        tipo_instrumento: this.evaluacionTipo,
                        asignatura_id: this.asignaturaId,
                        tab: "mis_plan_clases"
                    }
                })

                this.planClaseStorageService.clear()
            })
            .finally(() => {
                this.disableSave = false
            })
    }
}
