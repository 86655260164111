import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig, SessionService } from "@puntaje/shared/core"
declare const config: AppConfig
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { PaginatorComponent, AuthService } from "@puntaje/shared/core"
import {
    Establecimiento,
    Establecimientos,
    Evaluacion,
    Evaluaciones,
    GrupoUsuario,
    Instrumento,
    Instrumentos,
    Usuario,
    GrupoUsuarios,
    Estadisticas
} from "@puntaje/puntaje/api-services"
import { FiltroEstadisticasService } from "@puntaje/puntaje/new-modules/estadisticas"
import { CompartirReforzamientosComponent } from "./compartir_reforzamientos.component"

@Component({
    selector: "evaluaciones-compartidas",
    templateUrl: "evaluaciones_compartidas.component.html",
    styleUrls: ["evaluaciones_compartidas.component.scss"]
})
export class EvaluacionesCompartidasComponent implements OnInit {
    _asignaturaId: number
    _evaluacionTipo: string
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(CompartirReforzamientosComponent, { static: true })
    compartirReforzamientos: CompartirReforzamientosComponent
    instrumento: Instrumento
    evaluacionToEdit: Evaluacion
    evaluacionToReforzar: Evaluacion
    @Input() tipoId: number = 1
    @Input() rutaEvaluaciones: string
    @Input() showRevisionPersonalizada: boolean = false
    @Input() tipoInstrumentoAlias: string
    openModal: EventEmitter<any> = new EventEmitter<any>()
    openModalEdit: EventEmitter<any> = new EventEmitter<any>()
    nombreGrupo: string = "cursos"
    evaluacionId: number
    instrumentoId: number

    fechaInicial: Date
    fechaFinal: Date

    @Input()
    set evaluacionTipo(evaluacionTipo: string) {
        let old = this._evaluacionTipo
        this._evaluacionTipo = evaluacionTipo

        old && this.paginator && this.paginator.changePage(1)
    }

    get evaluacionTipo() {
        return this._evaluacionTipo
    }

    @Input()
    set asignaturaId(id: number) {
        let old = this._asignaturaId
        this._asignaturaId = id

        old && this.paginator && this.paginator.changePage(1)
    }

    get asignaturaId() {
        return this._asignaturaId
    }

    establecimiento: Establecimiento
    grupoUsuario: GrupoUsuario

    asignatura: Asignatura
    evaluaciones: Evaluacion[]
    establecimientos: Establecimiento[]
    propietarios: Usuario[]
    propietario: Usuario

    presencial: number = 0
    tipo: string

    numeroAlumnosByGrupoUsuarioId: any
    numeroContestadosByEvaluacionIdGrupoUsuarioId: any
    grupoUsuarios: GrupoUsuario[]

    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    utpByEstablecimientoId: any
    usuarioId: number

    asociacionIds: number[]

    constructor(
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected router: Router,
        protected asignaturasService: Asignaturas,
        protected evaluacionesService: Evaluaciones,
        protected establecimientosService: Establecimientos,
        protected grupoUsuariosService: GrupoUsuarios,
        protected estadisticasService: Estadisticas,
        protected instrumentosService: Instrumentos,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias || "curso"
        if (!this.establecimientos) {
            this.loadingLayout.standby()
        }

        this.utpByEstablecimientoId = this.authService.getEstablecimientos().reduce((acc, establecimientoId) => {
            acc[establecimientoId] = true

            return acc
        }, {})

        this.usuarioId = this.authService.getUserData().id
        this.asociacionIds = this.sessionService.getAsociaciones().map(asociacionId => +asociacionId)
    }

    setEstablecimientos() {
        let establecimientos: Establecimiento[]
        this.establecimientosService
            .where({
                mios: 1,
                establecimiento: { activo: 1 },
                fecha_inicial: this.fechaInicial,
                fecha_final: this.fechaFinal
            })
            .then(async rspEstablecimientos => {
                establecimientos = rspEstablecimientos
                const establecimiento_upt = await this.establecimientosService.where({
                    utp_establecimiento: 1,
                    establecimiento: { activo: 1 },
                    fecha_inicial: this.fechaInicial,
                    fecha_final: this.fechaFinal
                })
                if (establecimiento_upt) establecimientos = establecimientos.concat(establecimiento_upt)
            })
            .then(async () => {
                const establecimiento_asociacion = await this.establecimientosService.where({
                    utp_asociacion: 1,
                    establecimiento: { activo: 1 },
                    fecha_inicial: this.fechaInicial,
                    fecha_final: this.fechaFinal
                })
                if (establecimiento_asociacion) establecimientos = establecimientos.concat(establecimiento_asociacion)
            })
            .then(() => {
                this.establecimientos = establecimientos.reduce((unique, o) => {
                    const establecimiento = unique.find(
                        obj => obj.id === o.id && obj.establecimiento === o.establecimiento
                    )

                    if (!establecimiento) {
                        unique.push(o)
                    } else {
                        establecimiento.grupo_usuarios = establecimiento.grupo_usuarios.concat(
                            o.grupo_usuarios.filter(grupoUsuario =>
                                establecimiento.grupo_usuarios.every(gu => gu.id != grupoUsuario.id)
                            )
                        )
                    }

                    return unique
                }, [])
                if (this.establecimientos) {
                    const grupoUsuarios = this.establecimientos.map(e => e.grupo_usuarios).flat()
                    const grupoUsuarioIds = grupoUsuarios.map(gu => gu.id)

                    this.grupoUsuarios = grupoUsuarios
                    this.grupoUsuariosService.numeroAlumnosPost(grupoUsuarioIds).then(data => {
                        this.numeroAlumnosByGrupoUsuarioId = data

                        if (this.establecimientos.length >= 1) {
                            this.establecimiento = this.establecimientos[0]
                            this.setPropietarios()
                        } else if (this.establecimientos.length == 0) {
                            this.establecimiento = undefined
                            this.loadingLayout.ready()
                        }

                        if (this.paginator) this.paginator.reload()
                    })
                }
            })
    }

    setEvaluacionTipo(evaluacionTipo) {
        this.evaluacionTipo = evaluacionTipo

        this.paginator.changePage(1)
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicial = fechaInicial
        this.fechaFinal = fechaFinal

        this.setEstablecimientos()
    }

    setData = (page: number, per: number) => {
        this.loadingLayout.standby()

        const params: any = {
            page: page,
            per: per,
            mis_colegios: 1,
            with_instrumento: 1,
            instrumento: {
                asignatura_id: this.asignaturaId
            },
            evaluacion: {
                presencial: this.presencial
            },
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal,
            order: "desc",
            sort_by: "created_at"
        }

        const estadisticaParams: any = {
            collection: "EstadisticaEvaluacion",
            estadistica: {
                oficial: true
            }
        }

        if (this.establecimiento) {
            params.establecimiento = {
                id: this.establecimiento.id
            }

            if (!this.grupoUsuario) {
                estadisticaParams.estadistica.establecimiento_id = this.establecimiento.id
            }
        } else {
            estadisticaParams.estadistica.grupo_usuario_id = this.grupoUsuarios.map(gu => gu.id)
        }

        if (this.grupoUsuario) {
            params.grupo_usuario = { id: this.grupoUsuario.id }
            estadisticaParams.estadistica.grupo_usuario_id = this.grupoUsuario.id
        }

        this.propietario && (params["evaluacion_usuario"] = { usuario_id: this.propietario.id })
        this.evaluacionTipo && (params["evaluacion_tipo"] = { evaluacion_tipo: this.evaluacionTipo })

        this.evaluacionId && (params["evaluacion"]["id"] = [this.evaluacionId])
        this.instrumentoId && (params["instrumento"]["id"] = [this.instrumentoId])

        this.cdr.detectChanges()

        return this.evaluacionesService.where(params).then((evaluaciones: Evaluacion[], total: number) => {
            estadisticaParams.estadistica.evaluacion_id = evaluaciones.map(e => e.id)

            return this.estadisticasService.wherePost(estadisticaParams).then(estadisticas => {
                this.numeroContestadosByEvaluacionIdGrupoUsuarioId = estadisticas.reduce((acc, estadistica) => {
                    acc[estadistica.evaluacion_id] = acc[estadistica.evaluacion_id] || {}
                    acc[estadistica.evaluacion_id][estadistica.grupo_usuario_id] =
                        acc[estadistica.evaluacion_id][estadistica.grupo_usuario_id] || {}

                    acc[estadistica.evaluacion_id][estadistica.grupo_usuario_id] = estadistica.numero_evaluaciones

                    return acc
                }, {})

                evaluaciones.forEach(evaluacion => {
                    evaluacion.evaluacion_usuarios = evaluacion.evaluacion_usuarios.filter((eu, index, self) => {
                        const other = self.find(euo => euo.grupo_usuario.id == eu.grupo_usuario.id)

                        return other.id == eu.id
                    })
                })

                this.evaluaciones = evaluaciones
                this.loadingLayout.ready()

                return total
            })
        })
    }

    onVerEstadisticas(establecimientoId, grupoUsuarioId, evaluacionId, generadorInstrumentoId, tipoInstrumento) {
        this.filtroEstadisticasService.setFiltrosId(
            establecimientoId,
            grupoUsuarioId,
            null,
            evaluacionId,
            generadorInstrumentoId,
            tipoInstrumento,
            this.asignaturaId
        )
        this.router.navigate(["pruebas/estadisticas"], {
            queryParams: { tipo_instrumento: tipoInstrumento, asignatura_id: this.asignaturaId }
        })
    }

    compartirInstrumento(instrumentoId: number) {
        this.instrumentosService.find(instrumentoId).then((instrumento: Instrumento) => {
            this.instrumento = instrumento
            setTimeout(() => {
                this.openModal.emit()
            }, 100)
        })
    }

    editarEvaluacion(evaluacion: Evaluacion) {
        this.evaluacionToEdit = evaluacion
        this.openModalEdit.emit()
    }

    setPropietarios() {
        this.propietarios = []
        this.propietario = undefined
        if (this.establecimiento) {
            if (this.grupoUsuario) {
                this.propietarios = this.grupoUsuario.propietarios
            } else {
                this.establecimiento.grupo_usuarios.forEach(g => {
                    g.propietarios.forEach(p => {
                        let prop = this.propietarios.find(pr => pr.id == p.id)
                        if (!prop) {
                            this.propietarios.push(p)
                        }
                    })
                })
            }
        }
    }

    compartirReforzamiento(evaluacion: Evaluacion) {
        this.evaluacionToReforzar = evaluacion

        this.compartirReforzamientos.open()
    }

    searchEvaluacion(value: string) {
        this.evaluacionId = +value
        if (this.evaluacionId) this.paginator.changePage(1)
    }

    searchInstrumento(value: string) {
        this.instrumentoId = +value
        if (this.instrumentoId) this.paginator.changePage(1)
    }

    clearEvaluacion() {
        if (this.evaluacionId) {
            this.evaluacionId = null
            this.paginator.changePage(1)
        }
    }

    clearInstrumento() {
        if (this.instrumentoId) {
            this.instrumentoId = null
            this.paginator.changePage(1)
        }
    }
}
