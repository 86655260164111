<div class="filtro clearfix">
    <loading-layout #loadingLayout>
        <div class="clearfix periodo">
            <filtro-periodo (select)="onSelectPeriodo($event)"></filtro-periodo>
        </div>
        <ng-template [ngIf]="establecimientos && establecimientos.length > 0">
            <h4>
                <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                Filtrar datos
            </h4>
            <generador-instrumento-select
                *ngIf="generadorInstrumentos"
                [hidden]="!generadorInstrumentos || generadorInstrumentos.length == 1"
                [generadorInstrumentos]="generadorInstrumentos"
                [(generadorInstrumento)]="generadorInstrumento"
                (generadorInstrumentoChange)="getEvaluaciones()"
            ></generador-instrumento-select>
            <select
                class="form-control"
                [(ngModel)]="establecimiento"
                (ngModelChange)="usuarios = null; grupoUsuario = undefined; getEvaluaciones()"
            >
                <option selected [ngValue]="undefined">Seleccione establecimiento</option>
                <option *ngFor="let establecimiento of establecimientos" [ngValue]="establecimiento">
                    {{ establecimiento.establecimiento }}
                </option>
            </select>
            <select
                class="form-control"
                [(ngModel)]="grupoUsuario"
                (ngModelChange)="usuarios = null; getEvaluaciones(); getEstudiantes()"
            >
                <option selected [ngValue]="undefined">Seleccione {{ nombreGrupo }}</option>
                <ng-template [ngIf]="establecimiento">
                    <option *ngFor="let grupo_usuario of establecimiento.grupo_usuarios" [ngValue]="grupo_usuario">
                        {{ grupo_usuario.nombre }}
                    </option>
                </ng-template>
            </select>
            <select class="form-control" [(ngModel)]="usuario">
                <option selected [ngValue]="undefined">Seleccione Estudiante</option>
                <option *ngFor="let usuario of usuarios" [ngValue]="usuario">{{ usuario.nombre_completo }}</option>
            </select>
            <select class="form-control" [(ngModel)]="evaluacionMultiple">
                <option selected [ngValue]="undefined">
                    Seleccione {{ evaluacionTipo | transformInstrumento: "singular" }}
                </option>
                <option *ngFor="let evaluacionMultiple of evaluacionesMultiples" [ngValue]="evaluacionMultiple">
                    {{ evaluacionMultiple.evaluacion_multiple }}
                </option>
            </select>
            <cui-button
                [disabled]="loadingCount != 0"
                type="button"
                class="btn_style pull-right"
                (click)="setFiltros()"
            >
                <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                Filtrar resultados
            </cui-button>
        </ng-template>

        <ng-template [ngIf]="establecimientos && establecimientos.length == 0">
            Usted no posee {{ config.plataforma.grupoUsuarioAlias | pluralize }} asociados.
        </ng-template>
    </loading-layout>
</div>
