import { Component, OnInit, Input } from "@angular/core"
import { EvaluacionesCompartidasComponent } from "./evaluaciones_compartidas.component"

@Component({
    selector: "evaluaciones-presenciales",
    templateUrl: "evaluaciones_compartidas.component.html",
    styleUrls: ["evaluaciones_compartidas.component.scss"]
})
export class EvaluacionesPresencialesComponent extends EvaluacionesCompartidasComponent implements OnInit {
    presencial = 1

    ngOnInit() {
        super.ngOnInit()
    }
}
