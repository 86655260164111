import { InstrumentoMultipleComponent, InstrumentoComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    {
        path: "instrumentos/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: InstrumentoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "instrumentos_multiples/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: InstrumentoMultipleComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
