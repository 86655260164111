<div class="content-second">
    <div class="content-cuadrado">
        <div class="content-visto" *ngIf="visto">Visto</div>
        <div
            class="thumbnail-wrap"
            (click)="openMaterialPreview()"
            [class.disable-pointer]="planPersonalContenido.tipo == 'Evaluacion'"
            [ngClass]="config.aditionalClass ? 'inverse' : ''"
        >
            <div class="content-thumbnail">
                <thumbnail-material
                    *ngIf="planPersonalContenido.tipo == 'Material'"
                    class="icono"
                    [material]="planPersonalContenido.material"
                ></thumbnail-material>
                <thumbnail-evaluacion
                    *ngIf="planPersonalContenido.tipo == 'Evaluacion'"
                    class="icono"
                    [evaluacion]="planPersonalContenido.evaluacion"
                ></thumbnail-evaluacion>
            </div>
        </div>
        <div
            class="content-checkbox"
            *ngIf="!vistaPrevia && planPersonalContenido.plan_personal_contenido_grupo_usuarios[0]"
        >
            <p>
                <label class="seguimiento-checkbox">
                    <input
                        type="checkbox"
                        (click)="
                            planClaseService.toggleMarcado(
                                planPersonalContenido.plan_personal_contenido_grupo_usuarios[0]
                            )
                        "
                        [(ngModel)]="planPersonalContenido.plan_personal_contenido_grupo_usuarios[0].marcado"
                    />
                    <fa name="check" class="icono"></fa>
                </label>
            </p>
            <p></p>
        </div>
    </div>
    <div class="content-descripcion" *ngIf="planPersonalContenido.tipo == 'Material'">
        <a class="titulo-one" (click)="openMaterialPreview()">{{ planPersonalContenido.material.material }}</a>
        <label class="titulo-two">
            {{ clasificacionContextoByMaterialId[planPersonalContenido.material.id]?.clasificacion }}
        </label>
        <label class="titulo-three">
            {{ clasificacionActividadByMaterialId[planPersonalContenido.material.id]?.clasificacion }}
        </label>
    </div>
    <div class="content-descripcion" *ngIf="planPersonalContenido.tipo == 'Evaluacion'">
        <!-- <a class="titulo-one" (click)="openMaterialPreview()">{{ planPersonalContenido.evaluacion.evaluacion }}</a> -->
        {{ planPersonalContenido.evaluacion.evaluacion }}
    </div>
    <material-preview-modal [clasificacionTipo]="'objetivo de aprendizaje'"></material-preview-modal>
</div>
