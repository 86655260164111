<div class="tabla-container clearfix">
    <div class="scroll-wrap">
        <table *ngIf="detallePorContenidoAlumnos" class="table tabla-resumen">
            <thead>
                <tr>
                    <th>Estudiante</th>
                    <ng-container *ngFor="let contenido of detallePorContenidoAlumnos[0].contenido">
                        <td class="title-contenido">
                            <ogr-icon name="prueba" *ngIf="contenido.tipo == 'Evaluacion'"></ogr-icon>
                            <ogr-icon name="libro2-o" *ngIf="contenido.tipo == 'Material'"></ogr-icon>
                            {{ contenido.nombre }}
                        </td>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let alumno of detallePorContenidoAlumnos">
                    <td class="alumno">
                        <p>{{ alumno.nombre }} {{ alumno.apellido_paterno }} {{ alumno.materno }}</p>

                        <p>
                            <label>Curso:</label>
                            {{ alumno.curso }}
                        </p>
                    </td>
                    <ng-container *ngFor="let contenido of alumno.contenido">
                        <td class="contenido-alumno">
                            <ogr-icon
                                name="ticket-circle"
                                class="icono"
                                *ngIf="contenido.visto && contenido.tipo == 'Material'"
                            ></ogr-icon>
                            <span *ngIf="contenido.calificacion && contenido.tipo == 'Evaluacion'">
                                {{ contenido.calificacion | roundfloat }}
                            </span>
                            <span *ngIf="!contenido.calificacion && contenido.tipo == 'Evaluacion'">-</span>
                        </td>
                    </ng-container>
                </tr>
                <tr class="promedio">
                    <th>Promedio</th>
                    <ng-container *ngFor="let contenido of detallePorContenidoAlumnos[0].contenido">
                        <td class="contenido-alumno">
                            <span class="contenido-alumno">
                                {{ contenido.promedio ? contenido.promedio : "-" }}
                            </span>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
        <paginator [per]="10" [getList]="this.getData.bind(this)"></paginator>
    </div>
    <p *ngIf="!detallePorContenidoAlumnos" class="alert alert-warning">No hay información para mostrar.</p>
</div>
