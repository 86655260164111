import { Subscription } from "rxjs"

import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"

import { InstrumentoMultiple, InstrumentoMultiples } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "instrumento_multiple.component.html",
    styleUrls: ["instrumento.component.scss"]
})
export class InstrumentoMultipleComponent implements OnInit {
    instrumentoMultiple: InstrumentoMultiple
    evaluacionMultipleId: number
    subParams: Subscription
    subQuery: Subscription
    subData: Subscription
    rutaEvaluaciones: string

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected instrumentoMultiplesService: InstrumentoMultiples
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.instrumentos.instrumento_multiple")
        this.subParams = this.route.params.subscribe(params => {
            this.instrumentoMultiplesService
                .find(+params["id"], { with_generador_instrumento: 1 })
                .then((instrumentoMultiple: InstrumentoMultiple) => {
                    this.instrumentoMultiple = instrumentoMultiple
                })
        })

        this.subQuery = this.route.queryParams.subscribe(params => {
            this.evaluacionMultipleId = +params["evaluacion_multiple_id"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.rutaEvaluaciones = data.rutaEvaluaciones ?? this.rutaEvaluaciones
        })
    }

    ngOnDestroy() {
        this.subParams.unsubscribe()
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
    }
}
