<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-4">
            <div class="filtro">
                <h4 class="titulo-filtro">
                    <span class="glyphicon glyphicon-search icono"></span>
                    Ver datos del Establecimiento:
                </h4>
                <!--<label [attr.for]="establecimientos">Establecimientos</label>-->
                <select
                    class="form-control push-down"
                    id="establecimientos"
                    [(ngModel)]="establecimiento"
                    (change)="verEscala()"
                >
                    <option *ngFor="let e of establecimientos" [ngValue]="e">{{ e.establecimiento }}</option>
                </select>
            </div>
        </div>
    </div>
    <div style="display: flex; justify-content: flex-end; margin-bottom: 4px">
        <cui-button (click)="buscadorCollapsed = !buscadorCollapsed">
            <fa name="search" class="left-icon"></fa>
            {{ buscadorCollapsed ? "Buscar usuario" : "Cerrar buscador" }}
        </cui-button>
    </div>
    <usuario-buscador
        *ngIf="!buscadorCollapsed && establecimiento"
        [establecimiento]="establecimiento"
    ></usuario-buscador>
    <div class="big-title-margin" *ngIf="establecimiento">
        <div class="big-title-container">
            <div class="big-title-left">
                <span class="big-title-icon"><ogr-icon name="establecimiento2"></ogr-icon></span>
            </div>
            <h2 class="big-title-text">
                Resultados para:
                <span class="big-title-color">{{ establecimiento.establecimiento }}</span>
            </h2>
        </div>
    </div>
    <div>
        <p><b>Escala de notas predefinida:</b></p>
        <p>Nota mínima: {{ notaMin }}</p>
        <p>Nota máxima: {{ notaMax }}</p>
        <p>Porcentaje de aprobación para {{ enableNotaAprobacionEscala ? notaMid : "4.0" }}: {{ notaProm }}%</p>
        <cui-button (click)="editCollapsed = !editCollapsed">
            <fa name="pencil" class="left-icon"></fa>
            {{ editCollapsed ? "Cerrar edición" : "Editar escala" }}
        </cui-button>
        <div *ngIf="editCollapsed">
            <div class="tbl-cell">
                <div>
                    <div class="tbl">
                        <div class="tbl-row">
                            <div class="tbl-cell"><b>Nota min</b></div>
                            <div class="tbl-cell">
                                <input type="number" class="form-control" name="min" [(ngModel)]="min" />
                            </div>
                        </div>
                        <div class="tbl-row">
                            <div class="tbl-cell"><b>Nota max</b></div>
                            <div class="tbl-cell">
                                <input type="number" class="form-control" name="max" [(ngModel)]="max" />
                            </div>
                        </div>
                        <div class="tbl-row">
                            <div class="tbl-cell"><b>Porcentaje aprobación</b></div>
                            <div class="tbl-cell">
                                <input
                                    type="number"
                                    class="form-control"
                                    name="p_mid"
                                    [(ngModel)]="p_mid"
                                    placeholder="Por ejemplo 0.6"
                                />
                            </div>
                        </div>
                        <div *ngIf="enableNotaAprobacionEscala" class="tbl-row">
                            <div class="tbl-cell"><b>Nota aprobación</b></div>
                            <div class="tbl-cell">
                                <input
                                    type="number"
                                    class="form-control"
                                    name="mid"
                                    [(ngModel)]="mid"
                                    placeholder="Por ejemplo 4.0"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="clearfix">
                        <cui-button class="pull-right" (click)="editEscala(establecimiento)">Editar</cui-button>
                        <cui-button-link
                            class="pull-right small-margin-right"
                            buttonType="cancel"
                            (click)="editCollapsed = !editCollapsed"
                        >
                            Cancelar
                        </cui-button-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <grupo-usuarios-asociados
        *ngIf="establecimiento && showGrupoUsuarios"
        [establecimientoId]="establecimiento.id"
        [showVaciar]="showVaciar"
    ></grupo-usuarios-asociados>
</loading-layout>
