<div class="lateral-container">
    <div class="resultado_puntaje">
        <loading-layout #lateralLoadingLayout>
            <div *ngIf="!lateralLoadingLayout.loading">
                <div class="fade-lateral" *ngIf="evaluacionInstancia">
                    <div class="resultado_puntaje_titulo" role="heading" aria-level="2">
                        <ng-container *ngIf="evaluacionInstancia.evaluacion.tarea; else default">
                            Resultado Tarea #{{ evaluacionInstancia.evaluacion_id }}
                        </ng-container>
                        <ng-template #default>
                            Resultado
                            {{ tipoEvaluacionParaTexto || tipoEvaluacion | transformInstrumento: "singular" }} #{{
                                evaluacionInstancia.evaluacion_id
                            }}
                        </ng-template>
                    </div>
                    <div class="nombre-alumno">{{ evaluacionInstancia.usuario.nombreCompleto() }}</div>
                    <div class="grupos-table">
                        <div class="t-row">
                            <div class="t-hd">Establecimiento</div>
                            <div class="t-td">{{ establecimiento.establecimiento }}</div>
                        </div>
                        <div class="t-row">
                            <div class="t-hd">{{ nombreGrupo | capitalize }}</div>
                            <div class="t-td">{{ grupoUsuario.nombre }}</div>
                        </div>
                    </div>

                    <div class="">
                        <evaluacion-escalas-globales
                            *ngIf="escalasGlobales?.length"
                            [escalasGlobales]="escalasGlobales"
                        ></evaluacion-escalas-globales>
                        <div class="resultado_puntaje_puntos_wrap" *ngIf="!escalasGlobales?.length">
                            <div class="resultado_puntaje_puntos">
                                <div class="resultado_puntaje_puntos_numero">
                                    {{ evaluacionInstancia.calificacion | roundfloat }}
                                </div>
                                <!-- <div class="resultado_puntaje_puntos_texto">puntos</div> -->
                            </div>
                        </div>
                        <div class="resultado_puntaje_valores">
                            <table class="table">
                                <tbody>
                                    <tr
                                        class="puntuacion_row"
                                        *ngIf="evaluacionInstancia.evaluacion.instrumento.propio"
                                    >
                                        <td>
                                            <span class="glyphicon glyphicon glyphicon-pencil res-icon"></span>
                                            <span class="res-icon-text">Puntuación</span>
                                        </td>
                                        <td>{{ evaluacionInstancia.puntuacion ?? evaluacionInstancia.correctas }}</td>
                                    </tr>
                                    <tr class="correctas_row">
                                        <td>
                                            <span class="glyphicon glyphicon glyphicon-ok-circle res-icon"></span>
                                            <span class="res-icon-text">Correctas</span>
                                        </td>
                                        <td>{{ evaluacionInstancia.correctas }}</td>
                                    </tr>
                                    <tr class="incorrectas_row">
                                        <td>
                                            <span class="glyphicon glyphicon glyphicon-remove-circle res-icon"></span>
                                            Incorrectas
                                        </td>
                                        <td>{{ evaluacionInstancia.incorrectas }}</td>
                                    </tr>
                                    <tr class="omitidas_row">
                                        <td>
                                            <span class="glyphicon glyphicon glyphicon-ban-circle res-icon"></span>
                                            Omitidas
                                        </td>
                                        <td>{{ evaluacionInstancia.omitidas }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </loading-layout>
    </div>
    <!-- resultado_puntaje -->
</div>
<!-- contenido -->
