<loading-layout>
    <ng-container *ngIf="generadorInstrumento">
        <cui-section-headline>
            Modelos propuestos para: {{ generadorInstrumento.generador_instrumento }}
        </cui-section-headline>
        <div class="contentPrincipalPlan" *ngIf="planPersonales">
            <div class="planes" *ngFor="let planPersonal of planPersonales">
                <div class="colum_uno"><ogr-icon name="planificacion-o" class="icon"></ogr-icon></div>
                <div class="colum_dos">
                    <a href="javascript:void(0)">
                        {{ planPersonal.plan_personal }}
                    </a>
                </div>
                <div class="colim_tres">
                    <cui-button class="btn_style" (click)="selectPlan.emit(planPersonal)">Previsualizar</cui-button>
                </div>
            </div>
        </div>
        <paginator
            [per]="4"
            [extraMiniMode]="true"
            [class.hidden]="planPersonales?.length == 0"
            [getList]="getData"
        ></paginator>

        <div *ngIf="planPersonales?.length == 0">No hay planes para mostrar.</div>
    </ng-container>
</loading-layout>
