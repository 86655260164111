import {
    Component,
    OnInit,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    ElementRef,
    AfterViewInit,
    OnDestroy
} from "@angular/core"
import {
    PlanPersonalSesion,
    PlanPersonalContenido,
    MaterialUsuarios,
    PlanPersonalContenidos,
    PlanPersonalSesiones,
    Evaluacion,
    Evaluaciones,
    Instrumento
} from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { PlanClaseStorageService } from "../plan-clase-storage.service"
import { GenericModalComponent } from "@puntaje/shared/core"
import { SubirMaterialProfesorComponent } from "@puntaje/puntaje/new-modules/materiales"
import { MaterialesPropiosComponent } from "@puntaje/puntaje/new-modules/materiales"
import { MaterialTipo, GeneradorInstrumento, Clasificacion } from "@puntaje/nebulosa/api-services"
import { MaterialesBibliotecaComponent } from "@puntaje/puntaje/new-modules/materiales"
import { SimpleModalService } from "@puntaje/shared/layouts"

@Component({
    selector: "plan-clase-sesion-form",
    templateUrl: "./plan-clase-sesion-form.component.html",
    styleUrls: ["./plan-clase-sesion-form.component.scss"]
})
export class PlanClaseSesionFormComponent implements OnInit, AfterViewInit, OnDestroy {
    private domChanges: MutationObserver

    @Input() planPersonalSesion: PlanPersonalSesion
    @Input() asignaturaId: number
    @Input() materialTipos: MaterialTipo[]
    @Input() evaluacionTipo: string
    @Input() generadorInstrumento: GeneradorInstrumento
    @Input() clasificaciones: Clasificacion[]
    @Output() deleteSesion = new EventEmitter()
    @Input() indxSesion: number

    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent

    nombreSesion = config.plataforma.nombreSesion || "Sesión"

    buttonText: string = "Agregar"
    visible: boolean = false

    showScrollLeft: boolean = false
    showScrollRight: boolean = false
    scrollWidth: number
    scrollable

    @ViewChild(SubirMaterialProfesorComponent) subirMaterialProfesor: SubirMaterialProfesorComponent
    @ViewChild(MaterialesPropiosComponent) materialesPropios: MaterialesPropiosComponent
    @ViewChild(MaterialesBibliotecaComponent) materialesBiblioteca: MaterialesBibliotecaComponent
    @ViewChild("sortable", { read: ElementRef }) sortable: ElementRef

    nombreTabs: any = [
        {
            nombre: "biblioteca",
            activo: false
        },
        {
            nombre: "propios",
            activo: false
        },
        {
            nombre: "subir",
            activo: false
        },
        {
            nombre: "evaluacion",
            activo: false
        },
        {
            nombre: "prueba_propia",
            activo: false
        }
    ]

    selectedTab = this.nombreTabs[0].nombre

    constructor(
        public planClaseStorageService: PlanClaseStorageService,
        protected simpleModalService: SimpleModalService,
        protected materialUsuariosService: MaterialUsuarios,
        protected planPersonalContenidosService: PlanPersonalContenidos,
        protected planPersonalSesionesServices: PlanPersonalSesiones,
        protected evaluacionesService: Evaluaciones
    ) {}

    ngOnInit() {
        this.nombreTabs[0].activo = true
        this.visible = this.planPersonalSesion.fecha_inicial != undefined
    }

    ngAfterViewInit() {
        this.scrollable = this.sortable.nativeElement
        this.domChanges = new MutationObserver((mutations: MutationRecord[]) => {
            mutations.forEach((mutation: MutationRecord) => {
                this.setScrollWidth()
            })
        })
        this.domChanges.observe(this.scrollable, {
            attributes: true
        })
    }

    ngOnDestroy() {
        this.domChanges.disconnect()
    }

    async openModal() {
        this.genericModal.buttonPressed()
    }

    selectTab(tab) {
        this.nombreTabs.map(tab => (tab.activo = false))
        this.selectedTab = tab
        this.nombreTabs.filter(ntab => ntab.nombre == tab)[0].activo = true
    }

    addMaterial(material) {
        this.planPersonalSesion.plan_personal_contenidos = this.planPersonalSesion.plan_personal_contenidos || []
        const planPersonalContenido = new PlanPersonalContenido()
        planPersonalContenido.tipo = "Material"
        planPersonalContenido.tipo_id = material.id
        planPersonalContenido.material = material

        this.planPersonalSesion.plan_personal_contenidos = [
            ...this.planPersonalSesion.plan_personal_contenidos,
            planPersonalContenido
        ]

        this.planClaseStorageService.savePlanPersonal()

        this.genericModal.close()
        this.selectTab("biblioteca")
    }

    addEvaluacion({ instrumento, nombre, sumativa }: { instrumento: Instrumento; nombre: string; sumativa: boolean }) {
        let evaluacion = new Evaluacion()
        evaluacion.instrumento_id = instrumento.id
        evaluacion.evaluacion = nombre
        evaluacion.sumativa = sumativa
        ;(evaluacion as any).evaluacion_tipo = instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento

        this.evaluacionesService.save(evaluacion).then(ev => {
            this.planPersonalSesion.plan_personal_contenidos = this.planPersonalSesion.plan_personal_contenidos || []
            const planPersonalContenido = new PlanPersonalContenido()
            planPersonalContenido.tipo = "Evaluacion"
            planPersonalContenido.tipo_id = ev.id
            planPersonalContenido.evaluacion = ev

            this.planPersonalSesion.plan_personal_contenidos = [
                ...this.planPersonalSesion.plan_personal_contenidos,
                planPersonalContenido
            ]

            this.planClaseStorageService.savePlanPersonal()

            this.genericModal.close()
            this.selectTab("biblioteca")
        })
    }

    deleteContenido(contenidoIndex) {
        const newPlanPersonalContenidos = this.planPersonalSesion.plan_personal_contenidos.slice()
        newPlanPersonalContenidos.splice(contenidoIndex, 1)

        this.planPersonalSesion.plan_personal_contenidos = newPlanPersonalContenidos

        this.planClaseStorageService.savePlanPersonal()
    }

    showModalDeleteContenido(evento) {
        this.simpleModalService.showSimpleModal()
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este material?")
        this.simpleModalService.setModalCallback(() =>
            this.deleteContenidoOfDataBase(evento.planPersonalContenido, evento.i)
        )
    }

    deleteContenidoOfDataBase(planPersonalContenido, index) {
        this.planPersonalContenidosService.remove(planPersonalContenido.id).then(r => {
            this.deleteContenido(index)
        })
    }

    showModalDeleteSesion(planPersonalSesion: PlanPersonalSesion, index) {
        this.simpleModalService.showSimpleModal()
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta sesión?")
        this.simpleModalService.setModalCallback(() => this.deleteSesionOfDataBase(planPersonalSesion, index))
    }

    deleteSesionOfDataBase(planPersonalSesion, index) {
        this.planPersonalSesionesServices.remove(planPersonalSesion.id).then(r => {
            this.deleteSesion.emit(index)
        })
    }

    swapContenido(fromIndex, toIndex) {
        if (toIndex < 0 || toIndex >= this.planPersonalSesion.plan_personal_contenidos.length) return

        const fromContenido = this.planPersonalSesion.plan_personal_contenidos[fromIndex]
        this.planPersonalSesion.plan_personal_contenidos[fromIndex] =
            this.planPersonalSesion.plan_personal_contenidos[toIndex]
        this.planPersonalSesion.plan_personal_contenidos[toIndex] = fromContenido

        this.planClaseStorageService.savePlanPersonal()
    }

    updateSesion(index) {
        this.deleteSesion.emit(index)
    }

    updateContenidos(contenidos: PlanPersonalContenido[]) {
        contenidos.forEach(contenido => {
            contenido.plan_personal_sesion_id = this.planPersonalSesion.id
        })
        this.planClaseStorageService.savePlanPersonal()
    }

    scrollLeft() {
        this.sortable.nativeElement.querySelector(".contenidos-wrap").scrollLeft -= 130
        this.setScrollWidth()
    }

    scrollRight() {
        this.sortable.nativeElement.querySelector(".contenidos-wrap").scrollLeft += 130
        this.setScrollWidth()
    }

    setScrollWidth() {
        const div = this.scrollable.querySelector(".contenidos-wrap")
        if (div) {
            const scrollWidth = div.scrollWidth - div.clientWidth
            const enableScroll = scrollWidth > 0
            const scrollLeft = div.scrollLeft

            this.showScrollLeft = enableScroll && scrollLeft > 0
            this.showScrollRight = enableScroll && scrollLeft < scrollWidth
        }
    }
}
