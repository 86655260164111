import { Injectable } from "@angular/core"
import { PlanPersonal } from "@puntaje/puntaje/api-services"
import { BaseModel } from "@puntaje/shared/core"

const fn = (from: any, to: BaseModel) => {
    for (const key in from) {
        if (from[key] instanceof Array) {
            let type = (Reflect as any).getMetadata("design:type", to, key)
            if (typeof type === "function" && !BaseModel.prototype.isPrototypeOf(type.prototype)) {
                type = type()
            }

            to[key] = from[key].map(v => {
                if (v instanceof Object) {
                    if (!type) {
                        throw new TypeError(
                            `El objeto de la API posee un arreglo de objetos, pero el modelo no lo esperaba. (${key} en ${to.className})`
                        )
                    }

                    const arrObj = new type()

                    fn(v, arrObj)

                    return arrObj
                } else if (v instanceof Array) {
                    throw new TypeError("Un arreglo dentro de otro arreglo no está soportado actualmente")
                } else {
                    if (type) {
                        throw new TypeError(
                            "El objeto de la API posee un arreglo de tipos primitivos, sin embargo el modelo espera un objeto"
                        )
                    }

                    return v
                }
            })
        } else if (from[key] instanceof Object) {
            let type = (Reflect as any).getMetadata("design:type", to, key)

            if (typeof type === "function" && !BaseModel.prototype.isPrototypeOf(type.prototype)) {
                type = type()
            }

            if (!type) {
                throw new TypeError(
                    `El objeto de la API posee un objeto, pero el modelo no lo esperaba. (${key} en ${to.className})`
                )
            }

            const objType = new type()
            fn(from[key], objType)

            to[key] = objType
        } else {
            to[key] = from[key]
        }
    }
}

@Injectable({
    providedIn: "root"
})
export class PlanClaseStorageService {
    asignaturaId: number
    evaluacionTipo: string
    planPersonal: PlanPersonal

    key: string

    constructor() {}

    initialize(asignaturaId: number, evaluacionTipo: string, planPersonal: PlanPersonal) {
        this.asignaturaId = asignaturaId
        this.evaluacionTipo = evaluacionTipo
        this.planPersonal = planPersonal

        this.buildKey()
    }

    buildKey() {
        let key = `plan_personal_${this.asignaturaId}_${this.evaluacionTipo}`

        if (this.planPersonal.id) {
            key = `${key}_${this.planPersonal.id}`
        }

        this.key = key
    }

    setPlanPersonal(planPersonal: PlanPersonal) {
        this.planPersonal = planPersonal
    }

    getSavedPlanPersonal() {
        const planPersonalString = localStorage.getItem(this.key)

        if (planPersonalString) {
            const planPersonalObj = JSON.parse(planPersonalString)

            this.planPersonal = new PlanPersonal()
            fn(planPersonalObj, this.planPersonal)

            return this.planPersonal
        } else {
            return null
        }
    }

    savePlanPersonal() {
        localStorage.setItem(this.key, JSON.stringify(this.planPersonal))
    }

    clear() {
        localStorage.removeItem(this.key)
    }
}
