import { Component, OnInit, ViewChild, EventEmitter, Output } from "@angular/core"
import { Instrumentos, Instrumento } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "instrumentos-buscador",
    templateUrl: "./instrumentos-buscador.component.html",
    styleUrls: ["./instrumentos-buscador.component.scss"]
})
export class InstrumentosBuscadorComponent implements OnInit {
    buscado: boolean
    instrumentoBuscado: Instrumento
    instrumentoId: number
    @ViewChild("loadingLayoutSearch", { static: true }) loadingLayoutSearch: LoadingLayoutComponent
    @Output() selected = new EventEmitter<any>()
    //selected = new EventEmitter<Instrumento>()

    constructor(protected instrumentosService: Instrumentos) {}

    ngOnInit() {}

    buscar(instrumentoId: number) {
        if (!instrumentoId) {
            return
        }
        this.loadingLayoutSearch.standby()
        this.instrumentosService.enableIgnoreCatch()
        this.instrumentosService
            .find(instrumentoId, { with_generador_instrumento: 1 })
            .then((instrumento: Instrumento) => {
                this.loadingLayoutSearch.ready()
                this.buscado = true
                this.instrumentoBuscado = instrumento
            })
            .catch(response => {
                this.loadingLayoutSearch.ready()
                this.buscado = true
                this.instrumentoBuscado = null
            })
    }

    cargarInst(instrumentoBuscado) {
        this.selected.emit(instrumentoBuscado)
    }
}
