<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="row print-hide">
            <div class="col-lg-4 col-md-5">
                <filtro-estadisticas
                    class="print-hide"
                    (filter)="onFilter($event)"
                    [grupoUsuario]="grupoUsuario"
                    [asignaturaId]="asignaturaId"
                    [evaluacionTipo]="evaluacionTipo"
                ></filtro-estadisticas>
            </div>
            <div class="col-lg-6 col-md-7">
                <informacion-general-grupos
                    *ngIf="showEstadisticasCursos && estadisticas"
                    [establecimiento]="establecimiento"
                    [estadisticas]="estadisticas"
                    [asignaturaId]="asignaturaId"
                    [evaluacionTipo]="evaluacionTipo"
                ></informacion-general-grupos>
            </div>
        </div>
        <div class="clearfix">
            <cui-button class="btn_style pull-right print-hide" *ngIf="ready" (click)="print()">
                <fa name="print"></fa>
                &nbsp;{{ "estadisticas.profe.imprimir" | t }}
            </cui-button>
        </div>
        <br />
        <estadisticas-cursos
            *ngIf="showEstadisticasCursos && estadisticas"
            [grupoUsuarios]="establecimiento.grupo_usuarios"
            [asignaturaId]="asignaturaId"
            [estadisticas]="estadisticas"
            [evaluacionTipo]="evaluacionTipo"
            (onReady)="onReady($event)"
        ></estadisticas-cursos>
        <estadisticas-curso
            *ngIf="showEstadisticasCurso && estadisticas && estadisticaUsuarios"
            [asignaturaId]="asignaturaId"
            [generadorInstrumento]="generadorInstrumento"
            [usuarios]="grupoUsuario.usuarios"
            [grupoUsuario]="grupoUsuario"
            [estadisticas]="estadisticas"
            [estadisticaUsuarios]="estadisticaUsuarios"
            [evaluacionTipo]="evaluacionTipo"
            (onReady)="onReady($event)"
        ></estadisticas-curso>
        <datos-generales
            *ngIf="showEstadisticasEnsayo && evaluacion"
            [instrumento]="evaluacion.instrumento"
        ></datos-generales>
        <estadisticas-evaluacion
            *ngIf="showEstadisticasEnsayo && estadisticas"
            [establecimiento]="establecimiento"
            [asignaturaId]="asignaturaId"
            [evaluacion]="evaluacion"
            [previousEvaluacion]="previousEvaluacion"
            [estadisticas]="estadisticas"
            [grupoUsuario]="grupoUsuario"
            (onReady)="onReady($event)"
            [evaluacionTipo]="evaluacionTipo"
            [evaluacionTipoAlias]="evaluacionTipoAlias"
        ></estadisticas-evaluacion>
    </div>
</loading-layout>
