import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { LoadingLayoutComponent, CarouselSlide } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { LoginComponent } from "@puntaje/puntaje/new-modules/usuarios"

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    private totalHeight: number
    private windowHeight: number
    config: typeof config

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public authService: AuthService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.config = config
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }
    }
}
