import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Instrumento } from "@puntaje/puntaje/api-services"

@Component({
    selector: "agregar-prueba-propia-plan-clase",
    templateUrl: "./agregar-prueba-propia-plan-clase.component.html",
    styleUrls: ["./agregar-prueba-propia-plan-clase.component.scss"]
})
export class AgregarPruebaPropiaPlanClaseComponent implements OnInit {
    @Input() evaluacionTipo: string
    @Input() asignaturaId: number
    evaluacionSumativa: boolean
    @Output() saved = new EventEmitter<{ instrumento: Instrumento; nombre: string; sumativa: boolean }>()

    nombreEvaluacion: string
    instrumento: Instrumento

    constructor() {}

    ngOnInit() {}

    setInstrumento(instrumento: Instrumento) {
        this.instrumento = instrumento
    }

    confirmar() {
        this.saved.emit({
            instrumento: this.instrumento,
            nombre: this.nombreEvaluacion,
            sumativa: this.evaluacionSumativa
        })
    }
}
