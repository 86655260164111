import { Component, OnInit, Input, ViewChild } from "@angular/core"
import {
    PlanPersonalContenidoGrupoUsuarios,
    PlanClaseService,
    PlanPersonalContenido
} from "@puntaje/puntaje/api-services"
import { MaterialPreviewModalComponent } from "@puntaje/puntaje/new-modules/materiales"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "card-material-profesor",
    templateUrl: "./card-material-profesor.component.html",
    styleUrls: ["./card-material-profesor.component.scss"]
})
export class CardMaterialProfesorComponent implements OnInit {
    @Input() planPersonalContenido: PlanPersonalContenido
    @Input() clasificacionActividadByMaterialId
    @Input() clasificacionContextoByMaterialId
    @Input() vistaPrevia: boolean
    @ViewChild(MaterialPreviewModalComponent, { static: true }) modalMaterial: MaterialPreviewModalComponent

    typethumbnails: string
    visto: boolean
    src: string
    config: typeof config = config

    constructor(
        protected planPersonalContenidoGrupoUsuariosService: PlanPersonalContenidoGrupoUsuarios,
        public planClaseService: PlanClaseService
    ) {}

    ngOnInit() {
        this.visto = this.planClaseService.alumnoVisto(this.planPersonalContenido)
        this.typethumbnails = this.planClaseService.getTipoMaterial(this.planPersonalContenido)
        if (this.typethumbnails != "otros") {
            this.src = this.planClaseService.thumbnailsContenido(this.planPersonalContenido)
        }

        // this.modalMaterial.loadMaterial()
        // this.modalMaterial.open(this.planPersonalContenido.material)
    }

    openMaterialPreview() {
        this.modalMaterial.open(this.planPersonalContenido.material)
    }
}
