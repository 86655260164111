import { Component, OnInit, ViewChild, Input } from "@angular/core"
//import { TipoInstrumentos } from "@puntaje/nebulosa/api-services";
import {
    GeneradorInstrumento,
    GeneradorInstrumentos,
    Asignatura,
    Asignaturas,
    AsignaturaWithConfig
} from "@puntaje/nebulosa/api-services"
import {
    GrupoUsuario,
    GrupoUsuarios,
    GrupoUsuarioUsuario,
    GrupoUsuarioUsuarios,
    Estadisticas,
    Establecimiento
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { Subscription } from "rxjs"

@Component({
    selector: "resumen-estadisticas-generales",
    templateUrl: "resumen_estadisticas_generales.component.html",
    styleUrls: ["resumen_estadisticas_generales.component.scss"]
})
export class ResumenEstadisticasGeneralesComponent implements OnInit {
    generadorInstrumentos: GeneradorInstrumento[]
    generadoresPorAsignatura: { [key: number]: GeneradorInstrumento[] } = {}
    asignaturaGenerador: { [key: number]: number } = {}
    asignaturas: { [key: number]: AsignaturaWithConfig } = {}
    grupoUsuarios: { [key: number]: GrupoUsuario } = {}
    usuarioIds: number[] = []
    uGrupos: { [key: number]: number } = {}
    establecimientoPuntajes: { [key: number]: { [key: number]: { total: number; promedio: number } } } = {}
    establecimientos: { [key: number]: Establecimiento } = {}
    establecimientoGrupoUsuario: { [key: number]: number } = {}
    @Input() tipoInstrumento: string = config.plataforma.resumenGeneral.curriculum
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    sub: Subscription
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected giService: GeneradorInstrumentos,
        protected guuService: GrupoUsuarioUsuarios,
        protected guService: GrupoUsuarios,
        protected estadisticasService: Estadisticas,
        protected store: Store<State>,
        protected auth: AuthService
    ) {}

    ngOnInit() {
        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            asignaturasByEvaluacionTipo[this.tipoInstrumento].forEach(a => {
                this.asignaturas[a["id"]] = a
            })
            this.giService
                .where({
                    with_asignatura: true,
                    generador_instrumento: { id: config.plataforma.resumenGeneral.generadores }
                })
                .then((gis: GeneradorInstrumento[]) => {
                    this.generadorInstrumentos = gis
                    this.fillGeneradoresPorAsignatura(gis)
                    this.guService.where({ propios: true }).then((gus: GrupoUsuario[]) => {
                        let gUsuarios = gus.map(gu => gu.id)
                        this.fillEstablecimientoGrupoUsuarios(gus)
                        this.guuService
                            .where({ grupo_usuario_usuario: { grupo_usuario_id: gUsuarios, propietario: 0 } })
                            .then((guus: GrupoUsuarioUsuario[]) => {
                                this.fillUsuariosPorGrupos(guus)
                                let generadores = this.generadorInstrumentos.map(gi => gi.id)
                                this.estadisticasService
                                    .where({
                                        collection: "EstadisticaUsuario",
                                        estadistica: {
                                            oficial: 1,
                                            generador_instrumento_id: generadores,
                                            usuario_id: this.usuarioIds
                                        }
                                    })
                                    .then((estadisticas: any[]) => {
                                        estadisticas.forEach(e => {
                                            this.fillPuntajes(e)
                                        })
                                        this.loadingLayout.ready()
                                    })
                            })
                    })
                })
        })
    }

    public fillGeneradoresPorAsignatura(gis: GeneradorInstrumento[]) {
        gis.forEach((gi: GeneradorInstrumento) => {
            if (gi.asignatura) {
                this.asignaturaGenerador[gi.id] = gi.asignatura.id
                let generadoresA = this.generadoresPorAsignatura[gi.asignatura.id]
                if (generadoresA) {
                    this.generadoresPorAsignatura[gi.asignatura.id].push(gi)
                } else {
                    this.generadoresPorAsignatura[gi.asignatura.id] = [gi]
                }
            }
        })
    }
    public fillEstablecimientoGrupoUsuarios(gus: GrupoUsuario[]) {
        gus.forEach(gu => {
            this.establecimientos[gu.establecimiento_id] = gu.establecimiento
            this.establecimientoGrupoUsuario[gu.id] = gu.establecimiento_id
        })
    }

    public fillUsuariosPorGrupos(guus: GrupoUsuarioUsuario[]) {
        guus.forEach(guu => {
            this.usuarioIds.push(guu.usuario_id)
            this.uGrupos[guu.usuario_id] = guu.grupo_usuario_id
        })
    }

    public fillPuntajes(e: any) {
        let grupo = this.establecimientoGrupoUsuario[this.uGrupos[e["usuario_id"]]]
        if (grupo) {
            var grupoP = this.establecimientoPuntajes[grupo]
            if (!grupoP) {
                grupoP = {}
                this.establecimientoPuntajes[grupo] = {}
            }
            let asignaturaGenerador = -this.asignaturaGenerador[e["generador_instrumento_id"]]
            if (asignaturaGenerador && !grupoP[asignaturaGenerador]) {
                this.establecimientoPuntajes[grupo][asignaturaGenerador] = { promedio: 0, total: 0 }
            }
            var gp = grupoP[e["generador_instrumento_id"]]
            if (gp) {
                gp.promedio =
                    (gp.promedio * gp.total + e["promedio"] * e["numero_evaluaciones"]) /
                    (gp.total + e["numero_evaluaciones"])
                gp.total = gp.total + e["numero_evaluaciones"]
                this.establecimientoPuntajes[grupo][e["generador_instrumento_id"]] = gp
            } else {
                var newGp: { promedio: number; total: number } = { promedio: 0, total: 0 }
                newGp.promedio = e["promedio"]
                newGp.total = e["numero_evaluaciones"]
                this.establecimientoPuntajes[grupo][e["generador_instrumento_id"]] = newGp
            }
            let old = this.establecimientoPuntajes[grupo][asignaturaGenerador].total
            this.establecimientoPuntajes[grupo][asignaturaGenerador].total = old + e["numero_evaluaciones"]
        }
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub.unsubscribe()
    }
}
