<div class="content-principal" [class.isCurrent]="planPersonalSesion.plan_personal_sesion_grupo_usuarios[0]?.actual">
    <div class="statusWrap">
        <ng-container *ngIf="planPersonalSesion.plan_personal_sesion_grupo_usuarios[0]?.actual">
            <span class="currentMarker">
                <fa name="arrow-right" class="icono"></fa>
                {{ "estudiar.plan_de_clases.vamos_aqui" | t }}
            </span>
            <a (click)="planClaseService.toggleActual(planPersonalSesion.plan_personal_sesion_grupo_usuarios[0])">
                {{ "estudiar.plan_de_clases.no_vamos_aqui" | t }}
            </a>
        </ng-container>
        <button
            class="btn btn-marker"
            *ngIf="!planPersonalSesion.plan_personal_sesion_grupo_usuarios[0]?.actual"
            (click)="planClaseService.toggleActual(planPersonalSesion.plan_personal_sesion_grupo_usuarios[0])"
            [disabled]="vistaPrevia"
        >
            <fa name="arrow-down" class="icon-semana-no-marcada"></fa>
            {{ "estudiar.plan_de_clases.decir_vamos_aqui" | t }}
        </button>
    </div>
    <div class="botones">
        <button
            class="btn btn-success"
            *ngIf="planPersonalSesion.plan_personal_sesion_grupo_usuarios[0]?.visto"
            (click)="toggleVisto(planPersonalSesion.plan_personal_sesion_grupo_usuarios[0])"
            [disabled]="vistaPrevia"
        >
            <fa name="check"></fa>
            {{ nombreSesion | capitalize }} {{ "estudiar.plan_de_clases.marcada_vista" | t }}
        </button>
        <button
            *ngIf="!planPersonalSesion.plan_personal_sesion_grupo_usuarios[0]?.visto"
            class="btn btn-link"
            (click)="toggleVisto(planPersonalSesion.plan_personal_sesion_grupo_usuarios[0])"
            [disabled]="vistaPrevia"
        >
            <fa name="check-square-o"></fa>
            {{ "estudiar.plan_de_clases.marcar_vista1" | t }} {{ nombreSesion }}
            {{ "estudiar.plan_de_clases.marcar_vista2" | t }}
        </button>
    </div>
    <div class="descripcion">
        <ng-container
            *ngIf="config.plataforma.name == 'Aprendolibre' && config.app.name == 'profesores'; else elseDescripcion"
        >
            <label>{{ planPersonalSesion.plan_personal_sesion }}</label>
        </ng-container>
        <ng-template #elseDescripcion>
            <label>{{ nombreSesion | capitalize }} {{ index + 1 }}</label>
            <p>{{ planPersonalSesion.plan_personal_sesion }}</p>
        </ng-template>
    </div>
    <div *ngIf="planPersonalSesion.fecha_inicial" class="visible-desde">
        <label>{{ "estudiar.plan_de_clases.visible_desde" | t }}</label>
        <p>{{ planPersonalSesion.fecha_inicial | date: "dd-MM-yyyy h:mm a" }}</p>
    </div>

    <tabset>
        <tab heading="Contenido" class="tab-content">
            <div class="content-two">
                <div class="carousel">
                    <label class="titulo-carousel">{{ "estudiar.plan_de_clases.material_clases" | t }}</label>
                    <carousel-semanal
                        [clasificacionActividadByMaterialId]="clasificacionActividadByMaterialId"
                        [clasificacionContextoByMaterialId]="clasificacionContextoByMaterialId"
                        [plan_personal_contenidos]="planPersonalSesion.plan_personal_contenidos"
                        [vistaPrevia]="vistaPrevia"
                    ></carousel-semanal>
                </div>
                <div class="btn-bar-right">
                    <cui-button
                        *ngIf="!vistaPrevia"
                        class="btn_style"
                        [routerLink]="[urlNavigate, planPersonal.id, 'sesiones', planPersonalSesion.id]"
                        [queryParams]="{ grupo_usuario_id: grupoUsuarioId }"
                    >
                        {{ "estudiar.plan_de_clases.ver_clase" | t }}
                        <fa name="arrow-right"></fa>
                    </cui-button>
                    <cui-button
                        *ngIf="vistaPrevia"
                        class="btn_style"
                        [routerLink]="['/vista_previa_plan_clases', planPersonal.id, 'sesiones', planPersonalSesion.id]"
                        [queryParams]="{ grupo_usuario_id: grupoUsuarioId }"
                    >
                        {{ "estudiar.plan_de_clases.ver_clase" | t }}
                        <fa name="arrow-right"></fa>
                    </cui-button>
                </div>
            </div>
            <div class="content-actividad" *ngIf="actividad">
                <div class="row">
                    <div class="actividad col-sm-6">
                        <cui-section-headline>{{ "estudiar.plan_de_clases.actividad" | t }}</cui-section-headline>
                        <div class="barra-wrap">
                            <div class="barra-avance" [class.fullwidth]="startAnimValues">
                                <div class="avance" [style.width]="avance + '%'"></div>
                            </div>
                        </div>
                        <div class="avance">
                            {{ avanceActividad }} / {{ totalAlumnosActividad }}
                            {{ "estudiar.plan_de_clases.estudiantes" | t }}
                        </div>
                        <div class="btn-bar">
                            <cui-button class="btn_style" (click)="evaluacionPreview.open()">
                                {{ "estudiar.plan_de_clases.ver_actividad" | t }}&nbsp;
                                <fa name="arrow-right"></fa>
                            </cui-button>
                            <cui-button-link
                                class="btn_style"
                                [routerLinkValue]="[
                                    '/plan_personal_sesiones/',
                                    planPersonalSesion.id + '',
                                    'resultados_evaluacion'
                                ]"
                                [queryParamsValue]="{ grupo_usuario_id: grupoUsuarioId, selected_tab: 'respuestas' }"
                            >
                                {{ "estudiar.plan_de_clases.ver_respuestas" | t }}&nbsp;
                                <fa name="check-square-o"></fa>
                            </cui-button-link>
                        </div>
                    </div>
                    <div class="feedback col-sm-6">
                        <cui-section-headline>
                            {{ "estudiar.plan_de_clases.retroalimentacion" | t }}
                        </cui-section-headline>
                        <cui-button-link
                            class="btn_style"
                            [routerLinkValue]="[
                                '/plan_personal_sesiones/',
                                planPersonalSesion.id + '',
                                'resultados_evaluacion'
                            ]"
                            [queryParamsValue]="{ grupo_usuario_id: grupoUsuarioId, selected_tab: 'feedback' }"
                        >
                            {{ "estudiar.plan_de_clases.enviar_retroalimentacion" | t }}&nbsp;
                            <fa name="refresh"></fa>
                        </cui-button-link>
                    </div>
                </div>
            </div>
        </tab>

        <tab heading="Avance" class="tab-content">
            <ng-container *ngIf="avanceGrupoUsuario">
                <div class="row">
                    <div class="col-xs-12 col-md-4">
                        <div class="content-label">
                            <label class="label-barra">{{ "estudiar.plan_de_clases.avance" | t }}:</label>
                            <label class="label-barra">
                                {{ avanceGrupoUsuario.avance + "/" + avanceGrupoUsuario.total_alumnos }}
                                {{ "estudiar.plan_de_clases.estudiantes" | t }}
                            </label>
                        </div>
                        <div class="barra-wrap">
                            <div class="barra-avance" [class.fullwidth]="startAnimValues">
                                <div
                                    class="avance"
                                    [style.width]="
                                        (avanceGrupoUsuario.avance * 100) / avanceGrupoUsuario.total_alumnos + '%'
                                    "
                                ></div>
                            </div>
                        </div>
                        <div class="porcentaje-centro">
                            <p>
                                {{
                                    avanceGrupoUsuario.total_alumnos == 0
                                        ? 0
                                        : ((avanceGrupoUsuario.avance * 100) / avanceGrupoUsuario.total_alumnos
                                          | roundfloat)
                                }}%
                            </p>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-4">
                        <div class="content-label">
                            <label class="label-barra">{{ "estudiar.plan_de_clases.promedio_logro" | t }}:</label>
                            <label class="label-barra">{{ avanceGrupoUsuario.porcentaje_logro_promedio }}%</label>
                        </div>
                        <div class="barra-wrap">
                            <div class="barra-avance" [class.fullwidth]="startAnimValues">
                                <div
                                    class="avance"
                                    [style.width]="avanceGrupoUsuario.porcentaje_logro_promedio + '%'"
                                ></div>
                            </div>
                        </div>
                        <div class="porcentaje-centro">
                            <p>{{ avanceGrupoUsuario.porcentaje_logro_promedio | roundfloat }}%</p>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-4">
                        <div class="content-label">
                            <label class="label-barra">{{ "estudiar.plan_de_clases.promedio_tiempo" | t }}:</label>
                        </div>
                        <div class="content-label">
                            <label class="tiempo-promedio">{{ avanceGrupoUsuario.tiempo_promedio }}</label>
                        </div>
                    </div>
                </div>
                <div>
                    <cui-button buttonType="primary" (click)="showAvancePorAlumno = !showAvancePorAlumno">
                        {{ showAvancePorAlumno ? "Ocultar avance por alumno" : "Mostar avance por alumno" }}
                    </cui-button>
                    <cui-button
                        *ngIf="grupoUsuarioId"
                        buttonType="primary"
                        class="pull-right"
                        (click)="send_reminder(planPersonalSesion)"
                    >
                        {{ enviandoRecordatorios ? "enviando..." : "Enviar recordatorio" }}
                    </cui-button>
                </div>
                <br />
                <cui-tabs *ngIf="showAvancePorAlumno" justifyContent="flex-start">
                    <cui-tab title="Resumen" (select)="selectTab('resumen')">
                        <plan-clase-sesion-avance-por-alumno
                            [planPersonalSesionId]="planPersonalSesion.id"
                            [grupoUsuarioId]="grupoUsuarioId"
                        ></plan-clase-sesion-avance-por-alumno>
                    </cui-tab>
                    <cui-tab title="Detalle por material/evaluación" (select)="selectTab('detalle')">
                        <plan-clase-sesion-detalle-por-contenido
                            [planPersonalSesionId]="planPersonalSesion.id"
                            [grupoUsuarioId]="grupoUsuarioId"
                        ></plan-clase-sesion-detalle-por-contenido>
                    </cui-tab>
                </cui-tabs>
            </ng-container>
            <ng-container *ngIf="!avanceGrupoUsuario">{{ "estudiar.plan_de_clases.not_comp" | t }}</ng-container>
        </tab>
    </tabset>
</div>
<evaluacion-preview-modal
    #evaluacionPreview
    modalId="actividad"
    [evaluacionId]="evaluacionActividadId"
></evaluacion-preview-modal>
