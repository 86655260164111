import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Evaluacion, Evaluaciones } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "revisar_simulacros.component.html"
})
export class RevisarSimulacrosComponent {
    evaluaciones: Evaluacion[]
    evaluacionesString: string = ""
    evaluacionesGeneroString: string = ""

    tipoInstrumento: string

    constructor(
        protected titleService: TitleService,
        protected asignaturasService: Asignaturas,
        protected evaluacionesService: Evaluaciones,
        protected route: ActivatedRoute,
        protected router: Router
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.evaluaciones_multiples.revisar_simulacros")
        this.route.queryParams.subscribe(query => {
            this.tipoInstrumento = query["tipo_instrumento"] // "simulacro saber11"
        })
    }
}
