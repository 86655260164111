<ll-titulo>Resultados</ll-titulo>
<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas [showTitleOverMenu]="showTitleOverMenu"></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>

<libro-notas
    *ngIf="asignaturaId && tipoInstrumento"
    [asignaturaId]="asignaturaId"
    [evaluacionTipo]="tipoInstrumento"
    [goToEstadisticas]="true"
    [disableReforzamiento]="disableReforzamiento"
></libro-notas>
