<div class="clearfix" (window:resize)="onResize($event)">
    <ng-container *ngIf="innerTimeFrame">
        <timeframe-selector (onTimeFrameChanged)="onTimeFrameChange($event)"></timeframe-selector>
    </ng-container>
    <loading-layout #loadingLayout>
        <div *ngIf="!loadingLayout.loading">
            <grafico-desempeno-evolutivo
                *ngIf="!loadingLayout.loading && dataSets"
                [enableDemoMode]="false"
                [tipoEvaluacion]="evaluacionTipo"
                [dataSets]="dataSets"
                titulo="{{ evaluacionTipo | transformInstrumento: 'nombreCalificacion' | capitalize }} {{
                    'progreso.showcasebox.grafico.titulo' | t
                }}"
                descripcion="{{ 'progreso.showcasebox.grafico.descripcion' | t }} {{
                    evaluacionTipo | transformInstrumento: 'nombreCalificacionPlural'
                }}"
                class="no-top-margin"
            ></grafico-desempeno-evolutivo>

            <ng-container *ngIf="!withoutProgresoClasificacion">
                <cui-section-headline>
                    {{ "progreso.showcasebox.grafico.progreso_por" | t }} {{ nombreClasificacionTipo }}
                </cui-section-headline>
                <div class="progreso-container">
                    <div class="grafico-container" *ngFor="let dataSetsClasificacion of dataSetsClasificaciones">
                        <div class="grafico-wrap">
                            <grafico-desempeno-evolutivo
                                class="grafico"
                                *ngIf="!loadingLayout.loading"
                                [enablePorcentaje]="true"
                                [enableDemoMode]="false"
                                [titulo]="dataSetsClasificacion.titulo"
                                [descripcion]="dataSetsClasificacion.descripcion"
                                [dataSets]="[dataSetsClasificacion]"
                            ></grafico-desempeno-evolutivo>
                            <a
                                *ngIf="dataSetsClasificacion.dataClasificacionesHijas?.length > 0"
                                class="btn-sub"
                                href="javascript:void(0)"
                                toggleExpandable="clasificacion-{{ dataSetsClasificacion.nombre }}"
                            >
                                {{ graficosSub.expanded ? "Ocultar" : "Ver" }} subejes
                            </a>

                            <div
                                id="clasificacion-{{ dataSetsClasificacion.nombre }}"
                                #graficosSub="expandableContent"
                                [expandableContent]
                            >
                                <div
                                    class="grafico-sub-container"
                                    *ngFor="
                                        let dataSetSubClasificacion of dataSetsClasificacion.dataClasificacionesHijas
                                    "
                                >
                                    <grafico-desempeno-evolutivo
                                        class="grafico-sub"
                                        *ngIf="!loadingLayout.loading"
                                        [enablePorcentaje]="true"
                                        [enableDemoMode]="false"
                                        [titulo]="dataSetSubClasificacion.titulo"
                                        [descripcion]="dataSetSubClasificacion.descripcion"
                                        [dataSets]="[dataSetSubClasificacion]"
                                    ></grafico-desempeno-evolutivo>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </loading-layout>
</div>
