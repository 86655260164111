import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { Instrumento, Instrumentos, EvaluacionUsuario, Evaluacion } from "@puntaje/puntaje/api-services"
import {
    Clasificacion,
    Clasificaciones,
    Asignatura,
    Asignaturas,
    AsignaturaWithConfig
} from "@puntaje/nebulosa/api-services"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasById } from "@puntaje/puntaje/store"
import { Subscription, Observable } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "evaluacion-portada",
    templateUrl: "evaluacion_portada.component.html",
    styleUrls: ["evaluacion_portada.component.scss"]
})
export class EvaluacionPortadaComponent implements OnInit {
    @Input() evaluacion: Evaluacion
    asignaturas: Asignatura[]
    asignatura: AsignaturaWithConfig
    @Input() groupedBy
    groupedClasificaciones: Clasificacion
    nombreEvaluacion: string = "Evaluación"

    groups: string[]

    sub: Subscription
    asignaturasById$: Observable<{ [id: number]: AsignaturaWithConfig }> = this.store.pipe(
        select(selectAsignaturasById),
        filter(x => !!x)
    )

    constructor(
        protected clasificacionesService: Clasificaciones,
        protected router: Router,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.sub = this.asignaturasById$.subscribe(asignaturasById => {
            this.asignatura = asignaturasById[this.evaluacion.instrumento.asignatura_id]
        })
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub.unsubscribe()
    }
}
