<generic-modal [buttonClicked]="openModal" [id]="'modal-noticias'" #ModalNoticias>
    <modal-titulo class="modal-titulo">
        <div class="noticias-title">
            <div class="noticias-icon" *ngIf="hasNewsIcon">
                <img src="{{ config.app.assets.logoSquare }}" (error)="hasNewsIcon = false" />
            </div>
            <div class="noticias-icon" *ngIf="!hasNewsIcon">
                <ogr-icon name="megafono" class="noticias-icon-default"></ogr-icon>
            </div>
            <div class="noticias-title-text" *ngIf="currentNoticia">
                {{ currentNoticia.titulo }}
            </div>
        </div>
    </modal-titulo>
    <modal-contenido class="modal-contenido">
        <div *ngIf="currentNoticia">
            <div [innerHTML]="currentNoticia.noticia | trustedhtml"></div>
        </div>
    </modal-contenido>
    <modal-botones>
        <cui-button buttonType="cancel" type="button" class="btn_style_2" (click)="ModalNoticias.close()">
            Cerrar
        </cui-button>
    </modal-botones>
</generic-modal>
