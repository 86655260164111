import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, ViewChild } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    GrupoUsuario,
    GrupoUsuarios,
    Evaluaciones,
    EvaluacionInstancias,
    Usuarios,
    Usuario,
    Evaluacion,
    EvaluacionInstancia,
    PlanPersonal,
    PlanPersonales,
    EscalaGlobal
} from "@puntaje/puntaje/api-services"
import { PaginatorComponent, I18nService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"
import { FiltroEstadisticasService } from "@puntaje/puntaje/new-modules/estadisticas"
import { EvaluacionInstanciaEscalasService } from "../evaluacion_instancia_escalas.service"

@Component({
    selector: "libro-notas",
    templateUrl: "./libro-notas.component.html",
    styleUrls: ["./libro-notas.component.scss"]
})
export class LibroNotasComponent implements OnInit {
    @Input() asignaturaId: number
    @Input() evaluacionTipo: string
    @Input() searchTareas: boolean = false
    @Input() goToEstadisticas: boolean = false
    @Input() disableReforzamiento: boolean = false
    grupoUsuarioAlias = config.plataforma.grupoUsuarioAlias

    fechaInicial: Date
    fechaFinal: Date
    periodo: number[]

    cursos: GrupoUsuario[]
    selectedCurso: GrupoUsuario

    alumnos: Usuario[]
    tareas: Evaluacion[]
    instancias: EvaluacionInstancia[]
    reforzamientos: PlanPersonal[]
    reforzamientoInstancias: EvaluacionInstancia[]

    data: any
    promediosPorAlumno: any
    promediosPorTarea: any
    tareaReforzamientos: any
    escalasGlobales: any

    tareasPer = 5
    tareasPage = 1
    tareasPages: number[] = []
    totalTareas: number
    hasNextTareas = false
    hasPrevTareas = false
    totalTareasPages: number
    selectedPage: number

    evaluacionesSearch: Evaluacion[] = []
    evaluacionSearch: Evaluacion
    evalSearchError: string

    @ViewChild(PaginatorComponent) paginador: PaginatorComponent
    @ViewChild(LoadingLayoutComponent, { static: true }) loadinglayout: LoadingLayoutComponent
    showPaginador = false

    constructor(
        protected grupoUsuariosService: GrupoUsuarios,
        protected usuariosService: Usuarios,
        protected evaluacionesService: Evaluaciones,
        protected planPersonalesService: PlanPersonales,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected cdr: ChangeDetectorRef,
        protected router: Router,
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected evaluacionInstanciaEscalasService: EvaluacionInstanciaEscalasService,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        if (config.plataforma.name == "Puntaje Nacional") {
            this.disableReforzamiento = true
        }
        this.disableReforzamiento = config.plataforma.disableReforzamiento || false
        this.loadinglayout.ready()
    }

    resetData() {
        this.data = null
        this.tareaReforzamientos = null
        this.alumnos = null
        this.tareas = null
        this.instancias = null
        this.reforzamientos = null
        this.reforzamientoInstancias = null
    }

    async setData(page: number, per: number) {
        this.loadinglayout.standby()
        this.resetData()
        this.cdr.detectChanges()

        const total = await this.getAlumnos(page, per)

        await this.getTareas()
        await this.getInstancias()
        await this.getReforzamientos()
        await this.getPromedios()
        await this.getEscalas()

        this.processData()

        this.selectedPage = page

        return total
    }

    getAlumnos(page: number, per: number) {
        const usuarioParams = {
            grupo_usuario_usuario: {
                propietario: 0,
                grupo_usuario_id: this.selectedCurso.id
            },
            sort_by: "usuarios.apellido_paterno, usuarios.apellido_materno, usuarios.nombre",
            page: page,
            per: per
        }
        return this.usuariosService.where(usuarioParams).then((usuarios, total) => {
            this.alumnos = usuarios
            if (total == 0) this.showPaginador = false
            return total
        })
    }

    getCursos() {
        const params = {
            propios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal,
            render_options: {
                include: {
                    establecimiento: null
                }
            }
        }
        this.grupoUsuariosService.where(params).then(cursos => {
            this.cursos = cursos
            if (cursos.length > 0) {
                this.selectedCurso = cursos[0]
                this.onSelectCurso()
            } else {
                this.resetData()
            }
        })
    }

    getEvaluacionesParams(paging: boolean = true) {
        const evaluacionesParams = {
            evaluacion_usuario: {
                receptor_type: "GrupoUsuario",
                receptor_id: this.selectedCurso.id
            },
            instrumento: {
                asignatura_id: this.asignaturaId
            },
            sort_by: "created_at",
            order: "desc",
            render_options: {
                include: {
                    instrumento: null
                }
            }
        }

        if (paging) {
            evaluacionesParams["page"] = this.tareasPage
            evaluacionesParams["per"] = this.tareasPer
        }

        if (this.searchTareas) {
            evaluacionesParams["evaluacion"] = { tarea: 1 }
        }

        if (this.evaluacionTipo) {
            evaluacionesParams["evaluacion_tipo"] = { evaluacion_tipo: this.evaluacionTipo }
        }

        return evaluacionesParams
    }

    getTareas() {
        const evaluacionesParams = this.getEvaluacionesParams()

        return this.evaluacionesService.where(evaluacionesParams).then((evaluaciones, total) => {
            this.tareas = evaluaciones
            this.totalTareas = total
            if (total == 0) this.showPaginador = false
            this.totalTareasPages = Math.ceil(this.totalTareas / this.tareasPer)
            this.tareasPages = Array.from({ length: this.totalTareasPages }, (_, i) => i + 1)
            this.hasNextTareas = this.tareasPage < this.totalTareasPages
            this.hasPrevTareas = this.tareasPage > 1
        })
    }

    getEvaluacionesSearch(value: string) {
        this.evalSearchError = undefined

        let evaluacionesParams = this.getEvaluacionesParams(false)

        evaluacionesParams["render_options"]["only"] = ["id", "evaluacion"]

        const searchByLike = this.evaluacionesService.where({
            ...evaluacionesParams,
            evaluacion: { like: { evaluacion: value } }
        })
        const searchByID = this.evaluacionesService.where({ ...evaluacionesParams, evaluacion: { id: [value] } })

        Promise.all([searchByLike, searchByID]).then(values => {
            this.evaluacionesSearch = values.flat().filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
        })
    }

    searchPageByEvaluacion() {
        if (
            this.evaluacionSearch == null ||
            this.evaluacionSearch == undefined ||
            typeof this.evaluacionSearch == "string"
        ) {
            this.evaluacionSearch = undefined
            this.evalSearchError = this.i18nService.translate("libro_notas.not_found", {})
            return null
        }

        let evaluacionesParams = this.getEvaluacionesParams(false)

        evaluacionesParams["per_page"] = this.tareasPer

        return this.evaluacionesService
            .numeroPageLibroById(this.evaluacionSearch.id, evaluacionesParams)
            .then((response: any) => {
                this.tareasPage = response.page
                this.paginador.reload()
            })
    }

    getInstancias() {
        const params = {
            evaluacion_instancia: {
                evaluacion_id: this.tareas.map(tarea => tarea.id),
                oficial: 1,
                usuario_id: this.alumnos.map(alumno => alumno.id)
            },
            render_options: {
                include: {
                    evaluacion_instancia_escala_global_aliases: "null"
                }
            }
        }

        return this.evaluacionInstanciasService.where(params).then(eis => {
            this.instancias = eis
        })
    }

    async getEscalas() {
        this.escalasGlobales = {}

        for (let index = 0; index < this.instancias.length; index++) {
            const instancia = this.instancias[index]
            await this.evaluacionInstanciaEscalasService
                .getEscalas(instancia)
                .then((escalas: EscalaGlobal[]) => (this.escalasGlobales[instancia.evaluacion_id] = escalas))
        }
    }

    getReforzamientos() {
        const reforzamientosParams = {
            plan_personal_usuario: {
                receptor_type: "Usuario",
                receptor_id: this.alumnos.map(alumno => alumno.id)
            },
            plan_personal_ciclo: {
                evaluacion_id: this.tareas.map(tarea => tarea.id)
            },
            render_options: {
                include: {
                    plan_personal_usuarios: null,
                    plan_personal_ciclos: {
                        include: {
                            evaluacion: null,
                            plan_personal_sesiones: {
                                include: {
                                    plan_personal_contenidos: {
                                        include: [
                                            "plan_personal_material_instancias",
                                            "plan_personal_evaluacion_instancias"
                                        ]
                                    }
                                },
                                methods: ["activa"]
                            }
                        }
                    }
                }
            }
        }

        if (this.searchTareas) {
            reforzamientosParams["evaluacion"] = { tarea: 1 }
        }

        return this.planPersonalesService
            .where(reforzamientosParams)
            .then((planPersonales: PlanPersonal[]) => (this.reforzamientos = planPersonales))
    }

    processData() {
        this.tareaReforzamientos = {}
        this.reforzamientos.forEach(reforzamiento => {
            const evaluacion_ref_id = reforzamiento.plan_personal_ciclos[0].evaluacion_id
            this.tareaReforzamientos[evaluacion_ref_id] = this.tareaReforzamientos[evaluacion_ref_id] || []
            this.tareaReforzamientos[evaluacion_ref_id].push(reforzamiento)
        })
        this.data = {}
        this.alumnos.forEach(alumno => {
            this.data[alumno.id] = this.data[alumno.id] || {}
            this.tareas.forEach(tarea => {
                const reforzamientoAlumno =
                    this.tareaReforzamientos[tarea.id] &&
                    this.tareaReforzamientos[tarea.id].find(r => r.plan_personal_usuarios[0].receptor_id == alumno.id)
                this.data[alumno.id][tarea.id] = {
                    instancia: this.instancias.find(
                        instancia => instancia.usuario_id == alumno.id && instancia.evaluacion_id == tarea.id
                    ),
                    escalas: this.escalasGlobales[tarea.id],
                    reforzamientoCompartido: !!reforzamientoAlumno,
                    reforzamientoRealizado:
                        !!reforzamientoAlumno &&
                        this.tareaReforzamientos[tarea.id].find(
                            r =>
                                r.plan_personal_ciclos[0].plan_personal_sesiones[0] &&
                                r.plan_personal_usuarios[0].receptor_id == alumno.id &&
                                r.plan_personal_ciclos[0].plan_personal_sesiones[0].checkAvance()
                        )
                }
            })
        })

        this.loadinglayout.ready()
    }

    getPromedios() {
        return this.grupoUsuariosService
            .promediosLibroNotas(this.selectedCurso.id, {
                evaluacion_tipo: this.evaluacionTipo,
                asignatura_id: this.asignaturaId
            })
            .then(response => {
                this.promediosPorAlumno = response["por_alumno"]
                this.promediosPorTarea = response["por_evaluacion"]
            })
    }

    onSelectCurso() {
        this.tareasPage = 1
        this.showPaginador = false
        setTimeout(() => {
            this.showPaginador = true
        }, 50)

        // this.setData()
    }

    onSelectPeriodo(event) {
        this.fechaInicial = event.fechaInicial
        this.fechaFinal = event.fechaFinal
        this.periodo = event.periodo
        this.getCursos()
    }

    onSelectTareasPage() {
        this.paginador.reload()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes["asignaturaId"] && !changes["asignaturaId"].firstChange) ||
            (changes["evaluacionTipo"] && !changes["evaluacionTipo"].firstChange)
        ) {
            if (this.evaluacionTipo && this.cursos.length > 0) {
                this.onSelectCurso()
            } else {
                this.resetData()
            }
        }
    }

    prevTareas() {
        this.tareasPage -= 1
        this.paginador.reload()
    }

    nextTareas() {
        this.tareasPage += 1
        this.paginador.reload()
    }

    downloadCSV() {
        const filename = `libro_notas_${this.fechaInicial.getFullYear()}_${this.selectedCurso.nombre}.csv`

        const csvParams = {
            asignatura_id: this.asignaturaId,
            to_csv: filename.replace(/\s/g, "_")
        }

        if (this.searchTareas) {
            csvParams["tarea"] = 1
        }

        if (this.evaluacionTipo) {
            csvParams["evaluacion_tipo"] = this.evaluacionTipo
        }

        this.grupoUsuariosService.csvLibroNotas(this.selectedCurso.id, csvParams)
    }

    goToResults(evaluacion, selectedCurso) {
        if (this.goToEstadisticas) {
            this.filtroEstadisticasService.setFiltrosId(
                selectedCurso.establecimiento.id,
                selectedCurso.id,
                null,
                evaluacion.id,
                evaluacion.instrumento.generador_instrumento_id,
                this.evaluacionTipo,
                this.asignaturaId,
                this.periodo
            )
            this.router.navigate(["/estadisticas"], {
                queryParams: {
                    asignatura_id: this.asignaturaId,
                    tipo_instrumento: this.evaluacionTipo
                }
            })
        } else {
            this.router.navigate(["/evaluaciones/" + evaluacion.id + "/resultados"], {
                queryParams: { cursoId: selectedCurso.id }
            })
        }
    }
}
