import { Component, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core"
import { Evaluacion, GrupoUsuario, PlanPersonal, PlanPersonales } from "@puntaje/puntaje/api-services"
import { PopupGrupoUsuariosComponent } from "@puntaje/puntaje/new-modules/shared"

@Component({
    selector: "compartir-reforzamientos",
    templateUrl: "./compartir_reforzamientos.component.html"
})
export class CompartirReforzamientosComponent implements OnInit {
    @Input() evaluacion: Evaluacion
    @ViewChild(PopupGrupoUsuariosComponent, { static: true }) popupGrupoUsuarios: PopupGrupoUsuariosComponent
    grupoUsuarios: GrupoUsuario[]
    sharing: boolean = false

    constructor(protected planPersonalesService: PlanPersonales) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["evaluacion"] && changes["evaluacion"].currentValue) {
            this.grupoUsuarios = this.evaluacion.evaluacion_usuarios.map(eu => eu.grupo_usuario)
        }
    }

    crear(gruposSeleccionados: GrupoUsuario[]) {
        this.sharing = true
        let params = {
            grupo_usuario_id: gruposSeleccionados.map(g => g.id),
            evaluacion_id: this.evaluacion.id
        }
        this.planPersonalesService.createReforzamientos(params).then((planPersonales: PlanPersonal[]) => {
            this.sharing = false
            this.popupGrupoUsuarios.hide()
            this.popupGrupoUsuarios.setDefaultValues()
        })
    }

    open() {
        this.popupGrupoUsuarios.open()
    }
}
