import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { select, Store } from "@ngrx/store"
import { State, selectSelectedEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "progreso.component.html",
    styleUrls: ["progreso.component.scss"]
})
export class ProgresoComponent {
    asignatura: Asignatura
    cursoId: number = 1
    asignaturaId: number
    private sub: Subscription
    enableResponsive: boolean = false
    tipoInstrumento: string
    idUsuarios: number[]
    idGrupos: number[]
    timeFrame: Date[]
    nivelIds: number[]

    usuarioId: number

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(protected titleService: TitleService, protected route: ActivatedRoute, protected store: Store<State>) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.progreso")
        this.sub = this.evaluacionTipo$.subscribe(evaluacionTipo => {
            this.tipoInstrumento = evaluacionTipo

            setTimeout(() => {
                this.tipoInstrumento = evaluacionTipo
            }, 50)
        })
    }

    changeUsuarioIds(ids: number[]) {
        if (ids && ids.length == 1) {
            this.usuarioId = null

            setTimeout(() => {
                this.usuarioId = ids[0]
            }, 1)
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    onTimeFrameChange(timeFrame: any) {
        this.timeFrame = timeFrame
    }

    setNivelIds(grupoUsuarios) {
        this.nivelIds = grupoUsuarios.map(gu => gu.curso_id).filter(x => !!x)
    }
}
