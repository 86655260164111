import { PuntajeNotificacionesModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { UtilModule } from "@puntaje/shared/core"

import { routing } from "./profesores_notificaciones.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NotificacionesModule,
        ProfesoresLayoutsModule,
        UtilModule,
        routing,
        PuntajeNotificacionesModule
    ],
    exports: [],
    declarations: [],
    providers: []
})
export class ProfesoresNotificacionesModule {}
