<loading-layout #externalLoading>
    <cui-section-headline class="headline clearfix">
        {{ "grupo_usuarios.profesores.list" | t }} {{ grupoUsuarioAlias | pluralize }}
        <filtro-periodo (select)="onSelectPeriodo($event)" class="periodo"></filtro-periodo>
    </cui-section-headline>
    <cui-section-headline class="headline clearfix">
        <buscador-usuario></buscador-usuario>
    </cui-section-headline>
    <div class="form-group" *ngIf="establecimientos">
        <label for="establecimientos-filter">{{ "grupo_usuarios.profesores.select_establecimiento" | t }}</label>
        <select
            class="form-control"
            id="establecimientos-filter"
            [(ngModel)]="establecimientoSelected"
            (ngModelChange)="showAll()"
        >
            <option [ngValue]="undefined" [selected]="true">
                {{ "grupo_usuarios.profesores.all_establecimiento" | t }}
            </option>
            <option *ngFor="let establecimiento of establecimientos" [ngValue]="establecimiento">
                {{ establecimiento.establecimiento }}
            </option>
        </select>
    </div>
    <loading-layout #loadingLayout>
        <ng-container *ngIf="showGrupos; else noGrupos">
            <div *ngFor="let grupoUsuario of grupoUsuarios">
                <info-grupo-usuarios-profesor
                    [grupoUsuario]="grupoUsuario"
                    [searchParams]="searchParams"
                ></info-grupo-usuarios-profesor>
            </div>
        </ng-container>
        <paginator #paginator [getList]="showAll" [resultadosStringMapping]="mapCantidadCursos"></paginator>
        <ng-template #noGrupos>
            <p class="not-found">
                {{ "grupo_usuarios.profesores.not_found1" | t }}
                {{ grupoUsuarioAlias | pluralize }} {{ "grupo_usuarios.profesores.not_found2" | t }}.
            </p>
        </ng-template>
    </loading-layout>
</loading-layout>
