<div class="contentBuscador">
    <div class="selects" *ngIf="searchByCurso && cursosMaterial">
        Seleccione un curso
        <select class="form-control" [(ngModel)]="curso" (ngModelChange)="onChangeCurso($event)">
            <option [ngValue]="undefined">-- Seleccione clasificación --</option>
            <option *ngFor="let clasificacion of cursosMaterial" [ngValue]="clasificacion">
                {{ clasificacion.clasificacion }}
            </option>
        </select>
    </div>
    <div class="selects" *ngIf="clasificacionesPrimerNivel">
        Seleccione un
        {{ clasificacionMateriales && clasificacionMateriales[0] ? clasificacionMateriales[0].label : "eje" }}
        <select
            class="form-control margin"
            [(ngModel)]="clasificacionPrimerNivel"
            (ngModelChange)="onChangeClasificacionPrimerNivel($event)"
        >
            <option [ngValue]="undefined">-- Seleccione clasificación --</option>
            <option *ngFor="let clasificacion of clasificacionesPrimerNivel" [ngValue]="clasificacion">
                {{ clasificacion.clasificacion }}
            </option>
        </select>
    </div>
    <div class="selects" *ngIf="clasificacionesSegundoNivel">
        Seleccione un
        {{ clasificacionMateriales && clasificacionMateriales[1] ? clasificacionMateriales[1].label : "subeje" }}
        <select
            class="form-control margin"
            [(ngModel)]="clasificacionSegundoNivel"
            (ngModelChange)="onChangeClasificacionSegundoNivel($event)"
        >
            <option [ngValue]="undefined">-- Seleccione clasificación --</option>
            <option *ngFor="let clasificacion of clasificacionesSegundoNivel" [ngValue]="clasificacion">
                {{ clasificacion.clasificacion }}
            </option>
        </select>
    </div>
</div>
