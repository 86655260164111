import { Component } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"

/*#################################

Template simple para páginas una vez que usuario se haya logeado. Incluye el navbar en la parte
superior.
Implementa una sección de contenido, un título, un subtítulo y un set de tabs (no son requeridos, pero título puede incluir
un valor por defecto si no está definido).

logged-layout-toolnavbar
logged-layout-titulo
logged-layout-subtitulo
logged-layout-contenido
logged-layout-tabs
logged-layout-topside
logged-layout-over-title

#################################*/

@Component({
    templateUrl: "logged_layout.component.html",
    selector: "logged-layout",
    styleUrls: ["logged_layout.scss"]
})
export class LoggedLayoutComponent {
    constructor(public authService: AuthService) {}
}
