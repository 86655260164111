import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "resumen_uso.component.html"
})
export class ResumenUsoComponent implements OnInit {
    openingTab: string = "parcial"
    currentTab: string

    subData: Subscription
    enableInformeAnual: boolean = true
    enableAvancePlanesAdaptive: boolean = false
    enableInformeUso: boolean = true

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.resumen_uso")
        this.currentTab = this.openingTab

        this.subData = this.route.data.subscribe(data => {
            this.enableInformeAnual = data.enableInformeAnual ?? this.enableInformeAnual
            this.enableAvancePlanesAdaptive = data.enableAvancePlanesAdaptive ?? this.enableAvancePlanesAdaptive
            this.enableInformeUso = data.enableInformeUso ?? this.enableInformeUso
        })
    }

    setCurrentTab(tab: string) {
        this.currentTab = tab
    }
}
