<form action="">
    <div class="buscador-usuarios">
        <div class="row">
            <div class="form-group col-md-3">
                <label class="escondible" [ngClass]="{ visible: searchOpts.rut }" for="rut">
                    {{ placeholderIdPais }}
                </label>
                <br />
                <input
                    name="rut"
                    class="form-control"
                    type="text"
                    [placeholder]="placeholderIdPais"
                    [(ngModel)]="searchOpts.rut"
                />
            </div>
            <div class="form-group col-md-3">
                <label class="escondible" [ngClass]="{ visible: searchOpts.nombre }" for="rut">Nombre</label>
                <br />
                <input
                    name="nombre"
                    class="form-control"
                    type="text"
                    placeholder="Nombre"
                    [(ngModel)]="searchOpts.nombre"
                />
            </div>
            <div class="form-group col-md-3">
                <label class="escondible" [ngClass]="{ visible: searchOpts.paterno }" for="rut">Apellido paterno</label>
                <br />
                <input
                    name="paterno"
                    class="form-control"
                    type="text"
                    placeholder="Apellido paterno"
                    [(ngModel)]="searchOpts.paterno"
                />
            </div>
            <div class="form-group col-md-3">
                <label class="escondible" [ngClass]="{ visible: searchOpts.materno }" for="rut">Apellido materno</label>
                <br />
                <input
                    name="materno"
                    class="form-control"
                    type="text"
                    placeholder="Apellido materno"
                    [(ngModel)]="searchOpts.materno"
                />
            </div>
        </div>
        <div class="search-btn-container">
            <cui-button (click)="startSearch()">
                <fa name="search" class="left-icon"></fa>
                Buscar
            </cui-button>
        </div>
    </div>
</form>

<loading-layout>
    <ng-container *ngIf="paginatorActive">
        <cui-section-headline>Resultados</cui-section-headline>
    </ng-container>
    <div *ngFor="let usuario of usuarios" class="row usuario-row">
        <div class="col-md-4">
            <div>
                <span class="fix-width"><fa name="user"></fa></span>
                &nbsp;{{ usuario.nombreCompleto() }}
            </div>
            <div>
                <span class="fix-width"><fa name="envelope"></fa></span>
                &nbsp;{{ usuario.email }}
            </div>
            <div>
                <span class="fix-width"><fa name="id-card"></fa></span>
                &nbsp;{{
                    usuario["usuario_" + pais] &&
                        usuario["usuario_" + pais][$any(usuario).identificadorPais?.toLowerCase()]
                        | easyplaceholder: "-"
                }}
            </div>
            <div>
                <span class="fix-width"><fa name="calendar"></fa></span>
                &nbsp;
                <label>Última fecha de ingreso:</label>
                &nbsp;{{
                    usuario.current_sign_in_at | date: "dd/MM/yyyy HH:mm" | easyplaceholder: "Sin fecha de ingreso"
                }}
            </div>
        </div>

        <div class="col-md-4">
            <p><b>Cursos:</b></p>
            <p>
                <span *ngFor="let guu of usuario.grupo_usuario_usuarios; let i = index">
                    <ng-container *ngIf="i != 0">,</ng-container>
                    <a disabled>{{ guu.grupo_usuario.nombre }}</a>
                    ({{ guu.propietario ? "docente" : "alumno" }})
                </span>
            </p>
        </div>

        <div class="col-md-4">
            <opciones-usuario-admin [usuario]="usuario"></opciones-usuario-admin>
        </div>
    </div>
    <paginator *ngIf="paginatorActive" [getList]="this.fetchUsuarios.bind(this)"></paginator>
    <p *ngIf="searchComplete && usuarios.length == 0" class="alert alert-warning">
        No se encontraron usuarios con esos criterios de búsqueda.
    </p>
</loading-layout>
