import { Component, OnInit, Input, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import { Noticia, Noticias } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "ver-noticia-modal",
    templateUrl: "ver-noticia-modal.component.html",
    styleUrls: ["ver-noticia-modal.component.scss"]
})
export class VerNoticiaModalComponent implements OnInit {
    @Input() currentNoticia: Noticia
    openModal: EventEmitter<any> = new EventEmitter<any>()
    hasNewsIcon: boolean = true
    config: typeof config = config

    constructor(protected noticiasService: Noticias, protected router: Router) {}

    ngOnInit() {}

    showNoticia() {
        this.openModal.emit()
    }
}
