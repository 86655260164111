import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { Asignaturas, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { Store, select } from "@ngrx/store"
import { filter } from "rxjs/operators"

@Component({
    selector: "selects-evaluacion-asignatura",
    templateUrl: "./selects-evaluacion-asignatura.component.html",
    styleUrls: ["../subir-prueba-propia.component.scss"]
})
export class SelectsEvaluacionAsignaturaComponent implements OnInit {
    @Input() evaluacionTipo: string
    @Output() changeData: EventEmitter<any> = new EventEmitter()
    asignaturaId: number
    notAsignatura: boolean = false
    generadorInstrumento: GeneradorInstrumento
    generadorInstrumentos: GeneradorInstrumento[]
    tiempo = 0
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )
    validationSelects: boolean = false
    @Input() step: number = 3

    constructor(protected asignaturasService: Asignaturas, protected store: Store<State>) {}

    ngOnInit() {}

    changeInstrumento(data) {}

    onChangeAsignatura() {
        this.loadGeneradorInstrumentos()
        this.changeData.emit({ showContestableTipos: false })
    }

    loadGeneradorInstrumentos() {
        this.asignaturasService
            .find(this.asignaturaId, { with_generador_instrumentos: 1, tipo_instrumento: this.evaluacionTipo })
            .then(asignatura => {
                this.generadorInstrumentos = asignatura.generador_instrumentos
                if (asignatura.generador_instrumentos.length == 1) {
                    this.notAsignatura = false
                    this.generadorInstrumento = this.generadorInstrumentos[0]
                } else {
                    this.notAsignatura = true
                }
            })
    }

    sendData() {
        if (this.asignaturaId && this.generadorInstrumento) {
            this.validationSelects = false
            let data = {
                generadorInstrumento: this.generadorInstrumento,
                asignaturaId: this.asignaturaId,
                showContestableTipos: true,
                showPauta: true,
                tiempo: this.tiempo
            }
            this.changeData.emit(data)
        } else {
            this.validationSelects = true
        }
    }
}
