import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from "@angular/core"
import { MaterialUsuarios, MaterialUsuario } from "@puntaje/puntaje/api-services"
import { Clasificacion, GeneradorInstrumento, Material, Materiales } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PaginatorComponent, SessionService } from "@puntaje/shared/core"

@Component({
    selector: "materiales-propios",
    templateUrl: "./materiales-propios.component.html",
    styleUrls: ["./materiales-propios.component.scss"]
})
export class MaterialesPropiosComponent implements OnInit {
    @Input() asignaturaId: number
    @Input() evaluacionTipo: string
    @Input() generadorInstrumento: GeneradorInstrumento
    @Output() selected = new EventEmitter<Material>()

    materialUsuarios: MaterialUsuario[]
    materiales: Material[]

    curso: Clasificacion
    clasificacionPrimerNivel: Clasificacion
    clasificacionSegundoNivel: Clasificacion

    selectedMaterial: Material

    uploading: boolean = false

    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected materialUsuariosService: MaterialUsuarios,
        protected materialesService: Materiales,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {}

    reload() {
        this.paginator.reload()
    }

    onChangeCurso(curso: Clasificacion) {
        this.curso = curso

        this.reload()
    }

    onChangeClasificacionPrimerNivel(clasificacion: Clasificacion) {
        this.clasificacionPrimerNivel = clasificacion

        this.reload()
    }

    onChangeClasificacionSegundoNivel(clasificacion: Clasificacion) {
        this.clasificacionSegundoNivel = clasificacion

        this.reload()
    }

    getData = (page: number, per: number) => {
        this.loadingLayout.standby()

        const usuario = this.sessionService.getUserData()

        const params = {
            material_usuario: {
                usuario_id: usuario.id
            }
        }

        return this.materialUsuariosService.where(params).then((materialUsuarios, total) => {
            this.materialUsuarios = materialUsuarios

            const materialesParams: any = {
                per,
                page,
                material: {
                    id: materialUsuarios.map(mu => mu.material_id)
                }
            }

            if (this.curso || this.clasificacionPrimerNivel || this.clasificacionSegundoNivel) {
                const clasificacionIds = [this.curso, this.clasificacionPrimerNivel, this.clasificacionSegundoNivel]
                    .filter(x => !!x)
                    .map(c => c.id)

                materialesParams.clasificacion = { id: clasificacionIds }

                if (clasificacionIds.length > 1) {
                    materialesParams.and_clasificacion = 1
                }
            }

            return this.materialesService.where(materialesParams).then(materiales => {
                this.materiales = materiales
                this.loadingLayout.ready()

                return total
            })
        })
    }

    selectMaterial(material) {
        this.uploading = true
        this.selectedMaterial = material

        this.uploading = false
        this.selectedMaterial = undefined

        this.selected.emit(material)
    }
}
