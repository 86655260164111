import { Component, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription, BehaviorSubject } from "rxjs"

import { Establecimiento, Establecimientos, GrupoUsuarios, GrupoUsuario, Excels } from "@puntaje/puntaje/api-services"
import { Asignatura, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

@Component({
    selector: "avance-planes-adaptive",
    templateUrl: "avance-planes-adaptive.component.html",
    styleUrls: ["avance-planes-adaptive.component.scss"]
})
export class AvancePlanesAdaptiveComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    config: typeof config = config
    fechaInicio: any = {}
    fechaFin: any = {}

    establecimientos: Establecimiento[]
    establecimiento: Establecimiento
    grupoUsuario: GrupoUsuario
    grupoUsuarios: GrupoUsuario[]
    asignaturas: Asignatura[]
    asignatura: Asignatura
    generadorInstrumentos: GeneradorInstrumento[]
    generadorInstrumento: GeneradorInstrumento

    evaluacionTipo: string = config.plataforma.evaluacionTipoAdaptiveDefault
    evaluacionTipoSubject = new BehaviorSubject(null)

    sub: Subscription
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    isDownloading: boolean = false

    constructor(
        protected route: ActivatedRoute,
        protected authService: AuthService,
        protected establecimientosService: Establecimientos,
        protected store: Store<State>,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected grupoUsuariosService: GrupoUsuarios,
        protected excelsService: Excels
    ) {}

    async ngOnInit() {
        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.asignaturas = asignaturasByEvaluacionTipo[this.evaluacionTipo]
        })

        this.loadingLayout.standby()

        let params = { establecimiento: { id: this.authService.getEstablecimientos(), activo: 1 } }
        params["include"] = `[establecimiento_${config.plataforma.pais}]`

        this.establecimientos = await this.establecimientosService.where(params)

        if (this.establecimientos.length == 1) {
            this.establecimiento = this.establecimientos[0]

            await this.getGrupoUsuarios()
        }

        this.loadingLayout.ready()
    }

    getGrupoUsuarios() {
        this.grupoUsuario = undefined

        const grupoUsuarioParams = {
            grupo_usuario: {
                establecimiento_id: this.establecimiento.id
            },
            fecha_inicial: this.fechaInicio,
            fecha_final: this.fechaFin
        }

        return this.grupoUsuariosService.where(grupoUsuarioParams).then((grupoUsuarios: GrupoUsuario[]) => {
            this.grupoUsuarios = grupoUsuarios
        })
    }

    onChangeAsignatura() {
        this.generadorInstrumento = undefined

        let params = {
            asignatura_plataforma: {
                asignatura_id: this.asignatura.id,
                plataforma_id: config.plataforma.id
            },
            tipo_instrumento: {
                tipo_instrumento: this.evaluacionTipo
            }
        }

        this.generadorInstrumentosService.where(params).then((generadorInstrumentos: GeneradorInstrumento[]) => {
            this.generadorInstrumentos = generadorInstrumentos
        })
    }

    onChangeEstablecimiento() {
        if (this.establecimiento) {
            this.getGrupoUsuarios()
        }
    }

    downloadExcel() {
        this.isDownloading = true
        const params: any = {
            fecha_inicial: this.fechaInicio,
            fecha_final: this.fechaFin,
            ...(this.grupoUsuario && { grupo_usuario_id: this.grupoUsuario.id }),
            ...(this.asignatura && { asignatura_id: this.asignatura.id }),
            ...(this.generadorInstrumento && { generador_instrumento_id: this.generadorInstrumento.id })
        }

        this.establecimientosService.avancePlanesAdaptive(this.establecimiento.id, params).then((data: any) => {
            this.excelsService.downdloadExcel(data, "avance-planes-adaptive")
            this.isDownloading = false
        })
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicio = fechaInicial
        this.fechaFin = fechaFinal
        if (this.establecimiento) {
            this.getGrupoUsuarios()
        }
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub.unsubscribe()
    }
}
