<cui-section-headline>
    {{ evaluacionTipo | transformInstrumento: "plural" | capitalize }} {{ tipo | genderize: evaluacionTipo }}
</cui-section-headline>
<loading-layout #loadingLayout>
    <div class="filter-container" *ngIf="establecimientos?.length != 0">
        <h4 class="filter-title">
            <span class="glyphicon glyphicon-search icono"></span>
            Filtros de búsqueda
        </h4>
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <select
                    [disabled]="establecimientos && establecimientos.length == 1"
                    [(ngModel)]="establecimiento"
                    (change)="paginator.changePage(1)"
                    (change)="setPropietarios()"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- Seleccione un establecimiento --</option>
                    <option *ngFor="let e of establecimientos" [ngValue]="e">{{ e.establecimiento }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-6">
                <select
                    *ngIf="establecimiento"
                    [(ngModel)]="grupoUsuario"
                    (change)="paginator.changePage(1)"
                    (change)="setPropietarios()"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- Seleccione un curso --</option>
                    <option *ngFor="let gu of establecimiento.grupo_usuarios" [ngValue]="gu">{{ gu.nombre }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-6">
                <select
                    *ngIf="establecimiento"
                    [(ngModel)]="propietario"
                    (change)="paginator.changePage(1)"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- Seleccione docente --</option>
                    <option *ngFor="let p of propietarios" [ngValue]="p">{{ p.nombreCompleto() }}</option>
                </select>
            </div>

            <div class="col-md-3 col-sm-6">
                <filtro-periodo class="periodo" (select)="onSelectPeriodo($event)"></filtro-periodo>
            </div>
        </div>
    </div>
    <table class="table tabla_ensayos" *ngIf="evaluacionMultiples && evaluacionMultiples.length > 0">
        <thead>
            <tr>
                <th>Evaluaciones</th>
                <th class="actions">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let evaluacionMultiple of evaluacionMultiples; let i = index">
                <td>
                    <div class="table-cell-evaluacion row">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <a
                                        [routerLink]="[
                                            '/instrumentos_multiples',
                                            evaluacionMultiple.instrumento_multiple_id
                                        ]"
                                        [queryParams]="{ evaluacion_multiple_id: evaluacionMultiple.id }"
                                        class="titulo"
                                    >
                                        <ogr-icon name="prueba" class="icono"></ogr-icon>
                                        {{ evaluacionMultiple.evaluacion_multiple }}
                                    </a>
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label">Evaluación ID:</span>
                                            #{{ evaluacionMultiple.id }}
                                        </p>
                                    </div>
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label">Instrumento ID:</span>
                                            #{{ evaluacionMultiple.instrumento_multiple_id }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <!--<div class="evaluacion-data-pair">
                                        <p><span class="inline-label">Tipo:</span>{{evaluacion.instrumento.generador_instrumento.generador_instrumento}}</p>
                                    </div>-->
                                    <div
                                        class="evaluacion-data-pair"
                                        *ngIf="establecimientos && establecimientos.length > 1"
                                    >
                                        <p>
                                            <span class="inline-label">Establecimiento:</span>
                                            {{
                                                evaluacionMultiple.evaluacion_multiple_usuarios[0].grupo_usuario
                                                    .establecimiento.establecimiento
                                            }}
                                        </p>
                                    </div>
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label">Creador:</span>
                                            <fa name="user" class="icono"></fa>
                                            {{
                                                evaluacionMultiple.evaluacion_multiple_usuarios[0].usuario?.nombreCompleto()
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label date-label">Creación:</span>
                                            <span class="glyphicon glyphicon-time icono tiempo"></span>
                                            {{ evaluacionMultiple.created_at | date: "dd-MM-yyyy HH:mm" }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="evaluacion-data-pair">
                                        <p><span class="inline-label">Compartido con:</span></p>
                                    </div>
                                    <div toggleExpandable="eval_comp_curs_{{ i }}" class="tog_expand_cursos clearfix">
                                        {{ nombreGrupo | pluralize | titlecase }} ({{
                                            evaluacionMultiple.evaluacion_multiple_usuarios.length
                                        }})
                                        <span class="tog-icon plus"><fa [name]="'plus-square'"></fa></span>
                                        <span class="tog-icon minus"><fa [name]="'minus-square'"></fa></span>
                                    </div>
                                    <div id="eval_comp_curs_{{ i }}" class="expand_cursos" expandableContent>
                                        <p
                                            *ngFor="
                                                let evaluacion_multiple_usuario of evaluacionMultiple.evaluacion_multiple_usuarios
                                            "
                                        >
                                            {{ evaluacion_multiple_usuario.grupo_usuario.nombre }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="separador"></div>
                    <div *ngFor="let evaluacion of evaluacionMultiple.evaluaciones" class="row instrumento-info">
                        <div class="col-md-3 col-sm-12">
                            <label>{{ evaluacion.evaluacion }}</label>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="evaluacion-data-pair">
                                <p>
                                    <span class="inline-label date-label">Inicio:</span>
                                    <span class="glyphicon glyphicon-time icono tiempo"></span>
                                    {{ evaluacion.fecha_inicio | date: "dd-MM-yyyy HH:mm" | easyplaceholder: "--" }}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="evaluacion-data-pair">
                                <p>
                                    <span class="inline-label date-label">Cierre:</span>
                                    <span class="glyphicon glyphicon-time icono tiempo"></span>
                                    {{ evaluacion.fecha_termino | date: "dd-MM-yyyy HH:mm" | easyplaceholder: "--" }}
                                </p>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="actions">
                    <a
                        [routerLink]="['/instrumentos_multiples', evaluacionMultiple.instrumento_multiple_id]"
                        [queryParams]="{ evaluacion_multiple_id: evaluacionMultiple.id }"
                        class="btn btn-table"
                    >
                        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                    </a>
                    <button
                        *ngIf="presencial == 0"
                        type="button"
                        (click)="editarEvaluacion(evaluacionMultiple)"
                        class="btn btn-table"
                    >
                        <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                    </button>
                    <button
                        type="button"
                        (click)="compartirInstrumento(evaluacionMultiple.instrumento_multiple_id)"
                        class="btn btn-table"
                    >
                        <span class="glyphicon glyphicon-share-alt" aria-hidden="true"></span>
                    </button>
                    <a
                        (click)="
                            onVerEstadisticas(
                                evaluacionMultiple.evaluacion_multiple_usuarios[0].grupo_usuario.establecimiento.id,
                                null,
                                evaluacionMultiple.id,
                                evaluacionMultiple.instrumento_multiple.generador_instrumento_id,
                                evaluacionMultiple.instrumento_multiple.generador_instrumento.tipo_instrumento
                                    .tipo_instrumento
                            )
                        "
                        class="btn btn-table"
                    >
                        <fa [name]="'bar-chart'"></fa>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <p
        *ngIf="(!evaluacionMultiples || evaluacionMultiples.length <= 0) && establecimientos?.length != 0"
        class="not-found"
    >
        No se encontraron {{ evaluacionTipo | transformInstrumento: "plural" }}
        {{ "compartidas" | genderize: evaluacionTipo }}.
    </p>
    <p *ngIf="!establecimientos || establecimientos.length == 0">Usted no posee grupos asociados.</p>
    <paginator
        *ngIf="establecimientos && establecimientos.length > 0 && fechaInicial && fechaFinal"
        [getList]="setData"
    ></paginator>
    <compartir-instrumento-multiple-profesor
        *ngIf="instrumentoMultiple"
        [instrumentoMultiple]="instrumentoMultiple"
        [modalId]="'compartir_' + (presencial ? 'presencial' : 'online')"
        [openModal]="openModal"
        [tipoEvaluacion]="evaluacionTipo"
    ></compartir-instrumento-multiple-profesor>
    <!-- <compartir-reforzamientos [evaluacion]="evaluacionToReforzar"></compartir-reforzamientos> -->

    <editar-evaluacion
        *ngIf="presencial == 0"
        [evaluacion]="$any(evaluacionMultipleToEdit)"
        [openModalEdit]="openModalEdit"
        [tipoEvaluacion]="evaluacionTipo"
        [paginator]="paginator"
    ></editar-evaluacion>
</loading-layout>
