<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="" *ngFor="let e of establecimientos | keys">
            <div class="">
                <h2>{{ establecimientos[e].establecimiento }}</h2>
            </div>
            <div class="row">
                <div class="asignatura_container col-md-6" *ngFor="let c of generadoresPorAsignatura | keys">
                    <div>
                        <div class="icon_container {{ asignaturas[c]?.clase }}">
                            <ogr-icon
                                class="icono {{ asignaturas[c]?.clase }}"
                                name="{{ asignaturas[c].icono }}"
                            ></ogr-icon>
                        </div>
                        <span class="asignatura_titulo">{{ asignaturas[c].asignatura }}</span>
                    </div>
                    <div class="">
                        <table class="table table-striped tabla_resumen">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Número de Evaluaciones Realizadas</th>
                                    <th>Promedio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let g of generadoresPorAsignatura[c]">
                                    <td>{{ g.generador_instrumento }}</td>
                                    <td>
                                        <ng-container *ngIf="establecimientoPuntajes[e][g.id]">
                                            {{ establecimientoPuntajes[e][g.id].total }}
                                        </ng-container>
                                        <ng-container *ngIf="!establecimientoPuntajes[e][g.id]">-</ng-container>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="establecimientoPuntajes[e][g.id]">
                                            {{ establecimientoPuntajes[e][g.id].promedio | roundfloat }}
                                        </ng-container>
                                        <ng-container *ngIf="!establecimientoPuntajes[e][g.id]">-</ng-container>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td>
                                        <ng-container *ngIf="establecimientoPuntajes[e][-c]">
                                            {{ establecimientoPuntajes[e][-c].total }}
                                        </ng-container>
                                        <ng-container *ngIf="!establecimientoPuntajes[e][-c]">-</ng-container>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr />
        </div>
    </div>
</loading-layout>
