import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core"
import { PlanPersonalContenido } from "@puntaje/puntaje/api-services"
import { MaterialPreviewModalService } from "@puntaje/puntaje/new-modules/materiales"

@Component({
    selector: "plan-clase-contenido-box",
    templateUrl: "./plan-clase-contenido-box.component.html",
    styleUrls: ["./plan-clase-contenido-box.component.scss"]
})
export class PlanClaseContenidoBoxComponent implements OnInit {
    @Input() materialTipos
    @Input() planPersonalContenido
    @Input() index
    @Input() showbuttonDelete: boolean = true
    @Output() deleteContenido: EventEmitter<any> = new EventEmitter()
    @Output() showModalDeleteContenido: EventEmitter<any> = new EventEmitter()

    constructor(public materialPreviewModalService: MaterialPreviewModalService) {}

    ngOnInit() {}

    eliminarContenido(i) {
        this.deleteContenido.emit(i)
    }

    mostrarModalDeleteContenido(planPersonalContenido: PlanPersonalContenido, i) {
        this.showModalDeleteContenido.emit({
            planPersonalContenido: planPersonalContenido,
            i: i
        })
    }
}
