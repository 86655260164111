<div class="clearfix" id="step1">
    <div class="row">
        <div class="col-md-8">
            <div class="content-f">
                <div class="tutorial">
                    <div class="container-tutorial-icon">
                        <div class="tutorial-icon-pos">
                            <ogr-icon name="hacer-prueba" align="top" class="icon"></ogr-icon>
                        </div>
                    </div>
                </div>
                <div class="content-text">
                    <h3 class="step">{{ "evaluar.pasos.step" | t }} {{ step }}</h3>
                    <p class="tutorial-text">{{ "evaluar.pasos.subir_pauta.message" | t }}</p>
                    <ul class="tutorial-text-descripcion">
                        <li>
                            {{ "evaluar.pasos.subir_pauta.desc" | t }}
                            <input
                                type="number"
                                class="form-control"
                                [(ngModel)]="numero_alternativas"
                                placeholder="4"
                                (change)="validadaNumeroAlternativas()"
                            />
                            <div class="warning hide-warning" [class.show-warning]="validationNumeroAlternativas">
                                <div class="warning-content">
                                    <fa name="warning" class="warning-icon"></fa>
                                    <div class="warning-message">
                                        <p>{{ mensajeAlternativas }}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            {{ "evaluar.pasos.subir_pauta.instr1" | t }}
                        </li>
                        <li>{{ "evaluar.pasos.subir_pauta.instr2" | t }}</li>
                        <li>
                            {{ "evaluar.pasos.subir_pauta.instr3" | t }}
                        </li>
                        <li>
                            {{ "evaluar.pasos.subir_pauta.instruccion_puntuacion" | t }}
                        </li>
                        <li>{{ "evaluar.pasos.subir_pauta.instr4" | t }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="clearfix">
                <cui-button class="btn_style pull-right" (click)="sendData()">
                    <ogr-icon name="ticket" align="text-top"></ogr-icon>
                    &nbsp;{{ "evaluar.pasos.subir_pauta.button" | t }}
                </cui-button>
            </div>
            <div class="form-group">
                <div class="warning hide-warning" [class.show-warning]="wrongPauta">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>{{ "evaluar.pasos.subir_pauta.warn1" | t }}</p>
                        </div>
                    </div>
                </div>
                <div class="warning hide-warning" [class.show-warning]="blankPauta">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>{{ "evaluar.pasos.subir_pauta.warn2" | t }}</p>
                        </div>
                    </div>
                </div>
                <div class="warning hide-warning" [class.show-warning]="nalternativaIncorrecta">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>{{ "evaluar.pasos.subir_pauta.warn3" | t }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" class="margin-principal">
        <div class="col-md-8">
            <div class="canvas-container">
                <canvas #canvas id="canvas" (click)="onClickCanvas($event)" class="style-canvas"></canvas>
            </div>
        </div>
        <div class="col-md-4">
            <div class="multiple-col">
                <ul class="lista-resultados">
                    <li *ngFor="let obj of pauta; let i = index" class="respuesta">
                        <span class="numero">{{ i + 1 }}:</span>
                        &nbsp;
                        <input
                            #letraInput
                            type="text"
                            maxlength="1"
                            [(ngModel)]="obj.letra"
                            (ngModelChange)="nextFocus(i)"
                            (keydown)="filterLetra($event, i)"
                            class="valor edit-respuesta form-control"
                        />
                        <a
                            [ngClass]="!obj.piloto ? 'piloto-inactivo' : 'piloto-activo'"
                            class="tilde_piloto"
                            *ngIf="obj.letra != ''"
                            (click)="obj.piloto = !obj.piloto"
                        >
                            P
                        </a>
                        <input type="number" [(ngModel)]="obj.puntuacion" class="puntuacion form-control" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
