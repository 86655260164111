import { Component, Input } from "@angular/core"
import { EstadisticasPortadaComponent } from "../estadisticas-portada/estadisticas-portada.component"
import { EvaluacionMultiple } from "@puntaje/puntaje/api-services"

@Component({
    selector: "estadisticas-multiples-portada",
    templateUrl: "./estadisticas-multiples-portada.component.html",
    styleUrls: ["../estadisticas-portada/estadisticas-portada.component.scss"]
})
export class EstadisticasMultiplesPortadaComponent extends EstadisticasPortadaComponent {
    @Input() evaluacionMultiple: EvaluacionMultiple

    constructor() {
        super()
    }
}
