import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { GrupoUsuariosModule } from "@puntaje/puntaje/new-modules/grupo-usuarios"
import { GrupoUsuariosComponent } from "./grupo_usuarios.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { TranslationsModule, UtilModule } from "@puntaje/shared/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GrupoUsuariosModule,
        CUILayoutsModule,
        UtilModule,
        TranslationsModule
    ],
    declarations: [GrupoUsuariosComponent],
    exports: [GrupoUsuariosComponent]
})
export class PuntajeGrupoUsuariosModule {}
