import { PuntajePlanClasesModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { ProfesoresPlanClasesRoutingModule } from "./profesores-plan-clases-routing.module"

import { ProfesoresLayoutsModule } from "../layouts"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PlanClasesModule } from "@puntaje/puntaje/new-modules/plan-clases"

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        PlanClasesModule,
        ProfesoresLayoutsModule,
        ProfesoresPlanClasesRoutingModule,
        SharedModule,
        PuntajePlanClasesModule
    ]
})
export class ProfesoresPlanClasesModule {}
