import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { GrupoUsuario } from "@puntaje/puntaje/api-services"

@Component({
    selector: "cursos-plan-clase",
    templateUrl: "./cursos-plan-clase.component.html",
    styleUrls: ["./cursos-plan-clase.component.scss"]
})
export class CursosPlanClaseComponent implements OnInit {
    @Input() cursos: GrupoUsuario[] = []
    @Output() cursosSeleccionadoChange: EventEmitter<GrupoUsuario> = new EventEmitter<GrupoUsuario>()

    cursoSeleccionado: number
    showSelect: boolean = false

    constructor() {}

    ngOnInit() {
        if (this.cursos.length > 0) {
            this.cursoSeleccionado = 0
            this.showSelect = true
            this.cursoChanged(0)
        }
    }

    cursoChanged(pos: number) {
        this.cursosSeleccionadoChange.emit(this.cursos[pos])
        this.cursoSeleccionado = pos
    }
}
