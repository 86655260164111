<loading-layout #loadingLayout>
    <div class="informacion-container">
        <div class="calendario-icon-container">
            <div class="calendario-icon-wrap">
                <ogr-icon class="icono" name="planificacion-o"></ogr-icon>
            </div>
        </div>
        <div class="data-buttons-wrap">
            <form class="form-container" novalidate [formGroup]="form">
                <form-input
                    [form]="form"
                    [params]="params['plan_personal']"
                    [key]="'plan_personal'"
                    [(value)]="planClase.plan_personal"
                    (valueChange)="planClaseStorageService.savePlanPersonal()"
                ></form-input>
                <div class="fecha-gen-descripcion-wrap">
                    <div class="fecha-generador-container">
                        <form-input
                            [form]="form"
                            [params]="params['fecha_inicial']"
                            [key]="'fecha_inicial'"
                            [(value)]="planClase.fecha_inicial"
                            (valueChange)="planClaseStorageService.savePlanPersonal()"
                        ></form-input>

                        <label>
                            {{ 'estudiar.plan_de_clases.asignatura' | t }}&nbsp;
                            <span class="required-mark"></span>
                        </label>
                        <select
                            [(ngModel)]="planClase.generador_instrumento_id"
                            class="form-control select-generador"
                            formControlName="generador_instrumento_id"
                            (ngModelChange)="planClaseStorageService.savePlanPersonal()"
                            (ngModelChange)="setGeneradorInstrumento()"
                        >
                            <option [ngValue]="undefined">-- {{ 'estudiar.plan_de_clases.sel_asignatura' | t }} --</option>
                            <option *ngFor="let option of generadorInstrumentos" [ngValue]="option.id">
                                {{ option.generador_instrumento }}
                            </option>
                        </select>
                        <div
                            *ngIf="
                                form.controls['generador_instrumento_id'].touched &&
                                !form.controls['generador_instrumento_id'].valid
                            "
                            class="error-message"
                        >
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                            <span class="message">{{ 'estudiar.plan_de_clases.required_tipo' | t }}</span>
                        </div>
                    </div>
                    <div class="descripcion-container">
                        <form-input
                            [form]="form"
                            [params]="params['descripcion']"
                            [key]="'descripcion'"
                            [(value)]="planClase.descripcion"
                            (valueChange)="planClaseStorageService.savePlanPersonal()"
                        ></form-input>
                    </div>
                </div>
            </form>
            <div class="buttons-container">
                <cui-button (click)="onSave()" [disabled]="disableSave" type="button" class="btn_style">
                    {{ save_button_text | easyplaceholder: "Guardar cambios" }}
                </cui-button>
                <div class="divider"></div>
                <cui-button *ngIf="planClase?.id" (click)="openModalEliminar()" [isBlock]="true" buttonType="danger">
                    {{ 'estudiar.plan_de_clases.form_eliminar' | t }}
                </cui-button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <p class="alert alert-info">{{ 'estudiar.plan_de_clases.material_arrastre' | t }}.</p>
        </div>
        <div class="col-md-6" *ngIf="enableModelos && generadorInstrumento">
            <cui-button type="button" class="btn_style pull-right" (click)="genericModal.buttonPressed()">
                {{ 'estudiar.plan_de_clases.comenzar_modelo' | t }}
                <fa name="files-o"></fa>
            </cui-button>
        </div>
    </div>

    <ng-container
        *ngFor="let planPersonalSesion of planClase.plan_personal_ciclos[0].plan_personal_sesiones; let i = index"
    >
        <plan-clase-sesion-form
            [asignaturaId]="asignaturaId"
            [planPersonalSesion]="planPersonalSesion"
            [materialTipos]="materialTipos"
            [evaluacionTipo]="tipoEvaluacion"
            [generadorInstrumento]="generadorInstrumento"
            [clasificaciones]="clasificaciones"
            [indxSesion]="i"
            (deleteSesion)="updateSesion($event)"
            [indxSesion]="i"
        ></plan-clase-sesion-form>
    </ng-container>

    <div class="clearfix">
        <cui-button class="btn_style pull-right" (click)="addPlanPersonalSesion()">
            {{ 'estudiar.plan_de_clases.agregar' | t }} {{ nombreSesion }}&nbsp;
            <fa name="plus-circle"></fa>
        </cui-button>
    </div>
</loading-layout>

<generic-modal id="modal-modelo" #genericModal>
    <modal-titulo>{{ 'estudiar.plan_de_clases.comenzar_modelo' | t }}</modal-titulo>
    <modal-contenido>
        <div class="descripcion">
            {{ 'estudiar.plan_de_clases.seleccione_modelo' | t }}.
        </div>
        <plan-clase-modelos
            [generadorInstrumento]="generadorInstrumento"
            (selectPlan)="planClaseModeloService.planClaseModelo = $event"
        ></plan-clase-modelos>
        <plan-clase-modelo
            *ngIf="planClaseModeloService.planClaseModelo"
            [materialTipos]="materialTipos"
            [planPersonal]="planClaseModeloService.planClaseModelo"
        ></plan-clase-modelo>
    </modal-contenido>
    <modal-botones>
        <cui-button
            class="btn-primary btn_style"
            (click)="selectModelo()"
            *ngIf="planClaseModeloService.planClaseModelo"
        >
            {{ 'estudiar.plan_de_clases.seleccionar_aplicar' | t }}
            <fa name="calendar-check-o"></fa>
        </cui-button>
    </modal-botones>
</generic-modal>

<generic-modal id="modal-eliminar" #genericModalEliminar>
    <modal-titulo id="confirm-title">{{ 'estudiar.plan_de_clases.eliminar_plan' | t }}.</modal-titulo>
    <modal-contenido>
        <div>
            <div id="confirm-text">{{ 'estudiar.plan_de_clases.confirm_message' | t }}</div>
            <div>
               {{ 'estudiar.plan_de_clases.confirm_message2' | t }}
            </div>
            <div class="form-group" id="vaciar-container">
                <input class="form-control" type="text" [(ngModel)]="eliminarText" />
            </div>
        </div>
    </modal-contenido>
    <modal-botones>
        <div class="pull-right">
            <button id="btn-aceptar-vaciar" class="btn btn-default small-margin-right" (click)="eliminarPlan()">
                {{ 'estudiar.plan_de_clases.aceptar' | t }}
            </button>
            <button class="btn btn-default" (click)="genericModalEliminar.close()">{{ 'estudiar.plan_de_clases.cancelar' | t }}</button>
        </div>
    </modal-botones>
</generic-modal>
