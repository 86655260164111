import { Component, OnDestroy } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { Evaluacion, Evaluaciones } from "@puntaje/puntaje/api-services"
import { Subscription, combineLatest } from "rxjs"
import { Store, select } from "@ngrx/store"
import {
    State,
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    SetSelectedAsignaturaWithId,
    selectEvaluacionTipoAliases
} from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "revisar_ensayos.component.html"
})
export class RevisarEnsayosComponent implements OnDestroy {
    asignaturaId: number
    asignatura: Asignatura
    evaluaciones: Evaluacion[]
    tipoInstrumento: string
    tipoInstrumentoAlias: string

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    showRevisionPersonalizada = false
    showTitleOverMenu = true

    sub: Subscription
    subQuery: Subscription
    subData: Subscription

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected store: Store<State>,
        protected authService: AuthService,
        protected router: Router
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.revisar_ensayos")
        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$, this.evaluacionTipoAliases$).subscribe(
            ([asignatura, evaluacionTipo, evaluacionTipoAliases]) => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo
                this.tipoInstrumentoAlias = evaluacionTipoAliases[evaluacionTipo]
            }
        )

        this.subData = this.route.data.subscribe(data => {
            this.showRevisionPersonalizada = data.showRevisionPersonalizada ?? this.showRevisionPersonalizada
            this.showTitleOverMenu = data.showTitleOverMenu ?? this.showTitleOverMenu
        })
    }

    ngOnDestroy() {
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
    }
}
