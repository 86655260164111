<cui-section-headline>
    {{
        "historial.eval_comp.titulo_" + (presencial == 1 ? "impresas" : "online")
            | t
                : {
                      evaluacionTipo: (tipoInstrumentoAlias | transformInstrumento: "plural" | capitalize),
                      tipo: ((presencial == 1 ? "impresas" : "online") | genderize: evaluacionTipo)
                  }
    }}
</cui-section-headline>
<div class="filter-container">
    <h4 class="filter-title">
        <span class="glyphicon glyphicon-search icono"></span>
        {{ "historial.eval_comp.filtros" | t }}
    </h4>
    <div class="filter-wrap apart">
        <ng-container *ngIf="establecimientos?.length != 0">
            <div class="filter" *ngIf="establecimientos?.length > 1">
                <select
                    [disabled]="establecimientos && establecimientos.length == 1"
                    [(ngModel)]="establecimiento"
                    (change)="paginator.changePage(1)"
                    (change)="setPropietarios()"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- {{ "historial.eval_comp.sel_est" | t }} --</option>
                    <option *ngFor="let e of establecimientos" [ngValue]="e">{{ e.establecimiento }}</option>
                </select>
            </div>
            <div class="filter" *ngIf="establecimiento">
                <select
                    [(ngModel)]="grupoUsuario"
                    (change)="paginator.changePage(1)"
                    (change)="setPropietarios()"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- {{ "historial.eval_comp.sel_curso" | t }} --</option>
                    <option *ngFor="let gu of establecimiento.grupo_usuarios" [ngValue]="gu">{{ gu.nombre }}</option>
                </select>
            </div>
            <div class="filter" *ngIf="establecimiento">
                <select
                    [(ngModel)]="propietario"
                    (change)="paginator.changePage(1)"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- {{ "historial.eval_comp.sel_doc" | t }} --</option>
                    <option *ngFor="let p of propietarios" [ngValue]="p">{{ p.nombreCompleto() }}</option>
                </select>
            </div>
        </ng-container>
        <div class="filter">
            <filtro-periodo class="periodo" (select)="onSelectPeriodo($event)"></filtro-periodo>
        </div>
    </div>
    <div class="filter-wrap" *ngIf="establecimientos?.length != 0">
        <div class="filter">
            <simple-search-filter
                [elastic]="true"
                [placeholder]="'historial.eval_comp.sel_eval' | t"
                (updateSearch)="searchEvaluacion($event)"
                (clearSearch)="clearEvaluacion()"
            ></simple-search-filter>
        </div>
        <div class="filter">
            <simple-search-filter
                [elastic]="true"
                [placeholder]="'historial.eval_comp.sel_inst' | t"
                (updateSearch)="searchInstrumento($event)"
                (clearSearch)="clearInstrumento()"
            ></simple-search-filter>
        </div>
    </div>
</div>
<loading-layout #loadingLayout>
    <table class="table tabla_ensayos" *ngIf="evaluaciones && evaluaciones.length > 0">
        <thead>
            <tr>
                <th>{{ "historial.eval_comp.eval" | t }}</th>
                <th class="actions">{{ "historial.eval_comp.acciones" | t }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let evaluacion of evaluaciones; let i = index" id="evaluacion_{{ i }}">
                <td>
                    <div class="table-cell-evaluacion row">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <a
                                        [routerLink]="[rutaEvaluaciones || '/evaluaciones', evaluacion.id]"
                                        class="titulo"
                                    >
                                        <ogr-icon name="prueba" class="icono"></ogr-icon>
                                        {{ evaluacion.evaluacion }}
                                    </a>
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label">{{ "historial.eval_comp.eval_id" | t }}:</span>
                                            #{{ evaluacion.id }}
                                        </p>
                                    </div>
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label">{{ "historial.eval_comp.inst_id" | t }}:</span>
                                            #{{ evaluacion.instrumento_id }}
                                        </p>
                                    </div>
                                    <div *ngIf="evaluacion.usada_en_planificacion">
                                        <p>
                                            <span class="inline-label">{{ "historial.eval_comp.usada_plan" | t }}</span>
                                        </p>
                                    </div>
                                    <div *ngIf="evaluacion.instrumento.propio">
                                        <p>
                                            <span class="inline-label"></span>
                                            {{ "historial.eval_comp.propia" | t }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <!--<div class="evaluacion-data-pair">
                                        <p><span class="inline-label">Tipo:</span>{{evaluacion.instrumento.generador_instrumento.generador_instrumento}}</p>
                                    </div>-->
                                    <div
                                        class="evaluacion-data-pair"
                                        *ngIf="establecimientos && establecimientos.length > 1"
                                    >
                                        <p>
                                            <span class="inline-label">{{ "historial.eval_comp.est" | t }}:</span>
                                            {{
                                                evaluacion.evaluacion_usuarios[0].grupo_usuario.establecimiento
                                                    .establecimiento
                                            }}
                                        </p>
                                    </div>
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label">{{ "historial.eval_comp.creador" | t }}:</span>
                                            <fa name="user" class="icono"></fa>
                                            {{ evaluacion.evaluacion_usuarios[0]?.usuario?.nombreCompleto() }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label date-label">
                                                {{ "historial.eval_comp.creacion" | t }}:
                                            </span>
                                            <span class="glyphicon glyphicon-time icono tiempo"></span>
                                            {{ evaluacion.created_at | date: "dd-MM-yyyy HH:mm" }}
                                        </p>
                                    </div>
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label date-label">
                                                {{ "historial.eval_comp.inicio" | t }}:
                                            </span>
                                            <span class="glyphicon glyphicon-time icono tiempo"></span>
                                            {{
                                                evaluacion.fecha_inicio
                                                    | date: "dd-MM-yyyy HH:mm"
                                                    | easyplaceholder: "--"
                                            }}
                                        </p>
                                    </div>
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label date-label">
                                                {{ "historial.eval_comp.cierre" | t }}:
                                            </span>
                                            <span class="glyphicon glyphicon-time icono tiempo"></span>
                                            {{
                                                evaluacion.fecha_termino
                                                    | date: "dd-MM-yyyy HH:mm"
                                                    | easyplaceholder: "--"
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="evaluacion-data-pair">
                                        <p>
                                            <span class="inline-label">{{ "historial.eval_comp.avance" | t }}:</span>
                                        </p>
                                    </div>
                                    <div
                                        *ngFor="let evaluacion_usuario of evaluacion.evaluacion_usuarios"
                                        class="barra-curso-container"
                                    >
                                        <div class="nombre-avance">
                                            <div>{{ evaluacion_usuario.grupo_usuario.nombre }}</div>
                                            <div class="avance">
                                                ({{
                                                    (numeroContestadosByEvaluacionIdGrupoUsuarioId[evaluacion.id] &&
                                                        numeroContestadosByEvaluacionIdGrupoUsuarioId[evaluacion.id][
                                                            evaluacion_usuario.grupo_usuario.id
                                                        ]) ||
                                                        0
                                                }}
                                                /
                                                {{
                                                    numeroAlumnosByGrupoUsuarioId[
                                                        evaluacion_usuario.grupo_usuario.id
                                                    ] || 0
                                                }})
                                            </div>
                                        </div>

                                        <progressbar
                                            [max]="
                                                numeroAlumnosByGrupoUsuarioId[evaluacion_usuario.grupo_usuario.id] || 0
                                            "
                                            [value]="
                                                (numeroContestadosByEvaluacionIdGrupoUsuarioId[evaluacion.id] &&
                                                    numeroContestadosByEvaluacionIdGrupoUsuarioId[evaluacion.id][
                                                        evaluacion_usuario.grupo_usuario.id
                                                    ]) ||
                                                0
                                            "
                                        ></progressbar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="actions">
                    <!-- <a [routerLink]="'/planes_personales/reforzamientos'" [queryParams]="{evaluacion_id: evaluacion.id}" class="btn btn-table" title="Ver resultados reforzamiento">
                        Ver resultados reforzamiento
                    </a> -->
                    <!-- <a (click)="compartirReforzamiento(evaluacion)" class="btn btn-table">
                        <fa [name]="'list-alt'"></fa>
                    </a> -->
                    <a
                        [routerLink]="[rutaEvaluaciones || '/evaluaciones', evaluacion.id]"
                        class="link-action"
                        [attr.title]="'historial.eval_comp.ver_eval' | t"
                    >
                        <span class="glyphicon glyphicon-eye-open" aria-hidden="true"></span>
                        &nbsp;{{ "historial.eval_comp.ver_eval" | t }}
                    </a>
                    <a
                        *ngIf="
                            evaluacion.evaluacion_usuarios[0].usuario_id == usuarioId ||
                            (utpByEstablecimientoId[
                                evaluacion.evaluacion_usuarios[0].grupo_usuario.establecimiento.id
                            ] &&
                                (evaluacion.evaluacion_usuarios[0].usuario.asociacion_ids.length == 0 ||
                                    evaluacion.evaluacion_usuarios[0].usuario.hasSomeAsociacion(asociacionIds)))
                        "
                        (click)="editarEvaluacion(evaluacion)"
                        class="link-action"
                        id="link-editar-evaluacion-{{ i }}"
                        [attr.title]="'historial.eval_comp.edit_eval' | t"
                    >
                        <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                        &nbsp;{{ "historial.eval_comp.edit_eval" | t }}
                    </a>
                    <a
                        [attr.title]="'historial.eval_comp.recompartir' | t"
                        (click)="compartirInstrumento(evaluacion.instrumento_id)"
                        class="link-action"
                    >
                        <span class="glyphicon glyphicon-share-alt" aria-hidden="true"></span>
                        &nbsp;{{ "historial.eval_comp.recompartir" | t }}
                    </a>
                    <a
                        [attr.title]="'historial.eval_comp.ver_est' | t"
                        (click)="
                            onVerEstadisticas(
                                evaluacion.evaluacion_usuarios[0].grupo_usuario.establecimiento.id,
                                null,
                                evaluacion.id,
                                evaluacion.instrumento.generador_instrumento_id,
                                evaluacion.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento
                            )
                        "
                        class="link-action"
                    >
                        <fa [name]="'bar-chart'"></fa>
                        &nbsp;{{ "historial.eval_comp.ver_est" | t }}
                    </a>
                    <a
                        *ngIf="showRevisionPersonalizada"
                        title="Personalizar revisión"
                        class="link-action"
                        routerLink="/resultados_ensayo"
                        [queryParams]="{ evaluacion_id: evaluacion.id }"
                    >
                        <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                        &nbsp;{{ "historial.eval_comp.pers_rev" | t }}
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <p *ngIf="(!evaluaciones || evaluaciones.length <= 0) && establecimientos?.length != 0" class="not-found">
        {{
            "historial.eval_comp.eval_not_found"
                | t
                    : {
                          evaluacionTipo:
                              (tipoInstrumentoAlias | transformInstrumento: "plural") +
                              " " +
                              ("compartidas" | genderize: tipoInstrumentoAlias)
                      }
        }}.
    </p>
    <p *ngIf="!establecimientos || establecimientos.length == 0">{{ "historial.eval_comp.eval_not_found2" | t }}.</p>
    <paginator
        *ngIf="establecimientos && establecimientos.length > 0 && fechaInicial && fechaFinal"
        [getList]="setData"
    ></paginator>
    <ng-container *ngIf="instrumento">
        <compartir-instrumento-profesor
            [instrumento]="instrumento"
            [modalId]="'compartir_' + (presencial ? 'presencial' : 'online')"
            [openModal]="openModal"
            [tipoEvaluacion]="evaluacionTipo"
            [presencial]="!!presencial"
        ></compartir-instrumento-profesor>
    </ng-container>

    <compartir-reforzamientos [evaluacion]="evaluacionToReforzar"></compartir-reforzamientos>

    <editar-evaluacion
        *ngIf="establecimientos"
        [evaluacion]="evaluacionToEdit"
        [openModalEdit]="openModalEdit"
        [tipoEvaluacion]="evaluacionTipo"
        [establecimiento]="establecimiento"
        [paginator]="paginator"
    ></editar-evaluacion>
</loading-layout>
