<div class="clearfix" id="step6">
    <div class="row">
        <div class="col-md-12">
            <div class="content-f">
                <div class="tutorial">
                    <div class="container-tutorial-icon">
                        <div class="tutorial-icon-pos">
                            <ogr-icon name="prueba" align="top" class="icon"></ogr-icon>
                        </div>
                    </div>
                </div>
                <div class="content-text">
                    <h3 class="step">{{ "evaluar.pasos.step" | t }} {{ step }}</h3>
                    <p class="tutorial-text">{{ "evaluar.pasos.clasificar_preguntas.message" | t }}</p>
                    <p class="tutorial-text-descripcion">{{ "evaluar.pasos.clasificar_preguntas.desc" | t }}</p>
                    <div *ngIf="askNumeroPreguntas" class="form-group">
                        <label>Número de preguntas:</label>
                        <input type="number" class="form-control" [(ngModel)]="numeroPreguntas" placeholder="..."
                            min="1" />
                        <div class="warning hide-warning" [class.show-warning]="validationNumeroPreguntas">
                            <div class="warning-content">
                                <fa name="warning" class="warning-icon"></fa>
                                <div class="warning-message">
                                    <p>{{ mensajePreguntas }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contenedor_buttons">
                        <cui-button id="btnClasificar" class="btn_style pull-left"
                            (click)="showClasificadorPreguntas()">
                            {{ "evaluar.pasos.clasificar_preguntas.button_clasificar" | t }}
                        </cui-button>
                        <cui-button id="btnSkip" class="btn_style pull-left" (click)="skip()">
                            {{ "evaluar.pasos.clasificar_preguntas.button_skip" | t }}
                        </cui-button>
                    </div>
                    <clasificador-recursos *ngIf="showClasificador" [curriculum]="curriculum"
                        [asignaturaId]="asignaturaId" [numeroItems]="numeroPreguntas"
                        (changeData)="updateClasificaciones($event)">
                    </clasificador-recursos>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="clearfix">
                <cui-button *ngIf="showClasificador" id="btnSave" class="btn_style pull-right" (click)="sendData()">
                    <ogr-icon name="ticket" align="text-top"></ogr-icon>
                    &nbsp;{{ "evaluar.pasos.clasificar_preguntas.button_save" | t }}
                </cui-button>
            </div>
        </div>
    </div>
</div>
