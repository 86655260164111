import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"

import { Establecimiento, GrupoUsuario, Usuario, Evaluacion, Estadisticas } from "@puntaje/puntaje/api-services"
import { FiltroEstadisticasComponent } from "./filtro_estadisticas.component"
import { Asignaturas, Asignatura, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { BaseChart, PieChart, ColumnChart, StackedBarChart, BarChart } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "estadisticas-profesor",
    templateUrl: "estadisticas_profesor.component.html",
    styleUrls: ["estadisticas_profesor.component.scss"]
})
export class EstadisticasProfesorComponent implements OnInit {
    @ViewChild(FiltroEstadisticasComponent) filtroEstadisticas: FiltroEstadisticasComponent
    asignatura: Asignatura
    @Input() asignaturaId: number
    generadorInstrumento: GeneradorInstrumento
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    showEstadisticasCursos: boolean = false
    showEstadisticasCurso: boolean = false
    showEstadisticasEnsayo: boolean = false

    ready: boolean = false

    establecimiento: Establecimiento
    grupoUsuario: GrupoUsuario
    usuario: Usuario
    evaluacion: Evaluacion
    previousEvaluacion: Evaluacion

    estadisticas: any
    estadisticaUsuarios: any
    @Output() onReadyAsignatura: EventEmitter<any> = new EventEmitter<any>()
    @Input() evaluacionTipo: string
    @Input() evaluacionTipoAlias: string

    constructor(
        protected route: ActivatedRoute,
        protected asignaturasService: Asignaturas,
        protected estadisticasService: Estadisticas
    ) {}

    ngOnInit() {}

    setData() {
        this.loadingLayout.standby()
        this.asignaturasService.find(this.asignaturaId, { simple: 1 }).then((asignatura: Asignatura) => {
            this.asignatura = asignatura
            this.showEstadisticasCurso = false
            this.showEstadisticasCursos = false
            this.showEstadisticasEnsayo = false
            this.onReadyAsignatura.emit(this.asignatura)
            this.loadingLayout.ready()
        })
    }

    onFilter(filtros) {
        this.ready = false

        this.estadisticas = null
        this.estadisticaUsuarios = null
        this.establecimiento = filtros.establecimiento
        this.grupoUsuario = filtros.grupoUsuario
        this.usuario = filtros.usuario
        this.previousEvaluacion = filtros.previousEvaluacion
        this.evaluacion = filtros.evaluacion
        this.generadorInstrumento = filtros.generadorInstrumento

        this.showEstadisticasCursos = !!(
            this.establecimiento &&
            !this.grupoUsuario &&
            !this.usuario &&
            !this.evaluacion
        )
        this.showEstadisticasCurso = !!(this.establecimiento && this.grupoUsuario && !this.usuario && !this.evaluacion)
        this.showEstadisticasEnsayo = !!(
            (this.establecimiento || this.grupoUsuario) &&
            !this.usuario &&
            this.evaluacion
        )

        let grupoUsuariosId =
            (this.grupoUsuario && this.grupoUsuario.id) || this.establecimiento.grupo_usuarios.map(gu => gu.id)
        let evaluacion = this.evaluacion

        this.evaluacionTipo = this.generadorInstrumento.tipo_instrumento.tipo_instrumento

        let params: any = {
            collection: "EstadisticaEvaluacion",
            estadistica: {
                oficial: 1,
                evaluacion_tipo: this.generadorInstrumento.tipo_instrumento.tipo_instrumento,
                generador_instrumento_id: this.generadorInstrumento.id
            }
        }
        params.estadistica["grupo_usuario_id"] = grupoUsuariosId
        evaluacion && (params.estadistica["evaluacion_id"] = evaluacion.id)
        params.clasificacion_methods =
            config.evaluaciones[this.generadorInstrumento.tipo_instrumento.tipo_instrumento].clasificaciones.methods

        this.estadisticasService.wherePost(params).then((estadisticas: any) => {
            this.estadisticas = estadisticas
        })

        if (this.grupoUsuario) {
            delete params.estadistica["evaluacion_id"]
            params.estadistica["usuario_id"] = this.grupoUsuario.usuarios.map(u => u.id)
            params.collection = "EstadisticaUsuario"

            this.estadisticasService.wherePost(params).then((estadisticas: any) => {
                this.estadisticaUsuarios = estadisticas
            })
        }
    }

    print() {
        window.print()
    }

    onReady(asignatura) {
        this.ready = true
        this.asignatura = asignatura
        this.onReadyAsignatura.emit(this.asignatura)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["evaluacionTipo"]) {
            this.setData()
        }
    }
}
