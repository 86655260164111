<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="row print-hide">
            <div class="col-lg-4 col-md-5">
                <filtro-estadisticas-multiples
                    class="print-hide"
                    (filter)="onFilter($event)"
                    [grupoUsuario]="grupoUsuario"
                    [asignaturaId]="asignaturaId"
                    [evaluacionTipo]="evaluacionTipo"
                ></filtro-estadisticas-multiples>
            </div>
            <div class="col-lg-6 col-md-7">
                <!-- <informacion-general-grupos *ngIf="showEstadisticasCursos && estadisticas" [establecimiento]="establecimiento" [estadisticas]="estadisticas" [asignaturaId]="asignaturaId" [evaluacionTipo]="evaluacionTipo" ></informacion-general-grupos> -->
            </div>
        </div>
        <div class="clearfix">
            <cui-button class="btn_style pull-right print-hide" *ngIf="ready" (click)="print()">
                <fa name="print"></fa>
                &nbsp;Imprimir informe general
            </cui-button>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <estadisticas-multiples-cursos
                    *ngIf="showEstadisticasCursos && estadisticas"
                    [generadorInstrumento]="generadorInstrumento"
                    [establecimiento]="establecimiento"
                    [grupoUsuarios]="establecimiento.grupo_usuarios"
                    [asignaturaId]="asignaturaId"
                    [estadisticas]="estadisticas"
                    [evaluacionTipo]="evaluacionTipo"
                    (onReady)="onReady($event)"
                ></estadisticas-multiples-cursos>
                <estadisticas-multiples-curso
                    *ngIf="showEstadisticasCurso && estadisticas && estadisticaUsuarios"
                    [generadorInstrumento]="generadorInstrumento"
                    [establecimiento]="establecimiento"
                    [usuarios]="grupoUsuario.usuarios"
                    [grupoUsuario]="grupoUsuario"
                    [estadisticas]="estadisticas"
                    [estadisticaUsuarios]="estadisticaUsuarios"
                    [evaluacionTipo]="evaluacionTipo"
                    (onReady)="onReady($event)"
                ></estadisticas-multiples-curso>
                <estadisticas-multiples-evaluacion
                    *ngIf="showEstadisticasEnsayo && estadisticas"
                    [establecimiento]="establecimiento"
                    [evaluacionMultiple]="evaluacionMultiple"
                    [estadisticas]="estadisticas"
                    [grupoUsuario]="grupoUsuario"
                    (onReady)="onReady($event)"
                    [evaluacionTipo]="evaluacionTipo"
                ></estadisticas-multiples-evaluacion>
                <!-- <datos-generales *ngIf="evaluacion" [instrumento]="evaluacion.instrumento"></datos-generales> -->
                <!-- <estadisticas-evaluacion *ngIf="showEstadisticasEnsayo && estadisticas" [establecimiento]="establecimiento" [asignaturaId]="asignaturaId" [evaluacion]="evaluacion" [previousEvaluacion]="previousEvaluacion" [estadisticas]="estadisticas" [grupoUsuario]="grupoUsuario" (onReady)="onReady($event)" [evaluacionTipo]="evaluacionTipo"></estadisticas-evaluacion> -->
                <estadisticas-multiples-evaluacion-alumno
                    *ngIf="showEstadisticasEvaluacionAlumno && estadisticas"
                    [evaluacionMultiple]="evaluacionMultiple"
                    [estadisticas]="estadisticas"
                    [establecimiento]="establecimiento"
                    [grupoUsuario]="grupoUsuario"
                    [usuario]="usuario"
                    (onReady)="onReady($event)"
                    [evaluacionTipo]="evaluacionTipo"
                ></estadisticas-multiples-evaluacion-alumno>
            </div>
        </div>
    </div>
</loading-layout>
