<loading-layout #loadingLayout [opaque]="true">
    <div class="flex-row">
        <div class="flex-col">
            <div class="options-container">
                <h4 class="h4_sub_title_1">{{ 'evaluar.generar_instrumento.profesor.cantidad_tiempo' | t }}</h4>
                <numero-preguntas-tiempo
                    [generadorInstrumento]="generadorInstrumento"
                    [(numeroPreguntas)]="numeroPreguntas"
                    [(tiempo)]="tiempo"
                    [tipoEvaluacion]="tipoInstrumento"
                ></numero-preguntas-tiempo>
            </div>
            <br />
            <div
                class="options-container"
                *ngIf="generadorInstrumentos"
                [hidden]="!generadorInstrumentos || generadorInstrumentos.length == 1"
            >
                <h4 class="h4_sub_title_1">{{ 'evaluar.generar_instrumento.profesor.select_asignatura' | t }}</h4>
                <generador-instrumento-select
                    [generadorInstrumentos]="generadorInstrumentos"
                    [(generadorInstrumento)]="generadorInstrumento"
                    (generadorInstrumentoChange)="clasificacionesIds = null"
                ></generador-instrumento-select>
                <br />
            </div>
        </div>
        <div class="flex-col">
            <div
                id="contenidos-container"
                class="contenidos-container clear-both"
                [class.contenidos-container-show]="show_contenidos"
            >
                <h4 class="h4_sub_title_1">{{ 'evaluar.generar_instrumento.profesor.personalizar' | t}}</h4>
                <p class="tutorial">
                    {{ 'evaluar.generar_instrumento.profesor.select_categorias' | t}}
                </p>
                <div class="generar-col2 contenidos-ensayo">
                    <configurar-clasificaciones
                        *ngIf="generadorInstrumento"
                        [enableCantidadPreguntas]="true"
                        [enableObjetivoPriorizado]="true"
                        [tipoInstrumento]="tipoInstrumento"
                        (onReady)="onReady()"
                        [generadorInstrumentoId]="generadorInstrumento.id"
                        [asignaturaId]="asignatura?.id"
                        [(clasificacionesIds)]="clasificacionesIds"
                        (clasificacionesIdsChange)="onClasificacionesChange($event)"
                    ></configurar-clasificaciones>
                    <span *ngIf="generadorInstrumento">{{ 'evaluar.generar_instrumento.profesor.total_preguntas' | t}}:&nbsp;{{totalPreguntas}}</span>
                </div>
            </div>
        </div>
    </div>
    <br />
    <h4 class="h4_sub_title_1">{{ 'evaluar.generar_instrumento.profesor.generar' | t }}</h4>
    <cui-button class="btn-new" (click)="generarInstrumento()" [disabled]="!clasificacionesIds">
        <ogr-icon [name]="'prueba'" class="left-icon"></ogr-icon>
        {{ 'evaluar.generar_instrumento.profesor.generar' | t}}
    </cui-button>
</loading-layout>
