<popup-grupo-usuarios
    [grupoUsuarios]="grupoUsuarios"
    [postStep]="!!gruposSeleccionados"
    [gruposSeleccionados]="gruposPreseleccionados"
    [sharing]="sharing"
    [modalId]="modalId"
    (action)="aplicar($event)"
>
    <ng-container ngProjectAs="titulo">
        {{ titulo }}
    </ng-container>

    <ng-container ngProjectAs="titulo-grupos">
        {{ nombreGrupo | pluralize | capitalize }} {{ titulo_grupos }}
    </ng-container>

    <ng-container ngProjectAs="mensaje-sin-grupos">
        {{ mensaje_sin_grupos }} {{ nombreGrupo | pluralize }} activos.
    </ng-container>

    <ng-container ngProjectAs="nombre-submit">
        {{ nombre_submit }}
    </ng-container>

    <ng-container *ngIf="planPersonalCompartido" ngProjectAs="post-step">
        <div *ngIf="enableReplicar">
            {{ 'estudiar.plan_de_clases.plan_aplicado1' | t }} #{{ planPersonalCompartido.id }} {{ 'estudiar.plan_de_clases.plan_aplicado2' | t }}
            {{ nombreGrupo | pluralize }}:

            <ul *ngFor="let grupoUsuario of gruposSeleccionados">
                <li>{{ grupoUsuario.nombre }}</li>
            </ul>
            {{ 'estudiar.plan_de_clases.redirigido1' | t }}
            <a
                [routerLink]="['/planificaciones/aplicadas']"
                [queryParams]="{
                    tipo_instrumento: this.tipoInstrumento.tipo_instrumento,
                    asignatura_id: this.asignatura.id
                }"
            >
                {{ 'estudiar.plan_de_clases.redirigido2' | t }}
            </a>
            .
        </div>
        <div *ngIf="!enableReplicar">
            {{ 'estudiar.plan_de_clases.compartido1' | t }} #{{ planPersonalCompartido.id }} {{ 'estudiar.plan_de_clases.compartido2' | t }}
            {{ nombreGrupo | pluralize }}:

            <ul *ngFor="let grupoUsuario of gruposSeleccionados">
                <li>{{ grupoUsuario.nombre }}</li>
            </ul>
            {{ 'estudiar.plan_de_clases.redirigido3' | t }}
        </div>
    </ng-container>
</popup-grupo-usuarios>
