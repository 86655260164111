<loading-layout>
    <div *ngIf="!loadingLayout.loading" class="contentPrincipal">
        <cui-section-headline>{{ "subir_material_profesor.titulo" | t }}</cui-section-headline>
        <div class="row margin_top">
            <div class="col-lg-12">
                <cui-tabs justifyContent="flex-start">
                    <cui-tab title="{{ 'subir_material_profesor.tab_subir_pdf' | t }}" (select)="selectTab('pdf')">
                        <div class="alert alert-danger" *ngIf="errorMessage">
                            {{ errorMessage }}
                        </div>
                        <div class="form-group margin">
                            {{ "subir_material_profesor.subir_pdf_nombre" | t }}
                            <input
                                class="form-control margin"
                                type="text"
                                [(ngModel)]="materialPdf.material"
                                placeholder="Nombre del material"
                            />
                        </div>
                        <div class="form-group">
                            {{ "subir_material_profesor.subir_pdf_archivo" | t }}
                            <div class="margin">
                                <file [(ngModel)]="materialPdf.file" key="file"></file>
                            </div>
                        </div>
                    </cui-tab>
                    <cui-tab title="{{ 'subir_material_profesor.tab_subir_ppt' | t }}" (select)="selectTab('ppt')">
                        <div class="alert alert-danger" *ngIf="errorMessage">
                            {{ errorMessage }}
                        </div>
                        <div class="form-group margin">
                            {{ "subir_material_profesor.subir_ppt_nombre" | t }}
                            <input
                                class="form-control margin"
                                type="text"
                                [(ngModel)]="materialPPT.material"
                                placeholder="Nombre del material"
                            />
                        </div>
                        <div class="form-group">
                            {{ "subir_material_profesor.subir_ppt_archivo" | t }}
                            <div class="margin">
                                <file [(ngModel)]="materialPPT.file" key="ppt_file"></file>
                            </div>
                        </div>
                    </cui-tab>
                    <cui-tab title="{{ 'subir_material_profesor.tab_subir_word' | t }}" (select)="selectTab('word')">
                        <div class="alert alert-danger" *ngIf="errorMessage">
                            {{ errorMessage }}
                        </div>
                        <div class="form-group margin">
                            {{ "subir_material_profesor.subir_word_nombre" | t }}
                            <input
                                class="form-control margin"
                                type="text"
                                [(ngModel)]="materialWord.material"
                                placeholder="Nombre del material"
                            />
                        </div>
                        <div class="form-group">
                            {{ "subir_material_profesor.subir_word_archivo" | t }}
                            <div class="margin">
                                <file [(ngModel)]="materialWord.file" key="word_file"></file>
                            </div>
                        </div>
                    </cui-tab>
                    <cui-tab title="{{ 'subir_material_profesor.tab_subir_excel' | t }}" (select)="selectTab('excel')">
                        <div class="alert alert-danger" *ngIf="errorMessage">
                            {{ errorMessage }}
                        </div>
                        <div class="form-group margin">
                            {{ "subir_material_profesor.subir_excel_nombre" | t }}
                            <input
                                class="form-control margin"
                                type="text"
                                [(ngModel)]="materialExcel.material"
                                placeholder="Nombre del material"
                            />
                        </div>
                        <div class="form-group">
                            {{ "subir_material_profesor.subir_excel_archivo" | t }}
                            <div class="margin">
                                <file [(ngModel)]="materialExcel.file" key="excel_file"></file>
                            </div>
                        </div>
                    </cui-tab>
                    <cui-tab title="{{ 'subir_material_profesor.tab_agregar_video' | t }}" (click)="selectTab('video')">
                        <div class="alert alert-danger" *ngIf="errorMessage">
                            {{ errorMessage }}
                        </div>
                        <div class="form-group">
                            {{ "subir_material_profesor.agregar_video_nombre" | t }}
                            <input
                                class="form-control margin"
                                type="text"
                                [(ngModel)]="materialVideo.material"
                                placeholder="Nombre del material"
                            />
                        </div>
                        <div class="form-group">
                            {{ "subir_material_profesor.agregar_video_url" | t }}
                            <input
                                class="form-control margin"
                                type="text"
                                [(ngModel)]="materialVideo.url"
                                placeholder="Dirección de Youtube"
                            />
                        </div>
                    </cui-tab>
                    <cui-tab title="{{ 'subir_material_profesor.tab_subir_video' | t }}" (click)="selectTab('mp4')">
                        <div class="alert alert-danger" *ngIf="errorMessage">
                            {{ errorMessage }}
                        </div>
                        <div class="form-group margin">
                            {{ "subir_material_profesor.subir_video_nombre" | t }}
                            <input
                                class="form-control margin"
                                type="text"
                                [(ngModel)]="materialMp4.material"
                                placeholder="Nombre del material"
                            />
                        </div>
                        <div class="form-group">
                            {{ "subir_material_profesor.subir_video_archivo" | t }}
                            <div class="margin">
                                <file [(ngModel)]="materialMp4.file" key="video_file"></file>
                            </div>
                        </div>
                    </cui-tab>
                    <cui-tab title="{{ 'subir_material_profesor.tab_cargar_link' | t }}" (click)="selectTab('link')">
                        <div class="alert alert-danger" *ngIf="errorMessage">
                            {{ errorMessage }}
                        </div>
                        <div class="form-group">
                            {{ "subir_material_profesor.cargar_link_nombre" | t }}
                            <input
                                class="form-control margin"
                                type="text"
                                [(ngModel)]="materialLink.material"
                                placeholder="Nombre del material"
                            />
                        </div>
                        <div class="form-group">
                            {{ "subir_material_profesor.cargar_link_url" | t }}
                            <input
                                class="form-control margin"
                                type="text"
                                [(ngModel)]="materialLink.url"
                                placeholder="Vínculo externo"
                            />
                        </div>
                    </cui-tab>
                </cui-tabs>
                <div class="radio">
                    <label>
                        <input [(ngModel)]="clasificacion" type="radio" name="clasificacion" [value]="false" />
                        {{ "subir_material_profesor.sin_clasificacion" | t }}
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input [(ngModel)]="clasificacion" type="radio" name="clasificacion" [value]="true" />
                        {{ "subir_material_profesor.con_clasificacion" | t }}
                    </label>
                </div>
                <div *ngIf="clasificacion">
                    <filtro-clasificaciones-materiales
                        [clasificacionMateriales]="clasificacionMateriales"
                        [asignaturaId]="asignaturaId"
                        [evaluacionTipo]="evaluacionTipo"
                        (changeCurso)="handleSelectClasificacion($event, 'curso')"
                        (changeClasificacionPrimerNivel)="handleSelectClasificacion($event, 'primerNivel')"
                        (changeClasificacionSegundoNivel)="handleSelectClasificacion($event, 'segundoNivel')"
                    ></filtro-clasificaciones-materiales>
                </div>
            </div>
        </div>
        <br />

        <div class="clearfix">
            <span *ngIf="selectedTab == 'pdf'">{{ "subir_material_profesor.pdf_advertencia" | t }}</span>
            <span *ngIf="selectedTab == 'ppt'">
                {{ "subir_material_profesor.ppt_advertencia" | t }}
            </span>
            <span *ngIf="selectedTab == 'word'">
                {{ "subir_material_profesor.word_advertencia" | t }}
            </span>
            <span *ngIf="selectedTab == 'excel'">
                {{ "subir_material_profesor.excel_advertencia" | t }}
            </span>

            <span *ngIf="selectedTab == 'mp4'">{{ "subir_material_profesor.mp4_advertencia" | t }}</span>
            <div class="pull-right botones">
                <loader *ngIf="uploading" loaderType="default-xsmall"></loader>
                <cui-button *ngIf="selectedTab == 'pdf'" class="btn_style" [disabled]="uploading" (click)="uploadPDF()">
                    <ogr-icon name="upload" [align]="'middle'"></ogr-icon>
                    &nbsp;{{ "subir_material_profesor.boton_subir_pdf" | t }}
                </cui-button>
                <cui-button *ngIf="selectedTab == 'ppt'" class="btn_style" [disabled]="uploading" (click)="uploadPPT()">
                    <ogr-icon name="upload" [align]="'middle'"></ogr-icon>
                    &nbsp;{{ "subir_material_profesor.boton_subir_ppt" | t }}
                </cui-button>
                <cui-button
                    *ngIf="selectedTab == 'word'"
                    class="btn_style"
                    [disabled]="uploading"
                    (click)="uploadWord()"
                >
                    <ogr-icon name="upload" [align]="'middle'"></ogr-icon>
                    &nbsp;{{ "subir_material_profesor.boton_subir_word" | t }}
                </cui-button>
                <cui-button
                    *ngIf="selectedTab == 'excel'"
                    class="btn_style"
                    [disabled]="uploading"
                    (click)="uploadExcel()"
                >
                    <ogr-icon name="upload" [align]="'middle'"></ogr-icon>
                    &nbsp;{{ "subir_material_profesor.boton_subir_excel" | t }}
                </cui-button>
                <cui-button
                    *ngIf="selectedTab == 'video'"
                    class="btn_style"
                    [disabled]="uploading"
                    (click)="uploadVideo()"
                >
                    <ogr-icon name="upload" [align]="'middle'"></ogr-icon>
                    &nbsp;{{ "subir_material_profesor.boton_subir_video_url" | t }}
                </cui-button>
                <cui-button *ngIf="selectedTab == 'mp4'" class="btn_style" [disabled]="uploading" (click)="uploadMp4()">
                    <ogr-icon name="upload" [align]="'middle'"></ogr-icon>
                    &nbsp;{{ "subir_material_profesor.boton_subir_mp4" | t }}
                </cui-button>
                <cui-button
                    *ngIf="selectedTab == 'link'"
                    class="btn_style"
                    [disabled]="uploading"
                    (click)="uploadLink()"
                >
                    <ogr-icon name="upload" [align]="'middle'"></ogr-icon>
                    &nbsp;{{ "subir_material_profesor.boton_cargar_link" | t }}
                </cui-button>
            </div>
        </div>
    </div>
</loading-layout>
