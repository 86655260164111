import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Clasificacion, Clasificaciones, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "filtro-clasificaciones-materiales",
    templateUrl: "./filtro-clasificaciones-materiales.component.html",
    styleUrls: ["./filtro-clasificaciones-materiales.component.scss"]
})
export class FiltroClasificacionesMaterialesComponent implements OnInit {
    @Input() asignaturaId: number
    @Input() evaluacionTipo: string
    @Input() generadorInstrumento: GeneradorInstrumento
    @Input() unselectedCurso: boolean = false
    @Input() searchByCurso: boolean = true
    @Input() clasificacionMateriales: any[]

    @Output() searchByCursoChange = new EventEmitter<boolean>()

    @Output() changeCurso = new EventEmitter<Clasificacion>()
    @Output() changeClasificacionPrimerNivel = new EventEmitter<Clasificacion>()
    @Output() changeClasificacionSegundoNivel = new EventEmitter<Clasificacion>()

    cursosLoaded: boolean = false

    cursosMaterial: any[]
    clasificacionesPrimerNivel: Clasificacion[]
    clasificacionesSegundoNivel: Clasificacion[]

    curso: any
    clasificacionPrimerNivel: Clasificacion
    clasificacionSegundoNivel: Clasificacion

    constructor(protected clasificacionesService: Clasificaciones) {}

    ngOnInit() {
        this.cursosMaterial = config.evaluaciones[this.evaluacionTipo].cursosMaterial
        this.clasificacionMateriales =
            this.clasificacionMateriales || config.evaluaciones[this.evaluacionTipo].planPersonalClasificacionMateriales

        this.searchByCurso = this.cursosMaterial && this.cursosMaterial.length > 0
        this.searchByCursoChange.emit(this.searchByCurso)

        if (this.searchByCurso) {
            if (this.cursosMaterial) {
                const nombresCursos = this.cursosMaterial.map(cm => cm.clasificacion)
                const params = {
                    clasificacion: {
                        clasificacion: nombresCursos
                    },
                    clasificacion_tipo: {
                        clasificacion_tipo: config.evaluaciones[this.evaluacionTipo].cursoTipo
                    }
                }

                this.clasificacionesService.where(params).then(clasificaciones => {
                    this.cursosLoaded = true

                    this.cursosMaterial = this.cursosMaterial.map(cursoMaterial => {
                        const curso = clasificaciones.find(c => c.clasificacion == cursoMaterial.clasificacion)

                        return {
                            ...cursoMaterial,
                            ...curso
                        }
                    })

                    if (this.generadorInstrumento) {
                        let curso = this.cursosMaterial.find(
                            cm => cm.id == this.generadorInstrumento.generador_instrumento_categorias[0].categoria_id
                        )
                        if (!curso) {
                            curso = this.cursosMaterial[0]
                        }

                        if (!this.unselectedCurso) {
                            this.onChangeCurso(curso)
                        }
                    }
                })
            } else {
                this.onChangeCurso()
            }
        } else {
            this.getClasificacionesPrimerNivel()
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["generadorInstrumento"] && changes["generadorInstrumento"].currentValue && this.cursosLoaded) {
            let curso = this.cursosMaterial.find(
                cm => cm.id == this.generadorInstrumento.generador_instrumento_categorias[0].categoria_id
            )
            if (!curso) {
                curso = this.cursosMaterial[0]
            }

            if (!this.unselectedCurso) {
                this.onChangeCurso(curso)
            }
        }
    }

    onChangeCurso(curso?: Clasificacion) {
        this.clasificacionesPrimerNivel = undefined
        this.clasificacionesSegundoNivel = undefined

        this.clasificacionPrimerNivel = undefined
        this.clasificacionSegundoNivel = undefined

        this.curso = curso
        this.changeCurso.emit(curso)
        this.changeClasificacionPrimerNivel.emit(undefined)
        this.changeClasificacionSegundoNivel.emit(undefined)

        if (this.cursosMaterial && !curso) return
        this.getClasificacionesPrimerNivel(curso)
    }

    getClasificacionesPrimerNivel(curso?: Clasificacion) {
        const paramsPrimerNivel: any = {
            clasificacion_tipo: {
                clasificacion_tipo: this.clasificacionMateriales[0].clasificacion
            },
            clasificacion: {
                asignatura_id: this.asignaturaId
            }
        }

        if (curso) {
            paramsPrimerNivel.clasificacion_clasificaciones = {
                clasificacion_id: curso.id
            }
        }

        this.clasificacionesService.where(paramsPrimerNivel).then(clasificaciones => {
            this.clasificacionesPrimerNivel = clasificaciones
        })
    }

    onChangeClasificacionPrimerNivel(clasificacion: Clasificacion) {
        this.clasificacionSegundoNivel = undefined
        this.clasificacionesSegundoNivel = undefined

        this.changeClasificacionPrimerNivel.emit(clasificacion)
        this.changeClasificacionSegundoNivel.emit(undefined)

        if (!this.clasificacionMateriales[1] || !clasificacion) return

        const params = {
            clasificacion_tipo: {
                clasificacion_tipo: this.clasificacionMateriales[1].clasificacion
            },
            clasificacion_clasificaciones: {
                clasificacion_id: clasificacion.id
            },
            clasificacion: {
                asignatura_id: this.asignaturaId
            }
        }

        this.clasificacionesService.where(params).then(clasificaciones => {
            this.clasificacionesSegundoNivel = clasificaciones
        })
    }

    onChangeClasificacionSegundoNivel(clasificacion: Clasificacion) {
        this.changeClasificacionSegundoNivel.emit(clasificacion)
    }
}
