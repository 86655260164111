<popup-grupo-usuarios
    [grupoUsuarios]="grupoUsuarios"
    [sharing]="sharing"
    [modalId]="'reforzamiento'"
    (action)="crear($event)"
>
    <ng-container ngProjectAs="titulo">Compartir reforzamientos</ng-container>

    <ng-container ngProjectAs="titulo-grupos">Cursos a los cuales reforzar la evaluación.</ng-container>

    <ng-container ngProjectAs="mensaje-sin-grupos">Usted no puede compartir.</ng-container>

    <ng-container ngProjectAs="nombre-submit">Crear</ng-container>
</popup-grupo-usuarios>
