import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { Subject } from "rxjs"
import { Material } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "subir-archivo",
    templateUrl: "./subir-archivo.component.html",
    styleUrls: ["../subir-prueba-propia.component.scss"]
})
export class SubirArchivoComponent implements OnInit {
    @Input() material: Material = new Material()
    @Input() step: number = 1
    @Output() changeData: EventEmitter<any> = new EventEmitter()
    file: File
    wrongFileType: boolean = false
    loadingMessages: boolean = false
    xhrUpload: Subject<XMLHttpRequest> = new Subject()
    totalProgress: number = 0
    progressWidth: string = "0%"
    progress: number = 0
    typeFile: string

    constructor() {}

    ngOnInit() {}

    checkExtension() {
        if (
            (this.material.file && (this.material.file.name as any).endsWith(".pdf")) ||
            (this.material.file.name as any).endsWith(".doc") ||
            (this.material.file.name as any).endsWith(".docx")
        ) {
            this.wrongFileType = false
        } else {
            this.wrongFileType = true
        }
    }

    subirArchivo() {
        if (!this.wrongFileType) {
            this.typeFile = this.material.file.type
            const reader = new FileReader()
            reader.onloadend = (e: any) => {
                this.material.fileSrc = e.target.result
                this.changeData.emit({ material: this.material, typeFile: this.typeFile })
            }

            if (this.typeFile == "application/pdf") {
                reader.readAsArrayBuffer(this.material.file)
            } else {
                reader.readAsDataURL(this.material.file)
            }
        }
    }
}
