import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef
} from "@angular/core"
import {
    Asignaturas,
    Asignatura,
    ClasificacionTipo,
    ClasificacionTipos,
    GeneradorInstrumento
} from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Usuario, GrupoUsuario, Estadisticas, Establecimiento } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "estadisticas-multiples-curso",
    templateUrl: "./estadisticas-multiples-curso.component.html",
    styleUrls: ["../estadisticas.component.scss"]
})
export class EstadisticasMultiplesCursoComponent implements OnInit {
    @Input() generadorInstrumento: GeneradorInstrumento
    @Input() establecimiento: Establecimiento
    @Input() grupoUsuario: GrupoUsuario
    @Input() usuarios: Usuario[]
    @Input() estadisticas: any
    @Input() estadisticaUsuarios: any
    @Input() evaluacionTipo: string
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    asignatura: Asignatura
    asignaturaId: number = 0
    asignaturaIds: number[] = []

    clasificacionTipos: ClasificacionTipo[]
    clasificacionTiposEstadisticas: any

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    enableGraphs: boolean = false

    distribucion_ensayos_tramo_puntaje: { [key: number]: { [key: number]: number } } = {}
    desempeno_materia_omitidas: number[] = []
    desempeno_materia_correctas: number[] = []
    desempeno_materia_incorrectas: number[] = []
    desempeno_promedio_omitidas: number
    desempeno_promedio_correctas: number
    desempeno_promedio_incorrectas: number
    desempeno_subejes: string[] = []
    desempeno_subejes_omitidas: number[] = []
    desempeno_subejes_correctas: number[] = []
    desempeno_subejes_incorrectas: number[] = []
    comparacion_alumnos: string[] = []
    comparacion_promedios: number[] = []

    promedioGeneral: number
    promedioAsignaturas: { [key: number]: number }

    constructor(
        protected asignaturasService: Asignaturas,
        protected estadisticasService: Estadisticas,
        protected clasificacionTiposService: ClasificacionTipos,
        protected authService: AuthService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.loadingLayout.standby()
        this.setData()
    }

    setData() {
        this.loadingLayout.standby()

        this.clasificacionTiposEstadisticas = config.evaluaciones[this.evaluacionTipo].clasificacionTiposEstadisticas

        const cTipos = this.clasificacionTiposEstadisticas.filter(ct => ct.profesores).map(ct => ct.nombre)

        this.clasificacionTiposService
            .where({ clasificacion_tipo: { clasificacion_tipo: cTipos } })
            .then((cts: ClasificacionTipo[]) => {
                this.clasificacionTipos = cTipos.map(ct => cts.find(cto => cto.clasificacion_tipo == ct))

                this.setValues()
                this.enableGraphs = true
                this.loadingLayout.ready()
                this.onReady.emit(this.asignatura)
            })
    }

    setValues() {
        // Calculo promedio evaluaciones para el curso
        const numeroTotalEvaluaciones = this.estadisticas.reduce((acc, e) => acc + e.numero_evaluaciones, 0)
        const sumaPonderada = this.estadisticas.reduce((acc, e) => acc + e.promedio * e.numero_evaluaciones, 0)
        this.promedioGeneral = numeroTotalEvaluaciones == 0 ? 0 : sumaPonderada / numeroTotalEvaluaciones

        const estadisticaAsignaturas = this.estadisticas
            .map(e => e.estadistica_asignaturas || [])
            .flat()
            .groupBy(ea => ea.asignatura_id)

        this.asignaturaIds = Object.keys(estadisticaAsignaturas).map(id => +id)

        this.promedioAsignaturas = Object.keys(estadisticaAsignaturas).reduce((acc, asignaturaId) => {
            const numeroTotalEvaluacionesAsignatura = estadisticaAsignaturas[asignaturaId].reduce(
                (acc, ea) => acc + ea.numero_evaluaciones,
                0
            )
            const sumaPonderadaAsignatura = estadisticaAsignaturas[asignaturaId].reduce(
                (acc, ea) => acc + ea.promedio * ea.numero_evaluaciones,
                0
            )

            acc[asignaturaId] =
                numeroTotalEvaluacionesAsignatura == 0 ? 0 : sumaPonderadaAsignatura / numeroTotalEvaluacionesAsignatura

            return acc
        }, {})

        this.distribucion_ensayos_tramo_puntaje = this.estadisticas.reduce(
            (distribucion, e) => {
                Object.keys(e.distribucion_calificaciones).map(puntaje => {
                    if (!distribucion[0][puntaje]) {
                        distribucion[0][puntaje] = 0
                    }
                    distribucion[0][puntaje] += e.distribucion_calificaciones[puntaje]
                })

                const estadisticaAsignaturas = e.estadistica_asignaturas
                if (estadisticaAsignaturas) {
                    estadisticaAsignaturas.forEach(ea => {
                        if (!distribucion[ea.asignatura_id]) {
                            distribucion[ea.asignatura_id] = {}
                        }
                        Object.keys(ea.distribucion_calificaciones).map(puntaje => {
                            if (!distribucion[ea.asignatura_id][puntaje]) {
                                distribucion[ea.asignatura_id][puntaje] = 0
                            }
                            distribucion[ea.asignatura_id][puntaje] += ea.distribucion_calificaciones[puntaje]
                        })
                    })
                }

                return distribucion
            },
            { 0: {} }
        )

        let estadisticaClasificaciones = {}
        this.estadisticas.forEach(e => {
            if (e.estadistica_clasificaciones) {
                e.estadistica_clasificaciones.forEach(ec => {
                    if (!estadisticaClasificaciones[ec.clasificacion_id]) {
                        estadisticaClasificaciones[ec.clasificacion_id] = ec
                    } else {
                        estadisticaClasificaciones[ec.clasificacion_id].correctas += ec.correctas
                        estadisticaClasificaciones[ec.clasificacion_id].incorrectas += ec.incorrectas
                        estadisticaClasificaciones[ec.clasificacion_id].omitidas += ec.omitidas
                    }
                })
            }
        })
        estadisticaClasificaciones = (Object as any).values(estadisticaClasificaciones)
        const asignaturaIds = Object.keys(estadisticaAsignaturas).map(aId => +aId)
        asignaturaIds.push(0)

        asignaturaIds.forEach(asignaturaId => {
            this.clasificacionTipos.forEach(ct => {
                const ecs = (estadisticaClasificaciones as any[]).filter(
                    ec =>
                        ec.clasificacion.clasificacion_tipo_id == ct.id &&
                        (ec.clasificacion.asignatura_id == asignaturaId || asignaturaId == 0)
                )

                const nombre = (ct as any).clasificacion_tipo

                this["desempeno_" + nombre] = this["desempeno_" + nombre] || {}
                this["desempeno_" + nombre + "_omitidas"] = this["desempeno_" + nombre + "_omitidas"] || {}
                this["desempeno_" + nombre + "_incorrectas"] = this["desempeno_" + nombre + "_incorrectas"] || {}
                this["desempeno_" + nombre + "_correctas"] = this["desempeno_" + nombre + "_correctas"] || {}
                this["desempeno_" + nombre + "_total"] = this["desempeno_" + nombre + "_total"] || {}

                this["desempeno_" + nombre][asignaturaId] = ecs.map(
                    ec =>
                        ec.clasificacion.clasificacion +
                        (ec.clasificacion.curso ? " (" + ec.clasificacion.curso.clasificacion + ")" : "")
                )
                this["desempeno_" + nombre + "_omitidas"][asignaturaId] = ecs.map(ec => ec.omitidas)
                this["desempeno_" + nombre + "_incorrectas"][asignaturaId] = ecs.map(ec => ec.incorrectas)
                this["desempeno_" + nombre + "_correctas"][asignaturaId] = ecs.map(ec => ec.correctas)
                this["desempeno_" + nombre + "_total"][asignaturaId] = ecs.map(
                    ec => ec.omitidas + ec.incorrectas + ec.correctas
                )
            })
        })

        // const numero_evaluaciones = this.estadisticas.reduce((x, e) => x + e.numero_evaluaciones, 0);
        // this.desempeno_promedio_omitidas = ~~(this.estadisticas.reduce((x, e) => x + e.omitidas, 0) / numero_evaluaciones);
        // this.desempeno_promedio_incorrectas = ~~(this.estadisticas.reduce((x, e) => x + e.incorrectas, 0) / numero_evaluaciones);
        // this.desempeno_promedio_correctas = ~~(this.estadisticas.reduce((x, e) => x + e.correctas, 0) / numero_evaluaciones);

        // /*let estadistica_clasificacion_cursos = estadistica_clasificaciones.filter((ec) => ec.clasificacion.clasificacion_tipo_id == 1);
        // this.desempeno_materia_cursos = estadistica_clasificacion_cursos.map((ec) => ec.clasificacion.clasificacion);
        // this.desempeno_materia_omitidas = estadistica_clasificacion_cursos.map((ec) => ec.omitidas);
        // this.desempeno_materia_incorrectas = estadistica_clasificacion_cursos.map((ec) => ec.incorrectas);
        // this.desempeno_materia_correctas = estadistica_clasificacion_cursos.map((ec) => ec.correctas);
        // let numero_evaluaciones = this.estadisticas.reduce((x, e) => x + e.numero_evaluaciones, 0);
        // this.desempeno_promedio_omitidas = ~~(this.estadisticas.reduce((x, e) => x + e.omitidas, 0)/numero_evaluaciones);
        // this.desempeno_promedio_incorrectas = ~~(this.estadisticas.reduce((x, e) => x + e.incorrectas, 0)/numero_evaluaciones);
        // this.desempeno_promedio_correctas = ~~(this.estadisticas.reduce((x, e) => x + e.correctas, 0)/numero_evaluaciones);
        // let estadistica_clasificacion_subejes = estadistica_clasificaciones.filter((ec) => ec.clasificacion.clasificacion_tipo_id == 7 && ec.clasificacion.clasificacion_padre_id != null);
        // this.desempeno_subejes = estadistica_clasificacion_subejes.map((ec) => ec.clasificacion.clasificacion);
        // this.desempeno_subejes_omitidas = estadistica_clasificacion_subejes.map((ec) => ec.omitidas);
        // this.desempeno_subejes_incorrectas = estadistica_clasificacion_subejes.map((ec) => ec.incorrectas);
        // this.desempeno_subejes_correctas = estadistica_clasificacion_subejes.map((ec) => ec.correctas);*/
        // this.comparacion_alumnos = this.usuarios.map((u) => u.nombreCompleto());
        // this.estadisticasService.where({collection: "EstadisticaUsuario", estadistica: {oficial: 1, evaluacion_tipo: this.evaluacionTipo, grupo_usuario_id: this.grupoUsuario.id, generador_instrumento_id: this.generadorInstrumento.id, usuario_id: this.usuarios.map((u) => u.id)}}).then((estadisticas: any) => {
        //     this.comparacion_promedios = this.usuarios.map((u) => {
        //         const estadistica_usuario = estadisticas.find((e) => e.usuario_id == u.id);
        //         if(estadistica_usuario)
        //             return estadistica_usuario.promedio;
        //         else
        //             return 0;
        //     });
        // });
    }

    onChangeAsignatura(asignatura) {
        this.changeAsignatura(asignatura)
        this.cdr.detectChanges()
    }

    changeAsignatura(asignatura) {
        this.asignatura = asignatura
        if (asignatura) {
            this.asignaturaId = asignatura.id
        } else {
            this.asignaturaId = 0
        }
    }
}
