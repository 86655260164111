import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    ViewChildren,
    ViewChild,
    QueryList,
    ElementRef,
    TemplateRef,
    Output,
    SimpleChanges
} from "@angular/core"
import { S3Service, PdfView, S3, AuthService, ScrollToService, DocsView } from "@puntaje/shared/core"
import {
    Material,
    MaterialTipos,
    NebuAuthService,
    Pregunta,
    Contestable,
    Alternativa,
    GeneradorInstrumento,
    GeneradorInstrumentos,
    Asignaturas,
    Materiales,
    Preguntas,
    PreguntaMaterial,
    ContestableTipo,
    ContestableTipos,
    Clasificacion,
    ClasificacionPregunta
} from "@puntaje/nebulosa/api-services"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import {
    Instrumento,
    InstrumentoPregunta,
    InstrumentoMaterial,
    Instrumentos,
    Evaluacion,
    EvaluacionForma,
    Evaluaciones,
    EvaluacionTipo
} from "@puntaje/puntaje/api-services"
import { ModalDirective, BsModalService, ModalOptions, BsModalRef } from "ngx-bootstrap/modal"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
import { ModalClasificarRecursoComponent } from "@puntaje/puntaje/new-modules/clasificaciones"
declare const config: AppConfig

@Component({
    selector: "subir-prueba-propia",
    templateUrl: "./subir-prueba-propia.component.html",
    styleUrls: ["./subir-prueba-propia.component.scss"]
})
export class SubirPruebaPropiaComponent implements OnInit {
    @Input() evaluacionTipo: string
    @Input() doNotShowLastStep: boolean = false
    @Output() saved = new EventEmitter<Instrumento>()
    @Input() showCompartirMasTarde: boolean = true
    openModal: EventEmitter<any> = new EventEmitter<any>()

    material: Material = new Material()
    asignaturaId: number
    tiempo = 0
    generadorInstrumento: GeneradorInstrumento
    generadorInstrumentos: GeneradorInstrumento[]
    curriculum: string

    file: File
    fileSrc: Uint8Array

    notPdf: boolean
    notAsignatura: boolean
    notGeneradorInstrumento: boolean
    notPauta: boolean

    showPreview: boolean = false
    showPauta: boolean = false
    showAsignaturas: boolean = false
    showContestableTipos: boolean = false
    showTerminos: boolean = false
    showClasificarPreguntas: boolean = false

    disableChangeContestableTipo = false

    numero_alternativas: number
    numero_preguntas: number
    maximoAlternativas: number

    askNumeroPreguntas: boolean = true

    fileParams = {}

    pauta: {
        letra: string
        piloto: boolean
        contestableTipo?: ContestableTipo
        cantidadAlternativas?: number
        puntuacion: number
    }[]
    contestableTipo: ContestableTipo
    calificacion_manual: boolean = false
    clasificacionesByPreguntaIndex: Clasificacion[][]

    terminosChecked: boolean = false

    uploading: number = 0
    lastStep: boolean = false
    instrumento: Instrumento

    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    config = config

    @ViewChildren("letraInput") letraInputs: QueryList<ElementRef>
    @ViewChild("mandatoryLoading") mandatoryLoading: ModalDirective

    @ViewChild("mandatoryLoadingTemplate", { static: true }) mandatoryLoadingTemplate: TemplateRef<any>
    mandatoryLoadingRef: BsModalRef
    configMandatoryLoading: ModalOptions = { backdrop: "static", keyboard: false, class: "modal-sm" }

    // DATOS COMPARTIR
    ultimaEvaluacionId: number
    imprimirAlCompartir: boolean = false
    canceled: boolean
    errorMsg: boolean
    evaluacionId: number
    @ViewChild("imprimiendoLoading") imprimiendoLoading: ModalDirective

    @ViewChild("imprimiendoLoadingTemplate", { static: true }) imprimiendoLoadingTemplate: TemplateRef<any>
    imprimiendoLoadingRef: BsModalRef
    configImprimiendoLoading: ModalOptions = { backdrop: "static", keyboard: false, class: "modal-sm" }
    skipPaso4: boolean = config.componentsInSubmodule?.subirPruebaPropia?.skipContestableTipos

    @ViewChild(ModalClasificarRecursoComponent) modalClasificarRecurso: ModalClasificarRecursoComponent

    constructor(
        protected s3Service: S3Service,
        protected materialTiposService: MaterialTipos,
        protected materialesService: Materiales,
        protected preguntasService: Preguntas,
        protected nebuAuthService: NebuAuthService,
        protected authService: AuthService,
        protected store: Store<State>,
        protected instrumentosService: Instrumentos,
        protected asignaturasService: Asignaturas,
        protected scrollToService: ScrollToService,
        protected router: Router,
        protected modalService: BsModalService,
        protected evaluacionesService: Evaluaciones,
        protected contestableTiposService: ContestableTipos
    ) {}

    ngOnInit() {
        this.initialize()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            changes["evaluacionTipo"] &&
            changes["evaluacionTipo"].currentValue !== null &&
            changes["evaluacionTipo"].currentValue !== undefined
        ) {
            this.curriculum = EvaluacionTipo.curriculumByTipo[this.evaluacionTipo]
        }
    }

    initialize() {
        this.pauta = new Array(80).fill(0).map(_ => ({ letra: "", piloto: false, puntuacion: 1 }))

        this.material.visible = false

        this.materialTiposService.where({ material_tipo: { material_tipo: "Instrumento" } }).then(materialTipos => {
            const materialTipoId = materialTipos[0].id
            this.material.material_tipo_id = materialTipoId
        })

        if (this.skipPaso4) {
            let tipos = ["Pregunta de alternativas", "Respuesta libre", "Respuesta archivo"]
            this.contestableTiposService
                .where({ contestable_tipo: { contestable_tipo: tipos } })
                .then(contestableTipos => {
                    this.contestableTipo = contestableTipos.find(ct => ct.contestable_tipo == tipos[0])
                    this.calificacion_manual = this.contestableTipo.contestable_tipo != "Pregunta de alternativas"
                })
        }
    }

    // TODO REFACTORIZAR
    onEvaluacionCompartida(e: Evaluacion) {
        this.ultimaEvaluacionId = e.id
        if (this.imprimirAlCompartir) {
            this.imprimir("alumno")
        }
    }

    // TODO REFACTORIZAR
    imprimir(perfil: string = null, evaluacionForma: EvaluacionForma = null) {
        this.canceled = false
        this.errorMsg = false
        // this.imprimiendoLoading.show();
        this.imprimiendoLoadingRef = this.modalService.show(
            this.imprimiendoLoadingTemplate,
            this.configImprimiendoLoading
        )
        const params: any = {}
        params.plataforma = config.plataforma.name

        if (perfil == "profesor") {
            params.profesor = 1
            params.group_by =
                config.evaluaciones[
                    this.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento
                ].clasificaciones.groupBy
            if (!this.instrumento.propio)
                params.clasificacion_tipos_tabla_spec =
                    config.evaluaciones[
                        this.instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento
                    ].clasificaciones.clasificacionTiposTablaEspecificaciones
        } else if (perfil == "alumno") {
            params.alumno = 1
        }

        if (evaluacionForma) {
            params.evaluacion_forma_id = evaluacionForma.id
        }

        const evaluacionId = this.evaluacionId || this.ultimaEvaluacionId

        this.evaluacionesService.enableIgnoreModel()
        this.evaluacionesService.enableIgnoreCatch()
        this.evaluacionesService
            .imprimir(evaluacionId, params)
            .then((obj: any) => {
                if (this.canceled) {
                    throw new Error("Cancelado")
                }
                this.evaluacionesService.disableIgnoreModel()
                const info = obj.info
                this.imprimiendoLoadingRef.hide()
                const evaluacionFormaStr = evaluacionForma ? "_" + evaluacionForma.forma : ""
                const downloadString = "evaluacion_" + evaluacionId + "_forma_" + evaluacionFormaStr + ".pdf"
                this.createLinkAndOpen(info.link, downloadString)
            })
            .catch(response => {
                this.evaluacionesService.disableIgnoreModel()
                if (!this.canceled) {
                    this.errorMsg = true
                }
            })
    }

    // TODO REFACTORIZAR
    createLinkAndOpen(link, downloadString) {
        const a = document.createElement("a")
        a.download = downloadString
        a.href = link
        a.click()
    }

    cancelPrint() {
        this.canceled = true
    }

    uploadInstrumento(event) {
        if (event.accept) {
            this.numero_preguntas = this.numero_preguntas || event.numero_preguntas
            this.uploadToS3()
            this.mandatoryLoadingRef = this.modalService.show(
                this.mandatoryLoadingTemplate,
                this.configMandatoryLoading
            )
        }
    }

    uploadToS3() {
        this.s3Service.enableNebuBaseUrl()
        this.s3Service.changeAuthService(this.nebuAuthService)

        this.uploading = 1
        this.s3Service.where(this.material.getS3Params()).then(policy => {
            this.uploading = 2
            const p = policy as any as S3
            this.material.url = p["key"]

            this.s3Service.uploadToS3(p, this.material.file, this.materialesService.tableName, this.saveMaterial)

            this.s3Service.disableNebuBaseUrl()
            this.s3Service.restoreAuthService()
        })
    }

    createPregunta(
        material: Material,
        i: number,
        letra: string = null,
        contestableTipo: ContestableTipo = null,
        cantidadAlternativas: number = null
    ) {
        const contestableTipoPregunta = contestableTipo || this.contestableTipo
        const cantidadAlternativasPregunta = cantidadAlternativas || this.numero_alternativas

        const permittedLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

        const pregunta = new Pregunta(true)

        pregunta.asignatura_id = this.asignaturaId
        pregunta.estado = 1
        pregunta.visible = false
        pregunta.profesor_id = 7
        pregunta.encargado_reporte_id = 7

        const preguntaMaterial = new PreguntaMaterial()
        preguntaMaterial.material_id = material.id
        preguntaMaterial.orden = i
        pregunta.pregunta_material = preguntaMaterial

        // clasificaciones
        if (
            this.clasificacionesByPreguntaIndex &&
            this.clasificacionesByPreguntaIndex[i] &&
            this.clasificacionesByPreguntaIndex[i].length > 0
        ) {
            pregunta.clasificacion_preguntas = this.clasificacionesByPreguntaIndex[i].map(clasificacion => {
                let clasificacionPregunta = new ClasificacionPregunta()
                clasificacionPregunta.clasificacion_id = clasificacion.id

                return clasificacionPregunta
            })
        }

        // contestables
        const contestable = new Contestable(contestableTipoPregunta.id, 0)

        pregunta.contestables = [contestable]

        // alternativas
        if (contestableTipoPregunta.contestable_tipo == "Pregunta de alternativas") {
            const letraCorrecta = letra.toUpperCase()
            contestable.alternativas = []

            for (let j = 0; j < cantidadAlternativasPregunta; j++) {
                const letraAlternativa = permittedLetters[j]

                const alternativa = new Alternativa()
                alternativa.alternativa = "-"
                alternativa.letra = letraAlternativa.toUpperCase()
                alternativa.correcta = letraCorrecta == letraAlternativa
                alternativa.orden = j
                alternativa.ocultable = false

                contestable.alternativas.push(alternativa)
            }
        }

        return pregunta
    }

    saveMaterial = () => {
        this.material.asignatura_id = this.asignaturaId
        this.material.material = this.material.file.name
        this.uploading = 3

        let usuario = this.authService.getUserData()
        this.material["personaExterna"] = {
            usuario_id: usuario.id,
            nombre: usuario.nombre,
            apellido_paterno: usuario.apellido_paterno,
            apellido_materno: usuario.apellido_materno,
            plataforma_id: config.plataforma.id
        }
        delete this.material.file
        delete this.material.fileSrc
        this.materialesService.save(this.material).then((material: Material) => {
            let preguntas

            if (!this.contestableTipo) {
                preguntas = this.pauta
                    .filter(x => !!x.contestableTipo)
                    .map((obj, i) => {
                        return this.createPregunta(
                            material,
                            i,
                            obj.letra,
                            obj.contestableTipo,
                            obj.cantidadAlternativas
                        )
                    })
            } else if (this.contestableTipo.contestable_tipo == "Pregunta de alternativas") {
                preguntas = this.pauta
                    .filter(x => !!x.letra)
                    .map((obj, i) => {
                        return this.createPregunta(material, i, obj.letra)
                    })
            } else {
                preguntas = new Array(this.numero_preguntas).fill(null).map(() => this.createPregunta(material, 0))
            }
            let persona_externa = {
                usuario_id: usuario.id,
                nombre: usuario.nombre,
                apellido_paterno: usuario.apellido_paterno,
                apellido_materno: usuario.apellido_materno,
                plataforma_id: config.plataforma.id
            }
            this.uploading = 4
            this.preguntasService
                .save({ persona_externa: persona_externa, pregunta: preguntas } as any)
                .then((p: any) => {
                    const instrumento = new Instrumento()

                    instrumento.asignatura_id = this.asignaturaId
                    instrumento.generador_instrumento_id = this.generadorInstrumento.id
                    instrumento.numero_preguntas = p.length
                    instrumento.tiempo = this.tiempo
                    instrumento.numero_alternativas = this.numero_alternativas
                    instrumento.oficial = true
                    instrumento.categorias = "[]"
                    instrumento.usuario_id = this.authService.getUserData().id
                    instrumento.calificacion_manual = this.calificacion_manual
                    instrumento.tipo_contestables = this.contestableTipo?.contestable_tipo ?? "Mixto"

                    instrumento.instrumento_preguntas = p.map((pregunta, i) => {
                        const instrumentoPregunta = new InstrumentoPregunta()
                        instrumentoPregunta.pregunta_id = pregunta.id
                        instrumentoPregunta.orden = i
                        instrumentoPregunta.piloto =
                            !this.contestableTipo || this.contestableTipo.contestable_tipo == "Pregunta de alternativas"
                                ? this.pauta[i].piloto
                                : false
                        instrumentoPregunta.puntuacion = this.pauta[i].puntuacion

                        return instrumentoPregunta
                    })

                    const instrumentoMaterial = new InstrumentoMaterial()
                    instrumentoMaterial.material_id = material.id
                    instrumento.instrumento_material = instrumentoMaterial

                    this.uploading = 5
                    this.instrumentosService.savePropio(instrumento).then(i => {
                        this.uploading = 0

                        this.saved.emit(i)

                        this.instrumento = i
                        this.mandatoryLoadingRef.hide()
                        this.lastStep = true
                    })
                })
        })
    }

    restart() {
        this.file = undefined
        this.fileSrc = undefined
        this.instrumento = undefined
        this.pauta = undefined
        this.contestableTipo = undefined
        this.calificacion_manual = false
        this.lastStep = false
        this.showPreview = false
        this.showPauta = false
        this.showAsignaturas = false
        this.showContestableTipos = false
        this.showClasificarPreguntas = false
        this.showTerminos = false

        this.initialize()
    }

    /****** por componente ****++ */
    goToShowPreview(event) {
        this.showPreview = false
        this.showAsignaturas = false
        this.showContestableTipos = false
        this.showPauta = false
        this.showTerminos = false
        this.material = event.material
        this.setParams(event.typeFile)
        setTimeout(() => {
            this.showPreview = true
            this.scrollToStep("showPreview")
        }, 1)
    }

    setParams(typeFile) {
        if (typeFile == "application/pdf") {
            this.fileParams = { label: "Previsualizar", type: PdfView } //PdfView  DocsView
        } else {
            this.fileParams = { label: "Previsualizar", type: DocsView } //PdfView  DocsView
        }
    }

    goToAsignaturas() {
        this.showAsignaturas = true
        this.scrollToStep("showAsignaturas")
    }

    goToContestableTipos(event) {
        if (event.showContestableTipos == true) {
            this.tiempo = event.tiempo
            this.generadorInstrumento = event.generadorInstrumento
            this.maximoAlternativas = event.generadorInstrumento.maximo_alternativas
            this.asignaturaId = event.asignaturaId
            this.tiempo = event.tiempo
            if (!this.skipPaso4) {
                this.showContestableTipos = true
                this.scrollToStep("showContestableTipos")
            } else {
                this.showContestableTipos = false
                this.goToPauta(event)
            }
        } else {
            this.showContestableTipos = false
        }
    }

    goToPauta(event) {
        if (event.showPauta == true) {
            this.showPauta = true
            this.scrollToStep("showPauta")
        } else {
            this.showPauta = false
        }
    }

    goToClasificarPreguntas(event) {
        this.clasificacionesByPreguntaIndex = undefined
        this.showClasificarPreguntas = event.showClasificarPreguntas
        if (event.pauta) {
            this.calificacion_manual = false
            this.pauta = event.pauta
            this.numero_alternativas = event.numero_alternativas
            if (event.numero_preguntas) {
                this.numero_preguntas = +event.numero_preguntas
            } else {
                this.numero_preguntas = event.pauta.filter(p => !!p.letra).length
            }
        }

        this.scrollToStep("showClasificarPreguntas")
    }

    goToTerminos(event) {
        this.showTerminos = event.showTerminos
        this.clasificacionesByPreguntaIndex = event.clasificacionesByPreguntaIndex
        if (event.numeroPreguntas) {
            this.numero_preguntas = event.numeroPreguntas
        }
        this.scrollToStep("showTerminos")
    }

    goToPautaOrClasificarPreguntas(event) {
        this.calificacion_manual = event.calificacion_manual
        this.contestableTipo = event.contestableTipo
        this.askNumeroPreguntas = false

        this.disableChangeContestableTipo =
            this.contestableTipo && this.contestableTipo.contestable_tipo !== "Pregunta de alternativas"
        if (this.askNumeroPreguntas) {
            this.numero_preguntas = undefined
        }
        if (event.showPauta == true) {
            this.showClasificarPreguntas = false
            this.goToPauta(event)
        } else {
            this.showPauta = false
            this.goToClasificarPreguntas(event)
        }
    }

    scrollToStep(data) {
        this.scrollToService.scrollTo(data)
    }

    goToHistory() {
        this.router.navigate(["/evaluaciones/resultados/historial"], {
            queryParams: { tipo_instrumento: this.evaluacionTipo, asignatura_id: this.asignaturaId }
        })
    }

    openModalClasificarPregunta() {
        this.modalClasificarRecurso.open()
    }
}
