import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core"
import { Store } from "@ngrx/store"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import {
    EvaluacionInstancia,
    EvaluacionInstancias,
    EvaluacionTiempos,
    Excels,
    PlanPersonales,
    Usuarios
} from "@puntaje/puntaje/api-services"
import { State } from "@puntaje/puntaje/store"
import { RoundFloatPipe } from "@puntaje/shared/core"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { FiltroEstadisticasService } from "../filtro_estadisticas.service"
import { TablaResumenEvaluacionPorAlumnoComponent } from "../tabla-resumen-evaluacion-por-alumno/tabla-resumen-evaluacion-por-alumno.component"

@Component({
    selector: "tabla-resumen-evaluacion-multiple-por-alumno",
    templateUrl: "../tabla-resumen-evaluacion-por-alumno/tabla-resumen-evaluacion-por-alumno.component.html",
    styleUrls: ["./tabla-resumen-evaluacion-multiple-por-alumno.component.scss"]
})
export class TablaResumenEvaluacionMultiplePorAlumnoComponent
    extends TablaResumenEvaluacionPorAlumnoComponent
    implements OnInit
{
    @Input() evaluacionInstanciasFromMultiples: { [id: number]: EvaluacionInstancia[] }

    evaluacionMultipleInstanciasIdByUsuarioId: unknown = {}
    evaluacionInstanciasByEMI: unknown = {}

    constructor(
        protected usuariosService: Usuarios,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected simpleModalService: SimpleModalService,
        protected excelService: Excels,
        protected cdr: ChangeDetectorRef,
        protected planPersonalesService: PlanPersonales,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected store: Store<State>,
        protected asignaturasService: Asignaturas
    ) {
        super(
            usuariosService,
            evaluacionTiemposService,
            simpleModalService,
            excelService,
            cdr,
            planPersonalesService,
            evaluacionInstanciasService,
            filtroEstadisticasService,
            store,
            asignaturasService
        )
    }

    async ngOnInit() {
        await this.getAsignaturas()

        this.evaluacionMultipleInstanciasIdByUsuarioId = this.allEvaluacionInstancias?.reduce(
            (acc, evaluacion_instancia) => {
                const usuario_id = evaluacion_instancia.usuario_id

                acc[usuario_id] = acc[usuario_id] || []
                if (!acc[usuario_id].includes(evaluacion_instancia.evaluacion_multiple_instancia_id)) {
                    acc[usuario_id].push(evaluacion_instancia.evaluacion_multiple_instancia_id)
                }

                return acc
            },
            {}
        )

        this.evaluacionInstanciasByEMI = this.allEvaluacionInstancias?.groupBy(
            ei => ei.evaluacion_multiple_instancia_id
        )

        super.ngOnInit()
    }

    /**
     * Retorna las asignaturas ids de las evaluacion_instancias.
     */
    asignaturasIds() {
        let asignaturasIds = this.allEvaluacionInstancias
            .map(ei => ei.evaluacion_instancia_asignaturas.map(eia => eia.asignatura_id))
            .flat()
        asignaturasIds = Array.from(new Set(asignaturasIds))

        return asignaturasIds
    }

    /**
     * Retorna los grupo usuarios donde se compartió la evaluacion.
     */
    grupoUsuarios() {
        let grupoUsuarios
        grupoUsuarios = this.evaluacion.evaluacion_multiple_usuarios
        grupoUsuarios = grupoUsuarios.filter(eu => eu.grupo_usuario).map(eu => eu.grupo_usuario)

        return grupoUsuarios
    }

    /**
     * Retorna los labels del excel que se genera al exportar la tabla.
     */
    labelsExcel() {
        let asignaturasLabels = {}
        asignaturasLabels = this.asignaturas.reduce((acc, asignatura) => {
            acc["asignatura_" + asignatura.id] = asignatura.asignatura
            return acc
        }, {})

        const labels = {
            colegio: "Colegio",
            curso: "Curso",
            evaluacion_id: "ID Evaluación",
            evaluacion_nombre: "Nombre Evaluación",
            rut: "Rut",
            nombre: "Nombre",
            correctas: "Correctas",
            incorrectas: "Incorrectas",
            omitidas: "Omitidas",
            puntaje: "Puntaje",
            ...asignaturasLabels,
            tiempo: "Tiempo",
            fecha: "Fecha"
        }

        return labels
    }

    /**
     * Agrega distintos datos de evaluacion, instancia, grupo usuario, alumno a la variable data.
     *
     * @param data - variable con los datos de la tabla html
     * @param evaluacionInstancia - evaluacion instancia oficial del alumno
     * @param usuario - usuario a quien se le compartio la evaluacion
     * @param grupoUsuario - grupo usuario del alumno
     */
    setDataEvaluacion(data, evaluacionInstancia, usuario, grupoUsuario) {
        this.setDataEvaluacionGeneral(data, grupoUsuario)
        this.setDataInstanciaOficial(data, evaluacionInstancia)

        data.instanciasSimulacro = []
        Object.keys(this.evaluacionInstanciasFromMultiples).forEach(valor =>
            data.instanciasSimulacro.push(this.evaluacionInstanciasFromMultiples[valor][0])
        )

        const evaluacionInstanciasAcumuladas = []
        this.evaluacionMultipleInstanciasIdByUsuarioId[usuario.id].forEach(key => {
            const instancia = {
                id: key,
                correctas: 0,
                incorrectas: 0,
                omitidas: 0,
                calificacion: 0,
                tiempo_ocupado: 0,
                oficial: true,
                created_at: null,
                escala: null,
                usuario_id: usuario.id
            }
            const instanciasAcumuladas = this.evaluacionInstanciasByEMI[key].reduce(function (prev, current) {
                prev.correctas += current.correctas
                prev.incorrectas += current.incorrectas
                prev.omitidas += current.omitidas
                prev.calificacion += current.calificacion
                prev.tiempo_ocupado += current.tiempo_ocupado
                prev.oficial = prev.oficial && current.oficial
                prev.created_at =
                    new Date(current.created_at) > new Date(prev.created_at) ? current.created_at : prev.created_at
                prev.escala = current.escala || prev.escala

                return prev
            }, instancia)
            evaluacionInstanciasAcumuladas.push(instanciasAcumuladas)
        })
        this.setDataAsignaturas(data, evaluacionInstancia)
        this.setDataInstancias(data, evaluacionInstanciasAcumuladas, usuario, evaluacionInstancia)
    }

    /**
     * Agrega las calificaciones por asignatura de la evaluacion instancia a la variable data.
     */
    setDataAsignaturas(data, evaluacionInstancia) {
        this.evaluacionInstanciasByEMI[evaluacionInstancia.evaluacion_multiple_instancia_id].forEach(ei => {
            ei.evaluacion_instancia_asignaturas.forEach(evaluacionInstanciaAsignatura => {
                const asignatura = this.asignaturas.find(asig => asig.id == evaluacionInstanciaAsignatura.asignatura_id)
                const key = "asignatura_" + asignatura.id
                data[key] = data[key] || 0
                data[key] += evaluacionInstanciaAsignatura.calificacion
            })
        })

        const roundFloat = new RoundFloatPipe()
        this.asignaturas.forEach(asignatura => {
            const key = "asignatura_" + asignatura.id
            data[key] = +roundFloat.transform(data[key])
        })
    }

    setDataGeneral(data, a, grupoUsuario, usuario) {
        super.setDataGeneral(data, a, grupoUsuario, usuario)
        data.evaluacionMultiple = true
    }

    /**
     * Retorna las evaluacion_instancias de la evaluacion multiple instancia Old y New al cambiar la instancia oficial.
     *
     * @returns arreglo [...evaluacion_instancias old, ...evaluacion_instancias new]
     */
    instanciasOldNewOficial(): any[] {
        let evaluacion_instancias = super.instanciasOldNewOficial()
        const instanciaOldOficial = evaluacion_instancias[0]
        const instanciaNewOficial = evaluacion_instancias[1]

        evaluacion_instancias = [
            this.evaluacionInstanciasByEMI[instanciaOldOficial.id].map(instancia => {
                instancia.oficial = instanciaOldOficial.oficial
                return instancia
            }),
            this.evaluacionInstanciasByEMI[instanciaNewOficial.id].map(instancia => {
                instancia.oficial = instanciaNewOficial.oficial
                return instancia
            })
        ].flat()

        return evaluacion_instancias
    }

    /**
     * Retorna el id del generador instrumento de la evaluacion multiple.
     *
     * @returns generador instrumento id
     */
    generadorInstrumentoId() {
        return (this.evaluacion as any).instrumento_multiple.generador_instrumento_id
    }

    /**
     * Retorna el id de la asignatura de la evaluacion, pero como una evaluacion multiple tiene
     * multiples asignaturas entonces se retorna 0.
     *
     * @returns 0
     */
    asignaturaId() {
        return 0
    }
}
