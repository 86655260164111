import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "noticias-profesor.component.html"
})
export class NoticiasProfesorComponent implements OnInit {
    sub: Subscription
    noticiaId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.noticias.noticias_profesor")
    }

    ngOnDestroy() {}
}
