<cui-section-headline class="headline">Previsualizando: {{ planPersonal.plan_personal }}</cui-section-headline>
<div
    class="content-session"
    *ngFor="let planPersonalSesion of planPersonal.plan_personal_ciclos[0].plan_personal_sesiones; let i = index"
>
    <label class="numero-sesion">Sesión {{ i + 1 }}: {{ planPersonalSesion.plan_personal_sesion }}</label>
    <br />
    <div class="content-contenidos">
        <ng-template ngFor let-ppc [ngForOf]="planPersonalSesion.plan_personal_contenidos">
            <plan-clase-contenido-box
                class="box"
                [planPersonalContenido]="ppc"
                [materialTipos]="materialTipos"
                [showbuttonDelete]="false"
            ></plan-clase-contenido-box>
        </ng-template>
    </div>
</div>
