import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AppConfig, I18nService, ToggleExpandableDirective } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"
import { GrupoUsuario, GrupoUsuarios, GrupoUsuarioUsuarios, Usuario, Usuarios } from "@puntaje/puntaje/api-services"
import { BuscadorUsuarioService } from "./buscador-usuario/buscador_usuario.service"

@Component({
    selector: "info-grupo-usuarios-profesor",
    templateUrl: "info_grupo_usuarios_profesor.component.html",
    styleUrls: ["info_grupo_usuarios_profesor.component.scss"]
})
export class InfoGrupoUsuariosProfesorComponent {
    config: typeof config = config
    usuarios: Usuario[]
    totalUsuarios: number
    showDetails: boolean = false
    enableUserId: boolean = config.plataforma.userIdTablaGrupoUsuarios
    pais: string = config.plataforma.pais
    idPais: string[] = config.plataforma.identificadorUsuario
    aliases = config.plataforma?.identificadorUsuarioAlias
    nombreId: string = this.idPais.map(id => this.aliases?.[id] || id).join("/")
    enableCURPestudiantes: boolean = config.plataforma.enableCURPestudiantes || false
    @Input() searchParams: any

    @ViewChild("loadingLayoutUsers", { static: true }) loadingLayoutUsers: LoadingLayoutComponent
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    mappingCantidadAlumnos: { [k: string]: string } = {
        "=1": "alumno",
        other: "alumnos"
    }

    @ViewChild("buttonToExpand", { read: ToggleExpandableDirective }) buttonToExpand: ToggleExpandableDirective

    _grupoUsuario: GrupoUsuario
    @Input()
    set grupoUsuario(grupoUsuario: GrupoUsuario) {
        this._grupoUsuario = grupoUsuario
    }
    get grupoUsuario() {
        return this._grupoUsuario
    }

    constructor(
        protected usuariosService: Usuarios,
        protected grupoUsuariosService: GrupoUsuarios,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected router: Router,
        protected route: ActivatedRoute,
        protected authService: AuthService,
        protected simpleModalService: SimpleModalService,
        protected i18nService: I18nService,
        protected usuarioBuscadorService: BuscadorUsuarioService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.loadingLayout.ready()
    }

    verUsuariosFun() {
        return (page: number = 1, per: number = 10) => {
            let params = {
                sort_by: "apellido_paterno",
                page: page,
                per: per,
                grupo_usuario_usuario: {
                    grupo_usuario_id: this.grupoUsuario.id,
                    propietario: 0,
                    establecimiento: { activo: 1 }
                },
                methods: "[count_logins,fecha_ultimo_login]"
            }
            if (Object.keys(this.searchParams ?? {}).length > 0) {
                params = { ...params, ...this.searchParams }
            }
            return this.usuariosService.where(params).then((usuarios: Usuario[], total: number) => {
                this.usuarios = usuarios
                this.totalUsuarios = total
                this.loadingLayoutUsers.ready()

                this.cdr.detectChanges()

                if (Object.keys(this.searchParams ?? {}).length > 0) {
                    this.buttonToExpand.forceOpen()
                }

                return total
            })
        }
    }

    resetAllPass(gu: GrupoUsuario) {
        this.simpleModalService.cleanModal()
        this.simpleModalService.setModalHeader(
            this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_passwords.title", {})
        )
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_passwords.q1", {}) +
                gu.nombre +
                this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_passwords.q2", {})
        )
        this.simpleModalService.setModalCallback(() => this.confirmResetAllPass(gu))
        this.simpleModalService.showSimpleModal()
    }

    confirmResetAllPass(gu: GrupoUsuario) {
        this.loadingLayout.standby()
        this.simpleModalService.closeSimpleModal()
        this.grupoUsuariosService.reset_alum_pass(gu.id).then(() => {
            this.simpleModalService.cleanModal()
            this.simpleModalService.setModalHeader(
                this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_passwords2.title", {})
            )
            this.simpleModalService.setModalStringContent(
                this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_passwords2.a1", {}) +
                    this.idPais[0].toLowerCase() +
                    this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_passwords2.a2", {})
            )
            this.loadingLayout.ready()
            this.simpleModalService.showSimpleModal()
        })
    }

    resetPassword(u) {
        this.simpleModalService.setModalHeader(
            this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_password.title", {})
        )
        this.simpleModalService.setModalStringContent(
            this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_password.q1", {}) +
                u.nombreCompleto() +
                this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_password.q2", {})
        )
        this.simpleModalService.setModalCallback(() => this.confirmResetPasssword(u))
        this.simpleModalService.showSimpleModal()
    }

    confirmResetPasssword(u) {
        console.log(this.idPais)
        console.log(this.nombreId)
        let usuario = new Usuario()
        delete usuario.usuario_chile
        delete usuario.usuario_colombia

        let pass: string

        for (let i = 0; i < this.idPais.length; i++) {
            let idPais = u["usuario_" + this.pais][this.idPais[i].toLowerCase()]
            if (idPais) {
                pass = idPais.substring(0, 6)
                break
            }
        }

        if (!pass) {
            setTimeout(() => {
                this.simpleModalService.setModalHeader(
                    this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_password2.title", {})
                )
                this.simpleModalService.setModalStringContent(
                    this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_password2.a1", {}) +
                        this.nombreId +
                        this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_password2.a2", {})
                )
                this.simpleModalService.showSimpleModal()
            }, 500)
        } else {
            usuario.password = pass
            usuario.password_confirmation = pass
            usuario.id = u.id
            usuario.manual_reset_password = true
            this.usuariosService.update(u.id, usuario).then(u => {
                this.simpleModalService.setModalHeader(
                    this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_password2.title", {})
                )
                this.simpleModalService.setModalStringContent(
                    this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_password2.a3", {}) +
                        this.nombreId +
                        this.i18nService.translate("grupo_usuarios.profesores.info.modals.restart_password2.a4", {})
                )
                this.simpleModalService.showSimpleModal()
            })
        }
    }

    identificador() {
        let id = config.plataforma.identificadorUsuario[0]
        return this.aliases?.[id] || id
    }

    run(u: Usuario) {
        var ide = this.identificador().toLowerCase()
        var run
        if (u["usuario_" + config.plataforma.pais]) {
            run = u["usuario_" + config.plataforma.pais][ide]
        }
        return run
    }
}
