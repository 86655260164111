<div class="row">
    <div class="col-md-4">
        <select class="form-control" [(ngModel)]="establecimiento" (change)="changeEstablecimiento()">
            <option [ngValue]="undefined">-- {{ 'progreso.profe.seleccione' | t }} {{ 'progreso.profe.establecimiento' | t }} --</option>
            <option *ngFor="let establecimiento of establecimientos" [ngValue]="establecimiento">
                {{ establecimiento.establecimiento }}
            </option>
        </select>
    </div>
    <div class="col-md-4">
        <select class="form-control" [(ngModel)]="grupoUsuario" (change)="changeGrupo()">
            <option [ngValue]="undefined">-- {{ 'progreso.profe.seleccione' | t }} {{ 'progreso.profe.curso' | t }} --</option>
            <option *ngFor="let grupoUsuario of grupoUsuarios" [ngValue]="grupoUsuario">{{ grupoUsuario }}</option>
        </select>
    </div>
    <div class="col-md-4">
        <select class="form-control" [(ngModel)]="alumno" (change)="changeAlumno()">
            <option [ngValue]="undefined">-- {{ 'progreso.profe.seleccione' | t }} {{ 'progreso.profe.alumno' | t }} --</option>
            <option *ngFor="let alumno of usuarios" [ngValue]="alumno">{{ alumno }}</option>
        </select>
    </div>
</div>
