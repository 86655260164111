<form action="" class="form-inline filtros-form">
    <div class="form-group">
        <label class="label-periodo" for="periodo">{{ "libro_notas.periodo" | t }}:</label>
        <filtro-periodo (select)="onSelectPeriodo($event)"></filtro-periodo>
    </div>
    <div class="form-group">
        <label class="label-gu-alias" for="curso">{{ grupoUsuarioAlias | capitalize }}:</label>
        <select [(ngModel)]="selectedCurso" (ngModelChange)="onSelectCurso()" class="form-control" name="curso">
            <option *ngFor="let curso of cursos" [ngValue]="curso">
                {{ curso.establecimiento.establecimiento }} - {{ curso.nombre }}
            </option>
        </select>
    </div>
    <div class="clearfix pull-right" *ngIf="!disableReforzamiento">
        <div>
            <span class="verde"><b>R</b></span>
            : {{ "libro_notas.alumno_realizo" | t }}
        </div>
        <div>
            <span class="rojo"><b>R</b></span>
            : {{ "libro_notas.alumno_aun_no_realizo" | t }}
        </div>
    </div>
</form>
<loading-layout #loadinglayout>
    <div *ngIf="data && !(alumnos.length == 0 || tareas.length == 0)">
        <div class="buttons-right">
            <div class="search-eval-input-message">
                <div class="search-eval-input">
                    <label [attr.for]="'evaluacion'" class="label-eval">{{ "libro_notas.evaluacion" | t }}</label>
                    <autocomplete
                        [placeholder]="'Nombre o #ID'"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="evaluacionSearch"
                        [options]="evaluacionesSearch"
                        (filterChange)="getEvaluacionesSearch($event)"
                        showTextFun="toStringWithId"
                        class="autocomplete"
                        [autoSelect]="false"
                    ></autocomplete>

                    <cui-button (click)="searchPageByEvaluacion()" size="xsmall" class="button-eval">
                        <fa name="search" class="icono left-icon"></fa>
                        {{ "libro_notas.buscar_pagina" | t }}
                    </cui-button>
                </div>
                <span *ngIf="evalSearchError" class="search-error-message">{{ evalSearchError }}</span>
            </div>
            <cui-button (click)="downloadCSV()" size="xsmall" class="button-download">
                <fa name="download" class="icono left-icon"></fa>
                {{ "libro_notas.csv" | t }}
            </cui-button>
        </div>
        <div class="buttons">
            <button [disabled]="!hasPrevTareas" class="btn" (click)="prevTareas()">
                <fa name="long-arrow-left"></fa>
            </button>
            <div>
                <div class="form-inline">
                    <label for="page" class="label-tarea-page">
                        {{ totalTareas }} {{ "libro_notas.tareas_pagina" | t }}
                    </label>
                    <select
                        [(ngModel)]="tareasPage"
                        (ngModelChange)="onSelectTareasPage()"
                        class="form-control select-tarea-page"
                        name="page"
                        [disabled]="totalTareasPages == 0"
                    >
                        <option *ngFor="let page of tareasPages" [ngValue]="page">
                            {{ page }}
                        </option>
                    </select>
                    <span>{{ "libro_notas.de" | t }} {{ totalTareasPages }}</span>
                </div>
            </div>
            <button [disabled]="!hasNextTareas" class="btn" (click)="nextTareas()">
                <fa name="long-arrow-right"></fa>
            </button>
        </div>
    </div>
    <p *ngIf="!cursos || cursos.length == 0" class="alert alert-warning">{{ "libro_notas.not_found_cursos" | t }}.</p>
    <p
        *ngIf="cursos && cursos.length > 0 && data && (alumnos.length == 0 || tareas.length == 0)"
        class="alert alert-warning"
    >
        {{ "libro_notas.not_found2" | t }}.
    </p>
    <div class="table-responsive" *ngIf="data && !(alumnos.length == 0 || tareas.length == 0)">
        <table class="table table-striped table-bordered tabla-notas">
            <thead>
                <tr>
                    <th class="top-left">{{ "libro_notas.estudiantes" | t }}</th>
                    <th *ngFor="let tarea of tareas">
                        <div>
                            <div [title]="tarea.evaluacion" class="tarea-nombre">
                                <a (click)="goToResults(tarea, selectedCurso)" class="tarea-link">
                                    {{ tarea.evaluacion }}
                                </a>
                                <span class="tarea-id">#{{ tarea.id }}</span>
                            </div>
                            <div class="sumativa" *ngIf="tarea.sumativa != null">
                                <div *ngIf="tarea.sumativa">
                                    <p>{{ "libro_notas.sumativa" | t }}</p>
                                </div>
                                <div *ngIf="!tarea.sumativa">
                                    <p>{{ "libro_notas.formativa" | t }}</p>
                                </div>
                            </div>
                            <div class="tarea-fecha">
                                <span class="verde"><ogr-icon name="clock-start"></ogr-icon></span>
                                {{ "libro_notas.inicio" | t }}: {{ tarea.fecha_inicio | date: "dd/MM/yyyy HH:mm" }}
                            </div>
                            <div class="tarea-fecha">
                                <span class="rojo"><ogr-icon name="clock-start"></ogr-icon></span>
                                {{ "libro_notas.fin" | t }}: {{ tarea.fecha_termino | date: "dd/MM/yyyy HH:mm" }}
                            </div>
                        </div>
                    </th>
                    <th class="text-center">
                        {{ "libro_notas.promedio" | t }}
                        <i
                            class="fa fa-question-circle"
                            [tooltip]="'libro_notas.promedio_tooltip_formativas' | t"
                            aria-hidden="true"
                        ></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let alumno of alumnos">
                    <td>
                        <span class="alumno-nombre">{{ alumno.nombreCompleto() }}</span>
                    </td>
                    <td class="resultado-td" *ngFor="let tarea of tareas">
                        <span class="resultado">
                            <span class="dummy-resultado"></span>
                            <ng-template
                                [ngIf]="data[alumno.id] && data[alumno.id][tarea.id]?.instancia"
                                [ngIfElse]="showGuion"
                            >
                                <a
                                    class="calificacion"
                                    [routerLink]="[
                                        '/ensayo_instancias_estudiante',
                                        data[alumno.id][tarea.id]?.instancia.id
                                    ]"
                                >
                                    <evaluacion-escalas-globales
                                        *ngIf="
                                            data[alumno.id][tarea.id]?.escalas &&
                                            data[alumno.id][tarea.id]?.escalas.length > 0
                                        "
                                        [escalasGlobales]="data[alumno.id][tarea.id]?.escalas"
                                        [contenedor]="'libro'"
                                        [attr.tooltip]="'libro_notas.promedio_tooltip_escalas_globales' | t"
                                    ></evaluacion-escalas-globales>
                                    <ng-container
                                        *ngIf="
                                            data[alumno.id][tarea.id]?.escalas == undefined ||
                                            data[alumno.id][tarea.id]?.escalas.length == 0
                                        "
                                    >
                                        {{ data[alumno.id][tarea.id]?.instancia?.calificacion | roundfloat: 1 }}
                                    </ng-container>
                                </a>
                            </ng-template>
                            <ng-template #showGuion>-</ng-template>

                            <span
                                *ngIf="data[alumno.id] && data[alumno.id][tarea.id]?.reforzamientoCompartido"
                                [ngClass]="{
                                    rojo: !data[alumno.id][tarea.id]?.reforzamientoRealizado,
                                    verde: data[alumno.id][tarea.id]?.reforzamientoRealizado
                                }"
                            >
                                <b>R</b>
                            </span>
                            <span
                                class="dummy-resultado"
                                *ngIf="!(data[alumno.id] && data[alumno.id][tarea.id]?.reforzamientoCompartido)"
                            ></span>
                        </span>
                    </td>
                    <td>
                        <span class="resultado">
                            <span class="calificacion">{{ promediosPorAlumno[alumno.id] | roundfloat: 1 }}</span>
                        </span>
                    </td>

                    <!-- <td class="resultado-td" *ngFor="let resultado of alumno.resultados">

          </td> -->
                </tr>
                <tr>
                    <td class="text-center">{{ "libro_notas.promedios" | t }}</td>
                    <td *ngFor="let tarea of tareas">
                        <span class="resultado">
                            <span class="calificacion">{{ promediosPorTarea[tarea.id] | roundfloat: 1 }}</span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <paginator *ngIf="cursos && cursos.length > 0 && showPaginador" [getList]="this.setData.bind(this)"></paginator>
</loading-layout>
