<loading-layout #loadingLayout>
    <estadisticas-portada
        [titulo]="'Informe de Curso'"
        [evaluacionTipo]="evaluacionTipo"
        [establecimiento]="establecimiento"
        [asignatura]="asignatura"
        [grupoUsuario]="grupoUsuario"
        [estadisticas]="estadisticas"
    ></estadisticas-portada>
    <div class="row">
        <div class="col-md-12">
            <div class="big-title-margin">
                <div class="big-title-container big-title-green">
                    <div class="big-title-left">
                        <span class="big-title-icon"><fa [name]="'users'"></fa></span>
                    </div>
                    <h2 class="big-title-text">
                        Resultados para curso:
                        <span class="big-title-color">{{ grupoUsuario.nombre }}</span>
                    </h2>
                </div>
            </div>
            <div *ngIf="enableGraphs">
                <tabla-promedio-general-y-asignaturas
                    class="print-page-break-after"
                    [promedioGeneral]="promedioGeneral"
                    [promedioAsignaturas]="promedioAsignaturas"
                    [tipoInstrumento]="evaluacionTipo"
                ></tabla-promedio-general-y-asignaturas>

                <sticky-top>
                    <menu-asignaturas-horizontal
                        [asignaturaIds]="asignaturaIds"
                        [enableAllBtn]="true"
                        [tipoAsignaturas]="evaluacionTipo"
                        [enableResponsive]="true"
                        [enableMiniMode]="true"
                        (asignaturaObjChanged)="onChangeAsignatura($event)"
                    ></menu-asignaturas-horizontal>
                </sticky-top>

                <grafico-distribucion-tramo-puntaje
                    class="print-page-break-after"
                    [asignatura]="asignatura"
                    [data]="distribucion_ensayos_tramo_puntaje[asignaturaId]"
                    [tipoEvaluacion]="evaluacionTipo"
                    [tipoGrupo]="'cursos'"
                ></grafico-distribucion-tramo-puntaje>

                <ng-template ngFor let-cte [ngForOf]="clasificacionTiposEstadisticas">
                    <ng-template [ngIf]="cte.compuesto">
                        <grafico-desempeno-clasificacion-compuesto
                            class="print-page-break-after"
                            [evaluacionTipo]="evaluacionTipo"
                            [tipoClasificacion]="cte.nombreMostrable || cte.nombre"
                            [asignatura]="asignatura"
                            [clasificaciones]="this['desempeno_' + cte.nombre][asignaturaId]"
                            [dataOmitidas]="this['desempeno_' + cte.nombre + '_omitidas'][asignaturaId]"
                            [dataIncorrectas]="this['desempeno_' + cte.nombre + '_incorrectas'][asignaturaId]"
                            [dataCorrectas]="this['desempeno_' + cte.nombre + '_correctas'][asignaturaId]"
                            [dataTotal]="this['desempeno_' + cte.nombre + '_total'][asignaturaId]"
                        ></grafico-desempeno-clasificacion-compuesto>
                    </ng-template>

                    <ng-template [ngIf]="!cte.compuesto">
                        <grafico-desempeno-clasificacion
                            class="print-page-break-after"
                            [tipoClasificacion]="cte.nombreMostrable || cte.nombre"
                            [asignatura]="asignatura"
                            [clasificaciones]="this['desempeno_' + cte.nombre][asignaturaId]"
                            [data]="this['desempeno_' + cte.nombre + '_correctas'][asignaturaId]"
                            [dataTotal]="this['desempeno_' + cte.nombre + '_total'][asignaturaId]"
                        ></grafico-desempeno-clasificacion>
                    </ng-template>
                </ng-template>

                <!-- <grafico-distribucion-tramo-puntaje class="print-page-break-after" [asignatura]="asignatura" [data]="distribucion_ensayos_tramo_puntaje" [tipoGrupo]="'cursos'"></grafico-distribucion-tramo-puntaje> -->

                <!--<grafico-desempeno-materia-curso [asignatura]="asignatura" [dataOmitidas]="desempeno_materia_omitidas" [dataIncorrectas]="desempeno_materia_incorrectas" [dataCorrectas]="desempeno_materia_correctas"></grafico-desempeno-materia-curso>-->

                <!-- <grafico-desempeno-promedio class="print-page-break-after" [asignatura]="asignatura" [dataOmitidas]="desempeno_promedio_omitidas" [dataIncorrectas]="desempeno_promedio_incorrectas" [dataCorrectas]="desempeno_promedio_correctas"></grafico-desempeno-promedio> -->

                <!-- <tabla-desempeno-por-usuario-clasificacion class="print-page-break-after" *ngFor="let ct of clasificacionTipos" [usuarios]="grupoUsuario.usuarios" [estadisticaUsuarios]="estadisticaUsuarios" [clasificacionTipo]="ct"></tabla-desempeno-por-usuario-clasificacion> -->

                <!-- <ng-template ngFor let-cte [ngForOf]="clasificacionTiposEstadisticas">
					<ng-template [ngIf]="cte.compuesto">
						<grafico-desempeno-clasificacion-compuesto class="print-page-break-after" [tipoClasificacion]="cte.nombreMostrable || cte.nombre" [asignatura]="asignatura" [clasificaciones]="this['desempeno_' + cte.nombre]" [dataOmitidas]="this['desempeno_' + cte.nombre + '_omitidas']" [dataIncorrectas]="this['desempeno_' + cte.nombre + '_incorrectas']" [dataCorrectas]="this['desempeno_' + cte.nombre + '_correctas']" [dataTotal]="this['desempeno_' + cte.nombre + '_total']"></grafico-desempeno-clasificacion-compuesto>
					</ng-template>

					<ng-template [ngIf]="!cte.compuesto">
						<grafico-desempeno-clasificacion class="print-page-break-after" [tipoClasificacion]="cte.nombreMostrable || cte.nombre" [asignatura]="asignatura" [clasificaciones]="this['desempeno_' + cte.nombre]" [data]="this['desempeno_' + cte.nombre + '_correctas']" [dataTotal]="this['desempeno_' + cte.nombre + '_total']"></grafico-desempeno-clasificacion>
					</ng-template>
				</ng-template> -->

                <!-- <grafico-comparacion-puntaje-grupos [evaluacionTipo]="evaluacionTipo" [asignatura]="asignatura" [grupos]="comparacion_alumnos" [data]="comparacion_promedios" [tipoGrupo]="'alumnos'"></grafico-comparacion-puntaje-grupos> -->
            </div>
        </div>
    </div>
</loading-layout>
