<loading-layout #loadingLayout>
    <estadisticas-multiples-portada
        [titulo]="'Informe de Evaluación de Alumno'"
        [evaluacionTipo]="evaluacionTipo"
        [evaluacionMultiple]="evaluacionMultiple"
        [establecimiento]="establecimiento"
        [asignatura]="asignatura"
        [grupoUsuario]="grupoUsuario"
        [usuario]="usuario"
        [estadisticas]="estadisticas"
    ></estadisticas-multiples-portada>
    <div class="row">
        <div class="col-md-12">
            <div class="big-title-margin">
                <div class="big-title-container big-title-green">
                    <div class="big-title-left">
                        <span class="big-title-icon"><fa [name]="'users'"></fa></span>
                    </div>
                    <h2 class="big-title-text">
                        Resultados para alumno:
                        <span class="big-title-color">{{ usuario.nombreCompleto() }}</span>
                        con evaluación:
                        <span class="big-title-color">{{ evaluacionMultiple.evaluacion_multiple }}</span>
                    </h2>
                </div>
            </div>
            <div *ngIf="enableGraphs">
                <!-- <tabla-promedio-general-y-asignaturas [promedioGeneral]="promedioGeneral" [promedioAsignaturas]="promedioAsignaturas" [tipoInstrumento]="evaluacionTipo" ></tabla-promedio-general-y-asignaturas> -->

                <!-- <menu-asignaturas-horizontal [asignaturaIds]="asignaturaIds" [enableAllBtn]="true" [tipoAsignaturas]="evaluacionTipo" [enableResponsive]="true" [enableMiniMode]="true" (asignaturaObjChanged)="onChangeAsignatura($event)"></menu-asignaturas-horizontal> -->
                <!-- No lo puse en un componente porque no sé que otra cosa va a ir en este componente -->
                <div *ngIf="true">
                    <cui-section-headline>Calificacion Alumno</cui-section-headline>
                    <p class="tutorial">Tabla con los resultados del alumno por asignatura y general.</p>
                    <table class="table print-page-break-avoid">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Asignatura</th>
                                <th scope="col">Calificacion</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let asignaturaId of calificacionAsignaturas | keys">
                                <td>{{ asignaturasById[asignaturaId]?.asignatura }}</td>
                                <td>{{ calificacionAsignaturas[asignaturaId] | roundfloat }}</td>
                            </tr>
                            <tr>
                                <td><b>General</b></td>
                                <td>{{ !noResults ? (calificacionGeneral | roundfloat) : "-" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
