<div class="contentPrincipalMateriales">
    <label>Materiales</label>
    <div class="materiales" *ngFor="let material of materiales">
        <div class="colum_uno"><ogr-icon [name]="getIcon(material.material_tipo_nombre)" class="icon"></ogr-icon></div>
        <div class="colum_dos">
            <a href="javascript:void(0)" (click)="materialPreviewModal.open(material)">
                {{ material.material }}
            </a>
            <div class="warning-material" *ngIf="disponibleProfesor(material)">Disponible solo para docentes</div>
        </div>
        <div class="colum_tres">
            <cui-button class="btn_style" (click)="selectMaterial(material)">Seleccionar</cui-button>
        </div>
    </div>
    <div *ngIf="materiales && materiales.length == 0">No hay materiales para mostrar.</div>
</div>

<material-preview-modal #materialPreviewModal></material-preview-modal>
