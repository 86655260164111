import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    EstadisticasMultiplesComponent,
    EstadisticasComponent,
    ProgresoComponent,
    ResumenGeneralComponent,
    ResumenUsoComponent
} from "@puntaje/puntaje/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const estadisticasRoutes: Routes = [
    {
        path: "estadisticas/resumen_uso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ResumenUsoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "resumen_general",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ResumenGeneralComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const estadisticasRouting = RouterModule.forChild(estadisticasRoutes)
