<div>
    <loading-layout #loadingLayout>
        <cui-section-headline>{{ "estadisticas.info.title" | t }}</cui-section-headline>
        <table class="table table-striped tabla_estadisticas">
            <thead>
                <tr>
                    <th>{{ "estadisticas.info.cursos" | t }}</th>
                    <!-- Parametrizar por tipo de instrumento en algun momento-->
                    <ng-container *ngIf="evaluacionTipo === 'curricular'; else default">
                        <th>N° de Pruebas</th>
                    </ng-container>
                    <ng-template #default>
                        <th>{{ "estadisticas.info.n_ensayos" | t }}</th>
                    </ng-template>
                    <th>{{ "estadisticas.info.promedio" | t }}</th>
                    <th>{{ "estadisticas.info.acciones" | t }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let grupoUsuario of establecimiento.grupo_usuarios | orderBy: 'nombre'">
                    <th>{{ grupoUsuario.nombre }}</th>
                    <td>{{ groupedEstadisticas[grupoUsuario.id].numero_evaluaciones }}</td>
                    <td>{{ groupedEstadisticas[grupoUsuario.id].promedio | roundfloat }}</td>
                    <td>
                        <cui-button (click)="onGrupoUsuarioClick(grupoUsuario)" class="btn_style">
                            <fa [name]="'bar-chart'"></fa>
                        </cui-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </loading-layout>
</div>
