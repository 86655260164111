import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges } from "@angular/core"
import {
    GrupoUsuario,
    GrupoUsuarios,
    PlanPersonal,
    PlanPersonales,
    PlanPersonalUsuario,
    EvaluacionUsuario
} from "@puntaje/puntaje/api-services"
import { PopupGrupoUsuariosComponent } from "@puntaje/puntaje/new-modules/shared"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Router } from "@angular/router"
import {
    GeneradorInstrumento,
    GeneradorInstrumentos,
    TipoInstrumento,
    Asignatura
} from "@puntaje/nebulosa/api-services"

@Component({
    selector: "aplicar-plan-personal-profesores",
    templateUrl: "./aplicar-plan-personal-profesores.component.html",
    styleUrls: ["./aplicar-plan-personal-profesores.component.scss"]
})
export class AplicarPlanPersonalProfesoresComponent implements OnInit {
    private _planPersonal: PlanPersonal
    @Input() titulo: string = "Aplicar Planificación"
    @Input() titulo_grupos: string = "a los cuales aplicar la planificación:"
    @Input() mensaje_sin_grupos: string = "Usted no puede aplicar una planificación: No posee"
    @Input() nombre_submit: string = "Aplicar"
    @Input() urlNavigate: string = "/planificaciones/aplicadas"
    @Input() modalId: string = "plan-personal"
    @Input() closeOnFinished: boolean = false
    @Input() enableReplicar: boolean = true
    @Input() planPersonalUsuarios: PlanPersonalUsuario[]

    grupoUsuarios: GrupoUsuario[]
    gruposSeleccionados: GrupoUsuario[]
    gruposPreseleccionados: GrupoUsuario[]
    planPersonalCompartido: PlanPersonal

    @ViewChild(PopupGrupoUsuariosComponent, { static: true }) popupGrupoUsuarios: PopupGrupoUsuariosComponent

    sharing: boolean = false
    nombreGrupo = config.plataforma.grupoUsuarioAlias

    tipoInstrumento: TipoInstrumento
    asignatura: Asignatura

    @Output() planCompartidoEvent = new EventEmitter()

    @Input()
    get planPersonal() {
        return this._planPersonal
    }

    set planPersonal(pp: PlanPersonal) {
        this._planPersonal = pp

        const params = { include: "[tipo_instrumento,asignatura]" }
        this.generadorInstrumentosService
            .find(this.planPersonal.generador_instrumento_id, params)
            .then(generadorInstrumento => {
                this.asignatura = generadorInstrumento.asignatura
                this.tipoInstrumento = generadorInstrumento.tipo_instrumento
            })
    }

    constructor(
        protected grupoUsuariosService: GrupoUsuarios,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected router: Router,
        protected planPersonalesService: PlanPersonales,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        const params = {
            establecimiento_id_not_nil: 1,
            activos: 1,
            propios: 1,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            grupo_usuario: {
                privado: 1
            },
            grupo_usuario_usuario: {
                propietario: 1
            },
            establecimiento: {
                activo: 1
            }
        }

        this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[]) => {
            this.grupoUsuarios = grupoUsuarios

            if (this.planPersonalUsuarios) {
                this.setGruposPreseleccionados()
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["planPersonalUsuarios"] && this.grupoUsuarios) {
            this.setGruposPreseleccionados()
        }
    }

    setGruposPreseleccionados() {
        this.gruposPreseleccionados = this.grupoUsuarios.filter(grupoUsuario =>
            this.planPersonalUsuarios.find(ppu => ppu.receptor_id == grupoUsuario.id)
        )
    }

    aplicar(gruposSeleccionados: GrupoUsuario[]) {
        this.sharing = true

        if (this.planPersonal) {
            this.compartirPlanPersonal(this.planPersonal, gruposSeleccionados).then((pp: PlanPersonal) => {
                this.sharing = false
                this.planCompartido(pp)

                if (this.closeOnFinished) {
                    this.popupGrupoUsuarios.hide()
                } else {
                    this.gruposSeleccionados = gruposSeleccionados
                    this.planPersonalCompartido = pp

                    setTimeout(() => {
                        this.popupGrupoUsuarios.hide()
                        this.router.navigate([this.urlNavigate], {
                            queryParams: {
                                tipo_instrumento: this.tipoInstrumento.tipo_instrumento,
                                asignatura_id: this.asignatura.id
                            }
                        })
                    }, 10000)
                }
            })
        }
    }

    compartirPlanPersonal(planPersonal: PlanPersonal, gruposSeleccionados: GrupoUsuario[]) {
        const gruposToAdd = gruposSeleccionados.filter(gp => !this.gruposPreseleccionados.find(gu => gu.id == gp.id))

        const gruposToDelete = this.gruposPreseleccionados.filter(gp => !gruposSeleccionados.find(gu => gu.id == gp.id))

        const planPersonalUsuariosToAdd = gruposToAdd.map((grupoUsuario: GrupoUsuario) => {
            const ppu = new PlanPersonalUsuario()
            ppu.usuario_id = this.authService.getUserData().id
            ppu.receptor_type = "GrupoUsuario"
            ppu.receptor_id = grupoUsuario.id

            return ppu
        })

        const planPersonalUsuariosToDelete = gruposToDelete
            .map((grupoUsuario: GrupoUsuario) => {
                const savedPpus = this.planPersonalUsuarios.filter(ppu => ppu.receptor_id == grupoUsuario.id)

                return savedPpus.map(savedPpu => {
                    const ppu = new PlanPersonalUsuario()
                    ppu.id = savedPpu.id
                    ;(ppu as any)._destroy = true

                    return ppu
                })
            })
            .flat()

        planPersonal.plan_personal_usuarios = planPersonalUsuariosToAdd.concat(planPersonalUsuariosToDelete)

        planPersonal.plan_personal_ciclos.forEach(ciclo => {
            ciclo.plan_personal_sesiones.forEach((sesion, i) => {
                sesion.orden = i
                sesion.plan_personal_contenidos.forEach(contenido => {
                    if (contenido.evaluacion) {
                        const evaluacionUsuariosToAdd = gruposToAdd.map((grupoUsuario: GrupoUsuario) => {
                            const eu = new EvaluacionUsuario()
                            eu.usuario_id = this.authService.getUserData().id
                            eu.receptor_type = "GrupoUsuario"
                            eu.receptor_id = grupoUsuario.id

                            return eu
                        })

                        const evaluacionUsuariosToDelete = gruposToDelete
                            .map(gu =>
                                contenido.evaluacion.evaluacion_usuarios.filter(
                                    eu => eu.receptor_type == "GrupoUsuario" && eu.receptor_id == gu.id
                                )
                            )
                            .flat()
                            .map((evaluacionUsuario: EvaluacionUsuario) => {
                                const eu = new EvaluacionUsuario()
                                eu.id = evaluacionUsuario.id
                                ;(eu as any)._destroy = true

                                return eu
                            })

                        contenido.evaluacion.evaluacion_usuarios =
                            evaluacionUsuariosToAdd.concat(evaluacionUsuariosToDelete)

                        contenido.evaluacion.fecha_inicio = sesion.fecha_inicial
                        if (this.enableReplicar) {
                            contenido.evaluacion.fecha_mostrar_respuestas = sesion.fecha_pauta
                            contenido.evaluacion.fecha_termino = sesion.fecha_final
                        } else {
                            contenido.evaluacion.fecha_mostrar_respuestas = sesion.fecha_inicial
                            contenido.evaluacion.fecha_termino = null
                        }
                    }
                })
            })
        })

        if (this.enableReplicar) {
            return this.planPersonalesService.save(planPersonal)
        }

        if (!this.enableReplicar) {
            return this.planPersonalesService.update(planPersonal.id, planPersonal)
        }

        return null
    }

    open() {
        this.popupGrupoUsuarios.open()
    }

    planCompartido(pp: PlanPersonal) {
        this.planCompartidoEvent.emit(pp)
    }
}
