<div class="row">
    <div class="col-sm-6">
        <h3 class="h3_sub_title_2">Simulacros</h3>
        <p class="info">
            Comparte simulacros Saber 11 predefinidos con tus estudiantes y monitorea sus resultados y progreso.
        </p>
        <menu-grid-layout [menuItems]="menuItemsCol1" [maxColumns]="1"></menu-grid-layout>
    </div>
    <div class="col-sm-6 with-separator">
        <h3 class="h3_sub_title_2">Simulacros por asignatura</h3>
        <p class="info clearfix">
            <span *ngIf="asignaturas" class="iconos-asignaturas">
                <ogr-icon *ngFor="let a of asignaturas" [name]="a.icono" class="icono {{ a.clase }}"></ogr-icon>
            </span>
            Comparte simulacros predefinidos por asignatura con tus estudiantes y monitorea sus resultados y progreso.
        </p>
        <menu-grid-layout [menuItems]="menuItemsCol2" [maxColumns]="1"></menu-grid-layout>
    </div>
</div>
