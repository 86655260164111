import { Component, OnInit, Input } from "@angular/core"
import { Evaluacion } from "@puntaje/puntaje/api-services"

@Component({
    selector: "thumbnail-evaluacion",
    templateUrl: "./thumbnail-evaluacion.component.html",
    styleUrls: ["./thumbnail-evaluacion.component.scss"]
})
export class ThumbnailEvaluacionComponent implements OnInit {
    @Input() evaluacion: Evaluacion

    constructor() {}

    ngOnInit() {}
}
