import { Component, OnInit } from "@angular/core"
import { EstadisticasComponent } from "../estadisticas.component"
import { ActivatedRoute, Router } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { FiltroEstadisticasService } from "@puntaje/puntaje/new-modules/estadisticas"
import { LoggedLayoutService } from "@puntaje/shared/cui"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "estadisticas-multiples",
    templateUrl: "./estadisticas-multiples.component.html",
    styleUrls: ["./estadisticas-multiples.component.scss"]
})
export class EstadisticasMultiplesComponent extends EstadisticasComponent implements OnInit {
    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected authService: AuthService,
        protected store: Store<State>,
        protected filtroEstadistica: FiltroEstadisticasService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(titleService, route, store, authService, router, filtroEstadistica, loggedLayoutService)
    }

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas_multiples")
        this.sub = this.route.queryParams.subscribe(params => {
            this.tipoInstrumento = params["tipo_instrumento"] || this.tipoInstrumento
        })
    }
}
