import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

import { Establecimiento, GrupoUsuario } from "@puntaje/puntaje/api-services"

import { FiltroEstadisticasService } from "./filtro_estadisticas.service"

@Component({
    selector: "informacion-general-grupos",
    templateUrl: "informacion_general_grupos.component.html",
    styleUrls: ["informacion_general_grupos.component.scss"]
})
export class InformacionGeneralGruposComponent implements OnInit {
    @Input() asignaturaId: number
    @Input() evaluacionTipo: string
    @Input() estadisticas: any
    @Input() establecimiento: Establecimiento

    groupedEstadisticas: any

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    constructor(private filtroEstadisticasService: FiltroEstadisticasService) {}

    ngOnInit() {
        let groupBy = (arr: any[], f: Function) => {
            return arr.reduce((acc, x) => {
                acc[f(x)] = acc[f(x)] || []
                acc[f(x)].push(x)

                return acc
            }, {})
        }

        this.groupedEstadisticas = groupBy(this.estadisticas, e => e.grupo_usuario_id)
        this.establecimiento.grupo_usuarios.forEach(gu => {
            if (this.groupedEstadisticas[gu.id]) {
                this.groupedEstadisticas[gu.id] = this.groupedEstadisticas[gu.id].reduce((acc, e) => {
                    acc.numero_evaluaciones = acc.numero_evaluaciones || 0
                    acc.promedio = acc.promedio || 0

                    let numero_evaluaciones = e.numero_evaluaciones + acc.numero_evaluaciones
                    acc.promedio =
                        (acc.numero_evaluaciones * acc.promedio + e.numero_evaluaciones * e.promedio) /
                        numero_evaluaciones
                    acc.numero_evaluaciones = numero_evaluaciones

                    return acc
                }, {})
            } else {
                this.groupedEstadisticas[gu.id] = { promedio: 0, numero_evaluaciones: 0 }
            }
        })

        this.loadingLayout.ready()
    }

    onGrupoUsuarioClick(grupoUsuario) {
        this.filtroEstadisticasService.setFiltrosId(
            grupoUsuario.establecimiento_id,
            grupoUsuario.id,
            null,
            null,
            null,
            this.evaluacionTipo,
            this.asignaturaId
        )
        this.filtroEstadisticasService.onChangeParams()
    }
}
