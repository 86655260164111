import { Component, OnInit, Input, ViewChild } from "@angular/core"
import {
    Evaluacion,
    GrupoUsuario,
    EvaluacionForma,
    EvaluacionFormas,
    GrupoUsuarios,
    Usuarios,
    Usuario
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "tabla-evaluacion-forma-estudiantes",
    templateUrl: "tabla-evaluacion-forma-estudiantes.component.html",
    styleUrls: ["tabla-evaluacion-forma-estudiantes.component.scss"]
})
export class TablaEvaluacionFormaEstudiantesComponent implements OnInit {
    @Input() evaluacion: Evaluacion
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("paginator") paginator: PaginatorComponent
    grupos: any[]
    showFilter: boolean = false
    nombreGrupo: string = "Curso"
    grupoUsuario: GrupoUsuario
    estudiantes: Usuario[]
    formas: any

    constructor(
        protected grupoUsuariosService: GrupoUsuarios,
        protected evaluacionFormasService: EvaluacionFormas,
        protected usuariosService: Usuarios
    ) {}

    ngOnInit() {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias || "Curso"
        if (this.evaluacion && this.evaluacion.evaluacion_usuarios)
            this.grupos = this.evaluacion.evaluacion_usuarios.filter(eu => eu.receptor_type == "GrupoUsuario")
        this.grupoUsuario = this.grupos[0].grupo_usuario
        this.showFilter = this.grupos && this.grupos.length > 1
    }

    setData = (page: number, per: number) => {
        if (this.loadingLayout) this.loadingLayout.standby()
        this.grupos = this.evaluacion.evaluacion_usuarios
            .filter(eu => eu.receptor_type == "GrupoUsuario")
            .map(eu => eu.receptor_id)
        const params = {
            sort_by: "apellido_paterno",
            page: page,
            per: per,
            grupo_usuario_usuario: {
                grupo_usuario_id: this.grupoUsuario.id,
                propietario: 0
            }
        }
        let usuariosTotal

        return this.usuariosService
            .where(params)
            .then((usuarios: Usuario[], total: number) => {
                this.estudiantes = usuarios

                usuariosTotal = total

                const params2 = {
                    evaluacion_forma: {
                        evaluacion_id: this.evaluacion.id
                    },
                    evaluacion_forma_usuario: {
                        usuario_id: usuarios.map(u => u.id)
                    },
                    include: "[evaluacion_forma_usuarios]"
                }

                return this.evaluacionFormasService.where(params2)
            })
            .then((evaluacionFormas: EvaluacionForma[]) => {
                this.formas = {}
                evaluacionFormas.forEach(ef => {
                    ef.evaluacion_forma_usuarios.forEach(efu => {
                        this.formas[efu.usuario_id] = { forma_id: ef.id, forma: ef.forma }
                    })
                })
                if (this.loadingLayout) this.loadingLayout.ready()
                return usuariosTotal
            })
    }

    reloadData() {
        if (this.paginator) this.paginator.changePage(1)
    }
}
