<div *ngIf="true">
    <cui-section-headline>Promedio Puntajes</cui-section-headline>
    <p class="tutorial">Tabla con los promedios de los puntajes por asignatura y general.</p>
    <table class="table print-page-break-avoid">
        <thead class="thead-dark">
            <tr>
                <th scope="col">Asignatura</th>
                <th scope="col">Promedio</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let asignatura of asignaturas">
                <td>{{ asignatura.asignatura }}</td>
                <td>{{ promedioAsignaturas[asignatura.id] | roundfloat }}</td>
            </tr>
            <tr>
                <td><b>General</b></td>
                <td>{{ promedioGeneral | roundfloat }}</td>
            </tr>
        </tbody>
    </table>
</div>
