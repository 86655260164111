import { Component, OnInit, ViewChild, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import {
    Noticia,
    Noticias,
    NoticiaView,
    Asociaciones,
    Asociacion,
    Establecimiento,
    Establecimientos,
    GrupoUsuario,
    GrupoUsuarios,
    GrupoUsuarioUsuarios
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AuthService, PaginatorComponent, I18nService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { VerNoticiaModalComponent } from "../ver-noticia-modal/ver-noticia-modal.component"

@Component({
    selector: "noticias-profesor",
    templateUrl: "noticias-profesor.component.html",
    styleUrls: ["noticias-profesor.component.scss"]
})
export class NoticiasProfesorComponent implements OnInit {
    noticias: Noticia[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    @ViewChild("noticiaModal", { static: true }) verNoticiaModal: VerNoticiaModalComponent
    @ViewChild("publicarModal", { static: true }) publicarModal: VerNoticiaModalComponent
    @ViewChild("editarModal", { static: true }) editarModal: VerNoticiaModalComponent
    selectedCurso
    openModal: EventEmitter<any> = new EventEmitter<any>()
    openPublish: EventEmitter<any> = new EventEmitter<any>()
    openEdit: EventEmitter<any> = new EventEmitter<any>()
    currentNoticia: Noticia
    hasNewsIcon: boolean = true
    config: typeof config = config
    fechaInicial: Date
    fechaFinal: Date
    asociacionesLoaded: boolean = false
    asociaciones: Asociacion[]
    establecimiento: Establecimiento
    establecimientos: Establecimiento[]
    userId: number = this.authService.getUserData().id
    disableTodosFiltro: boolean = config.plataforma?.disableTodosLosEstablecimientos || false
    grupoUsuarios: GrupoUsuario[]
    grupoUsuario: GrupoUsuario
    noticiaId: number
    showEdit: boolean = false

    constructor(
        protected noticiasService: Noticias,
        protected router: Router,
        protected simpleModalService: SimpleModalService,
        protected grupoUsuariosService: GrupoUsuarios,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected authService: AuthService,
        protected establecimientosService: Establecimientos,
        protected asociacionesService: Asociaciones,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {}

    showAll(page: number = 1, count: number = 20) {
        this.loadingLayout.standby()
        let params = {
            page: page,
            per: count,
            sort_by: "created_at",
            order: "desc",
            noticia_usuario: {
                usuario_id: this.userId,
                receptor_type: "GrupoUsuario"
            },
            render_options: {
                include: {
                    noticia_usuarios: {
                        include: {
                            grupo_usuario: null
                        }
                    }
                }
            }
        }
        if (this.grupoUsuario) {
            params.noticia_usuario["receptor_id"] = this.grupoUsuario.id
        }

        if (this.establecimiento && !this.grupoUsuario) {
            const gus = this.establecimiento.grupo_usuarios.map(u => u.id)
            params.noticia_usuario["receptor_id"] = gus
        }
        return this.noticiasService.where(params).then((response: Noticia[], total: number) => {
            this.noticias = response
            this.loadingLayout.ready()

            return total
        })
    }

    view(noticia: Noticia) {
        this.router.navigate(["noticias/" + noticia.id])
    }

    showNoticia(noticia: Noticia) {
        this.currentNoticia = noticia
        this.verNoticiaModal.showNoticia()
    }

    showEditarModal(noticia: Noticia) {
        this.noticiaId = noticia.id
        this.showEdit = true
        setTimeout(() => {
            this.openEdit.emit()
        }, 150)
    }

    deleteEvent(noticia: Noticia) {
        this.simpleModalService.setModalHeader(this.i18nService.translate("noticias.profe.confirmacion", {}))
        this.simpleModalService.setModalStringContent(
            this.i18nService.translate("noticias.profe.confirmacion_message", {})
        )
        this.simpleModalService.setModalCallback(() => this.delete(noticia.id))
        this.simpleModalService.showSimpleModal()
    }

    delete(id: number) {
        this.noticiasService.remove(id).then(response => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    cancelar() {
        this.showEdit = false
    }

    openPublishModal() {
        this.openPublish.emit()
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicial = fechaInicial
        this.fechaFinal = fechaFinal
        this.setData()
    }

    setData() {
        let params: any = {
            mios: 1,
            establecimiento: { activo: 1 },
            utp_establecimiento: 1,
            utp_asociacion: 1,
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            this.establecimiento = undefined
            this.grupoUsuarios = establecimientos[0]?.grupo_usuarios || undefined
            this.grupoUsuario = undefined
        })
    }

    onSelectEstablecimiento() {
        this.grupoUsuarios = this.establecimiento ? this.establecimiento.grupo_usuarios : undefined
        this.grupoUsuario = undefined
        this.paginator.reload()
    }

    onSelectGrupoUsuario() {
        this.paginator.reload()
    }
}
