<cui-section-headline>{{ 'evaluar.pasos.subir_archivo.header' | t }}:</cui-section-headline>
<div class="clearfix" id="step1">
    <div class="row">
        <div class="col-md-4">
            <div class="content-f">
                <div class="tutorial">
                    <div class="container-tutorial-icon">
                        <div class="tutorial-icon-pos">
                            <ogr-icon name="upload" align="top" class="icon"></ogr-icon>
                        </div>
                    </div>
                </div>
                <div class="content-text">
                    <h3 class="step">{{ 'evaluar.pasos.step' | t }} {{ step }}</h3>
                    <p class="tutorial-text">{{ 'evaluar.pasos.subir_archivo.message' | t }}</p>
                    <p class="tutorial-text-descripcion">
                        {{ 'evaluar.pasos.subir_archivo.desc' | t }}
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="form-group">
                <file
                    [(ngModel)]="material.file"
                    [xhr_upload]="xhrUpload"
                    key="file"
                    (change)="checkExtension()"
                ></file>
                <div
                    class="progress-bar"
                    [class.hide]="totalProgress == 0"
                    role="progressbar"
                    [ngStyle]="{ width: progressWidth }"
                    style="height: 20px"
                >
                    {{ progress }} {{ 'evaluar.pasos.de' | t }} {{ totalProgress }}
                </div>
                <div class="warning hide-warning" [class.show-warning]="wrongFileType">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>{{ 'evaluar.pasos.subir_archivo.error' | t }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix">
                <cui-button [disabled]="loadingMessages" class="btn_style pull-right" (click)="subirArchivo()">
                    <ogr-icon name="upload" align="text-top"></ogr-icon>
                    &nbsp;{{ 'evaluar.pasos.subir_archivo.button' | t }}
                </cui-button>
                <loader *ngIf="loadingMessages" class="pushed pull-right" loaderType="default-xsmall"></loader>
            </div>
        </div>
    </div>
</div>
