import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"

import {
    Establecimiento,
    GrupoUsuario,
    Usuario,
    Evaluacion,
    Estadisticas,
    EvaluacionMultiple
} from "@puntaje/puntaje/api-services"
import { FiltroEstadisticasMultiplesComponent } from "../filtro-estadisticas-multiples/filtro-estadisticas-multiples.component"
import { EstadisticasProfesorComponent } from "../estadisticas_profesor.component"
import { Asignaturas, Asignatura, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { BaseChart, PieChart, ColumnChart, StackedBarChart, BarChart } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "estadisticas-multiples-profesor",
    templateUrl: "./estadisticas-multiples-profesor.component.html",
    styleUrls: ["./estadisticas-multiples-profesor.component.scss"]
})
export class EstadisticasMultiplesProfesorComponent extends EstadisticasProfesorComponent implements OnInit {
    @ViewChild(FiltroEstadisticasMultiplesComponent)
    filtroEstadisticas: FiltroEstadisticasMultiplesComponent

    evaluacionMultiple: EvaluacionMultiple
    evaluacionesMultiples: EvaluacionMultiple[]
    evaluacionesMultiplesEstadisticas: number[][]

    showEstadisticasEvaluacionAlumno: boolean

    constructor(
        protected route: ActivatedRoute,
        protected asignaturasService: Asignaturas,
        protected estadisticasService: Estadisticas
    ) {
        super(route, asignaturasService, estadisticasService)
    }

    ngOnInit() {
        this.setData()
    }

    setData() {
        this.loadingLayout.standby()

        this.showEstadisticasCurso = false
        this.showEstadisticasCursos = false
        this.showEstadisticasEnsayo = false
        this.showEstadisticasEvaluacionAlumno = false

        this.loadingLayout.ready()
    }

    onFilter(filtros) {
        this.ready = false

        this.estadisticas = null
        this.estadisticaUsuarios = null

        this.establecimiento = filtros.establecimiento
        this.grupoUsuario = filtros.grupoUsuario
        this.usuario = filtros.usuario
        this.previousEvaluacion = filtros.previousEvaluacion
        this.evaluacion = filtros.evaluacion
        this.evaluacionMultiple = filtros.evaluacionMultiple
        this.generadorInstrumento = filtros.generadorInstrumento

        this.showEstadisticasCursos = !!(
            this.establecimiento &&
            !this.grupoUsuario &&
            !this.usuario &&
            !this.evaluacionMultiple
        )
        this.showEstadisticasCurso = !!(
            this.establecimiento &&
            this.grupoUsuario &&
            !this.usuario &&
            !this.evaluacionMultiple
        )
        this.showEstadisticasEnsayo = !!(
            (this.establecimiento || this.grupoUsuario) &&
            !this.usuario &&
            this.evaluacionMultiple
        )
        this.showEstadisticasEvaluacionAlumno = !!(
            (this.establecimiento || this.grupoUsuario) &&
            this.usuario &&
            this.evaluacionMultiple
        )

        let grupoUsuariosId =
            (this.grupoUsuario && this.grupoUsuario.id) || this.establecimiento.grupo_usuarios.map(gu => gu.id)
        let evaluacion = this.evaluacion

        this.evaluacionTipo = this.generadorInstrumento.tipo_instrumento.tipo_instrumento

        let params: any = {
            collection: "EstadisticaEvaluacionMultiple",
            estadistica: {
                oficial: 1,
                evaluacion_tipo: this.generadorInstrumento.tipo_instrumento.tipo_instrumento,
                generador_instrumento_id: this.generadorInstrumento.id
            }
        }
        params.estadistica["grupo_usuario_id"] = grupoUsuariosId
        this.evaluacionMultiple && (params.estadistica["evaluacion_multiple_id"] = this.evaluacionMultiple.id)
        params.clasificacion_methods =
            config.evaluaciones[this.generadorInstrumento.tipo_instrumento.tipo_instrumento].clasificaciones.methods

        this.estadisticasService.wherePost(params).then((estadisticas: any) => {
            this.estadisticas = estadisticas
        })

        if (this.grupoUsuario) {
            delete params.estadistica["evaluacion_id"]
            params.estadistica["usuario_id"] = this.grupoUsuario.usuarios.map(u => u.id)
            params.collection = "EstadisticaUsuario"

            this.estadisticasService.wherePost(params).then((estadisticas: any) => {
                this.estadisticaUsuarios = estadisticas
            })
        }
    }
}
