<ll-titulo class="print-hide">
    {{ "puntaje_core.evaluacion.titulo" | t: { nombreEvaluacion: evaluacion?.evaluacion } }}
</ll-titulo>

<datos-generales-evaluacion
    *ngIf="evaluacion"
    [evaluacion]="evaluacion"
    [groupedBy]="['curso', 'habilidad cognitiva', 'eje temático,eje temático']"
    class="print-hide"
></datos-generales-evaluacion>
<tabla-evaluacion-forma-estudiantes
    *ngIf="evaluacionFormas && evaluacionFormas.length > 0 && evaluacion"
    [evaluacion]="evaluacion"
></tabla-evaluacion-forma-estudiantes>
<evaluacion-portada *ngIf="evaluacion" [evaluacion]="evaluacion"></evaluacion-portada>

<datos-generales
    *ngIf="instrumento"
    [instrumento]="instrumento"
    [groupedBy]="['curso', 'habilidad cognitiva', 'eje temático,eje temático']"
></datos-generales>
<div class="row top-margin">
    <div class="col-md-4 inverted-col padding-bottom print-hide">
        <ng-container *ngIf="instrumento && !instrumento.instrumento_material">
            <opciones-instrumento
                *ngIf="instrumento"
                [evaluacionId]="evaluacion.id"
                [instrumento]="instrumento"
                [evaluacionFormas]="evaluacionFormas"
                [tipoId]="1"
                [scrollAndFollow]="true"
                [responsiveBreak]="991"
                [topPadding]="20"
                [bottomPadding]="20"
                [followTopReference]="followReference"
                [followBottomReference]="followReference"
            ></opciones-instrumento>
        </ng-container>
        <ng-container *ngIf="instrumento && instrumento.instrumento_material">
            <cui-tabs justifyContent="space-around">
                <cui-tab title="Pauta" ariaLabelledBy="pauta">
                    <pauta-prueba-propia [evaluacion]="evaluacion" [instrumento]="instrumento"></pauta-prueba-propia>
                </cui-tab>

                <cui-tab title="Compartir e imprimir" ariaLabelledBy="compartir">
                    <opciones-instrumento
                        *ngIf="instrumento"
                        [evaluacionId]="evaluacion.id"
                        [instrumento]="instrumento"
                        [evaluacionFormas]="evaluacionFormas"
                        [tipoId]="1"
                    ></opciones-instrumento>
                </cui-tab>
            </cui-tabs>
        </ng-container>
    </div>
    <div class="col-md-8 print-full-width" #followReference>
        <cui-section-headline class="print-hide">Vista previa</cui-section-headline>
        <instrumento
            (onNuevaPregunta)="nuevaPregunta()"
            [instrumento]="instrumento"
            [evaluacionFormas]="evaluacionFormas"
        ></instrumento>
    </div>
</div>
