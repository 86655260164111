import { Component, OnInit, Input } from "@angular/core"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    Usuario,
    GrupoUsuario,
    GrupoUsuarioUsuarios,
    GrupoUsuarioUsuario,
    Usuarios
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "opciones-usuario-admin",
    templateUrl: "./opciones-usuario-admin.component.html",
    styleUrls: ["./opciones-usuario-admin.component.scss"]
})
export class OpcionesUsuarioAdminComponent implements OnInit {
    @Input() usuario: Usuario
    @Input() grupoUsuario: GrupoUsuario
    pais: string
    idPais: any
    nombreId: string

    constructor(
        protected simpleModalService: SimpleModalService,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected usuariosService: Usuarios
    ) {}

    ngOnInit() {
        this.pais = config.plataforma.pais
        this.idPais = config.plataforma.identificadorUsuario
        let aliases = config.plataforma?.identificadorUsuarioAlias
        this.nombreId = this.idPais.map(id => aliases?.[id] || id).join("/")
    }

    deleteUsuarioFromGroup(propietario) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro de eliminar a " + this.usuario.nombreCompleto() + " de " + this.grupoUsuario.nombre + "?"
        )
        this.simpleModalService.setModalCallback(() => this.confirmDeleteUsuarioFromGroup(propietario))
        this.simpleModalService.showSimpleModal()
    }

    confirmDeleteUsuarioFromGroup(propietario) {
        this.grupoUsuarioUsuariosService
            .where({
                grupo_usuario_usuario: {
                    usuario_id: this.usuario.id,
                    grupo_usuario_id: this.grupoUsuario.id,
                    propietario: propietario
                }
            })
            .then((guus: GrupoUsuarioUsuario[]) => {
                guus.forEach(guu => {
                    this.grupoUsuarioUsuariosService.remove(guu.id).then(r => {
                        // this.paginator.reload();
                    })
                })
            })
    }

    resetPassword() {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea restablecer la contraseña de " + this.usuario.nombreCompleto() + "?"
        )
        this.simpleModalService.setModalCallback(() => this.confirmResetPasssword())
        this.simpleModalService.showSimpleModal()
    }

    confirmResetPasssword() {
        let usuario = new Usuario()
        delete usuario.usuario_chile
        delete usuario.usuario_colombia

        let pass: string

        if (this.idPais instanceof Array) {
            for (let i = 0; i < this.idPais.length; i++) {
                let idPais = this.usuario["usuario_" + this.pais][this.idPais[i].toLowerCase()]
                if (idPais) {
                    pass = idPais.substring(0, 6)
                    break
                }
            }
        } else {
            let idPais = this.usuario["usuario_" + this.pais][this.idPais.toLowerCase()]
            if (idPais) {
                pass = idPais.substring(0, 6)
            }
        }

        usuario.password = pass
        usuario.password_confirmation = pass
        usuario.id = this.usuario.id
        usuario.manual_reset_password = true

        this.usuariosService.update(this.usuario.id, usuario).then(u => {
            this.simpleModalService.setModalHeader("Aviso")
            this.simpleModalService.setModalStringContent(
                "La contraseña ha sido restablecida exitosamente a los primeros 6 dígitos del " + this.nombreId
            )
            this.simpleModalService.showSimpleModal()
        })
    }
}
