<div class="clearfix" id="step1">
    <div class="row">
        <div class="col-md-5">
            <div class="content-f">
                <div class="tutorial">
                    <div class="container-tutorial-icon">
                        <div class="tutorial-icon-pos">
                            <ogr-icon name="prueba" align="top" class="icon"></ogr-icon>
                        </div>
                    </div>
                </div>
                <div class="content-text">
                    <h3 class="step">{{ "evaluar.pasos.step" | t }} 4</h3>
                    <p class="tutorial-text">{{ "evaluar.pasos.sel_tipo.message" | t }}</p>
                    <p class="tutorial-text-descripcion">{{ "evaluar.pasos.sel_tipo.desc" | t }}</p>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="contenedor_selects">
                <div class="elemento"></div>
                <div class="elemento1">
                    <ng-container *ngFor="let ct of contestableTipos">
                        <div class="radio" *ngIf="contestableTiposExplicacion[ct.contestable_tipo]">
                            <label>
                                <input
                                    id="ct-radio-{{ ct.id }}"
                                    type="radio"
                                    name="radio-contestableTipos"
                                    [value]="ct"
                                    [(ngModel)]="contestableTipo"
                                />
                                <span class="nombre">
                                    <b>{{ contestableTiposNombre[ct.contestable_tipo] }}</b>
                                </span>
                                <br />
                                <span class="explicacion">{{ contestableTiposExplicacion[ct.contestable_tipo] }}</span>
                            </label>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="form-group">
                <div class="warning hide-warning" [class.show-warning]="validationSelects">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>{{ "evaluar.pasos.sel_tipo.missing" | t }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix">
                <cui-button class="btn_style pull-right" (click)="sendData()">
                    <ogr-icon name="ticket" align="text-top"></ogr-icon>
                    &nbsp;{{ "evaluar.pasos.sel_tipo.button" | t }}
                </cui-button>
            </div>
        </div>
    </div>
</div>
