import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core"
import { Clasificacion } from "@puntaje/nebulosa/api-services"
import { GenericModalComponent } from "@puntaje/shared/core"

@Component({
    selector: "modal-clasificar-recurso",
    templateUrl: "./modal-clasificar-recurso.component.html",
    styleUrls: ["./modal-clasificar-recurso.component.scss"]
})
export class ModalClasificarRecursoComponent implements OnInit {
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent

    @Input() recursoEducativo: string = "pregunta"
    @Input() clasificacionesSeleccionadas: Clasificacion[] = []
    @Input() curriculum: string = "LGE"
    @Input() asignaturaId: number
    @Output() clasificacionesChange: EventEmitter<Clasificacion[]> = new EventEmitter<Clasificacion[]>()

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {}

    open() {
        this.openModal.emit()
    }

    onAccept() {
        this.genericModal.close()
    }

    updateClasificaciones(event) {
        this.clasificacionesChange.emit(event)
    }

    // ngOnChanges(changes: SimpleChanges) {
    //     if (
    //         changes &&
    //         changes["curriculum"] &&
    //         changes["curriculum"].currentValue !== null &&
    //         changes["curriculum"].currentValue !== undefined
    //     ) {
    //         this.cdr.detectChanges()
    //     }
    // }
}
