<loading-layout>
    <datos-generales-plan-clase *ngIf="planPersonal" [planPersonal]="planPersonal"></datos-generales-plan-clase>
    <div class="modelo-container" *ngIf="enableModelo">
        <cui-button (click)="selectModelo()">{{ "estudiar.plan_de_clases.select_modelo" | t }}</cui-button>
    </div>

    <div class="row" *ngIf="onlyShared && grupoUsuarios">
        <div class="col-md-12" *ngIf="grupoUsuarios.length > 0">
            <cui-section-headline>{{ "estudiar.plan_de_clases.cursos_comp" | t }}:</cui-section-headline>
            <ul>
                <li *ngFor="let gu of grupoUsuarios">
                    {{ gu.nombre }}
                </li>
            </ul>
        </div>
        <div class="col-md-12" *ngIf="grupoUsuarios.length == 0">
            <cui-section-headline>{{ "estudiar.plan_de_clases.cursos_comp" | t }}:</cui-section-headline>
            {{ "estudiar.plan_de_clases.not_comp" | t }}
        </div>
    </div>

    <!--pop up para compartir los planes de clase-->
    <ng-container *ngIf="showButtonCompartir && planPersonal">
        <aplicar-plan-personal-profesores
            [titulo]="titulo"
            [titulo_grupos]="titulo_grupos"
            [mensaje_sin_grupos]="mensaje_sin_grupos"
            [planPersonal]="planPersonal"
            [nombre_submit]="nombre_submit"
            [urlNavigate]="urlNavigate"
            [enableReplicar]="false"
            [planPersonalUsuarios]="planPersonalUsuarios"
        ></aplicar-plan-personal-profesores>
    </ng-container>

    <div class="clearfix" *ngIf="showButtonCompartir && planPersonal">
        <cui-button class="btn-primary btn_style2 pull-right" (click)="aplicar()">
            {{
                grupoUsuarios.length === 0
                    ? ("estudiar.plan_de_clases.compartir_plan" | t)
                    : ("estudiar.plan_de_clases.editar_comp" | t)
            }}
        </cui-button>
    </div>
    <!------------------------------------------------->
    <br />
    <div class="row">
        <div class="col-md-6">
            <cursos-plan-clase
                *ngIf="enableFiltroCurso && grupoUsuarios"
                [cursos]="grupoUsuarios"
                (cursosSeleccionadoChange)="onSelectGrupoUsuario($event)"
            ></cursos-plan-clase>
        </div>
        <div class="container-btn-replicar col-md-6">
            <ng-container *ngIf="!vistaPrevia && planPersonal && grupoUsuario && enableReplicar">
                <replicar-plan
                    *ngIf="grupoUsuarios && grupoUsuario"
                    [planPersonal]="planPersonal"
                    [grupoUsuarios]="grupoUsuarios"
                    [grupoUsuarioOrigen]="grupoUsuario"
                ></replicar-plan>
            </ng-container>
        </div>
    </div>
    <br />

    <ng-container *ngIf="planPersonal || (planPersonal && vistaPrevia)">
        <barra-avance-plan-clase *ngIf="!vistaPrevia" [planPersonal]="planPersonal"></barra-avance-plan-clase>
        <!--Plan semanal -->
        <div *ngFor="let planPersonalSesion of planPersonalSesiones; let i = index">
            <plan-clase-semanas
                [planPersonal]="planPersonal"
                [planPersonalSesion]="planPersonalSesion"
                [index]="i"
                [clasificacionActividadByMaterialId]="clasificacionActividadByMaterialId"
                [clasificacionContextoByMaterialId]="clasificacionContextoByMaterialId"
                [grupoUsuarioId]="grupoUsuario?.id"
                (avanceChange)="avanceChanged($event)"
                [vistaPrevia]="vistaPrevia"
                [actividad]="actividad"
                [urlNavigate]="urlNavigate"
            ></plan-clase-semanas>
        </div>
    </ng-container>
</loading-layout>
