import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Asignatura, Asignaturas, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import {
    Instrumento,
    Instrumentos,
    AsignaturaEvaluacionTipo,
    InstrumentoPredefinido
} from "@puntaje/puntaje/api-services"
import { State, selectSelectedAsignaturaEvaluacionTipo, selectAsignaturasById } from "@puntaje/puntaje/store"
import { Observable, Subscription } from "rxjs"
import { filter, first } from "rxjs/operators"
import { select, Store } from "@ngrx/store"

declare let $

@Component({
    selector: "generar-ensayo-profesor-dynamic-tabs",
    templateUrl: "generar_ensayo_profesor_dynamic_tabs.component.html",
    styleUrls: ["generar_ensayo_profesor_dynamic_tabs.component.scss"]
})
export class GenerarEnsayoProfesorDynamicTabsComponent implements OnInit, OnChanges, AfterViewChecked, AfterViewInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("tabGenerar") tabGenerar: ElementRef
    @ViewChild("tabRecomendados") tabRecomendados: ElementRef
    @ViewChild("tabBuscador") tabBuscador: ElementRef
    @ViewChild("tabSubirPruebaPropia") tabSubirPruebaPropia: ElementRef
    @ViewChild("tabRepositorioPruebasPropias") tabRepositorioPruebasPropias: ElementRef
    @Input() asignaturaId: number
    @Input() rutaEvaluaciones: string
    @Input() enableGenerador: boolean = config.generar?.generar
    @Input() enableRecomendados: boolean = config.generar?.intrumentosRecomendados
    @Input() enableBuscador: boolean = config.generar?.buscador
    @Input() enableSubirPruebaPropia: boolean = config.generar?.pruebasPropias
    @Input() enableRepositorioPruebasPropias: boolean = config.generar?.pruebasPropias
    @Input() defaultTab: string = ""
    @Input() enableReporte: boolean = true
    @Input() showTutorial: boolean = true
    @Input() enableEditRecomendado: boolean = false
    @Input() showButtonGuardar: boolean = true
    @Input() showCompartirMasTarde: boolean = true
    @Input() evaluacionTipoAlias: string
    @Input() redirect: string
    @Input() compartirTodosInstrumentos: boolean = false
    
    instrumentoPredefinidoSelected: InstrumentoPredefinido
    enableOpcionesPregunta: boolean = true
    asignatura: Asignatura
    instrumentoAsignatura: Asignatura
    @Output() onReadyAsignatura: EventEmitter<any> = new EventEmitter<any>()
    noTabs: boolean = false

    instrumento: Instrumento
    instrumentos: Instrumento[]
    selectedInstrumento: Instrumento

    nombreEvaluacion: string = ""
    fechaInicio: string = ""
    fechaTermino: string = ""
    fechaMostrarRespuestas: string = ""

    @Input() tipoEvaluacion: string
    config = config

    zonas: string[]
    zonasSize: { [key: string]: number } = {}
    showZonas = true

    @ViewChild("externalFrameReference", { read: ElementRef, static: true }) externalFrame: ElementRef
    enableScrollAndFollow: boolean = true

    asignaturaEvaluacionTipo: AsignaturaEvaluacionTipo
    sub: Subscription
    asignaturaEvaluacionTipo$ = this.store.pipe(select(selectSelectedAsignaturaEvaluacionTipo))
    asignaturasById: any

    constructor(
        protected route: ActivatedRoute,
        protected asignaturasService: Asignaturas,
        protected router: Router,
        protected instrumentosService: Instrumentos,
        protected cdr: ChangeDetectorRef,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        const asignaturasById$ = this.store.pipe(
            select(selectAsignaturasById),
            filter(x => !!x),
            first()
        )
        
        // Para esto no guardo la subscripción porque solo le llega el primer valor que tenga (por el first)
        asignaturasById$.subscribe(asignaturasById => {
            this.asignaturasById = asignaturasById
            this.asignaturaEvaluacionTipo =
                this.asignaturasById[this.asignaturaId].confByEvaluacionTipo[this.tipoEvaluacion]

            this.loadData()
        })

        this.loadData()
        this.checkIfTabs()
        this.zonas = config.instrumentosPredefinidos.zonas
        this.zonas.forEach(z => {
            this.zonasSize[z] = 1
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["tipoEvaluacion"]) {
            if (this.asignaturasById) {
                this.asignaturaEvaluacionTipo =
                    this.asignaturasById[this.asignaturaId].confByEvaluacionTipo[this.tipoEvaluacion]
                this.loadData()
            }
        }
    }

    ngAfterViewInit() {
        if (!this.noTabs) this.setDefaultTab()
    }

    ngAfterViewChecked() {
        this.enableScrollAndFollow = window.innerWidth > 991
    }

    getInstrumentoPredefinidos(instrumentoPredefinido: InstrumentoPredefinido) {
        this.instrumentoPredefinidoSelected = instrumentoPredefinido
    }

    callbackRecomendado(instrumento: Instrumento[]) {
        this.instrumento = null
        this.instrumentos = null
        this.instrumentoAsignatura = null

        setTimeout(() => {
            if (instrumento.length == 1) {
                this.instrumento = instrumento[0]
            } else {
                this.instrumentos = instrumento
                this.selectedInstrumento = this.instrumentos[0]
            }

            if (!this.enableEditRecomendado) {
                this.enableOpcionesPregunta = false
            }

            this.cdr.detectChanges()
        }, 5)
    }

    checkIfTabs() {
        this.noTabs =
            [
                this.enableBuscador,
                this.enableGenerador,
                this.enableRecomendados,
                this.enableSubirPruebaPropia,
                this.enableRepositorioPruebasPropias
            ].filter(v => v).length < 2
    }

    setDefaultTab() {
        const elementsByTab = {
            generar: this.tabGenerar,
            buscador: this.tabBuscador,
            recomendados: this.tabRecomendados,
            subirPruebaPropia: this.tabSubirPruebaPropia,
            pruebasPropias: this.tabSubirPruebaPropia
        }

        if (!elementsByTab[this.defaultTab]) {
            this.defaultTab = Object.keys(elementsByTab)
                .find(tab => !!elementsByTab[tab])
                .toString()
        }

        switch (this.defaultTab) {
            case "generar":
                ;(this.tabGenerar.nativeElement as HTMLElement).click()
                break

            case "buscador":
                ;(this.tabBuscador.nativeElement as HTMLElement).click()
                break

            case "recomendados":
                ;(this.tabRecomendados.nativeElement as HTMLElement).click()
                break

            case "subirPruebaPropia":
                ;(this.tabSubirPruebaPropia.nativeElement as HTMLElement).click()
                break

            case "pruebasPropias":
                ;(this.tabRepositorioPruebasPropias.nativeElement as HTMLElement).click()
                break

            default:
                if (this.tabGenerar) (this.tabGenerar.nativeElement as HTMLElement).click()
                break
        }
    }

    loadData() {
        this.loadingLayout.standby()
        if (this.asignaturaId) {
            this.asignaturasService
                .find(this.asignaturaId, {
                    tipo_instrumento: this.tipoEvaluacion,
                    generador_instrumento: { grupal: [0, 1] }
                })
                .then((asignatura: Asignatura) => {
                    this.asignatura = asignatura
                    this.onReadyAsignatura.emit(this.asignatura)
                    this.loadingLayout.ready()
                })
        }
    }

    cargarInstrumento(instrumentoTarget: number | Instrumento) {
        this.loadingLayout.standby()
        this.instrumento = null
        this.instrumentos = null
        this.instrumentoAsignatura = null
        this.enableOpcionesPregunta = true
        let targetId = typeof instrumentoTarget === "number" ? instrumentoTarget : instrumentoTarget.id

        this.instrumentosService
            .find(targetId, { with_generador_instrumento: 1, include: "[instrumento_material]" })
            .then((i: Instrumento) => {
                this.instrumento = i
            })
    }

    cargarInstrumentos(instrumentoSeleccionadoIds: Instrumento[]) {
        this.loadingLayout.standby()
        this.instrumento = null
        this.instrumentos = null
        this.instrumentoAsignatura = null

        let instrumentoIds = instrumentoSeleccionadoIds.map(i => i.id)
        this.instrumentosService
            .where({
                with_instrumento_preguntas: 1,
                include: "[instrumento_material]",
                instrumento: { id: instrumentoIds }
            })
            .then((i: Instrumento[]) => {
                this.instrumentos = i
                this.selectedInstrumento = i[0]
            })
    }

    resetData() {
        this.instrumento = this.instrumentos = this.instrumentoAsignatura = undefined
    }

    onAsignaturaReady(asignatura: Asignatura) {
        this.onReadyAsignatura.emit(asignatura)
    }

    onExternalLoadingInstrumento() {
        this.loadingLayout.standby()
    }

    onInstrumentoReady() {
        this.loadingLayout.ready()
        this.scrollToInstrumento()
    }

    onAsignaturaInstrumentoReady(instrumentoAsignatura) {
        this.instrumentoAsignatura = instrumentoAsignatura
    }

    onResize(event) {
        this.enableScrollAndFollow = event.target.innerWidth > 991
    }

    checkSize(size: any) {
        this.zonasSize[size[1]] = size[0]
        let total = Object.entries(this.zonasSize).reduce(function (total, pair) {
            let [key, value] = pair
            return total + value
        }, 0)
        this.showZonas = total != 0
    }

    scrollToInstrumento() {
        let target = document.getElementById("instrumentoSeleccionado")
        $("html, body").animate(
            {
                scrollTop: target.offsetTop + 120
            },
            1000,
            "easeInOutExpo"
        )
    }
}
