import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core"
import { Clasificacion, ContestableTipo } from "@puntaje/nebulosa/api-services"
import { ClasificadorRecursosComponent } from "@puntaje/puntaje/new-modules/clasificaciones"
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "clasificar-preguntas-pauta",
    templateUrl: "./clasificar-preguntas-pauta.component.html",
    styleUrls: ["../subir-prueba-propia.component.scss", "./clasificar-preguntas-pauta.component.scss"]
})
export class ClasificarPreguntasPautaComponent implements OnInit {
    @Input() numeroPreguntas: number
    @Input() curriculum: string = "LGE"
    @Input() asignaturaId: number
    @Input() contestableTipo: ContestableTipo
    @Input() askNumeroPreguntas: boolean
    @Input() step: number = 6

    @Output() changeData: EventEmitter<any> = new EventEmitter()

    @ViewChild(ClasificadorRecursosComponent) clasificadorRecursos: ClasificadorRecursosComponent

    clasificacionesByPreguntaIndex: Clasificacion[][]
    currentPreguntaIndex: number = 0
    showClasificador: boolean = false
    validationNumeroPreguntas: boolean = false
    mensajePreguntas: string = "Error"

    constructor(protected i18nService: I18nService) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            changes["contestableTipo"] &&
            !changes["contestableTipo"].firstChange &&
            changes["contestableTipo"].currentValue !== null &&
            changes["contestableTipo"].currentValue !== undefined
        ) {
            this.reset()
        }

        if (changes && changes["askNumeroPreguntas"] && !changes["askNumeroPreguntas"].firstChange) {
            this.reset()
        }

        if (
            changes &&
            changes["numeroPreguntas"] &&
            !changes["numeroPreguntas"].firstChange &&
            changes["numeroPreguntas"].currentValue !== null &&
            changes["numeroPreguntas"].currentValue !== undefined
        ) {
            this.reset()
        }
    }

    reset() {
        if (this.numeroPreguntas) {
            // this.setInitVariables()
            this.numeroPreguntas = +this.numeroPreguntas
        } else if (this.askNumeroPreguntas) {
            this.showClasificador = false
            this.clasificacionesByPreguntaIndex = undefined
        }
    }

    validateNumeroPreguntas() {
        if (!this.numeroPreguntas) {
            this.validationNumeroPreguntas = true
            this.mensajePreguntas = this.i18nService.translate("evaluar.pasos.subir_pauta_mixta.warn", {})

            return
        } else {
            this.validationNumeroPreguntas = false
        }
    }

    showClasificadorPreguntas() {
        this.validateNumeroPreguntas()

        if (this.validationNumeroPreguntas) {
            return
        }

        this.setInitVariables()
    }

    setInitVariables() {
        this.numeroPreguntas = +this.numeroPreguntas
        this.showClasificador = true
    }

    updateClasificaciones(event) {
        const data = {
            showTerminos: true,
            clasificacionesByPreguntaIndex: event.clasificacionesByItemIndex,
            numeroPreguntas: this.numeroPreguntas
        }
        this.changeData.emit(data)
    }

    skip() {
        this.showClasificador = false
        this.updateClasificaciones({})
    }

    sendData() {
        this.clasificadorRecursos.sendData()
    }
}
