<div class="contentPrincipal">
    <cui-section-headline>Desde la Biblioteca</cui-section-headline>

    <filtro-clasificaciones-materiales
        class="filtros"
        [asignaturaId]="asignaturaId"
        [evaluacionTipo]="evaluacionTipo"
        [generadorInstrumento]="generadorInstrumento"
        [(searchByCurso)]="searchByCurso"
        (changeCurso)="onChangeCurso($event)"
        (changeClasificacionPrimerNivel)="onChangeClasificacionPrimerNivel($event)"
        (changeClasificacionSegundoNivel)="onChangeClasificacionSegundoNivel($event)"
    ></filtro-clasificaciones-materiales>
    <simple-search-filter
        (updateSearch)="onSearchFilter($event)"
        (clearSearch)="onSearchFilter('')"
    ></simple-search-filter>
    <loading-layout>
        <materiales-lista [materiales]="materiales" (selected)="selectMaterial($event)"></materiales-lista>
        <paginator [getList]="getData"></paginator>
    </loading-layout>
</div>
