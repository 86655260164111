<ll-titulo>{{ "progreso.profe.titulo" | t }}</ll-titulo>

<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>

<filtro-periodo class="pull-right with-min-width"></filtro-periodo>
<h3 class="h3_sub_title_2 littledown">{{ "progreso.profe.instrucciones" | t }}</h3>
<p class="tutorial">
    {{ "progreso.profe.instrucciones_desc" | t }}
</p>
<br />
<selector-usuarios-por-colegio
    (onGrupoUsuariosObjChanged)="setNivelIds($event)"
    (onUsuariosChanged)="changeUsuarioIds($event)"
></selector-usuarios-por-colegio>
<br />
<grafico-mi-progreso
    *ngIf="usuarioId"
    [nivelIds]="nivelIds"
    [evaluacionTipo]="tipoInstrumento"
    [usuarioId]="usuarioId"
></grafico-mi-progreso>
