import { Component, OnInit, Input } from "@angular/core"
import { PlanPersonal } from "@puntaje/puntaje/api-services"
import { MaterialPreviewModalService } from "@puntaje/puntaje/new-modules/materiales"

@Component({
    selector: "plan-clase-modelo",
    templateUrl: "./plan-clase-modelo.component.html",
    styleUrls: ["./plan-clase-modelo.component.scss"]
})
export class PlanClaseModeloComponent implements OnInit {
    @Input() planPersonal: PlanPersonal
    @Input() materialTipos

    constructor(public materialPreviewModalService: MaterialPreviewModalService) {}

    ngOnInit() {}
}
