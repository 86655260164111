import { Component, Input, ViewChild, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import {
    EvaluacionInstancia,
    GrupoUsuarioUsuarios,
    Establecimiento,
    GrupoUsuario,
    EscalaGlobal
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { EvaluacionInstanciaEscalasService } from "./evaluacion_instancia_escalas.service"

@Component({
    selector: "ensayo-instancia-resumen-alumno",
    templateUrl: "ensayo_instancia_resumen_alumno.component.html",
    styleUrls: ["ensayo_instancia_resumen_alumno.component.scss"]
})
export class EnsayoInstanciaResumenAlumnoComponent implements OnInit, OnDestroy {
    @Input() evaluacionInstancia: EvaluacionInstancia
    @ViewChild("lateralLoadingLayout", { static: true }) lateralLoadingLayout: LoadingLayoutComponent
    @Input() tipoEvaluacion: string
    @Input() tipoEvaluacionParaTexto: string
    nombreGrupo: string = "curso"
    establecimiento: Establecimiento
    grupoUsuario: GrupoUsuario
    escalasGlobales: EscalaGlobal[]
    evaluacionInstanciaSub: Subscription

    constructor(
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected evaluacionInstanciaEscalasService: EvaluacionInstanciaEscalasService
    ) {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias ? config.plataforma.grupoUsuarioAlias : "curso"
    }

    ngOnInit() {
        this.tipoEvaluacion = this.tipoEvaluacion || this.evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo
        this.tipoEvaluacionParaTexto = this.tipoEvaluacionParaTexto || this.tipoEvaluacion
        const params = { grupo_usuario_usuario: { usuario_id: this.evaluacionInstancia.usuario_id } }
        this.grupoUsuarioUsuariosService.where(params).then(grupoUsuarioUsuarios => {
            const grupoUsuarioIds = grupoUsuarioUsuarios.map(guu => guu.grupo_usuario_id)
            const evaluacionUsuario = this.evaluacionInstancia.evaluacion.evaluacion_usuarios.find(eu =>
                grupoUsuarioIds.includes(eu.grupo_usuario?.id)
            )

            this.grupoUsuario = evaluacionUsuario.grupo_usuario
            this.establecimiento = evaluacionUsuario.grupo_usuario.establecimiento

            if (this.evaluacionInstancia.evaluacion.instrumento.propio && this.evaluacionInstancia.respuestas) {
                this.evaluacionInstanciaSub = this.evaluacionInstanciaEscalasService.evaluacionInstancia.subscribe(
                    (evaluacionInstancia: EvaluacionInstancia) => {
                        if (evaluacionInstancia) {
                            this.lateralLoadingLayout.standby()
                            this.evaluacionInstancia = evaluacionInstancia
                            this.cargarEscalasGlobales()
                        }
                    }
                )
            }

            this.cargarEscalasGlobales()
        })
    }

    cargarEscalasGlobales() {
        this.evaluacionInstanciaEscalasService.getEscalas(this.evaluacionInstancia).then((escalas: EscalaGlobal[]) => {
            this.escalasGlobales = escalas
            this.lateralLoadingLayout.ready()
        })
    }

    ngOnDestroy() {
        if (this.evaluacionInstanciaSub) {
            this.evaluacionInstanciaSub.unsubscribe()
        }
    }
}
