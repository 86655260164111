<cui-section-headline>Agregar evaluación</cui-section-headline>
<div class="form-group">
    <label for="">Nombre evaluación</label>
    <input type="text" [(ngModel)]="nombreEvaluacion" class="form-control" />
    <p class="alert alert-danger" *ngIf="!nombreEvaluacion">Campo obligatorio.</p>
</div>
<div>
    <h4 class="h4_sub_title_1">Selecciona el tipo de evaluacion:</h4>
    <div class="form-check">
        <input
            [attr.aria-label]="'sumativa'"
            class="form-check-input"
            type="radio"
            name="sumativa"
            id="sumativa"
            [(ngModel)]="evaluacionSumativa"
            value="1"
        />
        <label class="form-check-label" for="sumativa">&nbsp;Sumativo &nbsp;</label>
    </div>
    <div class="form-check">
        <input
            [attr.aria-label]="'formativa'"
            class="form-check-input"
            type="radio"
            name="sumativa"
            id="formativa"
            [(ngModel)]="evaluacionSumativa"
            value="0"
        />
        <label class="form-check-label" for="formativa">&nbsp;Formativo &nbsp;</label>
    </div>
</div>
<generar-instrumento-profesor
    *ngIf="asignatura"
    [asignatura]="asignatura"
    [tipoInstrumento]="tipoInstrumento"
    (onReadyInstrumentos)="cargarInstrumento($event)"
></generar-instrumento-profesor>
<div class="vista-previa-container" *ngIf="instrumento">
    <div class="sticky-container">
        <cui-section-headline>Vista previa y confirmación</cui-section-headline>
        <cui-button [disabled]="!nombreEvaluacion" (click)="confirmar()" type="button" class="btn_style">
            Confirmar y agregar evaluación
        </cui-button>
    </div>
    <instrumento [instrumento]="instrumento"></instrumento>
</div>
