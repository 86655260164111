import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import {
    Evaluacion,
    GrupoUsuarios,
    GrupoUsuarioUsuarios,
    EvaluacionInstancias,
    PlanPersonalSesiones
} from "@puntaje/puntaje/api-services"
import {
    PlanClaseService,
    PlanPersonal,
    PlanPersonalSesion,
    PlanPersonalSesionGrupoUsuario
} from "@puntaje/puntaje/api-services"
import { EvaluacionPreviewModalComponent } from "@puntaje/puntaje/new-modules/ensayos"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "plan-clase-semanas",
    templateUrl: "./plan-clase-semanas.component.html",
    styleUrls: ["./plan-clase-semanas.component.scss"]
})
export class PlanClaseSemanasComponent implements OnInit {
    @Input() planPersonalSesion: PlanPersonalSesion
    @Input() index: number
    @Input() planPersonal: PlanPersonal
    @Input() clasificacionActividadByMaterialId: any
    @Input() clasificacionContextoByMaterialId: any
    @Input() grupoUsuarioId: number
    @Input() vistaPrevia: boolean
    @Input() actividad = false
    @Input() urlNavigate = "plan_clases/"
    nombreSesion: string = config.plataforma.nombreSesion || "semana"

    @ViewChild("evaluacionPreview") evaluacionPreviewModal: EvaluacionPreviewModalComponent
    config: typeof config = config

    @Output() avanceChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    //actividad
    evaluacionActividadId: number
    avanceActividad: number
    totalAlumnosActividad: number
    avance: number = 0

    startAnimValues: boolean

    avanceGrupoUsuario: any
    selectedTab = "resumen"
    enviandoRecordatorios: boolean = false

    showAvancePorAlumno = false

    constructor(
        public planClaseService: PlanClaseService,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected planPersonalSesionesService: PlanPersonalSesiones
    ) {}

    ngOnInit() {
        if (this.actividad) {
            //deberia venir solo una evaluacion en este tipo de plan
            this.evaluacionActividadId = this.planPersonalSesion.plan_personal_contenidos.find(
                c => c.tipo == "Evaluacion"
            ).tipo_id
            this.getActividadAvance()
        }

        this.planPersonalSesionesService
            .avanceGrupoUsuario(this.planPersonalSesion.id, this.grupoUsuarioId)
            .then(agu => (this.avanceGrupoUsuario = agu))
    }

    toggleVisto(planPersonalSesionGrupoUsuario: PlanPersonalSesionGrupoUsuario) {
        this.planClaseService.toggleVisto(planPersonalSesionGrupoUsuario).then(res => {
            this.avanceChange.emit(true)
        })
    }

    send_reminder(sesion) {
        this.enviandoRecordatorios = true
        this.planPersonalSesionesService.sendReminder(sesion.id, this.grupoUsuarioId).then(response => {
            this.enviandoRecordatorios = false
        })
    }

    async getActividadAvance() {
        let guus = await this.grupoUsuarioUsuariosService.where({
            grupo_usuario_usuario: { grupo_usuario_id: this.grupoUsuarioId, propietario: false }
        })
        this.totalAlumnosActividad = guus.length
        let usuarioIds = guus.map(guu => guu.usuario_id)
        let instancias = await this.evaluacionInstanciasService.where({
            evaluacion_instancia: { evaluacion_id: this.evaluacionActividadId, usuario_id: usuarioIds, oficial: true }
        })
        this.avanceActividad = instancias.length
        this.avance = Math.round(100 * (this.avanceActividad / this.totalAlumnosActividad))
        if (this.avance > 100) this.avance = 100
    }

    selectTab(tab: string) {
        this.selectedTab = tab
    }
}
