import {
    Component,
    OnInit,
    ViewChildren,
    ViewChild,
    QueryList,
    ElementRef,
    Output,
    EventEmitter,
    Input
} from "@angular/core"
import { Scannerjs } from "@puntaje/shared/scanner"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { ScrollToService, I18nService } from "@puntaje/shared/core"

declare let loadImage: any

@Component({
    selector: "subir-pauta",
    templateUrl: "./subir-pauta.component.html",
    styleUrls: ["../subir-prueba-propia.component.scss"]
})
export class SubirPautaComponent implements OnInit {
    pauta: { letra: string; piloto: boolean; puntuacion: number }[]
    respuestas: any
    respuestasObj: any[]
    wrongPauta = false
    blankPauta = false
    config = config
    permittedLetters = "ABCD"
    _evaluacionTipo: string
    numero_alternativas = 4
    validationNumeroAlternativas = false
    nalternativaIncorrecta = false
    mensajeAlternativas = ""
    @Input() step = 5
    @Input() max_alternativas: number

    @ViewChildren("letraInput") letraInputs: QueryList<ElementRef>
    @ViewChild("canvas", { static: true }) canvas: ElementRef
    @Input()
    set evaluacionTipo(evalTipo) {
        this._evaluacionTipo = evalTipo
    }
    get evaluacionTipo() {
        return this._evaluacionTipo
    }
    @Output() changeData: EventEmitter<any> = new EventEmitter()
    imagen = "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/material/archivo/prueba_primer_formato.jpg"
    scanner: Scannerjs
    markers: any[] = []

    constructor(protected scrollToService: ScrollToService, protected i18nService: I18nService) {}

    ngOnInit() {
        this.setPauta()
        this.leerImagen()
    }

    setPauta() {
        if (config.plataforma.name == "Aprendolibre") {
            this.pauta = new Array(40).fill(0).map(_ => ({ letra: "", piloto: false, puntuacion: 1 }))
            this.imagen =
                "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/material/archivo/hoja_respuesta_aprendo_libre.jpg"
        } else {
            this.pauta = new Array(90).fill(0).map(_ => ({ letra: "", piloto: false, puntuacion: 1 }))
            if (config.plataforma.pauta_imagen) {
                this.imagen = config.plataforma.pauta_imagen
            }
        }
    }

    nextFocus(i: number) {
        const arrayLetraInputs = this.letraInputs.toArray()
        if (this.pauta[i].letra && arrayLetraInputs[i + 1]) {
            arrayLetraInputs[i + 1].nativeElement.focus()
        }
    }

    validadaNumeroAlternativas() {
        this.validationNumeroAlternativas = false
        let letras = "ABCDE"
        if (config.plataforma.name == "Aprendolibre") {
            letras = "ABCD"
        }
        this.nalternativaIncorrecta = false
        this.setPauta()
        if (this.numero_alternativas <= 1) {
            this.mensajeAlternativas = this.i18nService.translate("alternativas.min", {})
            this.validationNumeroAlternativas = true
        } else if (this.numero_alternativas > this.max_alternativas) {
            this.mensajeAlternativas = this.i18nService.translate("alternativas.max", { count: this.max_alternativas })
            this.validationNumeroAlternativas = true
        } else {
            this.permittedLetters = new Array(this.numero_alternativas)
                .fill("")
                .map((_, i) => letras[i])
                .join("")
        }
    }

    filterLetra(event, i) {
        if (event.key == "Backspace" || event.key == "Tab") {
            return true
        }

        if (this.permittedLetters.indexOf(event.key.toUpperCase()) < 0) {
            event.preventDefault()

            return false
        }

        return true
    }

    leerImagen() {
        const ctx = this.canvas.nativeElement.getContext("2d")

        loadImage(
            this.imagen,
            img => {
                this.respuestasObj = []
                try {
                    this.canvas.nativeElement.width = img.width
                    this.canvas.nativeElement.height = img.height
                    ctx.drawImage(img, 0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)

                    const scanner = new Scannerjs({
                        image: img,
                        context: ctx,
                        canvasWidth: this.canvas.nativeElement.width,
                        canvasHeight: this.canvas.nativeElement.height
                    })

                    scanner.detectMarkers()
                    scanner.align(1, 5)
                    this.markers = scanner.detectMarkers()

                    config.hojaRespuesta.sectores.forEach(s => {
                        this.respuestasObj.push(scanner.resolveIds(s[0], s[1], config.hojaRespuesta.alternativas, 10))
                    })

                    this.scanner = scanner
                    this.scrollToStep2()
                } catch (err) {
                    console.log(err)
                }
            },
            {
                maxWidth: 1200,
                orientation: true,
                canvas: true,
                imageSmoothingEnabled: true,
                imageSmoothingQuality: "high"
            }
        )
    }

    scrollToStep2() {
        this.scrollToService.scrollTo("step2")
    }

    defaultDimSectores() {
        return {
            "1,2": { cols: 5, rows: 10 },
            "2,3": { cols: 5, rows: 10 },
            "3,4": { cols: 5, rows: 10 },
            "5,2": { cols: 5, rows: 10 },
            "4,5": { cols: 5, rows: 10 },
            "2,7": { cols: 5, rows: 10 },
            "7,4": { cols: 5, rows: 10 },
            "5,6": { cols: 5, rows: 10 },
            "6,7": { cols: 5, rows: 10 },
            "8,9": { cols: 5, rows: 10 },
            "7,8": { cols: 5, rows: 10 },
            "9,6": { cols: 10, rows: 10 },
            "6,10": { cols: 10, rows: 10 }
        }
    }

    onClickCanvas(event) {
        const nCanvas = this.canvas.nativeElement

        const ratioX = nCanvas.width / nCanvas.offsetWidth
        const ratioY = nCanvas.height / nCanvas.offsetHeight

        //TODO AGREGAR AL CONFIG
        let dimSectores = this.defaultDimSectores()
        if (
            config.hojaRespuesta.dimSectoresByMarkers &&
            config.hojaRespuesta.dimSectoresByMarkers[this.markers.length]
        ) {
            dimSectores = config.hojaRespuesta.dimSectoresByMarkers[this.markers.length]
        }

        const sectoresRespuestas = config.hojaRespuesta.sectores.concat([9, 6], [6, 10])

        this.scanner.changeMarked(
            ~~(event.offsetX * ratioX),
            ~~(event.offsetY * ratioY),
            this.respuestasObj,
            dimSectores,
            sectoresRespuestas
        )

        this.setValues()
    }

    setValues() {
        const letras = "ABCDE"
        this.pauta = this.respuestasObj.flat().map(l => {
            return { letra: l.ans.map(a => letras.charAt(a)).join(","), piloto: false, puntuacion: 1 }
        })
    }

    sendData() {
        if (this.validationNumeroAlternativas) {
            this.validadaNumeroAlternativas()
            return
        }

        this.wrongPauta = false
        this.blankPauta = false
        if (this.numero_alternativas > 1 && this.numero_alternativas < this.max_alternativas + 1) {
            if (this.validacionPauta() && this.validacionPuntuacion()) {
                if (this.validaVacias()) {
                    const data = {
                        showClasificarPreguntas: true,
                        pauta: this.pauta,
                        numero_alternativas: this.numero_alternativas
                    }
                    this.changeData.emit(data)
                } else {
                    this.blankPauta = true
                }
            } else {
                this.wrongPauta = true
            }
        } else {
            this.nalternativaIncorrecta = true
        }
    }

    validaVacias() {
        let isFill = false
        for (let i = this.pauta.length - 1; i >= 0; i--) {
            if (isFill) {
                if (this.pauta[i].letra == "") {
                    return false
                } else if (i == 0) {
                    return true
                }
            } else {
                if (this.pauta[i].letra != "") {
                    isFill = true
                }
            }
        }

        return false
    }

    validacionPuntuacion() {
        let isValid = true
        for (const data of this.pauta) {
            if (
                data.letra != "" &&
                (data.puntuacion == null || data.puntuacion == undefined || data.puntuacion + "" == "")
            ) {
                isValid = false
                break
            }

            if (data.letra == "") {
                return isValid
            }
        }

        return isValid
    }

    validacionPauta() {
        for (const data of this.pauta) {
            if (data.letra != "") {
                return true
            }
        }
        return false
    }
}
