import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import {
    PlanPersonalForm,
    PlanPersonal,
    PlanPersonalCiclo,
    PlanPersonalTipo,
    PlanPersonalTipos,
    PlanPersonales,
    PlanPersonalSesion
} from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AuthService, SessionService, GenericModalComponent, I18nService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    GeneradorInstrumento,
    GeneradorInstrumentos,
    MaterialTipo,
    MaterialTipos,
    Clasificaciones,
    Clasificacion
} from "@puntaje/nebulosa/api-services"
import { Router } from "@angular/router"
import { PlanClaseStorageService } from "../plan-clase-storage.service"
import { PlanClaseModeloService } from "../plan-clase-modelo.service"

@Component({
    selector: "plan-clase-form",
    templateUrl: "./plan-clase-form.component.html",
    styleUrls: ["./plan-clase-form.component.scss"]
})
export class PlanClaseFormComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() asignaturaId: number
    @Input() tipoEvaluacion: string
    @Input() planClase: PlanPersonal
    @Output() save = new EventEmitter<PlanPersonal>()
    @Input() enableModelos: boolean = false
    @Input() urlRediret: string = "/curso"
    @Input() disableSave: boolean = false
    @ViewChild("genericModal") genericModal: GenericModalComponent
    @ViewChild("genericModalEliminar") genericModalEliminar: GenericModalComponent
    eliminarText: string

    planPersonalCiclo: PlanPersonalCiclo
    save_button_text = "Guardar cambios"
    params = PlanPersonalForm.formParams
    form: UntypedFormGroup
    config: typeof config = config
    generadorInstrumentos: GeneradorInstrumento[]
    generadorInstrumento: GeneradorInstrumento
    planPersonalTipos: PlanPersonalTipo[]

    nombreSesion = config.plataforma.sesionAlias || "Sesión"

    materialTipos: MaterialTipo[]
    clasificaciones: Clasificacion[]

    constructor(
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected planPersonalTiposService: PlanPersonalTipos,
        protected materialTiposService: MaterialTipos,
        protected clasificacionesService: Clasificaciones,
        private generadorInstrumentosService: GeneradorInstrumentos,
        protected planClaseService: PlanPersonales,
        public planClaseStorageService: PlanClaseStorageService,
        protected simpleModalService: SimpleModalService,
        protected router: Router,
        public planClaseModeloService: PlanClaseModeloService,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        this.setData()
        this.form = PlanPersonalForm.getForm(this.planClase)
        this.loadingLayout.ready()

        this.materialTiposService.where().then(materialTipos => {
            this.materialTipos = materialTipos
        })

        const params = {
            clasificacion: {
                clasificacion: ["Alumnos", "Profesores"]
            }
        }

        this.clasificacionesService.where(params).then(clasificaciones => {
            this.clasificaciones = clasificaciones
        })

        if (this.planClaseModeloService.planClaseModelo) {
            this.selectModelo()
        }
    }

    setData() {
        this.getGeneradorInstrumento()
        this.planPersonalCiclo = this.planClase.plan_personal_ciclos[0]

        this.planClaseStorageService.initialize(this.asignaturaId, this.tipoEvaluacion, this.planClase)
        const savedPlanPersonal = this.planClaseStorageService.getSavedPlanPersonal()
        if (savedPlanPersonal) {
            this.planClase = savedPlanPersonal
            this.planPersonalCiclo = this.planClase.plan_personal_ciclos[0]
        }
    }

    onSave() {
        this.planClase.plan_personal_tipo_id = this.planPersonalTipos[0].id
        PlanPersonalForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            const ciclo = this.planClase.plan_personal_ciclos[0]
            ciclo.plan_personal_sesiones?.forEach((sesion, i) => {
                sesion.orden = i

                sesion.plan_personal_contenidos?.forEach((contenido, j) => {
                    contenido.orden = j
                })
            })

            this.save.emit(this.planClase)
        }
    }

    async getGeneradorInstrumento() {
        let params = {
            asignatura_plataforma: {
                asignatura_id: this.asignaturaId,
                plataforma_id: this.config.plataforma.id
            },
            tipo_instrumento: {
                tipo_instrumento: this.tipoEvaluacion
            },
            generador_instrumento_categoria: {
                categoria_id: this.sessionService.getNivelesActivos()
            },
            include: "[tipo_instrumento,generador_instrumento_categorias]",
            sort_by: "orden",
            order: "asc"
        }
        this.generadorInstrumentos = (await this.generadorInstrumentosService.where(params))
            .sort((gi1, gi2) => gi1.orden - gi2.orden)
            .filter(gi => gi.visible)
        this.deleteDuplicados()
        this.planPersonalTipos = await this.planPersonalTiposService.where({
            plan_personal_tipo: { tipo: "plan de clase profesor" }
        })

        this.setGeneradorInstrumento()
    }

    deleteDuplicados() {
        var hash = {}
        this.generadorInstrumentos = this.generadorInstrumentos.filter(current => {
            var exists = !hash[current.id]
            hash[current.id] = true
            return exists
        })
    }

    setGeneradorInstrumento() {
        if (this.generadorInstrumentos) {
            this.planClaseModeloService.planClaseModelo = undefined

            this.generadorInstrumento = this.generadorInstrumentos.find(
                gi => gi.id == this.planClase.generador_instrumento_id
            )
        }
    }

    addPlanPersonalSesion() {
        this.planPersonalCiclo.plan_personal_sesiones = this.planPersonalCiclo.plan_personal_sesiones || []

        const planPersonalSesion = new PlanPersonalSesion()
        planPersonalSesion.orden = this.planPersonalCiclo.plan_personal_sesiones.length

        this.planPersonalCiclo.plan_personal_sesiones.push(planPersonalSesion)

        this.planClaseStorageService.savePlanPersonal()
    }

    updateSesion(sesionIndex) {
        const newPlanPersonalSesiones = this.planPersonalCiclo.plan_personal_sesiones.slice()
        newPlanPersonalSesiones.splice(sesionIndex, 1)

        this.planPersonalCiclo.plan_personal_sesiones = newPlanPersonalSesiones
        this.reOrden()
        this.planClaseStorageService.savePlanPersonal()
    }

    reOrden() {
        this.planPersonalCiclo.plan_personal_sesiones.forEach((pps, index) => {
            this.planPersonalCiclo.plan_personal_sesiones[index].orden = index
        })
    }

    selectModelo() {
        const planPersonalModeloCopia = this.planClaseModeloService.planClaseModelo.clone()

        planPersonalModeloCopia.plan_personal_ciclos.forEach(planPersonalCiclo => {
            planPersonalCiclo.id = undefined
            planPersonalCiclo.plan_personal_sesiones.forEach(pps => {
                pps.id = undefined
                pps.plan_personal_contenidos.forEach(ppc => {
                    ppc.id = undefined
                })
            })
        })

        this.planClase.plan_personal_ciclos = planPersonalModeloCopia.plan_personal_ciclos
        this.planPersonalCiclo = this.planClase.plan_personal_ciclos[0]
        this.reOrden()
        this.planClaseStorageService.savePlanPersonal()

        this.planClaseModeloService.planClaseModelo = undefined

        this.genericModal?.close()
    }

    openModalEliminar() {
        this.eliminarText = ""
        this.genericModalEliminar.buttonPressed()
    }

    eliminarPlan() {
        if (this.eliminarText != this.i18nService.translate("estudiar.plan_de_clases.confirm_elimnar_text", {})) {
            return
        }

        if (this.planClase.id) {
            this.planClaseService.enableIgnoreCatch()
            this.planClaseService
                .remove(this.planClase.id)
                .then(planClase => {
                    this.genericModalEliminar.close()
                    this.simpleModalService.cleanModal()
                    this.simpleModalService.setModalHeader(
                        this.i18nService.translate("estudiar.plan_de_clases.form_exito", {})
                    )
                    this.simpleModalService.setModalStringContent(
                        this.i18nService.translate("estudiar.plan_de_clases.form_exito_mensaje", {})
                    )
                    this.simpleModalService.setDisableBtnCancel(true)
                    this.simpleModalService.setModalCallback(() => {
                        this.router.navigate([this.urlRediret], {
                            queryParams: { tipo_instrumento: this.tipoEvaluacion, asignatura_id: this.asignaturaId }
                        })
                    })
                    this.simpleModalService.showSimpleModal()
                })
                .catch(err => {
                    this.genericModalEliminar.close()
                    this.simpleModalService.cleanModal()
                    this.simpleModalService.setModalHeader("Error")
                    this.simpleModalService.setModalStringContent(
                        this.i18nService.translate("estudiar.plan_de_clases.form_error", {})
                    )
                    this.simpleModalService.showSimpleModal()
                })
        } else {
            this.eliminarText = ""
            this.genericModalEliminar.close()
        }
    }
}
