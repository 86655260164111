<cui-section-headline>Agregar prueba propia</cui-section-headline>
<div>
    <h4 class="h4_sub_title_1">Selecciona el tipo de evaluacion:</h4>
    <div class="form-check">
        <input
            [attr.aria-label]="'sumativa'"
            class="form-check-input"
            type="radio"
            name="sumativa"
            id="sumativa"
            [(ngModel)]="evaluacionSumativa"
            value="1"
        />
        <label class="form-check-label" for="sumativa">&nbsp;Sumativo &nbsp;</label>
    </div>
    <div class="form-check">
        <input
            [attr.aria-label]="'formativa'"
            class="form-check-input"
            type="radio"
            name="sumativa"
            id="formativa"
            [(ngModel)]="evaluacionSumativa"
            value="0"
        />
        <label class="form-check-label" for="formativa">&nbsp;Formativo &nbsp;</label>
    </div>
</div>
<subir-prueba-propia
    *ngIf="!instrumento"
    [evaluacionTipo]="evaluacionTipo"
    [doNotShowLastStep]="true"
    (saved)="setInstrumento($event)"
></subir-prueba-propia>

<div *ngIf="instrumento">
    <p class="alert alert-success">Instrumento cargado exitosamente.</p>
    <div class="form-group">
        <label for="">Nombre evaluación</label>
        <input type="text" [(ngModel)]="nombreEvaluacion" class="form-control" />
        <p class="alert alert-danger" *ngIf="!nombreEvaluacion">Campo obligatorio.</p>
    </div>

    <cui-button [disabled]="!nombreEvaluacion" (click)="confirmar()" type="button" class="btn_style">
        Confirmar y agregar evaluación
    </cui-button>
</div>
