import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import {
    Establecimiento,
    Establecimientos,
    GrupoUsuario,
    GrupoUsuarios,
    Usuario,
    Usuarios
} from "@puntaje/puntaje/api-services"
import { PeriodosService } from "@puntaje/puntaje/new-modules/shared"

@Component({
    selector: "selector-usuarios-por-colegio",
    templateUrl: "selector_usuarios_por_colegio.component.html",
    styleUrls: ["selector_usuarios_por_colegio.component.scss"]
})
export class SelectorUsuariosPorColegioComponent implements OnInit {
    @Output() onUsuariosChanged = new EventEmitter()
    @Output() onGrupoUsuariosChanged = new EventEmitter()
    @Output() onGrupoUsuariosObjChanged = new EventEmitter()
    @Output() onEstablecimientoChanged = new EventEmitter()
    establecimientos: Establecimiento[]
    grupoUsuarios: GrupoUsuario[]
    usuarios: Usuario[]
    establecimiento: Establecimiento
    grupoUsuario: GrupoUsuario
    alumno: Usuario

    constructor(
        private usuariosService: Usuarios,
        private authService: AuthService,
        private grupoUsuariosService: GrupoUsuarios,
        private establecimientosService: Establecimientos,
        private generadorInstrumentosService: GeneradorInstrumentos,
        protected periodos: PeriodosService
    ) {
        this.establecimientos = []
        this.grupoUsuarios = []
        this.usuarios = []
    }

    ngOnInit() {
        this.periodos.periodosChange.subscribe(intervalo => {
            intervalo && this.setData(intervalo)
        })
    }

    setData({ fechaInicial, fechaFinal }) {
        let params: any = {
            mios: 1,
            establecimiento: { activo: 1 },
            utp_establecimiento: 1,
            utp_asociacion: 1,
            fecha_inicial: fechaInicial,
            fecha_final: fechaFinal
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
            this.establecimiento = this.establecimientos[0]
            this.onEstablecimientoChanged.emit(this.establecimiento.id)
            this.grupoUsuarios = this.establecimiento.grupo_usuarios
            this.grupoUsuario = this.grupoUsuarios[0]
            this.getAlumnosGrupoUsuario()
        })
    }

    getAlumnosGrupoUsuario() {
        if (this.grupoUsuario) {
            this.usuariosService
                .where({ grupo_usuario_usuario: { grupo_usuario_id: this.grupoUsuario.id, propietario: 0 } })
                .then((usuarios: Usuario[]) => {
                    this.usuarios = usuarios
                    this.updateAndEmitGrupoUsuario()
                })
        } else {
            this.usuarios = []
            this.alumno = undefined
            this.updateAndEmitUsuario()
        }
    }

    getAlumnosGrupoUsuarios() {
        this.usuariosService
            .where({ grupo_usuario_usuario: { grupo_usuario_id: this.grupoUsuarios.map(u => u.id), propietario: 0 } })
            .then((usuarios: Usuario[]) => {
                this.usuarios = usuarios
                this.updateAndEmitGrupoUsuarios()
            })
    }

    changeEstablecimiento() {
        this.onEstablecimientoChanged.emit(this.establecimiento.id)
        this.grupoUsuarios = this.establecimiento.grupo_usuarios
        this.grupoUsuario = undefined
        this.getAlumnosGrupoUsuarios()
    }

    changeGrupo() {
        this.getAlumnosGrupoUsuario()
    }

    changeAlumno() {
        this.updateAndEmitUsuario()
    }

    updateAndEmitGrupoUsuarios() {
        this.onGrupoUsuariosObjChanged.emit(this.grupoUsuarios)
        this.onGrupoUsuariosChanged.emit(this.grupoUsuarios.map(u => u.id))
        this.onUsuariosChanged.emit(this.usuarios.map(u => u.id))
    }

    updateAndEmitGrupoUsuario() {
        if (this.grupoUsuario) {
            this.onGrupoUsuariosObjChanged.emit([this.grupoUsuario])
            this.onGrupoUsuariosChanged.emit([this.grupoUsuario.id])
            this.onUsuariosChanged.emit(this.usuarios.map(u => u.id))
        } else {
            this.onGrupoUsuariosObjChanged.emit([])
        }
    }

    updateAndEmitUsuario() {
        if (this.alumno) {
            this.onUsuariosChanged.emit([this.alumno.id])
        } else {
            this.onUsuariosChanged.emit([])
            this.updateAndEmitGrupoUsuario()
        }
    }
}
