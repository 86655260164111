<div class="filtro clearfix">
    <loading-layout #loadingLayout>
        <div class="clearfix periodo">
            <filtro-periodo [periodo]="periodo" (select)="onSelectPeriodo($event)"></filtro-periodo>
        </div>
        <ng-template [ngIf]="establecimientos && establecimientos.length > 0">
            <h4>
                <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                {{ 'estadisticas.filtro.title' | t }}
            </h4>
            <generador-instrumento-select
                *ngIf="asignatura"
                [hidden]="!asignatura.generador_instrumentos || asignatura.generador_instrumentos.length == 1"
                [generadorInstrumentos]="asignatura.generador_instrumentos"
                [(generadorInstrumento)]="generadorInstrumento"
                (generadorInstrumentoChange)="getEvaluaciones()"
                [showNonVisible]="true"
            ></generador-instrumento-select>
            <select
                class="form-control"
                [(ngModel)]="establecimiento"
                (ngModelChange)="usuarios = null; grupoUsuario = undefined; getEvaluaciones()"
            >
                <option selected [ngValue]="undefined">{{ 'estadisticas.filtro.sel_est' | t }}</option>
                <option *ngFor="let establecimiento of establecimientos" [ngValue]="establecimiento">
                    {{ establecimiento.establecimiento }}
                </option>
            </select>
            <select
                class="form-control"
                [(ngModel)]="grupoUsuario"
                (ngModelChange)="usuarios = null; getEvaluaciones(); getEstudiantes()"
            >
                <option selected [ngValue]="undefined">{{ 'estadisticas.filtro.sel' | t }} {{ nombreGrupo }}</option>
                <ng-template [ngIf]="establecimiento">
                    <option *ngFor="let grupo_usuario of establecimiento.grupo_usuarios" [ngValue]="grupo_usuario">
                        {{ grupo_usuario.nombre }}
                    </option>
                </ng-template>
            </select>
            <!--
			<select [disabled]="true || !usuarios" class="form-control">
				<option selected [ngValue]="undefined">Seleccione Estudiante</option>
				<option *ngFor="let usuario of usuarios" [ngValue]="usuario">{{ usuario.nombre_completo }}</option>
			</select>-->

            <select-with-loader [options]="evaluaciones">
                <select target-select class="form-control" [(ngModel)]="evaluacion">
                    <option selected [ngValue]="undefined">
                        {{ 'estadisticas.filtro.sel' | t }} {{ evaluacionTipo | transformInstrumento: "singular" }}
                    </option>
                    <option *ngFor="let evaluacion of evaluaciones" [ngValue]="evaluacion">
                        {{ evaluacion.id }} - {{ evaluacion.evaluacion }}
                    </option>
                </select>
            </select-with-loader>

            <cui-button
                [disabled]="loadingCount != 0"
                type="button"
                class="btn_style pull-right"
                (click)="setFiltros()"
            >
                <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                {{ 'estadisticas.filtro.filtrar_res' | t }}
            </cui-button>
        </ng-template>

        <ng-template [ngIf]="establecimientos && establecimientos.length == 0">
            {{ 'estadisticas.filtro.not_found1' | t }} {{ config.plataforma.grupoUsuarioAlias | pluralize }} {{ 'estadisticas.filtro.not_found2' | t }}.
        </ng-template>
    </loading-layout>
</div>
