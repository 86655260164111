import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from "@angular/core"
import { Material } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "vista-previa-archivo",
    templateUrl: "./vista-previa-archivo.component.html",
    styleUrls: ["../subir-prueba-propia.component.scss"]
})
export class VistaPreviaArchivoComponent implements OnInit {
    @Input() material: Material = new Material()
    @Input() fileParams: any
    fileSrc: Uint8Array
    @Output() changeData: EventEmitter<any> = new EventEmitter()
    @Input() step: number = 2

    constructor() {}

    ngOnInit() {}

    goToAsignatura() {
        this.changeData.emit(true)
    }
}
