import { Component, OnInit, Input } from "@angular/core"

@Component({
    selector: "carousel-semanal",
    templateUrl: "./carousel-semanal.component.html",
    styleUrls: ["./carousel-semanal.component.scss"]
})
export class CarouselSemanalComponent implements OnInit {
    @Input() plan_personal_contenidos
    @Input() clasificacionActividadByMaterialId
    @Input() clasificacionContextoByMaterialId
    @Input() vistaPrevia: boolean

    itemsPerSlide = 5
    singleSlideOffset = false
    noWrap = true
    no_show_izq = false
    no_show_derec = false
    mostrarCarousel = true
    mostrarSecontCarousel = false

    slidesChangeMessage = ""

    constructor() {}

    ngOnInit() {}

    showControler(indexes: number[]): void {
        if (indexes[0] == 0) {
            this.no_show_izq = true
            this.no_show_derec = false
        } else if (indexes[indexes.length - 1] == this.plan_personal_contenidos.length - 1) {
            this.no_show_derec = true
            this.no_show_izq = false
        } else {
            this.no_show_derec = false
            this.no_show_izq = false
        }
    }

    adaptToSize(cs: number) {
        if (cs >= 706) {
            this.itemsPerSlide = 5
        }
        if (cs < 706 && cs > 598) {
            this.itemsPerSlide = 4
        }
        if (cs <= 598 && cs > 478) {
            this.itemsPerSlide = 3
        }
        if (cs <= 478 && cs > 322) {
            this.itemsPerSlide = 2
        }
        if (cs <= 322) {
            this.itemsPerSlide = 1
        }
        this.mostrarCarousel = false
        setTimeout(() => {
            this.mostrarCarousel = true
        }, 11)

        if (cs <= 400) {
            this.mostrarSecontCarousel = true
        }
        if (cs > 400) {
            this.mostrarSecontCarousel = false
        }
    }
}
