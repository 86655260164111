import { Component, OnInit } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "grupo_usuarios.component.html"
})
export class GrupoUsuariosComponent implements OnInit {
    constructor(protected titleService: TitleService, protected authService: AuthService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.grupo_usuarios.list")
    }
}
