<ll-titulo>Estadísticas</ll-titulo>
<ll-subtitulo>
    {{ asignatura?.asignatura }}
    <cui-button-link class="pull-right margin-bottom" size="small" (click)="back()" href="javascript:void(0)">
        <fa name="chevron-left" class="left-icon"></fa>
        Volver
    </cui-button-link>
</ll-subtitulo>
<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>

<estadisticas-profesor
    [asignaturaId]="asignaturaId"
    [evaluacionTipo]="tipoInstrumento"
    [evaluacionTipoAlias]="evaluacionTipoAlias"
></estadisticas-profesor>
