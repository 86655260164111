<ng-container *ngIf="!hideReplicarPlan">
    <cui-button-link class="btn_style" (click)="startReplicar()">{{ 'estudiar.plan_de_clases.replicar_context' | t }}</cui-button-link>
</ng-container>

<generic-modal [id]="'replicar-plan-grupo-usuarios'" [buttonClicked]="openModal">
    <modal-titulo>{{ 'estudiar.plan_de_clases.replicar_title' | t }}</modal-titulo>
    <modal-contenido>
        <loading-layout>
            <ng-container *ngIf="!replicado">
                <ng-container *ngFor="let g of _grupoUsuarios">
                    <ng-container *ngIf="g.grupoUsuario.id != grupoUsuarioOrigen.id">
                        <label>
                            <input type="checkbox" [(ngModel)]="g.selected" />
                            {{ g.grupoUsuario.nombre }}
                        </label>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="replicado">
                <p class="alert alert-success">{{ 'estudiar.plan_de_clases.replicar_success' | t }}</p>
            </ng-container>
        </loading-layout>
    </modal-contenido>
    <modal-botones>
        <a *ngIf="!replicado" class="btn btn-primary" (click)="replicar()">{{ 'estudiar.plan_de_clases.replicar' | t }}</a>
        <button *ngIf="replicado" class="btn btn-success" (click)="genericModal.close()">{{ 'estudiar.plan_de_clases.listo' | t }}</button>
    </modal-botones>
</generic-modal>
