import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core"
import { PlanPersonal, Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { Store } from "@ngrx/store"
import { selectAsignaturasById, State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { Subscription } from "rxjs"
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "agregar-evaluacion-plan-clase",
    templateUrl: "./agregar-evaluacion-plan-clase.component.html",
    styleUrls: ["./agregar-evaluacion-plan-clase.component.scss"]
})
export class AgregarEvaluacionPlanClaseComponent implements OnInit {
    @Input() asignaturaId: number
    @Input() evaluacionTipo: string
    evaluacionSumativa: boolean
    asignatura: Asignatura
    asignaturasById$ = this.store.select(selectAsignaturasById).pipe(filter(x => !!x))
    sub: Subscription
    tipoInstrumento: string
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    instrumento: Instrumento
    showInstrumento: boolean = false
    nombreEvaluacion: string
    @Output() saved = new EventEmitter<{ instrumento: Instrumento; nombre: string; sumativa: boolean }>()

    constructor(
        protected store: Store<State>,
        protected instrumentosService: Instrumentos,
        protected asignaturasService: Asignaturas
    ) {}

    ngOnInit() {
        this.tipoInstrumento = this.evaluacionTipo ? this.evaluacionTipo : config.plataforma.evaluacionDefault
        if (this.asignaturaId) {
            this.asignaturasService
                .find(this.asignaturaId, {
                    tipo_instrumento: this.tipoInstrumento,
                    generador_instrumento: { grupal: [0, 1] }
                })
                .then((asignatura: Asignatura) => {
                    this.asignatura = asignatura
                })
        }
    }

    cargarInstrumento(instrumentoTarget: number | Instrumento) {
        this.instrumento = null
        let targetId = typeof instrumentoTarget === "number" ? instrumentoTarget : instrumentoTarget.id

        this.instrumentosService.find(targetId, { with_generador_instrumento: 1 }).then((i: Instrumento) => {
            this.instrumento = i
        })
    }

    confirmar() {
        this.saved.emit({
            instrumento: this.instrumento,
            nombre: this.nombreEvaluacion,
            sumativa: this.evaluacionSumativa
        })
    }
}
