import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import {
    ContestableTipos,
    ContestableTipo,
    contestableTiposExplicacionKeys,
    contestableTiposNombreKeys
} from "@puntaje/nebulosa/api-services"
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "select-contestable-tipo",
    templateUrl: "./select-contestable-tipo.component.html",
    styleUrls: ["../subir-prueba-propia.component.scss"]
})
export class SelectContestableTipoComponent implements OnInit {
    @Input() step: number = 4
    @Output() changeData: EventEmitter<any> = new EventEmitter()
    contestableTipo: ContestableTipo = undefined
    contestableTipos: ContestableTipo[]
    contestableTiposExplicacion: any
    contestableTiposNombre: any
    validationSelects: boolean = false

    constructor(protected contestableTiposService: ContestableTipos, protected i18nService: I18nService) {}

    ngOnInit() {
        this.loadContestableTipos()
    }

    loadContestableTipos() {
        let tipos = ["Pregunta de alternativas", "Respuesta libre", "Respuesta archivo"]
        this.contestableTiposService.where({ contestable_tipo: { contestable_tipo: tipos } }).then(contestableTipos => {
            this.contestableTipos = contestableTipos

            // tipo inexistente para manejar el caso mixto
            const contestableMixto = new ContestableTipo()
            contestableMixto.contestable_tipo = "Mixto"

            this.contestableTipos.push(contestableMixto)
        })
        this.contestableTiposExplicacion = {
            "Pregunta de alternativas": this.i18nService.translate(
                contestableTiposExplicacionKeys["Pregunta de alternativas"],
                {}
            ),
            "Respuesta libre": this.i18nService.translate(contestableTiposExplicacionKeys["Respuesta libre"], {}),
            "Respuesta archivo": this.i18nService.translate(contestableTiposExplicacionKeys["Respuesta archivo"], {}),
            Mixto: this.i18nService.translate(contestableTiposExplicacionKeys["Mixto"], {})
        }

        this.contestableTiposNombre = {
            "Pregunta de alternativas": this.i18nService.translate(
                contestableTiposNombreKeys["Pregunta de alternativas"],
                {}
            ),
            "Respuesta libre": this.i18nService.translate(contestableTiposNombreKeys["Respuesta libre"], {}),
            "Respuesta archivo": this.i18nService.translate(contestableTiposNombreKeys["Respuesta archivo"], {}),
            Mixto: this.i18nService.translate(contestableTiposNombreKeys["Mixto"], {})
        }
    }

    sendData() {
        if (this.contestableTipo) {
            const calificacion_manual = this.contestableTipo.contestable_tipo != "Pregunta de alternativas"

            this.validationSelects = false
            const data = {
                contestableTipo: this.contestableTipo.id ? this.contestableTipo : null,
                calificacion_manual: calificacion_manual,
                showPauta: true,
                showClasificarPreguntas: calificacion_manual
            }
            this.changeData.emit(data)
        } else {
            this.validationSelects = true
        }
    }
}
