<div class="filtros">
    <loading-layout #loadingLayout>
        <h4>
            <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
            {{ "reportes.filtrar_datos" | t }}
        </h4>
        <filtro-periodo class="pull-right with-min-width" (select)="onSelectPeriodo($event)"></filtro-periodo>

        <div class="filtro-wrap">
            <div class="filtro">
                <label [attr.for]="establecimientos">{{ "reportes.avance_planes_adaptive.establecimiento" | t }}</label>
                <select
                    class="form-control push-down"
                    id="establecimientos"
                    (change)="onChangeEstablecimiento()"
                    [(ngModel)]="establecimiento"
                >
                    <option [ngValue]="undefined">
                        {{ "reportes.avance_planes_adaptive.seleccionar_establecimiento" | t }}
                    </option>
                    <option *ngFor="let e of establecimientos" [ngValue]="e">
                        {{ e.establecimiento }}
                    </option>
                </select>
            </div>
            <div class="filtro">
                <label [attr.for]="'grupos'">{{ "reportes.avance_planes_adaptive.curso" | t }}</label>
                <select class="form-control push-down" id="grupos" [(ngModel)]="grupoUsuario">
                    <option [ngValue]="undefined">{{ "reportes.resumen_uso.seleccionar_curso" | t }}</option>
                    <option *ngFor="let grupoUsuario of grupoUsuarios" [ngValue]="grupoUsuario">
                        {{ grupoUsuario.nombre }}
                    </option>
                </select>
            </div>
            <div class="filtro">
                <label>{{ "reportes.avance_planes_adaptive.asignatura" | t }}</label>
                <select class="form-control push-down" [(ngModel)]="asignatura" (ngModelChange)="onChangeAsignatura()">
                    <option [ngValue]="undefined">
                        {{ "reportes.avance_planes_adaptive.seleccionar_asignatura" | t }}
                    </option>
                    <option *ngFor="let a of asignaturas" [ngValue]="a">
                        {{ a.asignatura }}
                    </option>
                </select>
            </div>
            <div class="filtro">
                <label>{{ "reportes.avance_planes_adaptive.tipo" | t }}</label>
                <select class="form-control push-down" [(ngModel)]="generadorInstrumento">
                    <option [ngValue]="undefined">{{ "reportes.avance_planes_adaptive.seleccionar_tipo" | t }}</option>
                    <option *ngFor="let g of generadorInstrumentos" [ngValue]="g">
                        {{ g.generador_instrumento }}
                    </option>
                </select>
            </div>
        </div>
        <div class="bottom-options">
            <cui-button class="btn_style" (click)="downloadExcel()" [isLoading]="isDownloading">
                <span class="glyphicon glyphicon-download-alt" aria-hidden="true"></span>
                &nbsp;{{ "reportes.avance_planes_adaptive.buscar" | t }}
            </cui-button>
        </div>
    </loading-layout>
</div>
