<div class="options">
    <div class="filters">
        <div class="filter">
            <label>{{ 'noticias.profe.periodo' | t }}</label>
            <filtro-periodo (select)="onSelectPeriodo($event)" class="periodo"></filtro-periodo>
        </div>
        <div class="filter">
            <label>{{ 'noticias.profe.establecimiento' | t }}</label>
            <select
                class="form-control"
                id="establecimientos-filter"
                [(ngModel)]="establecimiento"
                (ngModelChange)="onSelectEstablecimiento()"
            >
                <option *ngIf="!disableTodosFiltro" [ngValue]="undefined" [selected]="true">
                    {{ 'noticias.profe.establecimiento_todos' | t }}
                </option>
                <option *ngFor="let establecimiento of establecimientos" [ngValue]="establecimiento" [selected]="true">
                    {{ establecimiento.establecimiento }}
                </option>
            </select>
        </div>
        <div class="filter">
            <label>
                {{ config.plataforma.grupoUsuarioAlias | capitalize }}
            </label>
            <select
                [(ngModel)]="grupoUsuario"
                (ngModelChange)="onSelectGrupoUsuario()"
                class="form-control"
                name="curso"
                [disabled]="!grupoUsuarios"
            >
                <option [ngValue]="undefined">{{ 'noticias.profe.grupo_usuarios_todos' | t }}</option>
                <option *ngFor="let gu of grupoUsuarios" [ngValue]="gu">
                    {{ gu.nombre }}
                </option>
            </select>
        </div>
    </div>
    <cui-button class="btn_style btn-publish" (click)="openPublishModal()">
        {{ 'noticias.profe.publicar' | t }}
        <fa name="plus-circle"></fa>
    </cui-button>
</div>
<loading-layout #loadingLayout>
    <noticia-profesor
        *ngFor="let noticia of noticias"
        [noticia]="noticia"
        (showNoticiaEvent)="showNoticia($event)"
        (showEditarEvent)="showEditarModal($event)"
        (showDeleteEvent)="deleteEvent($event)"
    ></noticia-profesor>
    <paginator [getList]="this.showAll.bind(this)"></paginator>
    <p *ngIf="noticias && noticias.length == 0">{{ 'noticias.profe.not_found' | t }}.</p>
</loading-layout>
<ver-noticia-modal #noticiaModal [currentNoticia]="currentNoticia"></ver-noticia-modal>

<!--Modal Nueva noticia-->
<ng-container>
    <generic-modal #publicarModal [buttonClicked]="openPublish">
        <modal-titulo>{{ 'noticias.profe.publicar' | t }}</modal-titulo>
        <modal-contenido class="modal-contenido">
            <div class="modal-contenido-wrap">
                <noticias-new
                    [fromProfesor]="true"
                    [noticias]="noticias"
                    (closeModal)="publicarModal.close()"
                ></noticias-new>
            </div>
        </modal-contenido>
    </generic-modal>
</ng-container>

<!--Modal editar noticia-->
<ng-container *ngIf="showEdit">
    <generic-modal #editarModal [buttonClicked]="openEdit" (onHide)="cancelar()">
        <modal-titulo>{{ 'noticias.profe.editar' | t }}</modal-titulo>
        <modal-contenido class="modal-contenido">
            <div class="modal-contenido-wrap">
                <noticias-edit
                    [fromProfesor]="true"
                    [noticiaId]="noticiaId"
                    (closeModal)="editarModal.close()"
                ></noticias-edit>
            </div>
        </modal-contenido>
    </generic-modal>
</ng-container>
