<cui-section-headline>{{ 'historial.eval_no_comp.title' | t }}</cui-section-headline>
<div class="filter-container">
    <h4 class="filter-title">
        <span class="glyphicon glyphicon-search icono"></span>
        {{ 'historial.eval_no_comp.sub_title' | t }}
    </h4>
    <div class="filter-wrap apart">
        <ng-container *ngIf="establecimientos?.length != 0 && usuariosUtp">
            <div class="filter" *ngIf="establecimientos?.length > 1">
                <select
                    [disabled]="establecimientos && establecimientos.length == 1"
                    [(ngModel)]="establecimiento"
                    (change)="reloadData()"
                    (change)="setPropietarios()"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- {{ 'historial.eval_no_comp.sel_est' | t }} --</option>
                    <option *ngFor="let e of establecimientos" [ngValue]="e">{{ e.establecimiento }}</option>
                </select>
            </div>
            <div class="filter" *ngIf="establecimiento">
                <select
                    [(ngModel)]="grupoUsuario"
                    (change)="reloadData()"
                    (change)="setPropietarios()"
                    class="form-control select-controller"
                >
                    <option [ngValue]="undefined">-- {{ 'historial.eval_no_comp.sel_curso' | t }} --</option>
                    <option *ngFor="let gu of establecimiento.grupo_usuarios" [ngValue]="gu">{{ gu.nombre }}</option>
                </select>
            </div>
            <div class="filter" *ngIf="establecimiento">
                <select [(ngModel)]="propietario" (change)="reloadData()" class="form-control select-controller">
                    <option [ngValue]="undefined">-- {{ 'historial.eval_no_comp.sel_doc' | t }} --</option>
                    <option *ngFor="let p of propietarios" [ngValue]="p">{{ p.nombreCompleto() }}</option>
                </select>
            </div>
        </ng-container>
        <div class="filter">
            <filtro-periodo class="periodo" (select)="onSelectPeriodo($event)"></filtro-periodo>
        </div>
    </div>
    <div class="filter-wrap" *ngIf="establecimientos?.length != 0 && usuariosUtp">
        <div class="filter">
            <simple-search-filter
                [elastic]="true"
                [placeholder]="'historial.eval_no_comp.sel_inst' | t"
                (updateSearch)="searchInstrumento($event)"
                (clearSearch)="clearInstrumento()"
            ></simple-search-filter>
        </div>
    </div>
</div>

<cui-table
    *ngIf="asignaturaId && evaluacionTipo"
    [verticalOnBreak]="600"
    [withVerticalLabels]="true"
    [paginatorGetList]="setData"
>
    <tr cui-table-header-row>
        <th class="resp-hide">ID</th>
        <th class="resp-hide">{{ 'historial.eval_no_comp.creador' | t }}</th>
        <th class="resp-hide">{{ 'historial.eval_no_comp.creacion' | t }}</th>
        <th class="actions resp-hide">{{ 'historial.eval_no_comp.acciones' | t }}</th>
    </tr>
    <tr cui-table-data-row *ngFor="let instrumento of instrumentos; let i = index">
        <td [attr.data-th]="'historial.eval_no_comp.inst_id' | t">
            {{ instrumento.id }}
        </td>
        <td [attr.data-th]="'historial.eval_no_comp.creador' | t">
            {{ instrumento.usuario.nombreCompleto() }}
        </td>
        <td [attr.data-th]="'historial.eval_no_comp.creacion' | t">
            {{ instrumento.created_at | date: "dd/MM/yyyy HH:mm" | easyplaceholder: "-" }}
        </td>
        <td class="actions">
            <a [routerLink]="['/instrumentos', instrumento.id]" class="btn btn-table" [attr.title]="'historial.eval_no_comp.ver_inst' | t">
                <span class="glyphicon glyphicon-eye-open" aria-hidden="true"></span>
            </a>
            <button
                type="button"
                (click)="compartirInstrumento(instrumento)"
                class="btn btn-table"
                [attr.title]="'historial.eval_no_comp.comp_inst' | t"
            >
                <span class="glyphicon glyphicon-share-alt" aria-hidden="true"></span>
            </button>
        </td>
    </tr>
    <cui-table-no-data>{{ 'historial.eval_no_comp.not_found' | t }}.</cui-table-no-data>
</cui-table>

<ng-container *ngIf="instrumento">
    <compartir-instrumento-profesor
        [instrumento]="instrumento"
        [modalId]="'compartir_no_compartido'"
        [openModal]="openModal"
        [tipoEvaluacion]="evaluacionTipo"
    ></compartir-instrumento-profesor>
</ng-container>
