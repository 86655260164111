<div class="clearfix" id="step1">
    <div class="row">
        <div class="col-md-5">
            <div class="content-f">
                <div class="tutorial">
                    <div class="container-tutorial-icon">
                        <div class="tutorial-icon-pos">
                            <ogr-icon name="prueba" align="top" class="icon"></ogr-icon>
                        </div>
                    </div>
                </div>
                <div class="content-text">
                    <h3 class="step">{{ 'evaluar.pasos.step' | t }} {{ step }}</h3>
                    <p class="tutorial-text">{{ 'evaluar.pasos.sel_eval_asig.message' | t }}</p>
                    <p class="tutorial-text-descripcion">
                        {{ 'evaluar.pasos.sel_eval_asig.desc' | t }}
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="contenedor_selects">
                <div class="elemento">{{ 'evaluar.pasos.sel_eval_asig.asignatura' | t }}:</div>
                <div class="elemento1">
                    <select class="form-control" [(ngModel)]="asignaturaId" (ngModelChange)="onChangeAsignatura()">
                        <option [ngValue]="undefined">-- {{ 'evaluar.pasos.sel_eval_asig.sel_asig' | t }} --</option>
                        <option
                            *ngFor="let asignatura of (asignaturasByEvaluacionTipo$ | async)[evaluacionTipo]"
                            [ngValue]="asignatura.id"
                        >
                            {{ asignatura.asignatura }}
                        </option>
                    </select>
                </div>
            </div>
            <div
                class="contenedor_selects"
                [hidden]="!generadorInstrumentos || generadorInstrumentos.length == 1"
                *ngIf="notAsignatura != undefined && notAsignatura"
            >
                <div class="elemento">{{ 'evaluar.pasos.sel_eval_asig.tipo' | t }}:</div>
                <div class="elemento1">
                    <generador-instrumento-select
                        [(generadorInstrumento)]="generadorInstrumento"
                        [generadorInstrumentos]="generadorInstrumentos"
                        (Change)="changeInstrumento(generadorInstrumento)"
                    ></generador-instrumento-select>
                </div>
            </div>
            <div class="contenedor_selects">
                <div class="elemento">{{ 'evaluar.pasos.sel_eval_asig.tiempo' | t }}:</div>
                <div class="elemento1">
                    <input [(ngModel)]="tiempo" type="number" class="form-control" />
                </div>
            </div>
            <div class="form-group">
                <div class="warning hide-warning" [class.show-warning]="validationSelects">
                    <div class="warning-content">
                        <fa name="warning" class="warning-icon"></fa>
                        <div class="warning-message">
                            <p>{{ 'evaluar.pasos.sel_eval_asig.missing' | t }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix">
                <cui-button class="btn_style pull-right" (click)="sendData()">
                    <ogr-icon name="ticket" align="text-top"></ogr-icon>
                    &nbsp;{{ 'evaluar.pasos.sel_eval_asig.button' | t }}
                </cui-button>
            </div>
        </div>
    </div>
</div>
