<div class="row">
    <div class="col-md-7">
        <h4 class="h4_sub_title_1">
            <fa name="arrow-right" class="highlight-chevron"></fa>
            &nbsp;{{ 'evaluar.buscador.buscar' | t }}
        </h4>
        <input type="text" [(ngModel)]="instrumentoId" class="form-control search-input" placeholder="{{ 'evaluar.buscador.placeholder' | t }}" />
        <cui-button type="button" class="btn_style btn-search" (click)="buscar(instrumentoId)">
            <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
            {{ 'evaluar.buscador.boton_buscar' | t }}
        </cui-button>
    </div>
</div>
<!--     Resultado Buscador   -->
<loading-layout [loading]="false" #loadingLayoutSearch>
    <div *ngIf="buscado" class="search-results">
        <h4 class="h4_sub_title_1">
            <fa name="arrow-right" class="highlight-chevron"></fa>
            &nbsp;{{ 'evaluar.buscador.resultados' | t }}
        </h4>
        <div *ngIf="instrumentoBuscado" class="search-result">
            <div class="search-result-row">
                <div class="search-result-cell">
                    <p class="nombreInstrumento">
                        <b>{{ 'evaluar.buscador.tabla.id' | t }}:</b>
                        {{ instrumentoBuscado.id }}
                    </p>
                    <p class="tipoInstrumento">
                        <b>{{ 'evaluar.buscador.tabla.tipo' | t }}:</b>
                        {{ instrumentoBuscado.generador_instrumento?.generador_instrumento }}
                    </p>
                    <p class="tiempoInstrumento">
                        <span class="option-icon-time"><fa [name]="'clock-o'"></fa></span>
                        {{ instrumentoBuscado.tiempo }} min.
                    </p>
                    <p class="preguntasInstrumento">
                        <span class="option-icon-quest">
                            <fa [name]="'pencil-square-o'"></fa>
                        </span>
                        {{ instrumentoBuscado.numero_preguntas }} {{ 'evaluar.buscador.tabla.preguntas' | t }}
                    </p>
                </div>
                <div class="search-result-cell al-right">
                    <cui-button
                        *ngIf="instrumentoBuscado"
                        type="button"
                        class="btn_style btn-select"
                        (click)="cargarInst(instrumentoBuscado)"
                    >
                        <ogr-icon [name]="'prueba'"></ogr-icon>
                        &nbsp;{{ 'evaluar.buscador.tabla.seleccionar' | t }}
                        <span class="hide-on-resp">{{ 'evaluar.buscador.tabla.instrumento' | t }}</span>
                    </cui-button>
                </div>
            </div>
            <p *ngIf="!instrumentoBuscado" class="no-results">{{ 'evaluar.buscador.tabla.not_found' | t }}</p>
        </div>
    </div>
</loading-layout>
