<div class="wrap_container" *ngIf="isExpandable">
    <div class="title_box" [toggleExpandable]="contenido">
        <div class="t-body">
            <div class="t-cell">
                <h2>{{ generadorInstrumento }}</h2>
            </div>
            <div class="t-cell title_count">
                <span>
                    <ogr-icon name="pdf" align="middle"></ogr-icon>
                    &nbsp;({{ totalInstrumentos }})
                </span>
            </div>
            <div class="t-cell title_icon">
                <span class="glyphicon glyphicon-minus-sign expanded-sign"></span>
                <span class="glyphicon glyphicon-plus-sign contracted-sign"></span>
            </div>
        </div>
    </div>
    <div expandableContent class="wrapped_content" #contenido>
        <div class="inner-margins_wrapped_content">
            <div class="content">
                <ng-container *ngTemplateOutlet="tableContent"></ng-container>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isExpandable">
    <ng-container *ngTemplateOutlet="tableContent"></ng-container>
</div>

<ng-template #tableContent>
    <loading-layout #loadingLayout>
        <table class="table tabla-instrumentos" [class.outside]="!isExpandable" [class.inside]="isExpandable">
            <thead>
                <tr>
                    <th colspan="2">{{ "evaluar.pruebas_propias.instrumento" | t }}</th>
                    <th>{{ "evaluar.pruebas_propias.tipo_contestables" | t }}</th>
                    <th>{{ "evaluar.pruebas_propias.fecha" | t }}</th>
                    <th>{{ "evaluar.pruebas_propias.acciones" | t }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let instrumento of instrumentos; let i = index" [class.odd]="i % 2 == 1">
                    <td class="file-icon-cell">
                        <div class="file-icon"><ogr-icon name="pdf"></ogr-icon></div>
                    </td>
                    <td>
                        <a [routerLink]="['/instrumentos', instrumento.id]">
                            {{ "evaluar.pruebas_propias.instrumento" | t }} #{{ instrumento.id }}
                            <span *ngIf="instrumento.material" class="nombre-material">{{ instrumento.material }}</span>
                        </a>
                    </td>
                    <td>
                        <span class="nombre-tipo-contestables">
                            {{
                                contestableTiposNombreKeys[instrumento.tipo_contestables || "Pregunta de alternativas"]
                                    | t
                            }}
                        </span>
                    </td>
                    <td>
                        <span>{{ instrumento.created_at | date: "dd/MM/yyyy HH:mm" }}</span>
                    </td>
                    <td>
                        <span class="btn-wrap">
                            <cui-button
                                type="button"
                                (click)="compartirInstrumento(instrumento)"
                                class="btn_style btn-small"
                                title="Compartir instrumento"
                            >
                                <span class="glyphicon glyphicon-share-alt" aria-hidden="true"></span>
                                &nbsp; {{ "evaluar.pruebas_propias.compartir" | t }}
                            </cui-button>
                            <cui-button-link
                                [routerLinkValue]="['/instrumentos', instrumento.id]"
                                class="btn_style btn-small"
                            >
                                {{ "evaluar.pruebas_propias.abrir" | t }}
                            </cui-button-link>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </loading-layout>
    <paginator [getList]="this.showAll.bind(this)"></paginator>
    <p class="no-cont" *ngIf="totalInstrumentos == 0">{{ "evaluar.pruebas_propias.not_found" | t }}.</p>
</ng-template>

<ng-container *ngIf="instrumento">
    <compartir-instrumento-profesor
        [instrumento]="instrumento"
        [modalId]="'compartir_no_compartido'"
        [openModal]="openModal"
        [tipoEvaluacion]="evaluacionTipo"
    ></compartir-instrumento-profesor>
</ng-container>
