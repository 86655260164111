import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "noticia.component.html"
})
export class NoticiaComponent implements OnInit {
    sub: Subscription
    noticiaId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.noticias.noticias.show")
        this.sub = this.route.params.subscribe(params => {
            this.noticiaId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
