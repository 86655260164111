<loading-layout #loadingLayout>
    <div class="clearfix" *ngIf="enableCreatePlan && !loadingLayout.loading">
        <a
            type="button"
            class="btn btn-default pull-right bottom-create btn-sm"
            [routerLink]="['/plan_clases/new']"
            [queryParams]="{ asignatura_id: asignaturaId, tipo_instrumento: tipoInstrumento }"
        >
            <fa name="plus"></fa>
            {{ 'estudiar.plan_de_clases.create' | t }}
        </a>
    </div>
    <div
        class="flex-container"
        *ngIf="!loadingLayout.loading"
        [class.col-3-ghost]="colNumber3"
        [class.col-4-ghost]="colNumber4"
    >
        <ng-container *ngFor="let planificacion of planificaciones">
            <plan-clase-box
                [planificacion]="planificacion"
                [class.col-number-1]="colNumber1"
                [class.col-number-2]="colNumber2"
                [class.col-number-3]="colNumber3"
                [hideProgress]="true"
                [enableEditPlan]="enableEditPlan"
            ></plan-clase-box>
        </ng-container>
        <div class="alert no-results" *ngIf="planificaciones.length == 0">{{ 'estudiar.plan_de_clases.not_found' | t }}</div>
    </div>
</loading-layout>
