<ll-tabs>
    <ul class="nav nav-tabs print-hide" #tabList>
        <li role="presentation" [ngClass]="{ in: currentTab == 'parcial', active: currentTab == 'parcial' }">
            <a data-toggle="tab" href="#parcial" (click)="setCurrentTab('parcial')">
                {{ "reportes.resumen_uso.titulo" | t }}
            </a>
        </li>
        <li role="presentation" *ngIf="enableInformeUso" [ngClass]="{ in: currentTab == 'anual', active: currentTab == 'anual' }">
            <a data-toggle="tab" href="#anual" (click)="setCurrentTab('anual')">
                {{ "reportes.informe_uso.titulo" | t }}
            </a>
        </li>
        <li role="presentation" [ngClass]="{ in: currentTab == 'evaluaciones', active: currentTab == 'evaluaciones' }">
            <a data-toggle="tab" href="#evaluaciones" (click)="setCurrentTab('evaluaciones')">
                {{ "reportes.resultados_evaluaciones.titulo" | t }}
            </a>
        </li>
        <li
            role="presentation"
            *ngIf="enableInformeAnual"
            [ngClass]="{ in: currentTab == 'informe', active: currentTab == 'informe' }"
        >
            <a data-toggle="tab" href="#informe" (click)="setCurrentTab('informe')">
                {{ "reportes.informe_anual.titulo" | t }}
            </a>
        </li>
        <li
            role="presentation"
            *ngIf="enableAvancePlanesAdaptive"
            [ngClass]="{ in: currentTab == '#planes-adaptive', active: currentTab == 'planes-adaptive' }"
        >
            <a data-toggle="tab" href="#planes-adaptive" (click)="setCurrentTab('planes-adaptive')">
                {{ "reportes.avance_planes_adaptive.titulo" | t }}
            </a>
        </li>
    </ul>
</ll-tabs>

<div class="tab-content">
    <div
        id="parcial"
        class="tab-pane fade print-hide"
        [ngClass]="{ in: currentTab == 'parcial', active: currentTab == 'parcial' }"
    >
        <resumen-utp></resumen-utp>
    </div>
    <div id="anual" class="tab-pane fade" [ngClass]="{ in: currentTab == 'anual', active: currentTab == 'anual' }">
        <resumen-utp-anual></resumen-utp-anual>
    </div>
    <div
        id="evaluaciones"
        class="tab-pane fade"
        [ngClass]="{ in: currentTab == 'evaluaciones', active: currentTab == 'evaluaciones' }"
    >
        <resultados-evaluaciones-utp></resultados-evaluaciones-utp>
    </div>
    <div
        id="informe"
        class="tab-pane fade"
        [ngClass]="{ in: currentTab == 'informe', active: currentTab == 'informe' }"
    >
        <informe-anual></informe-anual>
    </div>
    <div
        id="planes-adaptive"
        class="tab-pane fade"
        [ngClass]="{ in: currentTab == 'planes-adaptive', active: currentTab == 'planes-adaptive' }"
    >
        <avance-planes-adaptive></avance-planes-adaptive>
    </div>
</div>
