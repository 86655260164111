<div class="contenido-thumbnail">
    <thumbnail-material
        class="icono"
        *ngIf="materialTipos && planPersonalContenido.material"
        [material]="planPersonalContenido.material"
        [materialTipos]="materialTipos"
    ></thumbnail-material>
    <ogr-icon *ngIf="!planPersonalContenido.material" class="icono" name="hacer-prueba"></ogr-icon>
    <cui-button
        *ngIf="!planPersonalContenido.id && showbuttonDelete"
        class="btn_style btn-borrar"
        (click)="eliminarContenido(index)"
    >
        <fa name="trash-o"></fa>
    </cui-button>
    <cui-button
        *ngIf="planPersonalContenido.id && showbuttonDelete"
        class="btn_style btn-borrar"
        (click)="mostrarModalDeleteContenido(planPersonalContenido, index)"
    >
        <fa name="trash-o"></fa>
    </cui-button>
</div>
<div class="contenido-info">
    <div *ngIf="planPersonalContenido.material">
        <a (click)="materialPreviewModal.open(planPersonalContenido.material)">
            {{ planPersonalContenido.material.material }}
        </a>
    </div>

    <div *ngIf="planPersonalContenido.evaluacion">
        <a
            (click)="
                evaluacionPreviewModal.loadEvaluacion(planPersonalContenido.evaluacion); evaluacionPreviewModal.open()
            "
        >
            {{ planPersonalContenido.evaluacion.evaluacion }}
        </a>
    </div>
</div>

<material-preview-modal #materialPreviewModal modalId="modal-material-plan"></material-preview-modal>
<evaluacion-preview-modal #evaluacionPreviewModal modalId="modal-evaluacion-plan"></evaluacion-preview-modal>
