import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "terminos-condiciones",
    templateUrl: "./terminos-condiciones.component.html",
    styleUrls: ["../subir-prueba-propia.component.scss"]
})
export class TerminosCondicionesComponent implements OnInit {
    btnColor: string = "btn_style2"
    btnDisabled: boolean = true
    openModal: EventEmitter<any> = new EventEmitter<any>()
    companyName: string
    companySite: string
    numero_preguntas: number = 1

    @Input() askNumeroPreguntas: boolean = true
    @Input() step: number = 6
    @Output() changeData: EventEmitter<any> = new EventEmitter()

    constructor() {}

    ngOnInit() {
        this.companyName = config.plataforma.info.companyName
        this.companySite = config.plataforma.info.companySite
    }

    chengeBoton(e) {
        if (e.target.checked) {
            this.btnColor = "btn_style"
            this.btnDisabled = false
        } else {
            this.btnColor = "btn_style2"
            this.btnDisabled = true
        }
    }

    sendData() {
        const data = { accept: true, numero_preguntas: this.askNumeroPreguntas ? this.numero_preguntas : null }
        this.changeData.emit(data)
    }
}
