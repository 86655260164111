<div class="contentPrincipal">
    <cui-section-headline>Desde mi material</cui-section-headline>
    <filtro-clasificaciones-materiales
        class="filtros"
        [asignaturaId]="asignaturaId"
        [evaluacionTipo]="evaluacionTipo"
        [generadorInstrumento]="generadorInstrumento"
        [unselectedCurso]="true"
        (changeCurso)="onChangeCurso($event)"
        (changeClasificacionPrimerNivel)="onChangeClasificacionPrimerNivel($event)"
        (changeClasificacionSegundoNivel)="onChangeClasificacionSegundoNivel($event)"
    ></filtro-clasificaciones-materiales>
    <loading-layout>
        <materiales-lista
            *ngIf="materiales"
            (selected)="selectMaterial($event)"
            [materiales]="materiales"
        ></materiales-lista>
        <paginator [extraMiniMode]="true" [getList]="getData"></paginator>
    </loading-layout>
</div>
