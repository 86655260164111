import { Component, OnInit, Input } from "@angular/core"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription, combineLatest } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { select, Store } from "@ngrx/store"
import { filter } from "rxjs/operators"
import {
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    State,
    SetSelectedAsignaturaWithId
} from "@puntaje/puntaje/store"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-libro-notas",
    templateUrl: "./libro-notas.component.html"
})
export class LibroNotasComponent implements OnInit {
    asignaturaId: number
    asignatura: Asignatura
    tipoInstrumento: string
    generadorInstrumentos: GeneradorInstrumento[]

    sub: Subscription
    subQuery: Subscription
    subData: Subscription
    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    showTitleOverMenu = true
    disableReforzamiento = false

    constructor(
        protected titleService: TitleService,
        protected asignaturasService: Asignaturas,
        protected route: ActivatedRoute,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected authService: AuthService,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.libro_notas")
        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$).subscribe(
            ([asignatura, evaluacionTipo]) => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo
            }
        )

        this.subData = this.route.data.subscribe(data => {
            this.showTitleOverMenu = data.showTitleOverMenu ?? this.showTitleOverMenu
            this.disableReforzamiento = data.disableReforzamiento ?? this.disableReforzamiento
        })
    }

    ngOnDestroy() {
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
    }
}
