<loading-layout>
    <plan-clase-form
        *ngIf="planPersonal"
        [asignaturaId]="asignaturaId"
        [tipoEvaluacion]="evaluacionTipo"
        [planClase]="planPersonal"
        [urlRediret]="urlRediret"
        (save)="save($event)"
        [disableSave]="disableSave"
    ></plan-clase-form>
</loading-layout>
